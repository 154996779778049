import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ProductsCommercialFeatureService} from '../../../../../core/services/commercial/products-commercial-feature.service';
import {ProductCommercialFeature} from '../../../../../shared/models/commercial/product-commercial-feature.model';

@Component({
  selector: 'app-feature-commercial-detail',
  templateUrl: './feature-commercial-detail.component.html',
  styleUrls: ['./feature-commercial-detail.component.scss']
})
export class FeatureCommercialDetailComponent implements OnInit {

  feature: ProductCommercialFeature = new ProductCommercialFeature();
  uspLang: string = 'EN';

  constructor(private _route: ActivatedRoute, private _location: Location, private featuresService: ProductsCommercialFeatureService) { }

  ngOnInit() {
    const uuid = this._route.snapshot.params.id;

    this.featuresService.get(uuid).subscribe((data: any) => {
      this.feature = data.data;
    })
  }

  pictNotLoading(event) {
    event.target.src = 'assets/img/B-pro_21apr90.svg';
    event.target.style = 'height: 10vw; max-height: 10vw';
  }

  backClicked() {
    this._location.back();
  }

}
