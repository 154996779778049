import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Technologies } from 'src/app/shared/models/wheels/objectWheel/technologies';
import { TechnologiesService } from 'src/app/core/services/technologies.service';
import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';

@Component({
  selector: 'app-technologies-list',
  templateUrl: './technologies-list.component.html',
  styleUrls: ['./technologies-list.component.scss']
})
export class TechnologiesListComponent implements OnInit {

  technologies: Technologies[] = [];
  modal: NgbModalRef;
  modalDelete: NgbModalRef;
  searchText: string = '';
  uspLang: string = 'EN';

  constructor(private _router: Router,
    private _modal: NgbModal,
    private techService: TechnologiesService,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.techService.getTechAll().subscribe((data: any) => {
      this.technologies = data.data;
      //console.log(data.data);
    });
  }

  pictNotLoading(event) {
    event.target.src = 'assets/img/B-pro_21apr90.svg';
    event.target.style = 'width: 2vw: max-width: 2vw';
  }

  createTechnology() {
    this._router.navigate(['/dashboard/home/technologies/add']);
  }

  deleteButton(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, {backdrop: "static", keyboard: false});
    this.modal.componentInstance.messageTitle = 
    // "You will delete a technology.";
    this._translate.instant('technologies-list.messageTitle');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('technologies-list.messageDescription');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, {backdrop: "static", keyboard: false});
        this.techService.deleteTech(uuid)
          .subscribe(data => {
            this.modalClose();
            this.technologies.splice(index, 1);
            this.ngOnInit();
            //console.log(data)
          });
      } else {
        this.modal.close;
      }
    });
  }

  modalClose() {
    this.modalDelete.close();
  }
}
