import { Option } from "./option";
import { MultiselectAdapter } from "../../modules/home/components/forms/multiselect/multiselect-adapter";

export class PartnerModel {

	public uuid: string = "";
	public name: string = "";
	public managers: PartnerUserModel[] = [];
	public trainees: PartnerUserModel[] = [];
	public stockUsers: PartnerUserModel[] = []
	public mobileUsers: PartnerUserModel[] = []

	constructor(values: Partial<PartnerModel> = {}) {
		Object.assign(this, values);
	}
}

export class PartnerUserModel implements Option {
    public uuid: string = "";   
    public name: string = "";
    public surname: string = "";

    constructor(values: Partial<PartnerUserModel> = {}) {
        Object.assign(this, values);
    }

    getKey(): string {
        return this.uuid;
    }

    getLabel(): string {
        return this.name + " " + this.surname;
    }
    
    static defaultSort= (a, b) => a.getLabel().localeCompare(b.getLabel());

    static multiselectAdapter(): MultiselectAdapter<PartnerUserModel> {
        return {
            keySelect: c => c.uuid,
            render: c => c.name + " " + c.surname
        }
    }
}
