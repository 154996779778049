<div class="container-fluid">
	<form (ngSubmit)="edit(k)" #k="ngForm" method="POST" enctype="multipart/form-data">

		<div class="form-group mb-md-5">
			<div class="row form-group imageShare d-flex justify-content-center">
				<img class="mb-5" [src]="technologies.imageUrl" (error)="pictNotLoading($event)">
			</div>
			<div class="d-flex">
				<label>{{"technologies-edit.title" | translate}}</label>
				<div class="d-flex justify-content-end w-100">         
					<p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
						EN &nbsp; 
					</p>|
					<p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p> 
				</div>
			</div>
			<input *ngIf="uspLang=='EN'" [(ngModel)]="technologies.name.en" name="name.en" type="text" class="form-control form-control-lg"
				required>
			<input *ngIf="uspLang=='AR'" [(ngModel)]="technologies.name.ar" name="name.ar" type="text" class="form-control form-control-lg"
				required>

			<div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
				<label>{{"technologies-edit.chooseImageToUpload" | translate}}</label>
				<small>{{"technologies-edit.youCanOnlyUploadOneImage" | translate}}</small>
			</div>

			<div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped($event)">
				<input type="file" accept=".png, .jpg, .svg" (change)="fileBrowseHandler($event)" />
				<i class="fa fa-download fa-3x" aria-hidden="true"></i>
				<h3>{{"technologies-edit.dragAndDrop" | translate}}</h3>
				<p class="constraints-info">(.jpg, .png, .svg)</p>
			</div>

			<div class="image-preview" *ngIf="safePreviewUrl">
				<div class="d-md-flex justify-content-md-between align-items-md-center">
					<label>{{"technologies-edit.imagePreview" | translate}}</label>
					<button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
						<i class="fa fa-times-circle" aria-hidden="true"></i>
					</button>
				</div>
				<div class="imagePreview d-flex justify-content-md-center">
					<img [src]="safePreviewUrl" width="auto" height="300" />
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-2 buttonBack d-md-flex align-content-md-center">
				<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
			</div>
			<div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
				<button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
					<i class="fa fa-save mr-2"></i>{{"technologies-edit.save" | translate}}</button>
			</div>
			<div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center"
				[routerLink]="['/dashboard/home/technologies-commercial/detail', technologies.uuid]">
				<button type="button" class="btn btn-dark">
					<i class="fa fa-eye mr-2"></i>{{"technologies-edit.detail" | translate}}
				</button>
			</div>
		</div>
	</form>

	<div class="alert alert-danger" role="alert" *ngIf="errors.length">
		<div *ngFor="let error of errors">
			<h5>{{error}}</h5>
		</div>
	</div>

	<div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
		<h5>{{errorsm}}</h5>
	</div>
</div>