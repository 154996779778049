import {Component} from '@angular/core';
import {ModalService} from '../../../../core/services/modal.service';
import {MileageCommercialService} from '../../../../core/services/commercial/mileage-commercial.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-mileage-commercial',
    templateUrl: './mileage-commercial.component.html',
    styleUrls: ['./mileage-commercial.component.scss']
})
export class MileageCommercialComponent {
    file: any;

    constructor(
        private modalService: ModalService,
        private mileageService: MileageCommercialService,
        private router: Router,
    ) {
    }

    uploadFile() {
        if (!this.file) {
            this.modalService.showErrorModal('mileage-commercial.invalidFileTitle', 'mileage-commercial.invalidFileDescription');
            return;
        }

        const formData = new FormData();
        formData.append('file', this.file);
        const loadingModalRef = this.modalService.showLoadingModal()
        this.mileageService.uploadFile(formData).subscribe((res) => {
            loadingModalRef.close();
            this.modalService.showValidModal();
            this.router.navigateByUrl('home');
        }, () => {
            loadingModalRef.close();
            this.modalService.showErrorModal('mileage-commercial.invalidFileTitle', 'mileage-commercial.invalidFileDescription')
        });
    }

    fileBrowseHandler(files) {
        if (files.length === 1) {
            this.file = files[0]
        } else {
            this.modalService.showErrorModal('mileage-commercial.notSingleFileTitle', 'mileage-commercial.notSingleFileDescription');
        }
    }
}
