import {Injectable} from '@angular/core';
import {Generic} from '../../../shared/models/generic';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {LocalStorageKey, LocalStorageManager} from '../local-storage-manager';

@Injectable({
  providedIn: 'root'
})
export class MileageCommercialService {

  headers = new HttpHeaders({'Authorization': 'Bearer ' + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)});

  private API = environment.baseUrlCMS;

  constructor(private http: HttpClient) {
  }

  uploadFile(formData: FormData) {
    return this.http.post<Generic<string>>(this.API + '/commercial/mileage/import', formData, {headers: this.headers});
  }
}
