import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Cookie } from "ng2-cookies";

import { Generic } from "src/app/shared/models/generic";
import { environment } from "src/environments/environment";
import { PartnerModel, PartnerUserModel } from "../../shared/models/partner";
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
	providedIn: "root",
})
export class PartnersService {

	private API = environment.baseUrlCMS;

	private PARTNER = "/partner";

	headers = new HttpHeaders({
		Authorization: "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN),
	});

	constructor(private _http: HttpClient) { }

	getAllPartners(): Observable<Generic<PartnerModel[]>> {
		return this._http.get<Generic<PartnerModel[]>>(
			this.API + this.PARTNER
		);
	}

	getPartnersWithoutFileTypes(fileTypes: string[]): Observable<Generic<PartnerModel[]>> {
		return this._http.get<Generic<PartnerModel[]>>(
			this.API + this.PARTNER,
			{
				headers: this.headers,
				params: {
					withoutFileTypes: fileTypes
				}
			}
		);
	}

	getAllManagers(): Observable<Generic<PartnerUserModel[]>> {
		return this._http.get<Generic<PartnerUserModel[]>>(
			this.API + this.PARTNER + '/user/MANAGER'
		);
	}

	getAllTrainees(): Observable<Generic<PartnerUserModel[]>> {
		return this._http.get<Generic<PartnerUserModel[]>>(
			this.API + this.PARTNER + '/user/TRAINEE'
		);
	}

	getAllStockUsers(): Observable<Generic<PartnerUserModel[]>> {
		return this._http.get<Generic<PartnerUserModel[]>>(
			this.API + this.PARTNER + '/user/STOCK_USER'
		);
	}

	getAllMobileUsers(): Observable<Generic<PartnerUserModel[]>> {
		return this._http.get<Generic<PartnerUserModel[]>>(
			this.API + this.PARTNER + '/user/MOBILE_USER'
		);
	}

	getPartner(uuidItem: string): Observable<Generic<PartnerModel[]>> {
		return this._http.get<Generic<PartnerModel[]>>(
			this.API + this.PARTNER + "/" + uuidItem,
			{ headers: this.headers }
		);
	}

	createPartner(newPartner: any): Observable<Generic<PartnerModel[]>> {
		return this._http.post<Generic<PartnerModel[]>>(
			this.API + this.PARTNER, newPartner,
			{ headers: this.headers }
		);
	}

	updatePartner(uuidItem: string, editPartner: any): Observable<Generic<PartnerModel[]>> {
		return this._http.put<Generic<PartnerModel[]>>(
			this.API + this.PARTNER + "/" + uuidItem, editPartner,
			{ headers: this.headers }
		);
	}

	deletePartner(uuidItem: string) {
		return this._http.delete(this.API + this.PARTNER + "/" + uuidItem,
			{ headers: this.headers }
		);
	}
}
