import { Component, Inject } from '@angular/core';
import { COMMERCIAL_SHARE, ShareService } from 'src/app/core/services/share.service';

@Component({
  selector: 'app-share-commercial-detail',
  templateUrl: './share-commercial-detail.component.html',
  styleUrls: ['./share-commercial-detail.component.scss']
})
export class ShareCommercialDetailComponent {

  constructor(@Inject(COMMERCIAL_SHARE) readonly shareService: ShareService) { }

}
