

<div class="container-fluid">
    <div class="d-md-flex justify-content-end">
        <a class=" btn btn-dark btn-lg" role="button"
            href="assets/excel/Dynamic_Sheet_Sizes_Final_XLight.xlsx" download="Commercial_Products.xlsx"><i
                class="fa fa-cloud-download mr-1" aria-hidden="true"></i>
                {{"wheel-file.downloadExcel" | translate}}
        </a>
    </div>
    <label>{{"wheel-file.chooseFileToUpload" | translate}}</label>

    <form #fileForm="ngForm" method="POST" enctype="multipart/form-data">
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" accept=".xls, .xlsx" (change)="fileBrowseHandler($event.target.files)" />
            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
            <h3>{{"wheel-file.dragAndDropOrSelectExcelFileHere" | translate}}</h3>
            <p class="constraints-info">{{"wheel-file.type" | translate}}</p>
        </div>
        <div>
            <small>{{"wheel-file.subtitle" | translate}}</small>
        </div>
        <div class="files-list d-flex flex-column">
            <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="file-icon">
                    <img src="./assets/img/excelicon.ico" alt="Excel File">
                </div>
                <div class="info">
                    <h4 class="name">
                        {{ file?.name }}
                    </h4>
                    <p class="size">
                        {{ file?.size | formatBytes: 3 }}
                    </p>
                    <div class="delete justify-content-end">
                        <i class="fa fa-trash-o fa-2x" aria-hidden="true" (click)="deleteFile(i)"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="input-group-append d-flex justify-content-center">
            <button class="col-6 btn btn-dark btn-lg d-flex justify-content-center btnstatic" type="button" (click)="saveFile()">
                <i class="fa fa-floppy-o d-flex align-self-center mr-2" aria-hidden="true"></i>{{"wheel-file.saveStaticExcel" | translate}}
            </button>
        </div>
    </form>
    <div class="row mt-5">
        <div class="col-2 buttonBack d-md-flex align-items-md-center pl-2">
            <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels-commercial/list']"></i>
        </div>
    </div>
</div>
