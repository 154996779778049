import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LocalStorageKey, LocalStorageManager} from './local-storage-manager';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  private API = environment.baseUrlCMS;
  private SURVEY = '/survey';

  constructor(private http: HttpClient) {
  }

  getSurveysBlob(): Observable<Blob> {
    return this.http.get(
        this.API + this.SURVEY + '/export',
        {
          headers: this.headers,
          observe: 'body',
          responseType: 'blob'
        }
    );
  }
}
