import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-region",
	templateUrl: "./region.component.html",
	styleUrls: ["./region.component.scss"],
})
export class RegionComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
