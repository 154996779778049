import { UserModel } from 'src/app/shared/models/user';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Region } from "src/app/shared/models/regions/region";
import { UserRole } from 'src/app/shared/models/userRole';
import { RegionService } from "src/app/core/services/region.service";
import { UsersService } from "src/app/core/services/users.service";
import { Countries } from "src/app/shared/models/regions/objectRegion/countries";
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { Responsables } from 'src/app/shared/models/regions/objectRegion/responsables';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-region-edit",
  templateUrl: "./region-edit.component.html",
  styleUrls: ["./region-edit.component.scss"],
})
export class RegionEditComponent implements OnInit {

  editRegion: Region = new Region();
  userRole: UserRole = new UserRole();
  country: Array<Countries> = [];
  responsables: Array<Responsables> = [];
  modalLoad: NgbModalRef;
  users: Array<UserModel> = [];
  errors: any[] = [];
  id: string;
  uspLang: string = 'EN';

  responsableAdapter = Responsables.multiselectAdapter();

  constructor(private regionService: RegionService,
    private usersService: UsersService,
    private _location: Location,
    private _route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private translate: TranslateService) { }

  ngOnInit() {
    this.id = this._route.snapshot.params.id;

    this.regionService.getRegion(this.id).subscribe(data => {
      this.editRegion = data.data;
    });

    this.usersService.getAllUsers().subscribe(data => {
      this.users = data.data.filter(UserRole.isManager);
    });
  }

  edit(form: NgForm) {
    const editAux = {
      name: this.editRegion.name.en,
      arname: this.editRegion.name.ar,
      key: this.editRegion.key,
      responsables: this.editRegion.responsables.map(r => r.username)
    };

    if (form.valid) {
      this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });

      this.regionService.updateItem(this.id, editAux)
        .subscribe(data => {
          this.modalClose();
          this.router.navigateByUrl('home/region');
        }, err => {
          this.errors = this.translate.instant('region-edit.couldNotUpdate');
          console.error(err);
          this.modalClose();
        });
    }
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
