import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { ProductCommercialGroup } from "../../../../../shared/models/commercial/product-commercial-group.model";
import { ProductsCommercialService } from "../../../../../core/services/commercial/products-commercial.service";
import { DeletePopupComponent } from "../../../../../shared/components/popups/delete-popup/delete-popup.component";
import { CustomModalComponent } from "../../../../../shared/components/modals/custom-modal/custom-modal.component";

@Component({
  selector: 'app-wheel-list',
  templateUrl: './wheel-commercial-list.component.html',
  styleUrls: ['./wheel-commercial-list.component.scss']
})

export class WheelCommercialListComponent implements OnInit {

  wheels: ProductCommercialGroup[] = new Array(new ProductCommercialGroup());
  usp: Usp[];
  modal: NgbModalRef;
  modalDelete: NgbModalRef;
  searchText: string = '';
  uspLang: string = 'EN';

  constructor(private _router: Router,
    private _modal: NgbModal,
    private productService: ProductsCommercialService,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.productService.getProductGroups()
      .subscribe((data: any) => {
        this.wheels = data.data;
      });

      //For the cookie of editWheel
      if(localStorage.getItem("editWheel"))
      localStorage.removeItem("editWheel");
  }

  pictNotLoading(event) {
    event.target.src = 'assets/img/B-pro_21apr90_v2.svg';
    event.target.style = 'width: 2vw: max-width: 2vw';
  }

  pictNotLoadingLogo(event, i: number) {
    let idsiLogo = 'silogo' + i.toString();
    document.getElementById(idsiLogo).classList.add("d-none");

    let id = 'nologo' + i.toString();
    document.getElementById(id).classList.remove("d-none");
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/b_logo_black.png';
  }

  createWheel() {
    this._router.navigate(['/dashboard/home/wheels-commercial/add']);
  }

  readWheel() {
    this._router.navigate(['/dashboard/home/wheels-commercial/file']);
  }

  deleteButton(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modal.componentInstance.messageTitle = 
    // "You will delete a product.";
    this._translate.instant('wheel-list.messageTitle');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('wheel-list.messageDescription');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.productService.deleteProduct(uuid).subscribe(data => {
          this.modalClose();
          this.wheels.splice(index, 1);
          this.ngOnInit();
          //console.log(data)
        })
      }
    });
  }

  modalClose() {
    this.modalDelete.close();
  }
}
