<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-5">

        <div class="viewImage d-md-flex justify-content-center mb-5">
            <div class="viewImageCms d-md-flex mr-5">
                <div class="labelImageCms mr-2">
                    <label>{{"usp-detail.cmsImage" | translate}}:</label>
                </div>
                <div class="imageCms" style="background-color: #ffffff; width: 4vw">
                    <img [src]="detailUsp.cmsImgUrl" (error)="pictNotLoadingCms($event)">
                </div>
            </div>

            <div class="viewImageApp d-md-flex">
                <div class="labelImageApp mr-2">
                    <label>{{"usp-detail.appImage" | translate}}:</label>
                </div>
                <div class="imageApp" style="background-color: #000000; width: 4vw">
                    <img [src]="detailUsp.appImgUrl" (error)="pictNotLoadingApp($event)">
                </div>
            </div>
        </div>

        <div class="d-flex">
            <label>{{"usp-detail.name" | translate}}</label>
            <div class="d-flex justify-content-end w-100">
                <p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
                    EN &nbsp;
                </p>|
                <p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p>
            </div>
        </div>
        <input *ngIf="uspLang=='EN'" [value]="detailUsp.name.en" name="name" type="text" class="form-control form-control-lg" readonly>
        <input *ngIf="uspLang=='AR'" [value]="detailUsp.name.ar" name="arname" type="text" class="form-control form-control-lg" readonly>

        <!-- <label>Key</label>
        <input [value]="detailUsp.key" name="key" type="text" class="form-control form-control-lg" readonly> -->
    </div>

    <div class="row">
        <div class="col-2 buttonBack d-md-flex">
            <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
        </div>
        <div class="col-10 buttonEdit d-md-flex justify-content-md-end">
            <button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/usp/edit', uuid, wheelId]">
                <i class="fa fa-pencil mr-2"></i>{{"usp-detail.edit" | translate}}</button>
        </div>
    </div>
</div>