import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';

import { Attr } from 'src/app/shared/models/wheels/objectWheel/attr';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

@Component({
  selector: 'app-categories-add',
  templateUrl: './categories-add.component.html',
  styleUrls: ['./categories-add.component.scss']
})
export class CategoriesAddComponent implements OnInit {

  addCategory: Attr = new Attr();
  modalLoad: NgbModalRef;
  typeCategory: any = {
    type: 'SHARECATEGORY'
  };
  errors: string[] = [];
  errorsm: string;
  uspLang: string = 'EN';

  constructor(
    private _location: Location,
    private categoryService: CategoriesService,
    private _modal: NgbModal
  ) { }

  ngOnInit() {
  }

  //CATEGORY CREATE
  createCategory(form: NgForm) {

    let newCategory = {
      name: this.addCategory.name,
      arname: this.addCategory.arname,
      key: this.addCategory.key = this.addCategory.name.toUpperCase(),
      type: this.typeCategory.type
    };

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    if (this.modalLoad) {
      $(this.modalLoad).keydown(function (objEvent) {
        if (objEvent.keyCode == 9) {  //Tab pressed
          objEvent.preventDefault(); //Stops its action
        }
      });
    }
    this.categoryService.postAttrCreateCategory(newCategory)
      .subscribe(data => {
        this.modalClose();
        this._location.back();
      }, err => {
        if (err.error.errors)
          this.errors = err.error.errors;
        else
          this.errorsm = err.error.message;
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }

}
