import { Component, OnInit } from "@angular/core";
import { UsersService } from "src/app/core/services/users.service";
import { UserModel } from "src/app/shared/models/user";

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
	constructor(private usersService: UsersService) {}

	ngOnInit() {}
}
