import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { NotifyPopupComponent } from 'src/app/shared/components/popups/notify-popup/notify-popup.component';
import { ProductCommercialGroup } from "../../../../../shared/models/commercial/product-commercial-group.model";
import { ProductCommercialTechnology } from "../../../../../shared/models/commercial/product-commercial-technology.model";
import { ProductCommercialApplication } from "../../../../../shared/models/commercial/product-commercial-application.model";
import { ProductCommercialSubcategory } from "../../../../../shared/models/commercial/product-commercial-subcategory.model";
import { ProductCommercialAxle } from "../../../../../shared/models/commercial/product-commercial-axle.model";
import { ProductsCommercialApplicationService } from "../../../../../core/services/commercial/products-commercial-application.service";
import { ProductsCommercialSubcategoryService } from "../../../../../core/services/commercial/products-commercial-subcategory.service";
import { ProductsCommercialAxleService } from "../../../../../core/services/commercial/products-commercial-axle.service";
import { Translated } from "../../../../../shared/models/commercial/translated.model";
import { ProductsCommercialService } from "../../../../../core/services/commercial/products-commercial.service";
import { ProductsCommercialTechnologyService } from "../../../../../core/services/commercial/products-commercial-technology.service";


@Component({
  selector: 'app-wheel-add',
  templateUrl: './wheel-commercial-add.component.html',
  styleUrls: ['./wheel-commercial-add.component.scss']
})

export class WheelCommercialAddComponent implements OnInit {

  addProduct: ProductCommercialGroup = new ProductCommercialGroup();
  
  modalLoad: NgbModalRef;
  modalNotify: NgbModalRef;
  
  
  technologies: ProductCommercialTechnology[] = [];
  applications: ProductCommercialApplication[] = [];
  subcategories: ProductCommercialSubcategory[] = [];
  axles: ProductCommercialAxle[] = [];
  
  errors: string[] = [];
  errorsm: string;

  fileData: File = null;
  fileDataPreviewUrl: any = null;
  fileDataSafePreviewUrl: SafeUrl;
  fileTyre1Data: File = null;
  fileTyre1DataPreviewUrl: any = null;
  fileTyre1DataSafePreviewUrl: SafeUrl;
  fileTyre2Data: File = null;
  fileTyre2DataPreviewUrl: any = null;
  fileTyre2DataSafePreviewUrl: SafeUrl;
  fileTyre3Data: File = null;
  fileTyre3DataPreviewUrl: any = null;
  fileTyre3DataSafePreviewUrl: SafeUrl;
  
  uspLang: string = 'EN';

  constructor(
    private _router: Router,
    private productService: ProductsCommercialService,
    private applicationService: ProductsCommercialApplicationService,
    private subcategoryService: ProductsCommercialSubcategoryService,
    private axleService: ProductsCommercialAxleService,
    private technologyService: ProductsCommercialTechnologyService,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.loadApplications();
    this.loadSubcategories();
    this.loadAxles();
    this.loadTechnologies();
  }

  loadApplications() {
    this.applicationService.getAllApplications().subscribe((data: any) => {
      this.applications = data.data;
    });
  }

  loadSubcategories() {
    this.subcategoryService.getAllSubcategories().subscribe((data: any) => {
      this.subcategories = data.data;
    });
  }

  loadAxles() {
    this.axleService.getAllAxles().subscribe((data: any) => {
      this.axles = data.data;
    });
  }
  
  loadTechnologies() {
    this.technologyService.getAllTechnologies().subscribe((data: any) => {
      this.technologies = data.data;
    });
  }


  onMouseDownTechnology(event: MouseEvent, uuid: string, name: Translated) {
    event.preventDefault();

    if (!event.target['selected'] && (this.addProduct.technologies.length < 3)) {
      event.target['selected'] = !event.target['selected'];
      this.addProduct.technologies.push({ uuid: uuid, name: name, imageUrl: null });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.addProduct.technologies.forEach(technology => {
        if (technology.uuid == uuid) i = index;
        index++;
      });
      this.addProduct.technologies.splice(i, 1);
      if (index > -1) { }
    }
  }
  
  removeModel(i: number, key: string) {
    document.getElementById(key)['selected'] = false;
    this.addProduct.technologies.splice(i, 1);
  }

  onmousemove = function (event) {
    event.preventDefault();
  }

    //UPDATE IMAGE
    onFileDropped(type, $event) {
        this.prepareFilesList(type, $event);
    }

    fileBrowseHandler(type, file) {
        this.prepareFilesList(type, file);
    }

    deleteImageProduct(type) {
        switch (type) {
            case 'IMAGE':
                this.fileDataSafePreviewUrl = null;
                this.fileDataPreviewUrl = null;
                break;
            case 'TYRE1':
                this.fileTyre1DataSafePreviewUrl = null;
                this.fileTyre1DataPreviewUrl = null;
                break;
            case 'TYRE2':
                this.fileTyre2DataSafePreviewUrl = null;
                this.fileTyre2DataPreviewUrl = null;
                break;
            case 'TYRE3':
                this.fileTyre3DataSafePreviewUrl = null;
                this.fileTyre3DataPreviewUrl = null;
                break;
        }
    }

    prepareFilesList(type, fileInput: any) {
        switch (type) {
            case 'IMAGE':
                this.fileData = <File>fileInput.target.files[0];
                break;
            case 'TYRE1':
                this.fileTyre1Data = <File>fileInput.target.files[0];
                break;
            case 'TYRE2':
                this.fileTyre2Data = <File>fileInput.target.files[0];
                break;
            case 'TYRE3':
                this.fileTyre3Data = <File>fileInput.target.files[0];
                break;
        }
        this.preview(type);
    }

    preview(type) {

        let fd;

        switch (type) {
            case 'IMAGE':
                fd = this.fileData;
                break;
            case 'TYRE1':
                fd = this.fileTyre1Data;
                break;
            case 'TYRE2':
                fd = this.fileTyre2Data;
                break;
            case 'TYRE3':
                fd = this.fileTyre3Data;
                break;
        }

        let mimeType = fd.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(fd);
        reader.onload = (_event) => {
            this.updateUrls(type, reader.result);
        }
    }

    updateUrls(type, preview) {
        switch (type) {
            case 'IMAGE':
                this.fileDataPreviewUrl = preview;
                this.fileDataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE1':
                this.fileTyre1DataPreviewUrl = preview;
                this.fileTyre1DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE2':
                this.fileTyre2DataPreviewUrl = preview;
                this.fileTyre2DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE3':
                this.fileTyre3DataPreviewUrl = preview;
                this.fileTyre3DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
        }

    }

  createWheel(form: NgForm) {

    let technologies: string = '';


    this.addProduct.technologies.forEach(Technologies => {
      if (technologies == '')
        technologies = technologies + Technologies.uuid;
      else
        technologies = technologies + ',' + Technologies.uuid;
    });



      const formData = new FormData();
      formData.append('patternName', this.addProduct.patternName);
      formData.append('axle.uuid', this.addProduct.axle.uuid);
      formData.append('application.uuid', this.addProduct.application.uuid);
      formData.append('subcategory.uuid', this.addProduct.subcategory.uuid);
      formData.append('uspAr', this.addProduct.uspAr);
      formData.append('uspEn', this.addProduct.uspEn);
      formData.append('new', this.addProduct.new.toString());
      formData.append('performance.wear', this.addProduct.performance['wear'].toString());
      formData.append('performance.irregular', this.addProduct.performance['irregular'].toString());
      formData.append('performance.heat', this.addProduct.performance['heat'].toString());
      formData.append('performance.fuel', this.addProduct.performance['fuel'].toString());
      formData.append('performance.traction', this.addProduct.performance['traction'].toString());
      formData.append('performance.onOff', this.addProduct.performance['onOff'].toString());
      formData.append('performance.noise', this.addProduct.performance['noise'].toString());
      formData.append('performance.wet', this.addProduct.performance['wet'].toString());
      formData.append('iconUUIDs', technologies);


      if (!!this.fileData) {
          formData.append('image', this.fileData);
      }

      if (!!this.fileTyre1Data) {
          formData.append('tyreImage1', this.fileTyre1Data);
      }

      if (!!this.fileTyre2Data) {
          formData.append('tyreImage2', this.fileTyre2Data);
      }

      if (!!this.fileTyre3Data) {
          formData.append('tyreImage3', this.fileTyre3Data);
      }

      formData.append('quality', this.addProduct.quality);

      this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });

      this.productService.createProduct(formData)
        .subscribe(res => {
          this.modalClose();
          this._router.navigateByUrl('home/wheels-commercial/list')
        }, err => {
          if (err.error.errors)
            this.errors = err.error.errors;
          else
            this.errorsm = err.error.message;
        });
  }

    refreshSelectedTechnologies(){
        setTimeout(()=>{
            this.addProduct.technologies.forEach(technology => {
                document.getElementById(technology.uuid)['selected'] = true;
            });
        },100);
    
    }

  modalClose() {
    this.modalLoad.close();
  }
}
