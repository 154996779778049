export class ProductCommercialPerformance {

    private wear: number = 0;

    private irregular: number = 0;

    private heat: number = 0;

    private fuel: number = 0;

    private traction: number = 0;

    private onOff: number = 0;

    private noise: number = 0;

    private wet: number = 0;
    
}
