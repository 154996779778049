import { ProductCommercialPerformance } from "./product-commercial-performance.model";
import { ProductCommercialTechnology } from "./product-commercial-technology.model";
import { ProductCommercialApplication } from "./product-commercial-application.model";
import { ProductCommercialAxle } from "./product-commercial-axle.model";
import { ProductCommercialSubcategory } from "./product-commercial-subcategory.model";
import {ProductCommercialFeature} from './product-commercial-feature.model';

export class ProductCommercialGroup {

    public uuid: string = "";
    public patternName: string = "";
    public axle: ProductCommercialAxle = new ProductCommercialAxle();
    public application: ProductCommercialApplication = new ProductCommercialApplication();
    public subcategory: ProductCommercialSubcategory = new ProductCommercialSubcategory();
    public description: string = "";
    public imageUrl: string = "";
    public tyreImage1Url: string = "";
    public tyreImage2Url: string = "";
    public tyreImage3Url: string = "";
    public patternImageUrl: string = "";
    public uspAr: string = "";
    public uspEn: string = "";
    public new: boolean = false;
    public quality: string = "STANDARD";
    public createdAt: Date;
    public updatedAt: Date;
    public technologies: ProductCommercialTechnology[] = [];
    public features: ProductCommercialFeature[] = [];
    public performance: ProductCommercialPerformance = new ProductCommercialPerformance();
}
