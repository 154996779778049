<div class="container-fluid">
	<form (ngSubmit)="createUser(f)" #f="ngForm">
		<div class="form-group mb-md-5">
			<div class="form-group mb-md-5">
				<div class="d-md-flex justify-content-start">
					<div class="flex-row col-md-6">
						<label>{{"user-add.username" | translate}}</label>
						<input [(ngModel)]="addUser.username" name="username" type="text" class="form-control"
							required />
					</div>

					<div class="flex-row col-md-6">
						<label>{{"user-add.email" | translate}}</label>
						<input [(ngModel)]="addUser.email" name="email" type="email" class="form-control" required />
					</div>
				</div>

				<div class="d-md-flex justify-content-start">
					<div class="flex-row col-md-6">
						<label>{{"user-add.name" | translate}}</label>
						<input [(ngModel)]="addUser.name" name="name" type="text" class="form-control" required />
					</div>

					<div class="flex-row col-md-6">
						<label>{{"user-add.surname" | translate}}</label>
						<input [(ngModel)]="addUser.surname" name="surname" type="text" class="form-control" required />
					</div>
				</div>

				<div class="d-md-flex justify-content-between">
					<div class="flex-row col-md-6">
						<label>{{"user-add.role" | translate}}</label>
						<select [(ngModel)]="addUser.role" name="role" class="form-control form-control-lg"
							#formSelect="ngModel" *ngIf="cookieRole==adminRole" required>
							<option *ngFor="let Role of userRole.adminRoles" [value]="Role">
								{{Role}}</option>
						</select>

						<select [(ngModel)]="addUser.role" name="role" class="form-control form-control-lg"
							#formSelect="ngModel" *ngIf="cookieRole!=adminRole" required>
							<option *ngFor="let roles of userRole.roles" [value]="roles">
								{{roles}}</option>
						</select>
					</div>

					<div class="flex-row col-md-6">
						<label>{{"user-add.application" | translate}}</label>
						<select [(ngModel)]='addUser.application' name="application"
							class="form-control form-control-lg" required>
							<option *ngFor="let application of userApplications" [value]="application">{{application}}
							</option>
						</select>
					</div>
				</div>

				<div class="d-md-flex justify-content-between">
					<div class="flex-row col-md-6">
						<app-multiselect label="{{'user-add.countries' | translate}}" [availableOptions]="countries"
							[(value)]="selectedCountries" [adapter]="countryAdapter">
						</app-multiselect>
					</div>

					<div class="flex-row col-md-6">
						<app-multiselect label="{{'user-add.regions' | translate}}" [availableOptions]="regions"
							[(value)]="selectedRegions" [adapter]="regionAdapter">
						</app-multiselect>
					</div>
				</div>

				<div class="d-md-flex justify-content-end">
					<div class="flex-row col-md-6">
						<app-multiselect label="{{'user-add.regionsUploadAndShare' | translate}}" [availableOptions]="regionsUploadAndShare"
							[(value)]="selectedRegionsUploadAndShare" [adapter]="regionUploadAndShareAdapter">
						</app-multiselect>
					</div>
				</div>

				<div class="d-md-flex justify-content-start">
					<p></p>
				</div>
				<div class="d-md-flex justify-content-start">
						<div class="flex-row col-md-1">
							<div class="buttonSave">
								<button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
									{{"user-add.save" | translate}}</button>
							</div>
						</div>
						<div class="flex-row col-md-1">
							<div class="buttonCancel">
								<button type="button" class="btn btn-outline-danger"
								[routerLink]="['/dashboard/home/users/list']">
								{{"user-add.cancel" | translate}}</button>
							</div>
						</div>
					</div>
			</div>
		</div>
	</form>

	<div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
		<div *ngFor="let error of errors">
			<h5>{{ error }}</h5>
		</div>
	</div>

	<div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
		<h5>{{ errorsm }}</h5>
	</div>
</div>