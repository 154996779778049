<div class="container-fluid">
	<form (ngSubmit)="createPartner()" #f="ngForm">
		<div class="form-group mb-md-5">
			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-3">
					<label>{{"partner-add.name" | translate}}</label>
					<input [(ngModel)]="addPartner.name" name="name" type="text" class="form-control" required/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-3">
					<b>{{"partner-add.assessment" | translate}}</b>
					<br/>
					{{"partner-add.assessment-description" | translate}} <br/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6 managers">
					<app-multiselect [label]="'partner-add.managers' | translate" [availableOptions]="managers"
									 [(value)]="addPartner.managers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>

				<div class="flex-row col-md-6 trainees">
					<app-multiselect [label]="'partner-add.trainees' | translate" [availableOptions]="trainees"
									 [(value)]="addPartner.trainees" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>
			</div>

			<hr/>

			<div class="d-md-flex justify-content-between mt-5">
				<div class="flex-row col-md-6">
					<b>{{"partner-add.salesTools" | translate}}</b>
					<br/>
					{{"partner-add.salesTools-description" | translate}} <br/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6 managers">
					<app-multiselect [label]="'partner-add.stockList' | translate" [availableOptions]="stockUsers"
									 [(value)]="addPartner.stockUsers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>

				<div class="flex-row col-md-6 trainees">
					<app-multiselect [label]="'partner-add.mobileUsers' | translate" [availableOptions]="mobileUsers"
									 [(value)]="addPartner.mobileUsers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>
			</div>
			
			<br/>

			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-1">
					<div class="buttonSave">
						<button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
							{{"partner-add.save" | translate}}</button>
					</div>
				</div>
				<div class="flex-row col-md-1">
					<div class="buttonCancel">
						<button type="button" class="btn btn-outline-danger"
								[routerLink]="['/dashboard/home/partners/list']">
							{{"partner-add.cancel" | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
		<div *ngFor="let error of errors">
			<h5>{{ error }}</h5>
		</div>
	</div>
</div>
