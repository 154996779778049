import { Category } from './objectShare/category';
import { UserModel } from "../user";


export class ShareModel {

    uuid: string = "";
    title: string = "";
    imgUrl: string = "";
    region: string = "";
    category: Category;
    user: UserModel;
    country: string = "";
    status: string = "";
    description: string = "";

    constructor(values: Object = {}) {
        this.category = new Category();
        Object.assign(values, this);
    }
}
