import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterWheelCommercial'
})
export class WheelCommercialPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 2) return value;

    const resultWheels = [];
    for (const wheel of value) {
      if (wheel.patternName.toLowerCase().indexOf(arg.toLowerCase()) > -1) {

        resultWheels.push(wheel);
      };
    };
    return resultWheels;
  }

}
