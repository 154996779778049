export class Usp {

  uuid: string = "";
  name: any = "";
  arname: any = "";
  key: string = "";
  appImgUrl: string = "";
  cmsImgUrl: string = "";

  constructor(values: Object = {}) {
    Object.assign(values, this);
  }
}