import {Component, OnInit} from '@angular/core';
import {SalesToolsModel} from '../../../../../shared/models/salestools/sales-tools';
import {SalesToolsService} from '../../../../../core/services/sales-tools.service';

@Component({
  selector: 'app-catalogue-list',
  templateUrl: './catalogue-list.component.html',
  styleUrls: ['./catalogue-list.component.scss']
})
export class CatalogueListComponent implements OnInit {

  salesToolsItems: SalesToolsModel[]

  constructor(private salesToolsService: SalesToolsService) {
  }

  ngOnInit(): void {
    this.salesToolsService.getAllSalesTools()
        .subscribe(data =>
            this.salesToolsItems = data.data.filter(item => item.oeCatalogue || item.psrCatalogue || item.topVehicles)
        );
  }

}
