<div class="container-fluid">
    <div class="row d-md-flex justify-content-md-center align-items-md-baseline mb-5">
        <div class="col-8">
            <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                <input class="form-control" [(ngModel)]="paging.query" name="searchText" type="text"
                    placeholder='{{"assessment-list.searchAssessment" | translate}}'>
                <button (click)="findFilter()" class="btn btn-dark mr-1"><i class="fa fa-search" aria-hidden="true"></i></button>
                <button (click)="cleanFilter()" class="btn btn-dark"><i class="fa fa-broom" aria-hidden="true">
                    {{"assessment-list.clean" | translate}}</i></button>
            </div>
        </div>

        <div class="col-4 buttonDownload d-md-flex justify-content-md-end align-items-md-center pr-0">
			<button class="btn btn-outline-dark" (click)="downloadFile()">
				<i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i><span>
                    {{"assessment-list.downloadExcel" | translate}}</span>
            </button>
		</div>
    </div>

    <div class="row card mb-2 pr-0"
        *ngFor="let assessment of assessments | filterAssessment: searchText, let i = index">
        <div class="d-flex align-items-center bd-highlight flex-nowrap">
            <a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3"
                [routerLink]="['/dashboard/home/assessment/detail', assessment.uuid]">
                <div class="row w-100 flex-nowrap">
                    <div class="col-2">
                        <b>{{"assessment-list.assessor" | translate}}:</b> {{assessment.assessor}}
                    </div>
                    
                    <div class="col-2">
                        <b>{{"assessment-list.partner" | translate}}:</b>
                        {{assessment.partner}}
                    </div>
                    <div class="col-2">
                        <b>{{"assessment-list.dealer" | translate}}:</b>
                        {{assessment.dealer}}
                    </div>
                    <div class="col-2">
                        <b>{{"assessment-list.trainee" | translate}}:</b>
                        {{assessment.trainee}}
                    </div>
                    <div class="col-2">
                        <b>{{"assessment-list.status" | translate}}: </b>
                        <button class="badge badge-danger border-0" *ngIf="assessment.status=='pending'">{{assessment.status}} </button>
                        <button class="badge badge-secondary border-0" *ngIf="assessment.status=='reject'">{{assessment.status}} </button>
                        <button class="badge badge-success border-0" *ngIf="assessment.status=='accept'">{{assessment.status}} </button>
                    </div>
                    <div class="col-2">
                        <span><small><h6>{{"assessment-list.createdAt" | translate}}:</h6>{{assessment.createdAt | date: 'short'}}</small></span>
                    </div>
                       
                </div>
            </a>
            <div class="buttons mr-4">
                <button class="btn btn-outline-success mr-1" type="button" (click)="accept(assessment.uuid)"
                *ngIf="assessment.status == 'pending'"><i class="fa fa-check"
                    aria-hidden="true"></i></button>
            
            <button class="btn btn-outline-danger mr-1" type="button" (click)="reject(assessment.uuid)"
                *ngIf="assessment.status == 'pending'"><i class="fa fa-times"
                    aria-hidden="true"></i></button>
            <button class="btn btn-dark" type="button"
                [routerLink]="['/dashboard/home/assessment/detail', assessment.uuid]">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
            </div>
            
        </div>
    </div>

    <div class="row blockPagination d-md-flex justify-content-md-end align-items-md-center">
        <div class="blockPageNavigation">
          <nav aria-label="Page navigation">
            <ul class="pagination pagination-sm">
              <li class="page-item" *ngIf="filter.hasPreviousPage">
                <a class="page-link" (click)="pageDown()">{{"assessment-list.previous" | translate}}</a>
              </li>
              <li class="page-item" ><a class="page-link">{{"assessment-list.page" | translate}} {{this.filter.pageNumber}} {{"assessment-list.from" | translate}}
                  {{filter.totalNumberOfPages}}</a></li>
              <li class="page-item" *ngIf="filter.hasNextPage">
                <a class="page-link" (click)="pageUp()">{{"assessment-list.next" | translate}}</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      
</div>