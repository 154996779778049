<div class="container-fluid">
    <div class="row d-md-flex justify-content-md-center align-items-md-baseline">
        <div class="col-8">
            <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                <input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
                    placeholder='{{"technologies-list.searchTechnology" | translate}}'>
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
        </div>

        <div class="col-4 buttonAdd d-md-flex justify-content-md-end">
            <button class="btn btn-outline-dark" (click)="createTechnology()">
                <i class="fa fa-plus-circle mr-2"
                    aria-hidden="true"></i>{{"technologies-list.addTechnology" | translate}}
            </button>
        </div>
    </div>

    <div class="selectTranslate d-md-flex justify-content-md-end flex-nowrap ml-auto mb-5 mr-0 pr-0"> 
        <p class="font-weight-bold">
          Edition language:
        </p> 
        <div class="d-flex">
          <p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
            &nbsp; EN &nbsp; 
          </p>|
          <p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p> 
        </div>       
      </div>

    <div class="row card mb-2 pr-0"
        *ngFor="let technology of technologies | filterTechnologies: searchText, let i = index">
        <div class="col-12 d-inline-flex bd-highlight align-items-md-center">
            <div class="col-2 d-inline-flex bd-highlight imageWheel">
                <img class="mr-2" [src]="technology.image" (error)="pictNotLoading($event)" alt="IMG">
            </div>
            <div class="col-9">
                <a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3" *ngIf="uspLang=='EN'"
                    [routerLink]="['/dashboard/home/technologies/detail', technology.uuid]">
                    <span>{{i+1}}. <b>{{"technologies-list.title" | translate}}:</b> {{technology.title.en}}</span>
                </a>
                <a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3" *ngIf="uspLang=='AR'"
                    [routerLink]="['/dashboard/home/technologies/detail', technology.uuid]">
                    <span>{{i+1}}. <b>{{"technologies-list.title" | translate}}:</b> {{technology.title.ar}}</span>
                </a>
            </div>
                <div class="col-2 buttonAction d-md-flex justify-content-md-center align-items-md-end">
                    <button class="btn btn-secondary mr-1" type="button"
                        [routerLink]="['/dashboard/home/technologies/detail', technology.uuid]">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-dark mr-1" type="button"
                        [routerLink]="['/dashboard/home/technologies/edit', technology.uuid]">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-danger" type="button" (click)="deleteButton(technology.uuid, i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                </div>
                <!-- <button class="btn btn-dark mr-1" type="button"
                [routerLink]="['/dashboard/home/technologies/edit', technology.uuid]">
                <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button class="btn btn-danger" type="button" (click)="deleteButton(technology.uuid, i)">
                <i class="fa fa-trash" aria-hidden="true"></i>
            </button> -->
            
        </div>
    </div>
</div>