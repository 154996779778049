<div class="container-fluid">
    <div class="row justify-content-between">

        <div class="col-md-10">
            <div class="d-flex justify-content-start">
                <div class="mr-5">
                    <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
                </div>

                <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                    <input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
                           placeholder='{{"wheel-list.searchProductCommercial" | translate}}'>
                    <i class="fa fa-search mr-5" aria-hidden="true"></i>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="buttonAdd d-md-flex justify-content-md-end">
                <button class="btn btn-outline-dark mr-2 p-2" id="buttonAdd" (click)="createWheel()">
                    <i class="fa fa-plus-circle mr-2"
                        aria-hidden="true"></i><span>{{"wheel-list.addProduct" | translate}}</span>
                </button>

                <button class="btn btn-outline-dark p-2" id="buttonAdd" (click)="readWheel()">
                    <i class="fa fa-file-excel-o mr-2"
                        aria-hidden="true"></i><span>{{"wheel-list.addExcel" | translate}}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row card mb-3" *ngFor="let wheel of wheels | filterWheelCommercial: searchText, let i = index">

        <div class="col-12 d-inline-flex bd-highlight">
            <div class="col operationWheel d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='EN'">
                <span class="operationKey inline-block"><b>{{wheel.application?.name.en}}</b></span>
            </div>
            <div class="col operationWheel d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='AR'">
                <span class="operationKey inline-block"><b>{{wheel.application?.name.ar}}</b></span>
            </div>

            <div class="col-2 imageWheel d-md-flex justify-content-md-center">
                <img [src]="wheel.imageUrl" (error)="pictNotLoading($event)">
            </div>

            <div class="col infoWheel">
                <div class="logoSloganWhell d-md-flex flex-column">
                    <div class="yesLogo mb-3" *ngIf="wheel.cmsLogo!= ''" [id]="'silogo'+i">
                        <img [src]="wheel.cmsLogo" (error)="pictNotLoadingLogo($event,i)">
                    </div>
                    <div class="noLogo d-none" [id]="'nologo'+i">
                        <h3>{{wheel.patternName}}</h3>
                    </div>
                </div>

                <div class="uspWheel d-md-flex justify-content-md-between align-items-md-center">
                    <span class="d-md-flex justify-content-center align-items-md-center" *ngFor="let Usp of wheel.technologies">
                        <img [src]="Usp.imageUrl" (error)="pictNotLoadingCms($event)">{{uspLang=='AR' ? Usp.name.ar : Usp.name.en}}
                    </span>
                </div>
            </div>

            <div class="col-2 oerepWheel d-md-flex flex-column">
                <div class="buttonAction d-md-flex justify-content-md-center align-items-md-end">
                    <button class="btn btn-secondary mr-1" type="button"
                        [routerLink]="['/dashboard/home/wheels-commercial/detail', wheel.uuid]">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-dark mr-1" type="button"
                        [routerLink]="['/dashboard/home/wheels-commercial/edit', wheel.uuid]">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                    <button class="btn btn-danger" type="button" (click)="deleteButton(wheel.uuid, i)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>