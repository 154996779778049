import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { Wheel } from 'src/app/shared/models/wheels/wheel';
import { ProductsService } from 'src/app/core/services/products.service';

@Component({
  selector: 'app-wheel-detail',
  templateUrl: './wheel-detail.component.html',
  styleUrls: ['./wheel-detail.component.scss']
})

export class WheelDetailComponent implements OnInit {

  wheel: Wheel = new Wheel();
  uspLang: string = 'EN';
  safeUrl: SafeUrl;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private _location: Location,
    private pservice: ProductsService) { }

  ngOnInit() {
    let id = this._route.snapshot.params.id;

    this.pservice.getProductById(id)
      .subscribe((data: any) => {
        this.wheel = data.data;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wheel.modelRender);
      });
  }

  refreshSelectedUsp(){
    setTimeout(()=>{
      this.wheel.usp.forEach(usp => {
        document.getElementById(usp.key)['selected'] = true;
      });
    },100); 
  }

  pictNotLoadingProduct(event) {
    event.target.src = 'assets/img/B-pro_21apr90_v2.svg';
    event.target.style = 'width: 10vw; height: 10vw';
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  pictNotLoadingApp(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  // backClicked() {
  //   // this._location.back();
  //   this._router.navigateByUrl('home/wheels/list');
  // }
}
