import {Component, Input} from '@angular/core';
import {SalesToolsModel} from '../../../../../shared/models/salestools/sales-tools';

@Component({
  selector: 'app-catalogue-list-item',
  templateUrl: './catalogue-list-item.component.html',
  styleUrls: ['./catalogue-list-item.component.scss']
})
export class CatalogueListItemComponent {

  @Input()
  item: SalesToolsModel

}
