import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { Region } from "src/app/shared/models/regions/region";
import { RegionService } from "src/app/core/services/region.service";
import { UsersService } from "src/app/core/services/users.service";
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { Countries } from 'src/app/shared/models/regions/objectRegion/countries';
import { UserModel } from 'src/app/shared/models/user';
import {Responsables} from 'src/app/shared/models/regions/objectRegion/responsables';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-region-add",
  templateUrl: "./region-add.component.html",
  styleUrls: ["./region-add.component.scss"],
})
export class RegionAddComponent implements OnInit {

  addRegion: Region = new Region();
  modalLoad: NgbModalRef;
  country: Countries[] = [];
  users: UserModel[] = [];
  errors: string[] = [];
  errorsm: string;
  uspLang: string = 'EN';

  responsableAdapter = Responsables.multiselectAdapter();

  constructor(private regionService: RegionService,
    private usersService: UsersService,
    private router: Router,
    private _modal: NgbModal,
    private translate: TranslateService) { }

  ngOnInit() {
    this.usersService.getAllUsers()
      .subscribe(data => {
        this.users = data.data;
      });
  }

  createRegion(form: NgForm) {

    const newRegion = {
      name: this.addRegion.name,
      arname: this.addRegion.arname,
      key: this.addRegion.key = this.addRegion.name.toUpperCase(),
      responsables: this.addRegion.responsables.map(r => r.username)
    }

    this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});

    this.regionService.createRegion(newRegion)
      .subscribe(data => {
        console.log(data);
        this.errorsm = '';
        this.modalClose();
        this.router.navigateByUrl('home/region');
      }, err => {
        if (err.error.errors)
          this.errors = err.error.errors;
        else
          this.errorsm = err.error.message;
      });
  }

  modalClose() {
    this.modalLoad.close();
  }
}
