import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { BrandModel } from 'src/app/shared/models/brand';
import { BrandAModel } from 'src/app/shared/models/brandA';

@Injectable({
  providedIn: 'root'
})
export class OeService {

  private API = environment.baseUrlCMS;

  private OE = '/oe';

  constructor(private _http: HttpClient) {

  }

  getAllItems(): Observable<Generic<BrandModel[]>> {
    return this._http.get<Generic<BrandModel[]>>(this.API + this.OE);
  }

  getAllBrand(): Observable<Generic<BrandAModel[]>> {
    return this._http.get<Generic<BrandAModel[]>>(this.API + this.OE + '/aggregate');
  }

  deleteItem(uuidItem: string) {
    return this._http.delete(this.API + this.OE + '/' + uuidItem);
  }

  getItem(uuidItem: string): Observable<Generic<BrandModel[]>> {
    return this._http.get<Generic<BrandModel[]>>(this.API + this.OE + '/' + uuidItem);
  }

  updateItem(uuidItem: string, updateBrand: any): Observable<Generic<BrandModel>> {
    return this._http.put<Generic<BrandModel>>(this.API + this.OE + '/' + uuidItem, updateBrand);
  }

  createItem(createBrand: any): Observable<Generic<BrandModel>> {
    return this._http.post<Generic<BrandModel>>(this.API + this.OE, createBrand);
  }
}
