<div class="container-fluid">

	<div class="viewImageLogo d-md-flex mb-5">

		<div class="col-4 viewImageBrand d-md-flex">
			<div class="labelImageBrand">
				<label>{{"oe-detail-brands.brandLogo" | translate}}:</label>
			</div>
			<div class="imageBrand ml-5">
				<img [src]="brand.brandlogo" style="width: 5vw;" (error)="pictNotLoadingBrand($event)">
			</div>
		</div>
	</div>
	<div class="form-group d-md-flex flex-md-column mb-md-5">
		<div class="d-flex mb-5">
			<app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
		</div>
		<input class="form-control" *ngIf="uspLang=='EN'" name="name" type="text" [value]="brand.name.en" readonly>
		<input class="form-control" *ngIf="uspLang=='AR'" name="name" type="text" [value]="brand.name.ar" readonly>

		<label>{{"oe-detail-brands.brand" | translate}}</label>
		<input class="form-control" *ngIf="uspLang=='EN'" name="brand" type="text" [value]="brand.brand.en" readonly>
		<input class="form-control" *ngIf="uspLang=='AR'" name="brand" type="text" [value]="brand.brand.ar" readonly>
	</div>

	<div class="row">
		<div class="col-2 buttonBack">
			<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
		</div>
		<div class="col-10 buttonEdit d-md-flex justify-content-md-end">
			<button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/oe/edit', brand.uuid]">
				<i class="fa fa-pencil mr-2"></i>{{"oe-detail-brands.edit" | translate}}</button>
		</div>
	</div>
</div>