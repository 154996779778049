import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAssessment'
})
export class AssessmentPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 2) return value;

    const resultsAssessments = [];
    for (const assessment of value) {
      if (assessment.user.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultsAssessments.push(assessment);
      };
    };
    return resultsAssessments;
  }
}
