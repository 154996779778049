import { Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { ShareModel } from 'src/app/shared/models/share/share';
import { environment } from 'src/environments/environment';
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

export interface ShareServiceRoutes {
  sharesList: string,
  shareAdd: string,
  shareDetail: string,
  shareEdit: string
}

export class ShareService {
  private API = environment.baseUrlCMS;

  private accept = '/accept';
  private reject = '/reject';
  private attr = '/attr';

  headers = new HttpHeaders({
    Authorization: "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });


  constructor(private _http: HttpClient, private readonly SHARE: string, public readonly routes: ShareServiceRoutes) { }

  getAllComments(filter: any): Observable<Generic<ShareModel[]>> {

    let params = new HttpParams();

    if (filter.status != null)
      params = params.append('status', filter.status);
    if (filter.category != null)
      params = params.append('category', filter.category);
    if (filter.query != "")
      params = params.append('query', filter.query);

    params = params.append('size', filter.size.toString());
    params = params.append('page', filter.page.toString());

    return this._http.get<Generic<ShareModel[]>>(this.API + this.SHARE, { params: params });
  }

  getComment(uuidItem: string): Observable<Generic<ShareModel[]>> {
    return this._http.get<Generic<ShareModel[]>>(this.API + this.SHARE + "/" + uuidItem, { headers: this.headers });
  }

  createComment(formData: any): Observable<Generic<ShareModel[]>> {
    return this._http.post<Generic<ShareModel[]>>(this.API + this.SHARE, formData, { headers: this.headers });
  }

  getEditComment(uuidItem: string): Observable<Generic<ShareModel[]>> {
    return this._http.get<Generic<ShareModel[]>>(this.API + this.SHARE + '/' + uuidItem, { headers: this.headers });
  }

  updateItem(uuidItem: string, formData: any): Observable<Generic<ShareModel[]>> {
    return this._http.put<Generic<ShareModel[]>>(this.API + this.SHARE + '/' + uuidItem, formData, { headers: this.headers });
  }

  deleteComment(uuidItem: string) {
    return this._http.delete(this.API + this.SHARE + '/' + uuidItem, { headers: this.headers });
  }

  acceptUpdateShare(uuidItem: string, formData: any) {
    return this._http.put(this.API + this.SHARE + '/' + uuidItem + this.accept, formData, { headers: this.headers });
  }

  rejectUpdateShare(uuidItem: string) {
    return this._http.put(this.API + this.SHARE + '/' + uuidItem + this.reject, { headers: this.headers });
  }

  getExportBlob(): Observable<Blob> {
    return this._http.get(
        this.API + this.SHARE + '/export',
        {
          headers: this.headers,
          observe: 'body',
          responseType: 'blob'
        }
    );
  }
}

export const ConsumerShareFactory = () => new ShareService(
  inject(HttpClient),
  '/share',
  {
    sharesList: 'home/share',
    shareAdd: '/dashboard/home/share/add',
    shareDetail: '/dashboard/home/share/detail',
    shareEdit: '/dashboard/home/share/edit'
  });
export const CommercialShareFactory = () => new ShareService(
  inject(HttpClient),
  '/commercial/share',
  {
    sharesList: 'home/share/commercial',
    shareAdd: '/dashboard/home/share/commercial/add',
    shareDetail: '/dashboard/home/share/commercial/detail',
    shareEdit: '/dashboard/home/share/commercial/edit'
  }
);

export const CONSUMER_SHARE = new InjectionToken<ShareService>('ShareService.Consumer', {
  providedIn: 'root',
  factory: ConsumerShareFactory
});
export const COMMERCIAL_SHARE = new InjectionToken<ShareService>('ShareService.Commercial', {
  providedIn: 'root',
  factory: CommercialShareFactory
});