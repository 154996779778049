<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <h2 *ngIf="isAddMode">{{ 'catalogue-edit.addFile' | translate }}</h2>
        <h2 *ngIf="!isAddMode">{{ item?.partner.name}} - {{'catalogue-edit.fileEdit' | translate}}</h2>
        {{'catalogue-edit.uploadLatestFiles' | translate}}
    </div>
</div>

<form (ngSubmit)="edit()" #f="ngForm">
    <div class="d-flex flex-column col-4 mt-lg-5" *ngIf="isAddMode">
        <label for="partnerId">{{'catalogue-edit.partner' | translate}}</label>
        <select [(ngModel)]="partnerId" id="partnerId" name="partnerId"
                class="form-control form-control-lg"
                required>
            <option *ngFor="let partner of partners" [value]="partner.uuid">{{partner.name}}</option>
        </select>
    </div>
    <div class="d-flex flex-column mt-lg-5">
        <div class="flex-row col-4">
            <b>{{ 'catalogue-edit.topVehicles' | translate}}</b>
            <br/>
            <div class="form-control-file mt-3">
                <app-input-file [id]="'topVehiclesFile'" [(file)]="topVehiclesFile"
                                [fileTypes]="fileTypes"></app-input-file>
            </div>
            <ng-container *ngIf="!isAddMode && item?.topVehicles">
                {{ 'catalogue-edit.lastUpdate' | translate}} : {{item.topVehicles?.lastUpdate | date: 'short'}}
            </ng-container>
            <br/>
        </div>
        <div class="flex-row col-4 mt-lg-5">
            <b>{{ 'catalogue-edit.psrCatalogue' | translate}}</b>
            <br/>
            <div class="form-control-file mt-3">
                <app-input-file [id]="'psrCatalogueFile'" [(file)]="psrCatalogueFile"
                                [fileTypes]="fileTypes"></app-input-file>
            </div>
            <ng-container *ngIf="!isAddMode && item?.psrCatalogue">
                {{ 'catalogue-edit.lastUpdate' | translate}} : {{item.psrCatalogue?.lastUpdate | date: 'short'}}
            </ng-container>
            <br/>
        </div>
        <div class="flex-row col-4 mt-lg-5">
            <b>{{'catalogue-edit.oeCatalogue' | translate}}</b>
            <br/>
            <div class="form-control-file mt-3">
                <app-input-file [id]="'oeCatalogueFile'" [(file)]="oeCatalogueFile"
                                [fileTypes]="fileTypes"></app-input-file>
            </div>
            <ng-container *ngIf="!isAddMode && item?.oeCatalogue">
                {{ 'catalogue-edit.lastUpdate' | translate}} : {{item.oeCatalogue?.lastUpdate | date: 'short'}}
            </ng-container>
            <br/>
        </div>
    </div>
    <div class="d-md-flex justify-content-start  mt-lg-5">
        <div class="flex-row col-md-1">
            <div class="buttonSave">
                <button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
                    {{ 'catalogue-edit.save' | translate}}</button>
            </div>
        </div>
        <div class="flex-row col-md-1">
            <div class="buttonCancel">
                <button type="button" class="btn btn-outline-danger"
                        [routerLink]="['/dashboard/home/salestools/catalogue']">
                    {{ "catalogue-edit.cancel" | translate}}</button>
            </div>
        </div>
    </div>
</form>
<div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors">
    <h5>{{ "catalogue-edit.error" | translate }}</h5>
</div>

