import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-stock-upload-input',
  templateUrl: './stock-upload-input.component.html',
  styleUrls: ['./stock-upload-input.component.scss']
})
export class StockUploadInputComponent {

  fileTypes = ['.xlsx'];

  file?: File;

  @Output()
  fileChange = new EventEmitter<File>();

  @Input()
  lastChanged?: Date;

  @Input()
  message?: string;

  onFileUpload(file: File) {
    this.file = file;
    this.fileChange.emit(file);
  }
}
