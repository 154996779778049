import { Component, Inject } from '@angular/core';
import { COMMERCIAL_SHARE, ShareService } from 'src/app/core/services/share.service';

@Component({
  selector: 'app-share-commercial-edit',
  templateUrl: './share-commercial-edit.component.html',
  styleUrls: ['./share-commercial-edit.component.scss']
})
export class ShareCommercialEditComponent {

  constructor(@Inject(COMMERCIAL_SHARE) readonly shareService: ShareService) { }

}
