import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { UserModel } from "src/app/shared/models/user";
import { UsersService } from "src/app/core/services/users.service";
import { RegionService } from 'src/app/core/services/region.service';
import { Countries } from 'src/app/shared/models/regions/objectRegion/countries';
import { UserRole } from 'src/app/shared/models/userRole';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { Region } from 'src/app/shared/models/regions/region';
import { RegionUploadAndShare } from "src/app/shared/models/regions/regionUploadAndShare";
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/shared/models/application';
import { LocalStorageKey, LocalStorageManager } from "../../../../../core/services/local-storage-manager";

@Component({
  selector: "app-useradd",
  templateUrl: "./user-add.component.html",
  styleUrls: ["./user-add.component.scss"],
})
export class UserAddComponent implements OnInit {
  addUser: UserModel = new UserModel();
  userRole: UserRole = new UserRole();
  modalLoad: NgbModalRef;
  countries: Countries[] = [];
  regions: Region[] = [];
  regionsUploadAndShare: RegionUploadAndShare[] = [];

  errors: string[] = [];
  errorsm: string;
  cookieRole: string;
  userApplications: string[] = Object.values(Application);

  selectedRegions: Region[] = [];
  selectedRegionsUploadAndShare: RegionUploadAndShare[] = [];
  selectedCountries: Countries[] = [];

  regionAdapter = Region.multiselectAdapter(this.translate);
  countryAdapter = Countries.multiselectAdapter();
  regionUploadAndShareAdapter = RegionUploadAndShare.multiselectAdapter(
    this.translate
  );

  constructor(
    private userService: UsersService,
    private regionService: RegionService,
    private router: Router,
    private _modal: NgbModal,
    private translate: TranslateService
  ) {}

  get adminRole() {
    return UserRole.POWER_USER;
  }

  ngOnInit() {
    this.cookieRole = LocalStorageManager.get(LocalStorageKey.ROLE);
    this.regionService.getAllCountry().subscribe((data) => {
      this.countries = data.data;
    });

    this.regionService.getAllRegion().subscribe((data) => {
      this.regions = data.data || [];
    });

    this.regionService.getAllRegionUploadAndShare().subscribe((data) => {
      this.regionsUploadAndShare = data.data || [];
    });
  }

  createUser(form: NgForm) {
    const newUser = {
      name: this.addUser.name,
      surname: this.addUser.surname,
      email: this.addUser.email,
      username: this.addUser.username,
      role: this.addUser.role,
      partner: this.addUser.partner,
      regions: this.selectedRegions.map((region) => region.key),
      shareRegions: this.selectedRegionsUploadAndShare.map(
        (region) => region.key
      ),
      application: this.addUser.application,
      countries: this.selectedCountries.map((c) => c.key),
    };

    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });

    this.userService.createUser(newUser).subscribe(
      (data) => {
        this.errorsm = "";
        this.modalClose();
        this.router.navigateByUrl("home/users");
      },
      (err) => {
        console.log("Modal close");
        this.modalClose();

        if (err.error.errors) {
          this.errors = err.error.errors;
        } else {
          this.errorsm = err.error.message;
        }
      }
    );
  }

  modalClose() {
    this.modalLoad.close();
  }
}
