<div class="container-fluid">
    <div class="row justify-content-between">
        <div class="col-4">
            <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
        </div>
    
        <div class="col-4">
            <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                <input class="form-control" name="searchText" type="text"
                    placeholder='{{"categories-list.searchCategory" | translate}}' [(ngModel)]="searchText">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
        </div>
    
        <div class="col-4">
            <div class="buttonAdd d-md-flex justify-content-md-end align-items-md-center">
                <button class="btn btn-outline-dark" (click)="createCategory()">
                    <i class="fa fa-plus-circle mr-2"
                        aria-hidden="true"></i><span>{{"categories-list.addCategory" | translate}}</span>
                </button>
            </div>
        </div>
    </div>

    <div class="row card mb-2 pr-0"
        *ngFor="let category of listCategories | filterCategories: searchText, let i = index">
        <div class="d-flex align-items-center bd-highlight">
            <a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3"
                [routerLink]="['/dashboard/home/categories/detail', category.uuid]">
                <div class="row w-100">
                    <div class="col-9" *ngIf="uspLang=='EN'">
                        <span>{{i+1}}. </span>
                        <b>{{"categories-list.name" | translate}}:</b> {{category.name.en}}
                    </div>
                    <div class="col-9" *ngIf="uspLang=='AR'">
                        <span>{{i+1}}. </span>
                        <b>{{"categories-list.name" | translate}}:</b> {{category.name.ar}}
                    </div>
                </div>
            </a>
            <div class="buttons d-flex mr-4">
                <button class="btn btn-dark mr-1" type="button"
                    [routerLink]="['/dashboard/home/categories/edit', category.uuid]">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger" type="button" (click)="deleteCategory(category.uuid, i)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </div>

        </div>
    </div>

    <div class="row mt-5">
        <div class="col-2 buttonBack d-md-flex align-items-md-center pl-2">
            <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/share/list']"></i>
        </div>
    </div>
</div>