<div class="container-fluid">
        <form (ngSubmit)="edit(k)" #k="ngForm" method="POST" enctype="multipart/form-data">
    
            <div class="form-group mb-md-5">
                <div class="row form-group imageShare d-flex justify-content-center">
                    <img class="mb-5" [src]="editShare.imgUrl" (error)="pictNotLoading($event)">
                </div>
    
                <label>{{"share-edit.title" | translate}}</label>
                <input [(ngModel)]="editShare.title" name="title" type="text" class="form-control form-control-lg" required>
    
                <label>{{"share-edit.category" | translate}}</label>
                <select [(ngModel)]="editShare.category.key" name="category" class="form-control form-control-lg" required>
                    <option *ngFor="let Category of optionCategory" [value]="Category">
                        {{Category}}</option>
                </select>
    
                <div class="d-md-flex justify-content-md-center">
                    <button class="m-2 p-2 btn btn-outline-dark" [routerLink]="['/dashboard/home/categories/add']"
                        type="button">
                        <i class="fa fa-plus mr-1" aria-hidden="true"></i>{{"share-edit.addCategory" | translate}}
                    </button>
                </div>
    
                <label>{{"share-edit.description" | translate}}</label>
                <input [(ngModel)]="editShare.description" ngDefaultControl name="description" type="text"
                    class="form-control form-control-lg" required>
    
                <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                    <label>{{"share-edit.chooseImageToUpload" | translate}}</label>
                    <small>{{"share-edit.youCanOnlyUploadOneImage" | translate}}</small>
                </div>
    
                <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" accept=".png, .jpg, .svg" (change)="fileBrowseHandler($event)" />
                    <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                    <h3>{{"share-edit.dragAndDrop" | translate}}</h3>
                    <p class="constraints-info">(.jpg, .png, .svg)</p>
                </div>
    
    
                <div class="image-preview" *ngIf="safePreviewUrl">
                    <div class="d-md-flex justify-content-md-between align-items-md-center">
                        <label>{{"share-edit.imagePreview" | translate}}</label>
                        <button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
                            <i class="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                    <div class="imagePreview d-flex justify-content-md-center">
                        <img [src]="safePreviewUrl" width="auto" height="300" />
                    </div>
    
                </div>
    
            </div>
    
            <div class="row">
                <div class="col-2 buttonBack d-md-flex align-content-md-start align-items-md-center">
                    <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
                </div>
                <div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
                    <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                        <i class="fa fa-save mr-2"></i>{{"share-edit.save" | translate}}</button>
                </div>
                <div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center"
                    [routerLink]="[shareService.routes.shareDetail, editShare.uuid]">
                    <button type="button" class="btn btn-dark">
                        <i class="fa fa-eye mr-2"></i>{{"share-edit.detail" | translate}}
                    </button>
                </div>
            </div>
        </form>
    
        <div class="alert alert-danger" role="alert" *ngIf="errors.length">
            <div *ngFor="let error of errors">
                <h5>{{error}}</h5>
            </div>
        </div>
    
        <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
            <h5>{{errorsm}}</h5>
        </div>
    </div>