export class ProductRft {

    rft: string[] = [
        'false',
        'true'
    ];

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }
}