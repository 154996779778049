import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

import { UserModel } from "src/app/shared/models/user";
import { UsersService } from "src/app/core/services/users.service";
import { RegionService } from "src/app/core/services/region.service";
import { Countries } from 'src/app/shared/models/regions/objectRegion/countries';
import { UserRole } from 'src/app/shared/models/userRole';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { Region } from 'src/app/shared/models/regions/region';
import { RegionUploadAndShare } from "src/app/shared/models/regions/regionUploadAndShare";
import { TranslateService } from '@ngx-translate/core';
import { Application } from 'src/app/shared/models/application';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageKey, LocalStorageManager } from "../../../../../core/services/local-storage-manager";

@Component({
  selector: "app-useredit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.scss"],
})
export class UserEditComponent implements OnInit {
  editUser: UserModel = new UserModel();
  userRole: UserRole = new UserRole();
  userCountries: Countries[] = [];
  userRegions: Region[] = [];
  userRegionsUploadAndShare: RegionUploadAndShare[] = [];
  selectedRegions: Region[] = [];
  selectedRegionsUploadAndShare: RegionUploadAndShare[] = [];
  selectedCountries: Countries[] = [];
  userApplications: string[] = Object.values(Application);
  modalLoad: NgbModalRef;
  errors: any[];
  id: string;
  cookieRole: string;
  regionAdapter = Region.multiselectAdapter(this.translate);
  regionUploadAndShareAdapter = RegionUploadAndShare.multiselectAdapter(
    this.translate
  );
  countryAdapter = Countries.multiselectAdapter();

  constructor(
    private usersService: UsersService,
    private regionService: RegionService,
    private _route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private _modal: NgbModal,
    public translate: TranslateService
  ) {}

  get adminRole() {
    return UserRole.POWER_ADMIN;
  }

  ngOnInit() {
    this.cookieRole = LocalStorageManager.get(LocalStorageKey.ROLE);
    this.id = this._route.snapshot.params.id;

    forkJoin([
      this.usersService
        .getUser(this.id)
        .pipe(tap((data) => (this.editUser = data.data))),
      this.regionService
        .getAllCountry()
        .pipe(tap((data) => (this.userCountries = data.data))),
      this.regionService
        .getAllRegion()
        .pipe(tap((data) => (this.userRegions = data.data))),
      this.regionService
        .getAllRegionUploadAndShare()
        .pipe(tap((data) => (this.userRegionsUploadAndShare = data.data))),
    ]).subscribe(() => {
      this.selectedCountries = this.userCountries.filter((c) =>
        this.editUser.countries.includes(c.key)
      );
      this.selectedRegions = this.userRegions.filter((r) =>
        this.editUser.regions.includes(r.key)
      );
      this.selectedRegionsUploadAndShare =
        this.userRegionsUploadAndShare.filter((r) =>
          this.editUser.shareRegions.includes(r.key)
        );
    });
  }

  edit(form: NgForm) {
    const editAux = {
      name: this.editUser.name,
      surname: this.editUser.surname,
      username: this.editUser.username,
      email: this.editUser.email,
      role: this.editUser.role,
      countries: this.selectedCountries.map((c) => c.key),
      partner: this.editUser.partner,
      regions: this.selectedRegions.map((region) => region.key),
      shareRegions: this.selectedRegionsUploadAndShare.map(
        (region) => region.key
      ),
      application: this.editUser.application,
    };

    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });

    this.usersService.updateItem(this.id, editAux).subscribe(
      (data) => {
        this.modalClose();
        this.router.navigateByUrl("home/users");
      },
      (err) => {
        this.errors = err.error.errors;
        this.modalClose();
        console.error(err);
      }
    );
  }

  activate(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });

    this.usersService.activateUpdateUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
    });
  }

  deactivate(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });

    this.usersService.deactivateUpdateUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
    });
  }

  reset(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });

    this.usersService.resetUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
    });
  }

  //Modals
  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
