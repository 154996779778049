import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { ProductCommercialGroup } from '../../../shared/models/commercial/product-commercial-group.model';
import { ProductCommercialFeature } from '../../../shared/models/commercial/product-commercial-feature.model';
import { LocalStorageKey, LocalStorageManager } from "../local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class ProductsCommercialFeatureService {

  headers = new HttpHeaders({ "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) });

  private API = environment.baseUrlCMS;
  private FEATURE = "/commercial/product/feature";

  constructor(private http: HttpClient) { }

  get(uuid: string): Observable<Generic<ProductCommercialFeature>> {
    return this.http.get<Generic<ProductCommercialFeature>>(this.API + this.FEATURE + "/" + uuid, { headers: this.headers });
  }

  create(newFeature: any): Observable<Generic<ProductCommercialFeature>> {
    return this.http.post<Generic<ProductCommercialFeature>>(this.API + this.FEATURE, newFeature, { headers: this.headers });
  }

  update(uuid: string, technology: any): Observable<Generic<ProductCommercialFeature[]>> {
    return this.http.put<Generic<ProductCommercialFeature[]>>(this.API + this.FEATURE + "/" + uuid, technology, { headers: this.headers });
  }

  delete(uuid: string): Observable<Generic<ProductCommercialGroup[]>> {
    return this.http.delete<Generic<ProductCommercialGroup[]>>(this.API + this.FEATURE + "/" + uuid, { headers: this.headers });
  }
}
