import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { ProductCommercialGroup } from "../../../../../shared/models/commercial/product-commercial-group.model";
import { ProductsCommercialService } from "../../../../../core/services/commercial/products-commercial.service";

@Component({
  selector: 'app-wheel-detail',
  templateUrl: './wheel-commercial-detail.component.html',
  styleUrls: ['./wheel-commercial-detail.component.scss']
})

export class WheelCommercialDetailComponent implements OnInit {

  wheel: ProductCommercialGroup = new ProductCommercialGroup();
  uspLang: string = 'EN';
  safeUrl: SafeUrl;
  safeTyre1Url: SafeUrl;
  safeTyre2Url: SafeUrl;
  safeTyre3Url: SafeUrl;

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private sanitizer: DomSanitizer,
    private _location: Location,
    private productService: ProductsCommercialService) { }

  ngOnInit() {
    let id = this._route.snapshot.params.id;

    this.productService.getProductById(id)
      .subscribe((data: any) => {
        this.wheel = data.data;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wheel.imageUrl);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wheel.tyreImage1Url);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wheel.tyreImage2Url);
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.wheel.tyreImage3Url);
      });
  }

  pictNotLoadingProduct(event) {
    event.target.src = 'assets/img/B-pro_21apr90_v2.svg';
    event.target.style = 'width: 10vw; height: 10vw';
  }
}
