<div class="container-fluid">
	<div class="form-group d-md-flex flex-md-column mb-md-5">
		<div class="d-md-flex justify-content-md-end">
			<div>
				<button class="btn btn-outline-success" type="button" aria-hidden="true" (click)="activate(user.uuid)"
						*ngIf="user.status == 'inactive'">{{"user.activate" | translate}}</button>
				<button class="btn btn-outline-danger" type="button" aria-hidden="true" (click)="deactivate(user.uuid)"
						*ngIf="user.status == 'active'">{{"user.deactivate" | translate}}</button>
			</div>
			<div>
				<button class="btn btn-dark ml-2" type="button" aria-hidden="true"
						(click)="reset(user.uuid)">{{"user.reset"
						| translate}}</button>
			</div>
		</div>

		<div class="form-group mb-md-5">
			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-6">
					<label>{{"user.username" | translate}}</label>
					<input [(ngModel)]="user.username" name="username" type="text" class="form-control" readonly/>
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user.email" | translate}}</label>
					<input [(ngModel)]="user.email" name="email" type="email" class="form-control" readonly/>
				</div>
			</div>

			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-6">
					<label>{{"user.name" | translate}}</label>
					<input [(ngModel)]="user.name" name="name" type="text" class="form-control" readonly/>
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user.surname" | translate}}</label>
					<input [(ngModel)]="user.surname" name="surname" type="text" class="form-control" readonly/>
				</div>
			</div>
			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-6">
					<label>{{"user.role" | translate}}</label>
					<input [(ngModel)]="user.role" name="role" type="text" class="form-control" readonly/>
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user.application" | translate}}</label>
					<input [(ngModel)]="user.application" name="application" type="text" class="form-control" readonly/>
				</div>
			</div>
		</div>

		<div class="d-md-flex justify-content-between">
			<div class="flex-row col-md-6">
				<app-multiselect label="{{'user.countries' | translate}}" [availableOptions]="userCountries"
								 [(value)]="userCountries" [adapter]="countryAdapter" editable="false">
				</app-multiselect>
			</div>

			<div class="flex-row col-md-6">
				<app-multiselect label="{{'user.regions' | translate}}" [availableOptions]="userRegions"
								 [(value)]="userRegions" [adapter]="regionAdapter" editable="false">
				</app-multiselect>
			</div>
		</div>

		<div class="d-md-flex mt-4 justify-content-end">
			<div class="flex-row col-md-6">
				<app-multiselect label="{{'user.regionsUploadAndShare' | translate}}" [availableOptions]="userRegionsUploadAndShare"
								 [(value)]="userRegionsUploadAndShare" [adapter]="regionUploadAndShareAdapter" editable="false">
				</app-multiselect>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-2 buttonBack">
			<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
		</div>
		<div class="col-10 buttonEdit d-md-flex justify-content-md-end">
			<button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/users/edit', user.uuid]">
				<i class="fa fa-pencil mr-2"></i>{{"user.edit" | translate}}</button>
		</div>
	</div>
</div>
