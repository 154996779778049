<div class="container-fluid">
        <div class="row d-md-flex justify-content-md-between align-items-md-baseline">
            <div class="col-4 d-md-flex flex-grow-1 pl-0">
                <select class="form-control form-control-lg mr-1" type="text" [(ngModel)]="paging.category" *ngIf="uspLang=='EN'"
                    name="searchCategory">
                    <option value="">{{"share-list.selectCategory" | translate}}</option>
                    <option *ngFor="let filterCategories of optionCategory">
                        {{filterCategories.name.en}}</option>
                </select>
                <select class="form-control form-control-lg mr-1" type="text" [(ngModel)]="paging.category" *ngIf="uspLang=='AR'"
                    name="searchCategory">
                    <option value="">{{"share-list.selectCategory" | translate}}</option>
                    <option *ngFor="let filterCategories of optionCategory">
                        {{filterCategories.name.ar}}</option>
                </select>
    
                <select class="form-control form-control-lg" type="text" [(ngModel)]="paging.status" name="searchStatus">
                    <option value="">{{"share-list.selectStatus" | translate}}</option>
                    <option *ngFor="let filterStatus of optionStatus">
                        {{filterStatus}}</option>
                </select>
            </div>
    
            <div class="col-4 mx-auto form-group search d-md-flex justify-content-md-center align-items-md-baseline ml-5">
                <input class="form-control flex-grow-1" [(ngModel)]="paging.query" name="searchShare" type="text"
                    placeholder='{{"share-list.searchComment" | translate}}'>
                <button (click)="findFilter()" class="btn btn-dark mr-1"><i class="fa fa-search"
                        aria-hidden="true"></i></button>
                <button (click)="cleanFilter()" class="btn btn-dark"><i class="fa fa-broom"
                        aria-hidden="true">{{"share-list.clean" | translate}}</i></button>
            </div>
    
            <div class="col-4 d-md-flex justify-content-md-end pr-0">
                <button class="btn btn-outline-dark" [routerLink]="['/dashboard/home/categories/list']">
                    <i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>{{"share-list.category" | translate}}
                </button>
                &nbsp;
                <button class="btn btn-outline-dark" (click)="downloadFile()">
                    <i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i><span>
                    {{"share-list.downloadExcel" | translate}}</span>
                </button>
            </div>
    
            <div class="selectTranslate d-md-flex justify-content-md-end flex-nowrap ml-auto mb-5 mr-0 pr-0">
                <p class="font-weight-bold">
                    Edition language:
                </p>
                <div class="d-flex">
                    <p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
                        &nbsp; EN &nbsp;
                    </p>|
                    <p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p>
                </div>
            </div>
        </div>

        <div class="row card mb-2 pr-0"
            *ngFor="let Shares of share | filterAllShare: searchShare | shareCategory: searchCategory | shareStatus: searchStatus, let i = index">
            <div class="d-inline-flex bd-highlight">
                <div class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3">
                    <div class="row w-100 mh-100 highlight-let flex-nowrap">
                        <div class="col-2 text-wrap" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <b>{{"share-list.title" | translate}}:</b> {{Shares.title}}
                        </div>
                        <div class="col-2" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]" *ngIf="uspLang=='EN'">
                            <b>{{"share-list.category" | translate}}:</b> {{Shares.category.name.en}}
                        </div>
                        <div class="col-2" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]" *ngIf="uspLang=='AR'">
                            <b>{{"share-list.category" | translate}}:</b> {{Shares.category.name.ar}}
                        </div>
                        <div class="col-2" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <b>{{"share-list.region" | translate}}:</b> {{Shares.region}}
                        </div>
                        <div class="col-2" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <b>{{"share-list.status" | translate}}: </b>
                            <button class="badge badge-danger border-0" *ngIf="Shares.status=='pending'">{{Shares.status}}
                            </button>
                            <button class="badge badge-secondary border-0" *ngIf="Shares.status=='reject'">{{Shares.status}}
                            </button>
                            <button class="badge badge-success border-0" *ngIf="Shares.status=='accept'">{{Shares.status}}
                            </button>
                        </div>
                        <div class="col-1" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <h6>{{"share-list.username" | translate}}:</h6>
                            <small>{{Shares?.user.username}}</small>
                        </div>
                        <div class="col-1" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <h6>{{"share-list.country" | translate}}:</h6>
                            <small>{{Shares.country}}</small>
                        </div>
                        <div class="col-1" [routerLink]="[shareService.routes.shareDetail, Shares.uuid]">
                            <h6>{{"share-list.createdAt" | translate}}:</h6>
                            <small>{{Shares.createdAt|date:'short'}}</small>
                        </div>
                        <div class="col-1">
                            <div class="validateBtn text-right">
                                <button class="btn btn-outline-success mr-1" type="button"
                                    (click)="acceptShare(Shares.uuid)" *ngIf="Shares.status == 'pending'"><i
                                        class="fa fa-check" aria-hidden="true"></i></button>
                                <button class="btn btn-outline-danger mr-1" type="button" (click)="rejectShare(Shares.uuid)"
                                    *ngIf="Shares.status == 'pending'"><i class="fa fa-times"
                                        aria-hidden="true"></i></button>
    
                                <button class="btn btn-dark mr-1" type="button"
                                    [routerLink]="[shareService.routes.shareEdit, Shares.uuid]">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>
    
                                <button class="btn btn-danger" type="button" (click)="deleteComment(Shares.uuid, i)">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
    
    
    
            </div>
        </div>
    
        <div class="row blockPagination d-md-flex justify-content-md-end align-items-md-center">
            <div class="blockPageNavigation">
                <nav aria-label="Page navigation">
                    <ul class="pagination pagination-sm">
                        <li class="page-item" *ngIf="filterPaging.hasPreviousPage">
                            <a class="page-link" (click)="pageDown()">{{"share-list.previous" | translate}}</a>
                        </li>
                        <li class="page-item"><a class="page-link">{{"share-list.page" | translate}}
                                {{filterPaging.pageNumber}} {{"share-list.from" | translate}}
                                {{filterPaging.totalNumberOfPages}}</a></li>
                        <li class="page-item" *ngIf="filterPaging.hasNextPage">
                            <a class="page-link" (click)="pageUp()">{{"share-list.next" | translate}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
