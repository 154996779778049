import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterListLang'
})
export class FilterListLanguagesPipe implements PipeTransform {

  transform(items: any[], filter: string): any {
    if (!items || !filter) {
        return items;
    }

    return items.filter(group => group.id != filter);
  }

}
