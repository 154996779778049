import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

import { ShareModel } from 'src/app/shared/models/share/share';
import { ShareService } from 'src/app/core/services/share.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { CategoriesService } from 'src/app/core/services/categories.service';
import constants from 'src/app/core/constants';


@Component({
  selector: 'app-common-share-edit',
  templateUrl: './share-edit.component.html',
  styleUrls: ['./share-edit.component.scss']
})
export class ShareCommonEditComponent implements OnInit {

  editShare: ShareModel = new ShareModel();
  modalLoad: NgbModalRef;
  categories: any[] = [];
  allOptionCategory: any[] = [];
  optionCategory: any[] = [];
  errors: any[] = [];
  errorsm: string;
  id: string;
  fileData: File = null;
  previewUrl: any = null;
  safePreviewUrl: SafeUrl;

  @Input()
  shareService: ShareService;

  constructor(
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;

    this.shareService.getEditComment(this.id)
      .subscribe((data: any) => {
        this.editShare = data.data;
      });

    this.atrrDataCategory();
  }

  atrrDataCategory = () => {
    this.categoriesService.getAttrCategory().subscribe((data: any) => {
      this.categories = data.data;
      this.allOptionCategory = this.categories.map(c => c.key);
      this.optionCategory = Array.from(new Set(this.allOptionCategory));
    });
  }

  onFileDropped = ($event) => {
    this.prepareFilesList($event);
  }

  fileBrowseHandler = (file) => {
    this.prepareFilesList(file);
  }

  deleteImageProduct = () => {
    this.safePreviewUrl = null;
    this.previewUrl = null;
  }

  prepareFilesList = (fileInput: any) => {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview = () => {
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.safePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrl);
    }
  }

  edit = (form: NgForm) => {
    const formData = new FormData();
    formData.append('title', this.editShare.title);
    formData.append('category', this.editShare.category.key);
    formData.append('description', this.editShare.description);

    if (this.fileData) {
      formData.append('file', this.fileData);
    }

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });

    this.shareService.updateItem(this.id, formData)
      .subscribe(res => {
        this.modalClose();
        this.router.navigateByUrl(this.shareService.routes.sharesList);
      }, err => {
        if (err.error.errors) {
          this.errors = err.error.errors;
        } else {
          this.errorsm = err.error.message;
          const modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = this._translate.instant('share-edit.messageTitle');
          modal.componentInstance.messageDescription = this._translate.instant('share-edit.messageDescription');
          this.modalClose();
        }
      });

  }

  modalClose = () => {
    this.modalLoad.close();
  }

  pictNotLoading = (event) => {
    event.target.src = constants.imageUrl.noimage;
  }

  backClicked = () => {
    this._location.back();
  }
}
