<div class="container-fluid">
    <form (ngSubmit)="categoryEdit(k)" #k="ngForm">
        <div class="form-group d-md-flex flex-md-column mb-md-5">
            <div class="d-flex mb-5">
                <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
            </div>
            <input [(ngModel)]="editCategory.name[uspLang.toLowerCase()] " name="name" type="text" class="form-control form-control-lg">

            <div>
                <!-- <label>Key</label> -->
                <input [(ngModel)]="editCategory.key" name="key" type="hidden" class="form-control form-control-lg"
                    readonly>
            </div>

            <div>
                <!-- <label>Type</label> -->
                <input [(ngModel)]="typeCategory.type" name="type" type="hidden" class="form-control form-control-lg"
                    readonly>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack d-md-flex align-content-md-center">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    <i class="fa fa-save mr-2"></i>{{"categories-edit.save" | translate}}</button>
            </div>
            <div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center">
                <button type="button" class="btn btn-dark"
                    [routerLink]="['/dashboard/home/categories/detail', editCategory.uuid]">
                    <i class="fa fa-eye mr-2"></i>{{"categories-edit.detail" | translate}}
                </button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>
</div>