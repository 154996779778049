<div class="container-fluid">
    <form (ngSubmit)="createUsp(k)" #k="ngForm" method="POST" enctype="multipart/form-data">
        <div class="form-group d-md-flex flex-md-column mb-md-5">
            <div class="d-flex">
                <label>{{"usp-edit.name" | translate}}</label>
                <div class="d-flex justify-content-end w-100">         
					<p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
						EN &nbsp; 
					</p>|
					<p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p> 
				</div>
            </div>
            <input *ngIf="uspLang=='EN'" [(ngModel)]="addUsp.name" name="name" type="text" class="form-control form-control-lg" required>
            <input *ngIf="uspLang=='AR'" [(ngModel)]="addUsp.arname" name="arname" type="text" class="form-control form-control-lg" required>

            <!-- <div style="display: none;">
                <label>Key</label>
                <input [(ngModel)]="addTechnology.key" name="key" type="text" class="form-control form-control-lg" readonly>
            </div> -->

            <div class="updateImage d-md-flex flex-column">

                <div class="selectImage d-md-flex justify-content-md-between align-items-md-center"
                    style="width: 100%;">

                    <div class="selectImageCMS" style="width: 49%;">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label>{{"usp-add.cmsImageToUpload" | translate}}</label>
                            <small>{{"usp-add.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDroppedCms($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerCms($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"usp-add.dragAndDrop" | translate}}</h3>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="selectImageAPP" style="width: 49%;">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label>{{"usp-add.appImageToUpload" | translate}}</label>
                            <small>{{"usp-add.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDroppedApp($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerApp($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"usp-add.dragAndDrop" | translate}}</h3>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>
                </div>

                <div class="imagePreview2 d-md-flex justify-content-md-between align-items-md-center"
                    style="width: 100%;">

                    <div class="ImagePreviewCMS" style="width: 49%;">
                        <div class="image-preview" *ngIf="cmsPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"usp-add.cmsImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageCms()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="cmsPreviewUsp d-flex justify-content-md-center">
                                <img [src]="cmsPreviewUrl" />
                            </div>
                        </div>
                    </div>

                    <div class="ImagePreviewAPP" style="width: 49%;">
                        <div class="image-preview" *ngIf="appPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"usp-add.appImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageApp()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="appPreviewUsp d-flex justify-content-md-center">
                                <img [src]="appPreviewUrl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    <i class="fa fa-save mr-2"></i>{{"usp-add.save" | translate}}
                </button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>