import {Component, OnInit} from '@angular/core';
import {AssessmentCommercialService} from '../../../../../core/services/commercial/assessment-commercial.service';
import {AssessmentCommercial} from '../../../../../shared/models/commercial/assessment-commercial.model';
import {LoadingPopupComponent} from '../../../../../shared/components/popups/loading-popup/loading-popup.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileDownloadService} from '../../../services/file-download.service';
import {ErrorPopupComponent} from '../../../../../shared/components/popups/error-popup/error-popup.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-assessment-commercial-list',
  templateUrl: './assessment-commercial-list.component.html',
  styleUrls: ['./assessment-commercial-list.component.scss']
})
export class AssessmentCommercialListComponent implements OnInit {

  filter = {
    pageNumber: 1,
    sizeSize: 5,
    totalNumberOfRecords: null,
    totalNumberOfPages: null,
    hasPreviousPage: false,
    hasNextPage: false,
  }

  paging = {
    page: 0,
    size: 20,
    query: ''
  }
  assessments: AssessmentCommercial[];

  constructor(
      private assessmentCommercialService: AssessmentCommercialService,
      private modal: NgbModal,
      private fileDownload: FileDownloadService,
      private _translate: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.getAssessments();
  }

  readonly downloadFile = () => {
    const showErrorModal = () => {
      const modalError = this.modal.open(ErrorPopupComponent);
      modalError.componentInstance.messageTitle =
          this._translate.instant('assessment-commercial-list.excelDownloadError.title');
      modalError.componentInstance.messageDescription =
          this._translate.instant('assessment-commercial-list.excelDownloadError.description');
    };
    const modalLoading = this.modal.open(LoadingPopupComponent, {backdrop: 'static', keyboard: false});
    const fileName = `Assessment-${(new Date()).toDateString()}.xlsx`;
    this.assessmentCommercialService.getUsersAssessmentBlob()
        .subscribe(
            (value: Blob) => {
              modalLoading.close();
              this.fileDownload.downloadBlob(value, fileName);
            },
            (error: any) => {
              console.error('Error while trying to download Excel file', error);
              showErrorModal();
              modalLoading.close();
            }
        );
  }

  cleanFilter() {
    this.paging = {
      page: 0,
      size: 20,
      query: ''
    }
    this.getAssessments();
  }

  getAssessments() {
    this.assessmentCommercialService.getAssessment(this.paging)
        .subscribe((data: any) => {
          this.assessments = data.data;
          this.filter = data.paging;
          this.filter.pageNumber++;
        });
  }

  onItemDelete(uuid: string) {
    this.getAssessments()
  }

  pageDown() {
    if (this.filter.hasPreviousPage) {
      this.paging.page--;
      this.getAssessments();
    }
  }

  pageUp() {
    if (this.filter.hasNextPage) {
      this.paging.page++;
      this.getAssessments();
    }
  }
}
