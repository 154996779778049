import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { ProductCommercialAxle } from "../../../shared/models/commercial/product-commercial-axle.model";
import { LocalStorageKey, LocalStorageManager } from "../local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class ProductsCommercialAxleService {

  headers = new HttpHeaders({ "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) });

  private API = environment.baseUrlCMS;
  private WHEEL = "/commercial/product/axle";

  constructor(private http: HttpClient) { }

  getAllAxles(): Observable<Generic<ProductCommercialAxle[]>> {
    return this.http.get<Generic<ProductCommercialAxle[]>>(this.API + this.WHEEL, { headers: this.headers });
  }
}
