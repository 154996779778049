import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';

import { OeService } from 'src/app/core/services/oe.service';
import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { BrandAModel } from 'src/app/shared/models/brandA';


@Component({
  selector: 'app-oe-list-brands',
  templateUrl: './oe-list-brands.component.html',
  styleUrls: ['./oe-list-brands.component.scss']
})
export class OeListBrandsComponent implements OnInit {

  brands: BrandAModel[] = [];
  modalConfirm: NgbModalRef;
  modalDelete: NgbModalRef;
  searchText: string = '';
  uspLang: string = 'EN';

  arrayBrands: boolean[]=[];

  constructor(
    private router: Router,
    private oeService: OeService,
    private _modal: NgbModal,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.oeService.getAllBrand()
      .subscribe(data => {
        //console.log(data);
        this.brands = data.data;
        this.initializeArray();
      })
  }

  initializeArray(){
    this.brands.forEach(element => {
      this.arrayBrands.push(false);
    });
  }

  createOE() {
    this.router.navigate(["/dashboard/home/oe/add"]);
  }

  deleteItem(id: string, index: number) {
    this.modalConfirm = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modalConfirm.componentInstance.messageTitle = 
    // 'Are you sure you want to delete this item?';
    this._translate.instant('oe-list-brands.messageTitle');
    this.modalConfirm.componentInstance.messageDescription = 
    // 'This action cannot be';
    this._translate.instant('oe-list-brands.messageDescription');

    this.modalConfirm.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.oeService.deleteItem(id)

          .subscribe(data => {
            this.modalClose();
            this.brands.splice(index, 1);
            this.ngOnInit();
            //console.log(data);
          })
      }
    });
  }

  pictNotLoadingBrand(event) {
    event.target.src = 'assets/img/b_logo_black.png';
    event.target.style = 'height: 2vw; max-height: 2vw';
  }

  modalClose() {
    this.modalDelete.close();
  }
}
