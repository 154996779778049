import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Assessment } from 'src/app/shared/models/assessment';
import { AssessmentService } from 'src/app/core/services/assessment.service';

@Component({
  selector: 'app-assessment-detail',
  templateUrl: './assessment-detail.component.html',
  styleUrls: ['./assessment-detail.component.scss']
})
export class AssessmentDetailComponent implements OnInit {

  assessment: Assessment = new Assessment();
  id: string;

  constructor(
    private _route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private _translate: TranslateService
  ) {
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.ngOnInit();
    });
  }

  ngOnInit() {

    this.id = this._route.snapshot.params.id;
    let actualLanguage = this._translate.currentLang;
    this.assessmentService.getAssessmentById(this.id, actualLanguage.toUpperCase()).subscribe((data: any) => {
      this.assessment = data.data;
      //console.log(data.data);
    });
  }
}
