import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

import { Generic } from "src/app/shared/models/generic";
import { Region } from "src/app/shared/models/regions/region";
import { RegionUploadAndShare } from "src/app/shared/models/regions/regionUploadAndShare";
import { environment } from "src/environments/environment";
import { Countries } from 'src/app/shared/models/regions/objectRegion/countries';

@Injectable({
  providedIn: "root",
})
export class RegionService {
  private API = environment.baseUrlCMS;

  private REGION = "/region";
  private COUNTRY = "/country";
  private REGION_UPLOAD_AND_SHARE = "/share-region";

  headers = new HttpHeaders({ "Content-type": "application/json" });

  constructor(private http: HttpClient) {}

  getAllRegion(): Observable<Generic<Region[]>> {
    return this.http.get<Generic<Region[]>>(this.API + this.REGION, {
      headers: this.headers,
    });
  }

  getAllRegionUploadAndShare(): Observable<Generic<RegionUploadAndShare[]>> {
    return this.http.get<Generic<RegionUploadAndShare[]>>(
      this.API + this.REGION_UPLOAD_AND_SHARE,
      {
        headers: this.headers,
      }
    );
  }

  getRegion(uuidItem: string): Observable<Generic<Region>> {
    return this.http.get<Generic<Region>>(
      this.API + this.REGION + "/" + uuidItem,
      { headers: this.headers }
    );
  }

  createRegion(newRegion: any): Observable<Generic<Region[]>> {
    return this.http.post<Generic<Region[]>>(
      this.API + this.REGION,
      newRegion,
      { headers: this.headers }
    );
  }

  updateItem(uuidItem: string, region: any): Observable<Generic<Region[]>> {
    return this.http.put<Generic<Region[]>>(
      this.API + this.REGION + "/" + uuidItem,
      region,
      { headers: this.headers }
    );
  }

  deleteRegion(uuidItem: string) {
    return this.http.delete(this.API + this.REGION + "/" + uuidItem, {
      headers: this.headers,
    });
  }

  getAllCountry(): Observable<Generic<Countries[]>> {
    return this.http.get<Generic<Countries[]>>(this.API + this.COUNTRY, {
      headers: this.headers,
    });
  }
}
