import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { Cookie } from 'ng2-cookies';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { UspService } from 'src/app/core/services/usp.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

@Component({
  selector: 'app-usp-add',
  templateUrl: './usp-add.component.html',
  styleUrls: ['./usp-add.component.scss']
})
export class UspAddComponent implements OnInit {

  addUsp: Usp = new Usp();
  modalLoad: NgbModalRef;
  errors: string[] = [];
  errorsm: string;

  fileDataCms: File = null;
  fileDataApp: File = null;
  previewUrlCms: any = null;
  previewUrlApp: any = null;
  cmsPreviewUrl: SafeUrl;
  appPreviewUrl: SafeUrl;
  uspLang: string = 'EN';

  constructor(
    private uspService: UspService,
    private _location: Location,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() { }

  //UPDATE CMS IMAGE
  //On file drop handler
  onFileDroppedCms($event) {
    this.prepareFilesListCms($event);
  }

  //Handle file from browsing
  fileBrowseHandlerCms($event) {
    this.prepareFilesListCms($event);
  }

  //Delete Image
  deleteImageCms() {
    this.cmsPreviewUrl = null;
    this.previewUrlCms = null;
  }

  prepareFilesListCms(fileInput: any) {
    this.fileDataCms = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    let mimeTypeCms = this.fileDataCms.type;
    if (mimeTypeCms.match(/image\/*/) == null) {
      return;
    }

    let readerCms = new FileReader();
    readerCms.readAsDataURL(this.fileDataCms);
    readerCms.onload = (_event) => {
      this.previewUrlCms = readerCms.result;
      this.cmsPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlCms);
    }
  }


  //UPDATE APP IMAGE
  //On file drop handler
  onFileDroppedApp($event) {
    this.prepareFilesListApp($event);
  }

  //Handle file from browsing
  fileBrowseHandlerApp($event) {
    this.prepareFilesListApp($event);
  }

  //Delete Image
  deleteImageApp() {
    this.appPreviewUrl = null;
    this.previewUrlApp = null;
  }

  prepareFilesListApp(fileInput: any) {
    this.fileDataApp = <File>fileInput.target.files[0];
    this.previewApp();
  }

  previewApp() {
    let mimeTypeApp = this.fileDataApp.type;
    if (mimeTypeApp.match(/image\/*/) == null) {
      return;
    }

    let readerApp = new FileReader();
    readerApp.readAsDataURL(this.fileDataApp);
    readerApp.onload = (_event) => {
      this.previewUrlApp = readerApp.result;
      this.appPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlApp);
    }
  }


  //CREATE USP
  createUsp(form: NgForm) {

    const formData = new FormData();
    formData.append('name', this.addUsp.name);
    formData.append('arname', this.addUsp.arname);
    formData.append('key', this.addUsp.key = this.addUsp.name.toUpperCase());
    formData.append('cmsfile', this.fileDataCms);
    formData.append('appfile', this.fileDataApp);

    let newUsp = {
      name: this.addUsp.name,
      arname: this.addUsp.arname,
      key: this.addUsp.key = this.addUsp.name.toUpperCase()
    };

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
		// 	$(document).keydown(function(objEvent) {
		// 		if (objEvent.keyCode == 9) {  //Tab pressed
		// 			objEvent.preventDefault(); //Stops its action
		// 		}
		// 	});
		// }
    this.uspService.createUsp(formData)
      .subscribe(res => {
        this.modalClose();
        Cookie.set("usp_key", newUsp.key);
        this._location.back();
      }, err => {
        if (err.error.errors)
          this.errors = err.error.errors;
        else
          this.errorsm = err.error.message;

      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
