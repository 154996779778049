import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { UsersComponent } from './pages/users/users.component';
import { HomeComponent } from './pages/home/home.component';
import { WheelsComponent } from './pages/wheels/wheels.component';
import { RegionComponent } from './pages/region/region.component';
import { WheelListComponent } from './pages/wheels/wheel-list/wheel-list.component';
import { WheelAddComponent } from './pages/wheels/wheel-add/wheel-add.component';
import { WheelDetailComponent } from './pages/wheels/wheel-detail/wheel-detail.component';
import { WheelEditComponent } from './pages/wheels/wheel-edit/wheel-edit.component';
import { WheelFileComponent } from './pages/wheels/wheel-file/wheel-file.component';
import { RegionListComponent } from './pages/region/region-list/region-list.component';
import { RegionDetailComponent } from './pages/region/region-detail/region-detail.component';
import { RegionAddComponent } from './pages/region/region-add/region-add.component';
import { UserComponent } from './pages/users/user/user.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { UserAddComponent } from './pages/users/user-add/user-add.component';
import { UserFileComponent } from './pages/users/user-file/user-file.component';
import { RegionEditComponent } from './pages/region/region-edit/region-edit.component';
import { ShareComponent } from './pages/share/share.component';
import { ShareListComponent } from './pages/share/share-list/share-list.component';
import { ShareDetailComponent } from './pages/share/share-detail/share-detail.component';
import { ShareEditComponent } from './pages/share/share-edit/share-edit.component';
import { ShareAddComponent } from './pages/share/share-add/share-add.component';
import { OeComponent } from './pages/oe/oe.component';
import { OeListBrandsComponent } from './pages/oe/oe-list-brands/oe-list-brands.component';
import { OeEditBrandsComponent } from './pages/oe/oe-edit-brands/oe-edit-brands.component';
import { OeAddBrandsComponent } from './pages/oe/oe-add-brands/oe-add-brands.component';
import { OeDetailBrandsComponent } from './pages/oe/oe-detail-brands/oe-detail-brands.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { AssessmentListComponent } from './pages/assessment/assessment-list/assessment-list.component';
import { AssessmentDetailComponent } from './pages/assessment/assessment-detail/assessment-detail.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { TechnologiesListComponent } from './pages/technologies/technologies-list/technologies-list.component';
import { TechnologiesDetailComponent } from './pages/technologies/technologies-detail/technologies-detail.component';
import { TechnologiesAddComponent } from './pages/technologies/technologies-add/technologies-add.component';
import { TechnologiesEditComponent } from './pages/technologies/technologies-edit/technologies-edit.component';
import { CountryComponent } from './pages/country/country.component';
import { CountryListComponent } from './pages/country/country-list/country-list.component';
import { CountryEditComponent } from './pages/country/country-edit/country-edit.component';
import { CountryDetailComponent } from './pages/country/country-detail/country-detail.component';
import { CountryAddComponent } from './pages/country/country-add/country-add.component';
import { UspComponent } from './pages/usp/usp.component';
import { UspAddComponent } from './pages/usp/usp-add/usp-add.component';
import { UspEditComponent } from './pages/usp/usp-edit/usp-edit.component';
import { UspDetailComponent } from './pages/usp/usp-detail/usp-detail.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { CategoriesDetailComponent } from './pages/categories/categories-detail/categories-detail.component';
import { CategoriesAddComponent } from './pages/categories/categories-add/categories-add.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { AppPasswordComponent } from './pages/app-password/app-password.component';
import { AppForgotPasswordComponent } from './pages/app-password/app-forgot-password/app-forgot-password.component';
import { AppUpdatePasswordComponent } from './pages/app-password/app-update-password/app-update-password.component';


//Import Pipe
import { CommentPipe } from './pipe/filterComment/comment.pipe';
import { OePipe } from './pipe/filterOe/oe.pipe';
import { RegionPipe } from './pipe/filterRegion/region.pipe';
import { WheelPipe } from './pipe/filterWheel/wheel.pipe';
import { UserPipe } from './pipe/filterUser/user.pipe';
import { TechnologiesPipe } from './pipe/filterTechnologies/technologies.pipe';
import { AssessmentPipe } from './pipe/filterAssessment/assessment.pipe';
import { CountryPipe } from './pipe/filterCountry/country.pipe';
import { ShareCategoryPipe } from './pipe/filterShareCategory/share-category.pipe';
import { ShareStatusPipe } from './pipe/filterShareStatus/share-status.pipe';
import { CategoriesPipe } from './pipe/filterCategories/categories.pipe';

import { TranslateModule } from '@ngx-translate/core';
import { WheelsCommercialComponent } from "./pages/wheels-commercial/wheels-commercial.component";
import { WheelCommercialListComponent } from "./pages/wheels-commercial/list/wheel-commercial-list.component";
import { WheelCommercialEditComponent } from "./pages/wheels-commercial/edit/wheel-commercial-edit.component";
import { WheelCommercialDetailComponent } from "./pages/wheels-commercial/detail/wheel-commercial-detail.component";
import { WheelCommercialAddComponent } from "./pages/wheels-commercial/add/wheel-commercial-add.component";
import { WheelCommercialFileComponent } from "./pages/wheels-commercial/file/wheel-commercial-file.component";
import { WheelCommercialPipe } from "./pipe/filterWheel/wheel-commercial.pipe";
import { TechnologiesCommercialComponent } from "./pages/technologies-commercial/technologies-commercial.component";
import { TechnologiesCommercialListComponent } from "./pages/technologies-commercial/technologies-list/technologies-commercial-list.component";
import { TechnologiesCommercialAddComponent } from "./pages/technologies-commercial/technologies-add/technologies-commercial-add.component";
import { TechnologiesCommercialEditComponent } from "./pages/technologies-commercial/technologies-edit/technologies-commercial-edit.component";
import { TechnologiesCommercialDetailComponent } from "./pages/technologies-commercial/technologies-detail/technologies-commercial-detail.component";
import { FeatureCommercialComponent } from './pages/feature-commercial/feature-commercial.component';
import { FeatureCommercialDetailComponent } from './pages/feature-commercial/feature-detail/feature-commercial-detail.component';
import { FeatureCommercialEditComponent } from './pages/feature-commercial/feature-edit/feature-commercial-edit.component';
import { FeatureCommercialAddComponent } from './pages/feature-commercial/feature-add/feature-commercial-add.component';
import { FileDownloadService } from './services/file-download.service';
import { MultiselectComponent } from './components/forms/multiselect/multiselect.component';
import { LangEditionSwitchComponent } from './components/forms/lang-edition-switch/lang-edition-switch.component';
import { PartnersComponent } from "./pages/partners/partners.component";
import { PartnerAddComponent } from "./pages/partners/partner-add/partner-add.component";
import { PartnerEditComponent } from "./pages/partners/partner-edit/partner-edit.component";
import { PartnerListComponent } from "./pages/partners/partner-list/partner-list.component";
import { AssessmentCommercialComponent } from './pages/assessment-commercial/assessment-commercial.component';
import { AssessmentCommercialListComponent } from './pages/assessment-commercial/assessment-commercial-list/assessment-commercial-list.component';
import { AssessmentCommercialItemComponent } from './pages/assessment-commercial/assessment-commercial-item/assessment-commercial-item.component';
import { AssessmentCommercialDetailComponent } from './pages/assessment-commercial/assessment-commercial-detail/assessment-commercial-detail.component';
import { MileageCommercialComponent } from './pages/mileage-commercial/mileage-commercial.component';
import {FormatBytesPipe} from './pipe/format-bytes/format-bytes.pipe';
import { ShareCommonAddComponent } from './pages/share/common/share-add/share-add.component';
import { ShareCommonEditComponent } from './pages/share/common/share-edit/share-edit.component';
import { ShareCommonDetailComponent } from './pages/share/common/share-detail/share-detail.component';
import { ShareCommonListComponent } from './pages/share/common/share-list/share-list.component';
import { ShareCommercialAddComponent } from './pages/share-commercial/share-commercial-add/share-commercial-add.component';
import { ShareCommercialEditComponent } from './pages/share-commercial/share-commercial-edit/share-commercial-edit.component';
import { ShareCommercialDetailComponent } from './pages/share-commercial/share-commercial-detail/share-commercial-detail.component';
import { ShareCommercialListComponent } from './pages/share-commercial/share-commercial-list/share-commercial-list.component';
import { SalesToolsComponent } from './pages/sales-tools/sales-tools.component';
import { StockListComponent } from './pages/sales-tools/stock-list/stock-list.component';
import { StockListItemComponent } from './pages/sales-tools/stock-list-item/stock-list-item.component';
import { CatalogueListComponent } from './pages/sales-tools/catalogue-list/catalogue-list.component';
import { CatalogueListItemComponent } from './pages/sales-tools/catalogue-list-item/catalogue-list-item.component';
import { CatalogueEditComponent } from './pages/sales-tools/catalogue-edit/catalogue-edit.component';
import { InputFileComponent } from './components/forms/input-file/input-file.component';
import { StockListUploadComponent } from './pages/sales-tools/stock-list-upload/stock-list-upload.component';
import { StockUploadInputComponent } from './pages/sales-tools/stock-list-upload/stock-upload-input/stock-upload-input.component';
import { SurveyExportComponent } from './pages/survey-export/survey-export.component';


@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    UsersComponent,
    UserComponent,
    PartnersComponent,
    PartnerAddComponent,
    PartnerEditComponent,
    PartnerListComponent,
    WheelsCommercialComponent,
    WheelCommercialListComponent,
    WheelCommercialAddComponent,
    WheelCommercialDetailComponent,
    WheelCommercialEditComponent,
    WheelCommercialFileComponent,
    TechnologiesCommercialComponent,
    TechnologiesCommercialListComponent,
    TechnologiesCommercialDetailComponent,
    TechnologiesCommercialAddComponent,
    TechnologiesCommercialEditComponent,
    WheelsComponent,
    RegionComponent,
    WheelListComponent,
    WheelAddComponent,
    WheelDetailComponent,
    WheelEditComponent,
    WheelFileComponent,
    RegionListComponent,
    RegionDetailComponent,
    RegionAddComponent,
    UserComponent,
    UserListComponent,
    UserEditComponent,
    UserAddComponent,
    UserFileComponent,
    RegionEditComponent,
    ShareComponent,
    ShareListComponent,
    ShareDetailComponent,
    ShareEditComponent,
    ShareAddComponent,
    OeComponent,
    OeListBrandsComponent,
    OeEditBrandsComponent,
    OeAddBrandsComponent,
    OeDetailBrandsComponent,
    AssessmentComponent,
    AssessmentListComponent,
    AssessmentDetailComponent,
    TechnologiesComponent,
    TechnologiesListComponent,
    TechnologiesDetailComponent,
    TechnologiesAddComponent,
    TechnologiesEditComponent,
    CommentPipe,
    OePipe,
    RegionPipe,
    WheelPipe,
    WheelCommercialPipe,
    UserPipe,
    TechnologiesPipe,
    AssessmentPipe,
    CountryPipe,
    CountryComponent,
    CountryListComponent,
    CountryEditComponent,
    CountryDetailComponent,
    CountryAddComponent,
    ShareCategoryPipe,
    ShareStatusPipe,
    FormatBytesPipe,
    UspComponent,
    UspAddComponent,
    UspEditComponent,
    UspDetailComponent,
    CategoriesListComponent,
    CategoriesEditComponent,
    CategoriesDetailComponent,
    CategoriesAddComponent,
    CategoriesComponent,
    CategoriesPipe,
    AppPasswordComponent,
    AppForgotPasswordComponent,
    AppUpdatePasswordComponent,
    FeatureCommercialComponent,
    FeatureCommercialDetailComponent,
    FeatureCommercialEditComponent,
    FeatureCommercialAddComponent,
    MultiselectComponent,
    LangEditionSwitchComponent,
    FeatureCommercialAddComponent,
    AssessmentCommercialComponent,
    AssessmentCommercialListComponent,
    AssessmentCommercialItemComponent,
    AssessmentCommercialDetailComponent,
    MileageCommercialComponent,
    SalesToolsComponent,
    StockListComponent,
    StockListItemComponent,
    CatalogueListComponent,
    CatalogueListItemComponent,
    CatalogueEditComponent,
    InputFileComponent,
    ShareCommonAddComponent,
    ShareCommonEditComponent,
    ShareCommonDetailComponent,
    ShareCommonListComponent,
    ShareCommercialAddComponent,
    ShareCommercialEditComponent,
    ShareCommercialDetailComponent,
    ShareCommercialListComponent,
    StockListUploadComponent,
    StockUploadInputComponent,
    SurveyExportComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    FormsModule,
    TranslateModule.forChild()
  ],
  exports: [
    HomeComponent,
    LoginComponent,
    UsersComponent,
    LangEditionSwitchComponent,
    StockListUploadComponent,
    SurveyExportComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [FileDownloadService]
})
export class HomeModule { }
