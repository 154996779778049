<div class="container-fluid">
    <form (ngSubmit)="edit(k)" #k="ngForm">
        <div class="form-group d-md-flex flex-md-column mb-md-5">

            <div class="viewImage d-md-flex justify-content-center mb-5">
                <div class="viewImageCms d-md-flex mr-5">
                    <div class="labelImageCms mr-2">
                        <label>{{"usp-edit.cmsImage" | translate}}:</label>
                    </div>
                    <div class="imageCms" style="background-color: #ffffff; width: 4vw">
                        <img [src]="editUsp.cmsImgUrl" (error)="pictNotLoadingCms($event)" style="width: 4vw; max-width: 4vw;">
                    </div>
                </div>

                <div class="viewImageApp d-md-flex">
                    <div class="labelImageApp mr-2">
                        <label>{{"usp-edit.appImage" | translate}}:</label>
                    </div>
                    <div class="imageApp" style="background-color: #000000; width: 4vw">
                        <img [src]="editUsp.appImgUrl" (error)="pictNotLoadingApp($event)" style="width: 4vw; max-width: 4vw;">
                    </div>
                </div>
            </div>
            
            <div> 
                <div class="d-flex">
                    <label>{{"usp-edit.name" | translate}}</label>
                    <div class="d-flex justify-content-end w-100">         
                        <p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
                            EN &nbsp; 
                        </p>|
                        <p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p> 
                    </div>
                </div>
                <input *ngIf="uspLang=='EN'" [(ngModel)]="editUsp.name.en" name="name" type="text" class="form-control form-control-lg" required>
                <input *ngIf="uspLang=='AR'" [(ngModel)]="editUsp.name.ar" name="arname" type="text" class="form-control form-control-lg" required>
            </div>
            
            
            
            
            <!-- <div style="display: none;">
                <label>Key</label>
                <input [(ngModel)]="editUsp.key" name="key" type="text" class="form-control form-control-lg" readonly>
            </div> -->

            <div class="updateImage d-md-flex flex-column">

                <div class="selectImage d-md-flex justify-content-md-between align-items-md-center"
                    style="width: 100%;">

                    <div class="selectImageCMS" style="width: 49%;">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label>{{"usp-edit.cmsImageToUpload" | translate}}</label>
                            <small>{{"usp-edit.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDroppedCms($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerCms($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"usp-edit.dragAndDrop" | translate}}</h3>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="selectImageAPP" style="width: 49%;">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label>{{"usp-edit.appImageToUpload" | translate}}</label>
                            <small>{{"usp-edit.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDroppedApp($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerApp($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"usp-edit.dragAndDrop" | translate}}</h3>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>
                </div>

                <div class="imagePreview2 d-md-flex justify-content-md-between align-items-md-center"
                    style="width: 100%;">

                    <div class="ImagePreviewCMS" style="width: 49%;">
                        <div class="image-preview" *ngIf="cmsPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"usp-edit.cmsImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageCms()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="cmsPreviewUsp d-flex justify-content-md-center">
                                <img [src]="cmsPreviewUrl" />
                            </div>
                        </div>
                    </div>

                    <div class="ImagePreviewAPP" style="width: 49%;">
                        <div class="image-preview" *ngIf="appPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"usp-edit.appImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageApp()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="appPreviewUsp d-flex justify-content-md-center">
                                <img [src]="appPreviewUrl" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack d-md-flex align-content-md-center">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    <i class="fa fa-save mr-2"></i>{{"usp-edit.save" | translate}}</button>
            </div>
            <div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center"
                [routerLink]="['/dashboard/home/usp/detail', editUsp.uuid, wheelId]">
                <button type="button" class="btn btn-dark">
                    <i class="fa fa-eye mr-2"></i>{{"usp-edit.detail" | translate}}
                </button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>
</div>