import { HttpParams, HttpClient } from '@angular/common/http';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cookie } from 'ng2-cookies';

import { TranslateService } from '@ngx-translate/core';

import { Wheel } from 'src/app/shared/models/wheels/wheel';
import { ProductsService } from 'src/app/core/services/products.service';
import { Technologies } from 'src/app/shared/models/wheels/objectWheel/technologies';
import { TechnologiesService } from 'src/app/core/services/technologies.service';
import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { UspService } from 'src/app/core/services/usp.service';
import { ProductRft } from 'src/app/shared/models/wheels/objectWheel/rft';
import { Light } from 'src/app/shared/models/wheels/objectWheel/light';
import { Zoom } from 'src/app/shared/models/wheels/objectWheel/zoom';
import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { NotifyPopupComponent } from 'src/app/shared/components/popups/notify-popup/notify-popup.component';

@Component({
  selector: 'app-wheel-edit',
  templateUrl: './wheel-edit.component.html',
  styleUrls: ['./wheel-edit.component.scss']
})
export class WheelEditComponent implements OnInit {

  editWheel: Wheel = new Wheel();
  productRft: ProductRft = new ProductRft();
  usp: Usp[] = [];
  technologies: Technologies[] = [];
  lightWheel3D: Light = new Light();
  zoom3D: Zoom = new Zoom();
  modal: NgbModalRef;
  modalLoad: NgbModalRef;
  modalDelete: NgbModalRef;
  modalNotify: NgbModalRef;
  segment: any[] = [];
  oeRep: any[] = [];
  operation: any[] = [];
  rft: string;
  uuid: string;
  newUsp: string;
  newTechnology: string;

  fileDataProduct: File = null;
  fileDataCms: File = null;
  fileDataApp: File = null;
  previewUrlProduct: any = null;
  previewUrlCms: any = null;
  previewUrlApp: any = null;
  productPreviewUrl: SafeUrl;
  cmsPreviewUrl: SafeUrl;
  appPreviewUrl: SafeUrl;
  uspLang: string = 'EN';
  safeUrl: SafeUrl;

  errorMessage: string;

  constructor(private _route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private productService: ProductsService,
    private techService: TechnologiesService,
    private uspService: UspService,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService,
    private _httpClient: HttpClient) { }

  ngOnInit() {
    this.uuid = this._route.snapshot.params.id;

    if (localStorage.getItem("editWheel") && JSON.parse(localStorage.getItem("editWheel")).uuid == this.uuid) {
      console.log(this.uuid)
      this.editWheel = JSON.parse(localStorage.getItem("editWheel"));
    } else {
      this.atrrDataId();
    }

    // this.productService.getProductById(this.uuid)
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.editWheel.modelRender);
    //   });

    this.atrrDataSegment();
    this.atrrDataOperation();
    this.atrrDataOe();
    this.attrDataUsp();
    this.attrDataTech();
  }

  allParamsDefault() {
    var url = new URL(this.editWheel.modelRender);
    this.editWheel.modelLight2 = (
      url.searchParams.get('light2') === null ||
      url.searchParams.get('light2') === "undefined" || 
      url.searchParams.get('light2') === '' ) ? "left" : url.searchParams.get('light2');
    this.editWheel.modelZoom = (
      url.searchParams.get('zoom') === null ||
      url.searchParams.get('zoom') === "undefined" ||
      url.searchParams.get('zoom') === "" ) ? "med" : url.searchParams.get('zoom'); 
    this.editWheel.modelIntentisy = (
      url.searchParams.get('intentisy') === null ||
      url.searchParams.get('intentisy') === "undefined" ||
      url.searchParams.get('intentisy') === "" ) ? "1" : url.searchParams.get('intentisy'); 
    this.editWheel.modelRotation = (
      url.searchParams.get('rotation') === null ||
      url.searchParams.get('rotation') ==="undefined" ||
      url.searchParams.get('rotation') ==="" ) ? "-0.8" : url.searchParams.get('rotation');
    this.editWheel.modelFlHeight = (
      url.searchParams.get('flheight') === null ||
      url.searchParams.get('flheight') ==="undefined" ||
      url.searchParams.get('flheight') === "" ) ? "1.0" : url.searchParams.get('flheight'); 
  }

  atrrDataId() {
    this.productService.getProductById(this.uuid).subscribe((data: any) => {
      this.editWheel = data.data;
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.editWheel.modelRender);
      this.allParamsDefault();
      console.log(data);
    });
  }

  atrrDataSegment() {
    this.productService.getAttrSegment().subscribe((data: any) => {
      this.segment = data.data;
    });
  }

  atrrDataOperation() {
    this.productService.getAttrOperation().subscribe((data: any) => {
      this.operation = data.data;
    });
  }

  atrrDataOe() {
    this.productService.getAttrOe().subscribe((data: any) => {
      this.oeRep = data.data;
    });
  }

  attrDataUsp() {
    this.uspService.getUspAll()
      .subscribe(data => {
        this.usp = data.data;

        setTimeout(() => {
          if (Cookie.check('usp_key'))
            this.newusp();
          this.editWheel.usp.forEach(usp => {
            document.getElementById(usp.key)['selected'] = true;
          });
        }, 100);
      });
  }

  attrDataTech() {
    this.techService.getTechAll()
      .subscribe(data => {
        this.technologies = data.data;

        setTimeout(() => {
          if (Cookie.check('technology_title'))
            this.newtechnology();
          this.editWheel.technologies.forEach(technologies => {
            document.getElementById(technologies.title)['selected'] = true;
          });
        }, 100);
      });
  }

  maxNumber(event: any) {
    if (parseFloat(this.editWheel.modelIntentisy) >= 2) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-edit.messageTitle1');
          modal.componentInstance.messageDescription = 
          // 'The value can only be from 0.00001 to 2, both included.';
          this._translate.instant('wheel-edit.messageDescription3');
          this.modalClose();
    }
    parseFloat(this.editWheel.modelIntentisy) == 0.00001 && parseFloat(this.editWheel.modelIntentisy) <= 2;
    return this.editWheel.modelIntentisy;
  }

  maxZoom(event: any) {
    if (parseFloat(this.editWheel.modelZoom) >= 15 && parseFloat(this.editWheel.modelZoom) !== null) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-edit.messageTitle1');
          modal.componentInstance.messageDescription = 
          // 'The entered value cannot be greater than 15.';
          this._translate.instant('wheel-edit.messageDescription1');
          this.modalClose();
    }
  }

  maxRotation(event: any) {
    if (parseFloat(this.editWheel.modelRotation) > 6.283) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-edit.messageTitle1');
          modal.componentInstance.messageDescription = 
          // 'The entered value cannot be larger than 6,283.';
          this._translate.instant('wheel-edit.messageDescription2');
          this.modalClose();
    }
  }

  //Light2 Live
  light2(event: any) {
    var url = new URL(this.editWheel.modelRender);
    url.searchParams.set('light2', this.editWheel.modelLight2);
    url.searchParams.set('zoom', this.editWheel.modelZoom);
    url.searchParams.set('intentisy', this.editWheel.modelIntentisy);
    url.searchParams.set('rotation', this.editWheel.modelRotation);
    url.searchParams.set('flheight', this.editWheel.modelFlHeight);
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  zoom(event: any) {
    var url = new URL(this.editWheel.modelRender);
    url.searchParams.set('light2', this.editWheel.modelLight2);
    url.searchParams.set('zoom', this.editWheel.modelZoom);
    url.searchParams.set('intentisy', this.editWheel.modelIntentisy);
    url.searchParams.set('rotation', this.editWheel.modelRotation);
    url.searchParams.set('flheight', this.editWheel.modelFlHeight);
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  intensity(event: any) {
    var url = new URL(this.editWheel.modelRender);
    url.searchParams.set('light2', this.editWheel.modelLight2);
    url.searchParams.set('zoom', this.editWheel.modelZoom);
    url.searchParams.set('intentisy', this.editWheel.modelIntentisy);
    url.searchParams.set('rotation', this.editWheel.modelRotation);
    url.searchParams.set('flheight', this.editWheel.modelFlHeight);
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  rotation(event: any) {
    // let params = new HttpParams();
    // params = params.append("modelRotation", "-0.8");
    var url = new URL(this.editWheel.modelRender);
    url.searchParams.set('light2', this.editWheel.modelLight2);
    url.searchParams.set('zoom', this.editWheel.modelZoom);
    url.searchParams.set('intentisy', this.editWheel.modelIntentisy);
    url.searchParams.set('rotation', this.editWheel.modelRotation);
    url.searchParams.set('flheight', this.editWheel.modelFlHeight);
    console.log(url);
    //console.log(this.editWheel.modelRender);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  flHeight(event: any) {
    var url = new URL(this.editWheel.modelRender);
    url.searchParams.set('light2', this.editWheel.modelLight2);
    url.searchParams.set('zoom', this.editWheel.modelZoom);
    url.searchParams.set('intentisy', this.editWheel.modelIntentisy);
    url.searchParams.set('rotation', this.editWheel.modelRotation);
    url.searchParams.set('flheight', this.editWheel.modelFlHeight);
    console.log(url);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
  }

  // For the ZOOM that the red warning does not appear
  // emptyParameter(event: any) {
  //   if (this.editWheel.modelZoom === null || 
  //     this.editWheel.modelZoom === "undefined" ||
  //     this.editWheel.modelZoom === "" ||
  //     this.editWheel.modelZoom > "15") {
  //     return this.errorMessage;
  //   } else {
  //     return false;
  //   }
  // }

  refreshSelectedUsp(){
    setTimeout(()=>{
      this.editWheel.usp.forEach(usp => {
        document.getElementById(usp.key)['selected'] = true;
      });
    },100); 
  }


  //MULTI-SELECT USP
  onMouseDownUsp(event: MouseEvent, item: string, uuid: string, name: string, arname: string, appImgUrl: string, cmsImgUrl: string) {
    event.preventDefault();

    if (!event.target['selected'] && (this.editWheel.usp.length < 3)) {
      event.target['selected'] = !event.target['selected'];
      this.editWheel.usp.push({ key: item, name: name, arname: arname, uuid: uuid, appImgUrl: appImgUrl, cmsImgUrl: cmsImgUrl });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.editWheel.usp.forEach(usp => {
        if (usp.key == item) i = index;
        //console.log(i);
        index++;
      });
      this.editWheel.usp.splice(i, 1);
      if (index > -1) { }
    }
  }
  removeModel(i: number, key: string) {
    document.getElementById(key)['selected'] = false;
    this.editWheel.usp.splice(i, 1);
  }

  newusp() {
    this.newUsp = Cookie.get("usp_key");

    this.usp.forEach(usp => {
      if (usp.key == this.newUsp)
        this.editWheel.usp.push({ key: usp.key, name: usp.name, arname: usp.arname, uuid: usp.uuid, appImgUrl: usp.appImgUrl, cmsImgUrl: usp.cmsImgUrl });
    })
    Cookie.delete('usp_key');
  }


  //MULTI-SELECT TECHNOLOGIES
  onMouseDownTechnologies(event: MouseEvent, uuid: string, title: string, description: string, image: string) {
    event.preventDefault();

    if (!event.target['selected']) {
      event.target['selected'] = !event.target['selected'];
      this.editWheel.technologies.push({ uuid: uuid, title: title, artitle: title, description: description, ardescription: description, image: image });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.editWheel.technologies.forEach(technologies => {
        if (technologies.title == title) i = index;
        //console.log(i);
        index++;
      });
      this.editWheel.technologies.splice(i, 1);
      if (index > -1) { }
    }
  }
  removeModelTechnologies(i: number, title: string) {
    document.getElementById(title)['selected'] = false;
    this.editWheel.technologies.splice(i, 1);
  }
  onmousemove = function (event) {
    event.preventDefault();
  }


  newtechnology() {
    this.newTechnology = Cookie.get("technology_title");

    this.technologies.forEach(technologies => {
      if (technologies.title == this.newTechnology)
        this.editWheel.technologies.push({ uuid: technologies.uuid, title: technologies.title, artitle: technologies.artitle, description: technologies.description, ardescription: technologies.ardescription, image: technologies.image });
    })
    Cookie.delete('technology_title');
  }

  //UPDATE PRODUCT IMAGE
  //On file drop handler
  onFileDroppedProduct($event) {
    this.prepareFilesListProduct($event);
  }

  //Handle file from browsing
  fileBrowseHandlerProduct($event) {
    this.prepareFilesListProduct($event);
  }

  //Delete Image
  deleteImageProduct() {
    this.productPreviewUrl = null;
    this.previewUrlProduct = null;
    // this.fileDataProduct = null;
  }

  prepareFilesListProduct(fileInput: any) {
    this.fileDataProduct = <File>fileInput.target.files[0];
    this.previewProduct();
  }

  previewProduct() {
    let mimeTypeProduct = this.fileDataProduct.type;
    if (mimeTypeProduct.match(/image\/*/) == null) {
      return;
    }

    let readerProduct = new FileReader();
    readerProduct.readAsDataURL(this.fileDataProduct);
    readerProduct.onload = (_event) => {
      this.previewUrlProduct = readerProduct.result;
      this.productPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlProduct);
    }
  }

  //UPDATE CMS LOGO
  //On file drop handler
  onFileDroppedCms($event) {
    this.prepareFilesListCms($event);
  }

  //Handle file from browsing
  fileBrowseHandlerCms($event) {
    this.prepareFilesListCms($event);
  }

  //Delete Image
  deleteImageCMS() {
    this.cmsPreviewUrl = null;
    this.previewUrlCms = null;
  }

  prepareFilesListCms(fileInput: any) {
    this.fileDataCms = <File>fileInput.target.files[0];
    this.previewCms();
  }

  previewCms() {
    let mimeTypeCms = this.fileDataCms.type;
    if (mimeTypeCms.match(/image\/*/) == null) {
      return;
    }

    let readerCms = new FileReader();
    readerCms.readAsDataURL(this.fileDataCms);
    readerCms.onload = (_event) => {
      this.previewUrlCms = readerCms.result;
      this.cmsPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlCms);
    }
  }


  //UPDATE APP LOGO
  //On file drop handler
  onFileDroppedApp($event) {
    this.prepareFilesListApp($event);
  }

  //Handle file from browsing
  fileBrowseHandlerApp($event) {
    this.prepareFilesListApp($event);
  }

  //Delete Image
  deleteImageApp() {
    this.appPreviewUrl = null;
    this.previewUrlApp = null;
  }

  prepareFilesListApp(fileInput: any) {
    this.fileDataApp = <File>fileInput.target.files[0];
    this.previewApp();
  }

  previewApp() {
    let mimeTypeApp = this.fileDataApp.type;
    if (mimeTypeApp.match(/image\/*/) == null) {
      return;
    }

    let readerApp = new FileReader();
    readerApp.readAsDataURL(this.fileDataApp);
    readerApp.onload = (_event) => {
      this.previewUrlApp = readerApp.result;
      this.appPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlApp);
    }
  }

  pictNotLoadingProduct(event) {
    event.target.src = 'assets/img/B-pro_21apr90_v2.svg';
    event.target.style = 'width: 10vw; height: 10vw';
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  pictNotLoadingApp(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }


  //EDIT PRODUCT
  edit(form: NgForm) {

    let newProduct = {
      usp: '',
      technologies: ''
    };

    this.editWheel.usp.forEach(Usp => {
      if (newProduct.usp == '')
        newProduct.usp = newProduct.usp + Usp.key;
      else
        newProduct.usp = newProduct.usp + ',' + Usp.key;
    });

    //console.log(newProduct.usp);

    this.editWheel.technologies.forEach(Technologies => {
      if (newProduct.technologies == '')
        newProduct.technologies = newProduct.technologies + Technologies.uuid;
      else
        newProduct.technologies = newProduct.technologies + ',' + Technologies.uuid;
    });


    this.modalNotify = this._modal.open(NotifyPopupComponent, { backdrop: "static", keyboard: false });
    this.modalNotify.componentInstance.messageTitle = 
    // "Notification";
    this._translate.instant('wheel-edit.messageTitleNotify');

    this.modalNotify.result.then((result) => {

      //console.log(newProduct.technologies);

      const formData = new FormData();
      formData.append('familyName', this.editWheel.familyName);
      formData.append('patternName', this.editWheel.patternName);
      formData.append('patternSlogan', this.editWheel.patternSlogan.en);
      formData.append('arpatternSlogan', this.editWheel.patternSlogan.ar);
      formData.append('segment', this.editWheel.segment.key);
      formData.append('operation', this.editWheel.operation.key);
      formData.append('oeRep', this.editWheel.oeRep.key);
      formData.append('usp', newProduct.usp);
      formData.append('technologies', newProduct.technologies);
      formData.append('rft', this.editWheel.rft);
      formData.append('productOrder', this.editWheel.productOrder);
      formData.append('applogo', this.fileDataApp);
      formData.append('cmslogo', this.fileDataCms);
      formData.append('image', this.fileDataProduct);
      formData.append('notify', result);
      formData.append('modelLight2', this.editWheel.modelLight2);
      formData.append('modelZoom', this.editWheel.modelZoom);
      formData.append('modelIntentisy', this.editWheel.modelIntentisy);
      formData.append('modelRotation', this.editWheel.modelRotation);
      formData.append('modelFlHeight', this.editWheel.modelFlHeight);


      //Peticion
      //console.log(wheelUpdate);
      this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
      // if (this.modalLoad) {
      //   $(document).keydown(function (objEvent) {
      //     if (objEvent.keyCode == 9) {  //Tab pressed
      //       objEvent.preventDefault(); //Stops its action
      //     }
      //   });
      // }
      this.productService.updateProduct(this.uuid, formData)
        .subscribe(res => {
          //console.log(res);
          this.modalClose();
          this.router.navigateByUrl('home/wheels');
          try {
            localStorage.removeItem("editWheel");
          } catch { }
        });
    });
  }

  //Delete Technology
  deleteButton(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modal.componentInstance.messageTitle = 
    // "You will delete a technology.";
    this._translate.instant('wheel-edit.messageTitle');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('wheel-edit.messageDescription');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.techService.deleteTech(uuid)
          .subscribe(data => {
            this.modalCloseDelete();
            this.editWheel.technologies.splice(index, 1);
            this.attrDataTech();
            //this.ngOnInit();
            // console.log(data)
          });
        response => { this.attrDataTech() };
      } else {
        this.modal.close;
      }
    });
  }

  //Delete Usp
  deleteButtonUSP(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modal.componentInstance.messageTitle = 
    // "You will delete an USP.";
    this._translate.instant('wheel-edit.messageTitleUSP');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('wheel-edit.messageDescriptionUSP');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.uspService.deleteUsp(uuid)
          .subscribe(data => {
            this.modalCloseDelete();
            this.editWheel.usp.splice(index, 1);
            this.attrDataUsp();
            //this.ngOnInit();
            // console.log(data)
          });
        response => { this.attrDataUsp() };
      } else {
        this.modal.close;
      }
    });
  }

  addTechnology() {
    localStorage.setItem('editWheel', JSON.stringify(this.editWheel))
    this.router.navigateByUrl('/dashboard/home/technologies/add');
  }

  addUsp() {
    if (this.editWheel.usp.length < 3) {
      localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
      this.router.navigateByUrl('/dashboard/home/usp/add');
    } else {
      this.modal = this._modal.open(ErrorPopupComponent);
      this.modal.componentInstance.messageTitle = 
      // "There is already 3 USP";
      this._translate.instant('wheel-edit.messageTitle3USP');
      this.modal.componentInstance.messageDescription = 
      // "You need to remove one in order to create a new one";
      this._translate.instant('wheel-edit.messageDescription3USP');
    }
  }

  viewUsp(uspId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/usp/detail', uspId, this.editWheel.uuid]);
  }

  editUsp(uspId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/usp/edit', uspId, this.editWheel.uuid])
  }

  viewTechnology(tecId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/technologies/detail', tecId]);
  }

  editTechnology(tecId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/technologies/edit', tecId]);
  }

  modalClose() {
    this.modalLoad.close();
  }

  modalCloseDelete() {
    this.modalDelete.close();
  }

  backClicked() {
    this.router.navigate(['/dashboard/home/wheels/list']);
  }
}
