import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { Login } from 'src/app/shared/models/login';
import { Subject } from "rxjs/internal/Subject";
import { LocalStorageKey, LocalStorageManager } from "../../core/services/local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_LOGIN = environment.baseUrlCMS + '/login';

  reset: boolean;
  uuid: string;

  roleSubject = new Subject<string>();

  constructor(
    private _http: HttpClient,
    private _modal: NgbModal,
    private _translate: TranslateService
  ) { }

  obtainAccessToken(loginData: Login, callback: () => void) {
    this._http.post(this.API_LOGIN, {
      username: loginData.username,
      password: loginData.password
    }).subscribe(
      (data: any) => {
        this.saveToken(data)
        this.reset = data.userInfo.reset;
        this.uuid = data.userInfo.uuid;
        callback();
      },
      err => {
        let modal = this._modal.open(ErrorPopupComponent);
        modal.componentInstance.messageTitle = this._translate.instant('error-popup.messageTitle');
        modal.componentInstance.messageDescription = this._translate.instant('error-popup.messageDescription');
      }
    );
  }

  private saveToken(token) {
    LocalStorageManager.set(LocalStorageKey.ACCESS_TOKEN, token.access_token);
    LocalStorageManager.set(LocalStorageKey.ROLE, token.userInfo.role);
    
    this.roleSubject.next(this.getRole());
  }

  checkCredentials(): boolean {
    return LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) != null;
  }

  logout() {
    LocalStorageManager.removeAll();
  }

  getToken(): string {
    return LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN);
  }
  
  getRole(): string {
      return LocalStorageManager.get(LocalStorageKey.ROLE);
  }
}
