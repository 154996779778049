import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Assessment } from 'src/app/shared/models/assessment';
import { Generic } from 'src/app/shared/models/generic';
import { environment } from "src/environments/environment";
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  headers: HttpHeaders = new HttpHeaders({
    // "Content-Type": "multipart/form-data",
    "Content-Type": "application/json",
    "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  excelHeaders: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/octet-stream",
    "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  // API
  private API = environment.baseUrlCMS;
  private EXCEL = "/assessment/export";

  // Endpoints
  private ASSESSMENT = "/assessment";

  constructor(private http: HttpClient) { }

  // Obtain all assessments
  getAssessment(filter: any, lang: string): Observable<Generic<Assessment[]>> {
    let headerAux: HttpHeaders = this.headers;
    headerAux.set("bpro-language", lang);
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.size.toString());

    if (filter.query != "")
      params = params.append('query', filter.query);

    return this.http.get<Generic<Assessment[]>>(this.API + this.ASSESSMENT, { params: params, headers: headerAux });
  }

  // Obtain assessment 
  getAssessmentById(uuid: string, lang: string) {
    let headerAux: HttpHeaders = this.headers;
    headerAux.set("bpro-language", lang);
    return this.http.get(this.API + this.ASSESSMENT + "/" + uuid, { headers: headerAux });
  }

  // Accept assessment
  acceptAssessment(uuid: string): Observable<Generic<Assessment[]>> {
    return this.http.put<Generic<Assessment[]>>(this.API + this.ASSESSMENT + "/" + uuid + "/accept", { headers: this.headers });
  }

  // Reject assessment
  rejectAssessment(uuid: string): Observable<Generic<Assessment[]>> {
    return this.http.put<Generic<Assessment[]>>(this.API + this.ASSESSMENT + "/" + uuid + "/reject", { headers: this.headers });
  }

  // Get Excel file
  getExcel(): Observable<any> {
    return this.http.get(this.API + this.EXCEL, {headers: this.excelHeaders, responseType: 'arraybuffer'});
		// return this.http.get<Generic<Assessment[]>>(this.API + this.EXCEL, { headers: this.headers, responseType:'blob' as 'json' });
  }
}
