import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shareStatus'
})
export class ShareStatusPipe implements PipeTransform {

  transform(value: any, searchStatus?: any): any {
    return (searchStatus && searchStatus != 'Select Status') ? value.filter(opt => opt.status === searchStatus) : value;
  }

}
