<div class="container-fluid">
    <form (ngSubmit)="createRegion(k)" #k="ngForm">
        <div class="d-md-flex justify-content-md-start">
            <h2>{{'region-add.addRegion' | translate}}</h2>
        </div>
        <div class="mb-5"></div>
        <div class="d-flex justify-content-md-start">
            <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
        </div>
        <div class="mb-5"></div>
        <div class="d-md-flex justify-content-between">
            <div class="col-6">
                <label>{{'region-add.name' | translate}}</label>
                <input *ngIf="uspLang=='EN'" [(ngModel)]="addRegion.name" name="name" type="text" class="form-control"
                    required>
                <input *ngIf="uspLang=='AR'" [(ngModel)]="addRegion.arname" name="arname" type="text"
                    class="form-control" required>
            </div>
            <div class="col-6">
                <app-multiselect label='{{"region-add.responsables" | translate}}' [(value)]='addRegion.responsables'
                    [adapter]="responsableAdapter"
                    [availableOptions]='users'></app-multiselect>
            </div>
        </div>

        <div class="d-md-flex">
            <div class="buttonSave mr-1">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    {{"region-add.save" | translate}}</button>
            </div>
            <div class="buttonBack">
                <button type="button" class="btn btn-outline-danger"
                    [routerLink]="['/dashboard/home/region/list']">{{"region-add.cancel" | translate}}</button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>