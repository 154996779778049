import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import * as $ from 'jquery';

import { ValidPopupComponent } from 'src/app/shared/components/popups/valid-popup/valid-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { UsersService } from 'src/app/core/services/users.service';
@Component({
  selector: 'app-user-file',
  templateUrl: './user-file.component.html',
  styleUrls: ['./user-file.component.scss']
})
export class UserFileComponent {

  isHovering: boolean;
  files: any[] = [];
  modalLoad: NgbModalRef;

  //On file drop handler
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  //Handle file from browsing
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  //Delete file from files list
  //@param index (File index)
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  //Convert Files list to normal array list
  //@param files (Files List)
  prepareFilesList(file) {
    for (const item of file) {
      item.progress = 0;
      this.files.length === 1;
      if (this.files.length < 1) {
        this.files.push(item);
      } else {
        let modal = this._modal.open(ErrorPopupComponent);
        modal.componentInstance.messageTitle = 
        // 'Maximum number of Excel files exceeded.';
        this._translate.instant('user-file.messageTitle2');
        modal.componentInstance.messageDescription = 
        // 'Only a single Excel file can be uploaded.';
        this._translate.instant('user-file.messageDescription2');
      }
    }
    const fileTypes = ['.xls', '.xlsx'];
    return fileTypes.includes(file.type);
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private http: HttpClient,
    private _modal: NgbModal,
    private usersService: UsersService,
    private _translate: TranslateService
  ) {
  }

  //Commented HTML methods to save and upload files to a server
  saveFile() {
    let formData = new FormData();
    for (let i in this.files) {
      formData.append("file", this.files[i]);
    }
    this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});
    if (this.modalLoad) {
			$(document).keydown(function(objEvent) {
				if (objEvent.keyCode == 9) {  //Tab pressed
					objEvent.preventDefault(); //Stops its action
				}
			});
		}
    this.usersService.uploadFile(formData).subscribe((res) => {
      //console.log('Response:', res);
      this.modalClose();
      let modal = this._modal.open(ValidPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'Successful upload!';
      this._translate.instant('user-file.messageTitle');
      modal.componentInstance.messageDescription = 
      // 'The file has been successfully uploaded.'
      this._translate.instant('user-file.messageDescription');
      this._router.navigateByUrl('home/users');
    }, (err) => {
      this.modalClose();
      let modal = this._modal.open(ErrorPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'The file is invalid.';
      this._translate.instant('user-file.messageTitle1');
      modal.componentInstance.messageDescription = 
      // 'Please check the file type and try again.'
      this._translate.instant('user-file.messageDescription1');
    });
  }

  modalClose() {
    this.modalLoad.close();
  }

//   downloadFile(){
//     let link = document.createElement("a");
//     link.download = "B-pro profile master";
//     link.href = "assets/excel/B-pro profile master.xlsx";
//     link.click();
// }

  ngOnInit() {
  }
}
