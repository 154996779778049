import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { UsersService } from 'src/app/core/services/users.service';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { LocalStorageKey, LocalStorageManager } from "../../../../../core/services/local-storage-manager";

@Component({
  selector: 'app-app-update-password',
  templateUrl: './app-update-password.component.html',
  styleUrls: ['./app-update-password.component.scss']
})
export class AppUpdatePasswordComponent implements OnInit {

  id: string;
  modalLoad: NgbModalRef;

  editPassword = {
    oldPass: '',
    newPass: '',
    reNewPass: ''
  }

  constructor(
    private usersService: UsersService,
    private _route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private _location: Location,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.id = this._route.snapshot.params.id;
  }

  update(form: NgForm) {

    const formData = new FormData();
    formData.append('oldPass', this.editPassword.oldPass);
    formData.append('newPass', this.editPassword.newPass);
    formData.append('reNewPass', this.editPassword.reNewPass);

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
    //   $(document).keydown(function (objEvent) {
    //     if (objEvent.keyCode == 9) {  //Tab pressed
    //       objEvent.preventDefault(); //Stops its action
    //     }
    //   });
    // };

    this.usersService.updatePassword(this.id, formData)
      .subscribe(res => {

        this.modalClose();
        this.router.navigate(['']);

      }, err => {

        if (this.editPassword.oldPass === this.editPassword.newPass) {

          let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Current and new passwords are the same';
          this._translate.instant('app-update.messageTitle');
          modal.componentInstance.messageDescription = 
          // 'Please check all the fields and try again.';
          this._translate.instant('app-update.messageDescription');
          this.modalClose();

        } else if (this.editPassword.newPass != this.editPassword.reNewPass) {

          let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'The new password and new password confirmation are different';
          this._translate.instant('app-update.messageTitle1');
          modal.componentInstance.messageDescription = 
          // 'Please check all the fields and try again.';
          this._translate.instant('app-update.messageDescription');
          this.modalClose();
        }
      });
    LocalStorageManager.remove(LocalStorageKey.ACCESS_TOKEN);
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
