import { TranslateService } from "@ngx-translate/core";
import { MultiselectAdapter } from "src/app/modules/home/components/forms/multiselect/multiselect-adapter";

export class RegionUploadAndShare {
  public uuid: string = "";
  public name: any = "";
  public arname: any = "";
  public key: string = "";

  constructor(values: object = {}) {
    Object.assign(this, values);
  }

  static multiselectAdapter(
    translate: TranslateService
  ): MultiselectAdapter<RegionUploadAndShare> {
    return {
      render: (region) => {
        const currentLang =
          translate.currentLang || translate.defaultLang || "en";

        const result = region.name[currentLang];

        if (!result) {
          console.error(`No value for shareRegion.name.${currentLang}`);
          return Object.values(region.name)[0] as string;
        }

        return result;
      },
      keySelect: (region) => region.uuid,
    };
  }
}
