import { Component, OnInit } from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';

import { UsersService } from "src/app/core/services/users.service";
import { UserModel } from "src/app/shared/models/user";
import { CustomModalComponent } from "src/app/shared/components/modals/custom-modal/custom-modal.component";
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { FileDownloadService } from 'src/app/modules/home/services/file-download.service';

@Component({
	selector: "app-userlist",
	templateUrl: "./user-list.component.html",
	styleUrls: ["./user-list.component.scss"],
})
export class UserListComponent implements OnInit {

	users: UserModel[] = [];
	modalConfirm: NgbModalRef;
	modalDelete: NgbModalRef;
	modalLoading: NgbModalRef;
	modalError: NgbModalRef;
	searchText: string = '';

	constructor(
		private usersService: UsersService,
		private router: Router,
		private modal: NgbModal,
		private _translate: TranslateService,
		private fileDownload: FileDownloadService
	) { }

	ngOnInit() {
		this.usersService.getAllUsers()
			.subscribe((data: any) => {
				this.users = data.data;
				// console.log(this.users);
			});
	}

	createUser() {
		this.router.navigate(["/dashboard/home/users/add"]);
	}

	readExcel() {
		this.router.navigate(['/dashboard/home/users/file']);
	}

	exportExcel() {
		const showErrorModal = () => {
			this.modalError = this.modal.open(ErrorPopupComponent);

			this.modalError.componentInstance.messageTitle = this._translate.instant('user-list.excelDownloadError.title');
			this.modalError.componentInstance.messageDescription = this._translate.instant('user-list.excelDownloadError.description');
		};
		const fileName = `Users-${(new Date()).toDateString()}.xlsx`;

		this.modalLoading = this.modal.open(LoadingPopupComponent, {backdrop: 'static', keyboard: false});

		this.usersService
			.getUsersExcelBlob()
			.subscribe(
				(value: Blob) => {
					this.modalLoading.close();
					this.fileDownload.downloadBlob(value, fileName);
				},
				(error: any) => {
					console.error('Error while trying to download Excel file', error);
					showErrorModal();
					this.modalLoading.close();
				}
			);
	}

	deleteUser(id: string, index: number) {
		this.modalConfirm = this.modal.open(CustomModalComponent, {backdrop: "static", keyboard: false});
		this.modalConfirm.componentInstance.messageTitle =
			// "Are you sure you want to delete this item?";
			this._translate.instant('user-list.messageTitle');
		this.modalConfirm.componentInstance.messageDescription =
			// "This action cannot be ";
			this._translate.instant('user-list.messageDescription');

		this.modalConfirm.result.then((result) => {
			if (result === true) {
				this.modalDelete = this.modal.open(DeletePopupComponent, {backdrop: "static", keyboard: false});
				this.usersService.deleteUser(id)
					.subscribe((data) => {
						this.modalClose();
						this.users.splice(index, 1);
						this.ngOnInit();
					});
			}
		});
	}

	modalClose() {
		this.modalDelete.close();
	}
}
