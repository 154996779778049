import {Component, OnInit} from '@angular/core';
import {SalesToolsService} from '../../../../../core/services/sales-tools.service';
import {SalesToolsModel} from '../../../../../shared/models/salestools/sales-tools';

@Component({
  selector: 'app-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss']
})
export class StockListComponent implements OnInit {

  stockListItems: SalesToolsModel[]

  constructor(private salesToolsService: SalesToolsService) {
  }

  ngOnInit(): void {
    this.loadStockListItems();
  }

  onItemDelete() {
    this.loadStockListItems()
  }

  private loadStockListItems() {
    this.salesToolsService.getAllSalesTools()
        .subscribe(data =>
            this.stockListItems = data.data.filter(item => !!item.stockList)
        );
  }
}
