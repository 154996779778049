import { Component, OnInit, Input } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ShareService } from 'src/app/core/services/share.service';
import { ShareModel } from 'src/app/shared/models/share/share';
import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { NotifyPopupComponent } from 'src/app/shared/components/popups/notify-popup/notify-popup.component';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { FileDownloadService } from "../../../../services/file-download.service";
import { ErrorPopupComponent } from "../../../../../../shared/components/popups/error-popup/error-popup.component";

interface Paging {
  page: number,
  size: number,
  status: string | null,
  category: string | null,
  query: string
}

@Component({
  selector: 'app-common-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss']
})
export class ShareCommonListComponent implements OnInit {

  constructor(
    private categoriesService: CategoriesService,
    private fileDownload: FileDownloadService,
    private modal: NgbModal,
    private _translate: TranslateService
  ) {}
;

  share: ShareModel[];
  modalConfirm: NgbModalRef;
  modalLoad: NgbModalRef;
  modalDelete: NgbModalRef;
  modalNotify: NgbModalRef;
  searchShare = '';
  searchCategory = '';
  searchStatus = '';
  allOptionCategory: any[] = [];
  optionCategory: any[] = [];
  allOptionStatus: any[] = [];
  optionStatus: any[] = [];
  uspLang = 'EN';

  filterPaging = {
    pageNumber: 0,
    pageSize: 20,
    totalNumberOfRecords: null,
    totalNumberOfPages: null,
    hasPreviousPage: false,
    hasNextPage: false,
  }

  paging: Paging = ShareCommonListComponent.getClearPaging();

  @Input()
  shareService: ShareService

  private static getClearPaging = (): Paging => ({
    page: 0,
    size: 20,
    status: null,
    category: null,
    query: ''
  });

  ngOnInit() {
    this.getComments();
    this.getCategory();
  }

  getComments() {
    this.shareService.getAllComments(this.paging)
      .subscribe((data: any) => {
        this.share = data.data;
        this.filterPaging = data.paging;
        this.getStatus(data);
        this.filterPaging.pageNumber++;
      });
  }

  getCategory() {
    this.categoriesService.getAttrCategory()
      .subscribe(data => {
        this.optionCategory = data.data;
      });
  }

  getStatus(data) {

    this.share = data.data;

    for (let i = 0; i < this.share.length; i++) {
      this.allOptionStatus.push(this.share[i].status)
    }
    this.optionStatus = Array.from(new Set(this.allOptionStatus));

  }

  deleteComment(id: string, index: number) {
    this.modalConfirm = this.modal.open(CustomModalComponent, { backdrop: 'static', keyboard: false });
    this.modalConfirm.componentInstance.messageTitle =
    this._translate.instant('share-list.messageTitle');
    this.modalConfirm.componentInstance.messageDescription =
    this._translate.instant('share-list.messageDescription');

    this.modalConfirm.result.then((result) => {
      if (result === true) {
        this.modalDelete = this.modal.open(DeletePopupComponent, { backdrop: 'static', keyboard: false });
        this.shareService.deleteComment(id)
          .subscribe((data) => {
            this.modalCloseDelete();
            this.share.splice(index, 1);
            this.ngOnInit();
          });
      }
    });
  }

  acceptShare(uuid: string) {

    this.modalNotify = this.modal.open(NotifyPopupComponent, { backdrop: 'static', keyboard: false });
    this.modalNotify.componentInstance.messageTitle =
    this._translate.instant('share-list.messageTitle1');

    this.modalNotify.result.then((result) => {
      const formData = new FormData();
      formData.append('notify', result);

      this.modalLoad = this.modal.open(LoadingPopupComponent, { backdrop: 'static', keyboard: false });

      this.shareService.acceptUpdateShare(uuid, formData)
        .subscribe(res => {
          this.modalClose();
          this.ngOnInit();
        });
    });
  }

  rejectShare(uuid: string) {
    this.modalDelete = this.modal.open(DeletePopupComponent, { backdrop: 'static', keyboard: false });
    this.shareService.rejectUpdateShare(uuid)
      .subscribe(data => {
        this.modalCloseDelete();
        this.ngOnInit();
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  modalCloseDelete() {
    this.modalDelete.close();
  }

  findFilter() {
    this.paging.page = 0;
    this.getFilter();
  }


  getFilter() {
    this.shareService.getAllComments(this.paging)
      .subscribe((data: any) => {
        this.share = data.data;
        this.filterPaging = data.paging;
        this.filterPaging.pageNumber++;
      });
  }

  clearPaging() {
    this.paging = ShareCommonListComponent.getClearPaging();
  }

  cleanFilter() {
    this.clearPaging();
    this.shareService.getAllComments(this.paging)
      .subscribe((data: any) => {
        this.share = data.data;
        this.getCategory();
        this.getStatus(data);
      })
  }
  pageUp() {
    if (this.filterPaging.hasNextPage) {
      this.paging.page++;
      this.getFilter();
    }
  }
  pageDown() {
    if (this.filterPaging.hasPreviousPage) {
      this.paging.page--;
      this.getFilter();
    }
  }

  readonly downloadFile = () => {
    const showErrorModal = () => {
      const modalError = this.modal.open(ErrorPopupComponent);
      modalError.componentInstance.messageTitle =
          this._translate.instant('share-list.excelDownloadError.title');
      modalError.componentInstance.messageDescription =
          this._translate.instant('share-list.excelDownloadError.description');
    };
    const modalLoading = this.modal.open(LoadingPopupComponent, {backdrop: 'static', keyboard: false});
    const fileName = `Share-${(new Date()).toDateString()}.xlsx`;
    this.shareService.getExportBlob()
        .subscribe(
            (value: Blob) => {
              modalLoading.close();
              this.fileDownload.downloadBlob(value, fileName);
            },
            (error: any) => {
              console.error('Error while trying to download Excel file', error);
              showErrorModal();
              modalLoading.close();
            }
        );
  }
}
