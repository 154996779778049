import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { PartnerModel, PartnerUserModel } from "../../../../../shared/models/partner";
import { PartnersService } from "../../../../../core/services/partners.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs/index";
import { tap } from "rxjs/operators";

@Component({
    selector: "app-partneredit",
    templateUrl: "./partner-edit.component.html",
    styleUrls: ["./partner-edit.component.scss"],
})
export class PartnerEditComponent implements OnInit {

    editPartner: PartnerModel = new PartnerModel();
    managers: PartnerUserModel[] = [];
    trainees: PartnerUserModel[] = [];
    stockUsers: PartnerUserModel[] = [];
    mobileUsers: PartnerUserModel[] = [];
    modalLoad: NgbModalRef;
    errors: any[];
    id: string;
    partnerUserAdapter = PartnerUserModel.multiselectAdapter();

    constructor(
        private partnersService: PartnersService,
        private t: TranslateService,
        private _route: ActivatedRoute,
        private router: Router,
        private _location: Location,
        private _modal: NgbModal) {
    }

    ngOnInit() {
        this.id = this._route.snapshot.params.id;

        forkJoin([
            this.getPartner(),
            this.getAllManagers(),
            this.getAllTrainees(),
            this.getAllStockUsers(),
            this.getAllMobileUsers()
        ]).subscribe(() => {
            this.applyMissingManagers();
            this.applyMissingTrainees();
            this.applyMissingStockUsers();
            this.applyMissingMobileUsers();
        });
    }

    private getPartner() {
        return this.partnersService.getPartner(this.id)
            .pipe(tap((data: any) => {
                this.editPartner = data.data;
                this.editPartner.managers = this.editPartner.managers
                    .map(res => new PartnerUserModel(res));
                this.editPartner.trainees = this.editPartner.trainees
                    .map(res => new PartnerUserModel(res));
                this.editPartner.stockUsers = this.editPartner.stockUsers
                    .map(res => new PartnerUserModel(res));
                this.editPartner.mobileUsers = this.editPartner.mobileUsers
                    .map(res => new PartnerUserModel(res));
            }));
    }

    private getAllManagers() {
        return this.partnersService.getAllManagers()
            .pipe(tap((data: any) => {
                this.managers = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            }));
    }

    private getAllTrainees() {
        return this.partnersService.getAllTrainees()
            .pipe(tap((data: any) => {
                this.trainees = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            }));
    }

    private getAllStockUsers() {
            return this.partnersService.getAllStockUsers()
                .pipe(tap((data: any) => {
                    this.stockUsers = data.data
                        .map(res => new PartnerUserModel(res))
                        .sort(PartnerUserModel.defaultSort)
                }));
        }

    private getAllMobileUsers() {
            return this.partnersService.getAllMobileUsers()
                .pipe(tap((data: any) => {
                    this.mobileUsers = data.data
                        .map(res => new PartnerUserModel(res))
                        .sort(PartnerUserModel.defaultSort)
                }));
        }

    private applyMissingManagers() {
        let missingManagers = this.editPartner.managers
            .filter(partnerManager => !this.managers.find(manager => manager.uuid === partnerManager.uuid));

        if (missingManagers) {
            this.managers = [...this.managers, ...missingManagers];
            this.managers.sort(PartnerUserModel.defaultSort);
        }
    }

    private applyMissingTrainees() {
        let missingTrainees = this.editPartner.trainees
            .filter(partnerTrainee => !this.trainees.find(trainee => trainee.uuid === partnerTrainee.uuid));

        if (missingTrainees) {
            this.trainees = [...this.trainees, ...missingTrainees];
            this.trainees.sort(PartnerUserModel.defaultSort);
        }
    }

    private applyMissingStockUsers() {
        let missingStockUsers = this.editPartner.stockUsers
            .filter(partnerStockUser => !this.stockUsers.find(stockUser => stockUser.uuid === partnerStockUser.uuid));

        if (missingStockUsers) {
            this.stockUsers = [...this.stockUsers, ...missingStockUsers];
            this.stockUsers.sort(PartnerUserModel.defaultSort);
        }
    }

    private applyMissingMobileUsers() {
        let missingMobileUsers = this.editPartner.mobileUsers
            .filter(partnerMobileUser => !this.mobileUsers.find(mobileUser => mobileUser.uuid === partnerMobileUser.uuid));

        if (missingMobileUsers) {
            this.mobileUsers = [...this.mobileUsers, ...missingMobileUsers];
            this.mobileUsers.sort(PartnerUserModel.defaultSort);
        }
    }

    edit() {

        let editAux = {
            name: this.editPartner.name,
            managers: this.editPartner.managers,
            trainees: this.editPartner.trainees,
            stockUsers: this.editPartner.stockUsers,
            mobileUsers: this.editPartner.mobileUsers
        };

        this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});
        this.partnersService.updatePartner(this.id, editAux)
            .subscribe(data => {
                this.modalClose();
                this.router.navigateByUrl("home/partners");
            }, err => {
                this.errors = [this.t.instant("partner-edit.error")];
            });
    }

    modalClose() {
        this.modalLoad.close();
    }

    backClicked() {
        this._location.back();
    }
}
