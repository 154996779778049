export class Segment {

    uuid: string = "";
    key: string = "";
    type: string = "";
    name: any = "";

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }
}
