<div class="container-fluid">
    <!-- <button class="col-1 btn btn-dark btn-lg btn-block ml-auto" type="button" (click)="downloadFile()">
        <i class="fa fa-cloud-download mr-1" aria-hidden="true"></i>{{"users-file.download" | translate}}
    </button> -->
    <a class="col-1 btn btn-dark btn-lg btn-block d-md-flex justify-content-md-center align-items-md-baseline ml-auto" role="button" href="assets/excel/B-pro profile master.xlsx" 
        download="B-pro profile master.xlsx"><i class="fa fa-cloud-download mr-2" aria-hidden="true"></i>
        {{"user-file.download" | translate}}
    </a>
    <label>{{ "user-file.title-1" | translate }}</label>

    <form #fileForm="ngForm" method="POST" enctype="multipart/form-data">
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" accept=".xls, .xlsx" (change)="fileBrowseHandler($event.target.files)" />
            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
            <h3> {{ "user-file.title-2" | translate }} </h3>
            <p class="constraints-info"> {{ "user-file.type" | translate }} </p>
        </div>
        <div>
            <small>{{ "user-file.subtitle" | translate }}</small>
        </div>
        <div class="files-list d-flex flex-column">
            <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="file-icon">
                    <img src="./assets/img/excelicon.ico" alt="Excel File">
                </div>
                <div class="info">
                    <h4 class="name">
                        {{ file?.name }}
                    </h4>
                    <p class="size">
                        {{ file?.size | formatBytes: 3 }}
                    </p>
                    <div class="delete justify-content-end">
                        <i class="fa fa-trash-o fa-2x" aria-hidden="true" (click)="deleteFile(i)"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="input-group-append d-flex justify-content-center">
            <button class="btn btn-dark btn-lg btn-block" type="button" (click)="saveFile()">
                <i class="fa fa-floppy-o mr-2" aria-hidden="true"></i>{{"user-file.save" | translate}}
            </button>
        </div>
    </form>
    <div class="row mt-5">
        <div class="col-2 buttonBack d-md-flex align-items-md-center pl-2">
            <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/users/list']"></i>
        </div>
    </div>
</div>
