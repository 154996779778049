import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SalesToolsService} from '../../../../../core/services/sales-tools.service';
import {SalesToolsModel} from '../../../../../shared/models/salestools/sales-tools';
import {PartnersService} from '../../../../../core/services/partners.service';
import {PartnerModel} from '../../../../../shared/models/partner';
import {ModalService} from '../../../../../core/services/modal.service';

@Component({
  selector: 'app-catalogue-edit',
  templateUrl: './catalogue-edit.component.html',
  styleUrls: ['./catalogue-edit.component.scss']
})
export class CatalogueEditComponent implements OnInit {

  isAddMode: boolean;
  private id: number;
  item: SalesToolsModel;

  topVehiclesFile: File;
  psrCatalogueFile: File;
  oeCatalogueFile: File;
  partnerId: string;
  partners: PartnerModel[];
  errors = false;
  fileTypes: string[] = ['.pdf'];

  constructor(
      private salesToolsService: SalesToolsService,
      private partnersService: PartnersService,
      private modalService: ModalService,
      private route: ActivatedRoute,
      private router: Router
  ) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.isAddMode = !this.id;

    if (!this.isAddMode) {
      this.salesToolsService.getSalesTools(this.id.toString())
          .subscribe(data => this.item = data.data)
    }

    this.partnersService.getPartnersWithoutFileTypes(['VEHICLE_LIST', 'OE_CATALOGUE', 'PSR_CATALOGUE'])
        .subscribe(data => this.partners = data.data)
  }

  edit() {
    this.errors = false;
    const formFiles = {
      partnerId: this.partnerId || this.item.partner.uuid,
      vehicleList: this.topVehiclesFile,
      psrCatalogue: this.psrCatalogueFile,
      oeCatalogue: this.oeCatalogueFile
    }

    const formData = new FormData();
    Object.entries(formFiles)
        .forEach(([key, value]) => {
          if (value) {
            formData.append(key, value);
          }
        })

    const modalRef = this.modalService.showLoadingModal()
    this.salesToolsService.uploadFiles(formData)
        .subscribe(_ => {
              modalRef.close();
              this.router.navigateByUrl('/dashboard/home/salestools/catalogue')
            },
            _ => {
              modalRef.close();
              this.errors = true;
            }
        )
  }
}
