import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  @Input() messageTitle;
  @Input() messageDescription;

  constructor(public _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  accept(){
    this._activeModal.close(true);
  }

  decline(){
    this._activeModal.close(false);
  }

}
