export class Technologies {

  uuid: string = "";
  title: any = "";
  artitle: any = "";
  description: any = "";
  ardescription: any = "";
  image: string = "";

  constructor(values: Object = {}) {
    Object.assign(values, this);
  }
}