<div class="row card mb-2 pt-3 pb-3 pr-0">
    <div class="d-flex align-items-center bd-highlight">
        <div class="row w-100 d-flex flex-grow-1 bd-highlight align-items-center ml-md-3">
            <div class="col-4">
                <b> {{"sales-tools-item.partner" | translate }} : </b> {{stockListItem.partner.name}}
            </div>
            <div class="col-4">
                <b> {{"sales-tools-item.user" | translate }} : </b> {{stockListItem.stockList.username}}
            </div>
            <div class="col-4">
                <b> {{"sales-tools-item.lastUpdate" | translate }}: </b> {{stockListItem.stockList.lastUpdate | date: 'short'}}
            </div>
        </div>
        <div class="buttons d-flex mr-4">
            <button class="btn btn-dark mr-1"
                    type="button" (click)="downloadFile()">
                <i class="fa fa-download" aria-hidden="true"></i>
            </button>
            <button class="btn btn-danger" type="button" (click)="deleteFile()">
                <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
