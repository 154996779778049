<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ messageTitle | translate}}</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="decline()"></button>
</div>
<div class="modal-body">
    <p><strong>{{ messageDescription | translate }}</strong></p>
    <span class="text-danger">{{"custom-modal.messageDescription1" | translate}}</span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="decline()">{{"custom-modal.cancel" | translate}}</button>
    <button type="button" ngbAutofocus class="btn btn-success" (click)="accept()">{{"custom-modal.accept" | translate}}</button>
</div>
