<div class="container-fluid">
    <h1>Assessment details</h1>
    <br/>
    <div class="form-group d-md-flex flex-md-column col-4">
                <label for="assessor">{{"assessment-commercial-detail.assessor" | translate}}</label>
                <input [ngModel]="(assessment | async)?.assessor" id="assessor" name="assessor" type="text" class="form-control" readonly>

                <label for="partner">{{"assessment-commercial-detail.partner" | translate}}</label>
                <input [ngModel]="(assessment | async)?.partner" id="partner" name="assessor" type="text" class="form-control" readonly>

                <label for="dealer">{{"assessment-commercial-detail.dealer" | translate}}</label>
                <input [ngModel]="(assessment | async)?.dealer" id="dealer" name="assessor" type="text" class="form-control" readonly>

                <label for="trainee">{{"assessment-commercial-detail.trainee" | translate}}</label>
                <input [ngModel]="(assessment | async)?.trainee" id="trainee" name="assessor" type="text" class="form-control" readonly>
    </div>

    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <h3>{{"assessment-commercial-detail.visitPreparation" | translate}}</h3>
        <br/>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="appearance">{{"assessment-commercial-detail.appearance" | translate}}</label>
                <input [ngModel]="(assessment | async)?.appearance" name="appearance" id="appearance" type="text" class="form-control"
                       readonly>
            </div>
            <div class="flex-row col-md-3">
                <label for="salesFolder">{{"assessment-commercial-detail.salesFolder" | translate}}</label>
                <input [ngModel]="(assessment | async)?.salesFolder" name="salesFolder" id="salesFolder" type="text" class="form-control"
                       readonly>
            </div>
        </div>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="visitObjective">{{"assessment-commercial-detail.visitObjective" | translate}}</label>
                <input [ngModel]="(assessment | async)?.visitObjective" name="visitObjective" id="visitObjective" type="text" class="form-control"
                       readonly>
            </div>
            <div class="flex-row col-md-3">
                <label for="fleetCard">{{"assessment-commercial-detail.fleetCard" | translate}}</label>
                <input [ngModel]="(assessment | async)?.fleetCard" name="fleetCard" id="fleetCard" type="text" class="form-control"
                       readonly>
            </div>
        </div>
    </div>

    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <h3>{{"assessment-commercial-detail.workshopVisit" | translate}}</h3>
        <br/>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="workshopTour">{{"assessment-commercial-detail.workshopTour" | translate}}</label>
                <input [ngModel]="(assessment | async)?.workshopTour" name="appearance" id="workshopTour" type="text" class="form-control"
                       readonly>
            </div>
            <div class="flex-row col-md-3">
                <label for="tireCheck">{{"assessment-commercial-detail.tireCheck" | translate}}</label>
                <input [ngModel]="(assessment | async)?.tireCheck" name="tireCheck" id="tireCheck" type="text" class="form-control"
                       readonly>
            </div>
        </div>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="scrapTyre">{{"assessment-commercial-detail.scrapTyre" | translate}}</label>
                <input [ngModel]="(assessment | async)?.scrapTyre" name="scrapTyre" id="scrapTyre" type="text" class="form-control"
                       readonly>
            </div>
        </div>
    </div>
    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <h3>{{"assessment-commercial-detail.managerMeeting" | translate}}</h3>
        <br/>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="discussVisit">{{"assessment-commercial-detail.discussVisit" | translate}}</label>
                <input [ngModel]="(assessment | async)?.discussVisit" name="discussVisit" id="discussVisit" type="text" class="form-control"
                       readonly>
            </div>
            <div class="flex-row col-md-3">
                <label for="understandNeeds">{{"assessment-commercial-detail.understandNeeds" | translate}}</label>
                <input [ngModel]="(assessment | async)?.understandNeeds" name="understandNeeds" id="understandNeeds" type="text" class="form-control"
                       readonly>
            </div>
        </div>
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="presentingSolution">{{"assessment-commercial-detail.presentingSolution" | translate}}</label>
                <input [ngModel]="(assessment | async)?.presentingSolution" name="appearance" id="presentingSolution" type="text" class="form-control"
                       readonly>
            </div>
        </div>
    </div>

    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <div class="d-md-flex">
            <div class="flex-row col-md-3">
                <label for="percentage">{{"assessment-commercial-detail.percentage" | translate}}</label>
                <input [ngModel]="(assessment | async)?.percentage" name="percentage" id="percentage" type="text" class="form-control"
                       readonly>
            </div>
        </div>
        <div class="d-md-flex">
            <div class="flex-row col-md-6">
                <label for="comment">{{"assessment-commercial-detail.comment" | translate}}</label>
                <textarea [ngModel]="(assessment | async)?.comment" name="comment" id="comment" class="form-control"
                       readonly></textarea>
            </div>
        </div>
    </div>
</div>
