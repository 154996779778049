<div class="container-fluid">
	<form (ngSubmit)="edit(k)" #k="ngForm" method="POST" enctype="multipart/form-data">

		<div class="mb-5">
			<app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
		</div>

		<div class="viewImageLogo d-md-flex mb-5">

			<div class="col-md-12 d-md-flex">
				<div class="col-4 viewImageProduct d-md-flex">
					<div class="labelImageProduct">
						<label>{{"wheel-edit.productImage" | translate}}:</label>
					</div>
					<div class="imageProduct ml-5">
						<img [src]="editWheel.imageUrl" (error)="pictNotLoadingProduct($event)">
					</div>
				</div>
			</div>
		</div>

		<div class="form-group d-md-flex flex-md-column mb-md-5">

			<div class="d-md-flex justify-content-between">

				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.patternName" | translate}}</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.patternName" name="patternName" type="text" class="form-control"
						required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.patternName" name="patternName" type="text" class="form-control"
						required>
				</div>

                <div class="flex-row col-md-4">
                    <label>{{"wheel-edit.quality" | translate}}</label>
                    <select [(ngModel)]="editWheel.quality" name="quality" class="form-control form-control-lg"
                            required>
                        <option [value]='"STANDARD"'>{{"wheel-edit.quality.standard" | translate}}</option>
                        <option [value]='"PREMIUM"'>{{"wheel-edit.quality.premium" | translate}}</option>
                    </select>
                </div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.application" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.application.uuid" name="application" class="form-control form-control-lg"
						required>
						<option *ngFor="let application of applications" [value]="application.uuid">{{application.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.application.uuid" name="application" class="form-control form-control-lg"
                        required>
						<option *ngFor="let application of applications" [value]="application.uuid">{{application.name.ar}}</option>
					</select>
				</div>
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.subcategory" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.subcategory.uuid" name="subcategory" class="form-control form-control-lg"
						required>
						<option *ngFor="let subcategory of subcategories" [value]="subcategory.uuid">{{subcategory.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.subcategory.uuid" name="subcategory" class="form-control form-control-lg"
						required>
						<option *ngFor="let subcategory of subcategories" [value]="subcategory.uuid">{{subcategory.name.ar}}</option>
					</select>
				</div>
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.axle" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.axle.uuid" name="axle" class="form-control form-control-lg"
						required>
						<option *ngFor="let axle of axles" [value]="axle.uuid">{{axle.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.axle.uuid
" name="axle" class="form-control form-control-lg"
						required>
						<option *ngFor="let axle of axles" [value]="axle.uuid">{{axle.name.ar}}</option>
					</select>
				</div>
			</div>


			<div class="d-md-flex justify-content-between">
<!--				Technologies-->
				<div class="selectUSP col-md-6 mb-5">
					<div class="titleUSP d-flex justify-content-md-between align-items-md-center">
						<label>{{"wheel-edit.technology" | translate}}</label>
						<small class="uspSelect d-flex justify-content-end">{{"wheel-edit.youCanOnlySelectUpTo" | translate}}</small>
					</div>
					<select multiple *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.technologies" name="technology" class="form-control form-control-lg"
						#formSelect="ngModel">
						<option id="{{technology.uuid}}" *ngFor="let technology of technologies" [value]="technology"
							(mousedown)="onMouseDownTechnologies($event, technology.uuid, technology.name)"
							(mousemove)="$event.preventDefault()">
							{{technology.name.en}}
						</option>
					</select>
					<select multiple *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.technologies" name="technology" class="form-control form-control-lg"
						#formSelect="ngModel">
						<option id="{{technology.uuid}}" *ngFor="let technology of technologies" [value]="technology"
							(mousedown)="onMouseDownTechnologies($event, technology.uuid, technology.name)"
							(mousemove)="$event.preventDefault()">
							{{technology.name.ar}}
						</option>
					</select>
					

					<div class="d-md-flex justify-content-md-center">
						<button class="m-4 p-2 btn btn-outline-dark add" type="button" (click)="addTechnology()">
							<i class="fa fa-plus mr-1" aria-hidden="true"></i>{{"wheel-edit.addTechnology" | translate}}
						</button>
					</div>

					<div class="form-control form-control-lg SelectFormControl d-md-flex justify-content-md-between align-items-md-center"
						*ngFor="let technology of editWheel.technologies, let i=index">
						<div *ngIf="uspLang=='EN'">
							<i (click)="removeModel(i, technology.uuid)" class="fa fa-times"></i> {{technology.name.en}}
						</div>
						<div *ngIf="uspLang=='AR'">
							<i (click)="removeModel(i, technology.uuid)" class="fa fa-times"></i> {{technology.name.ar}}
						</div>
						<div class="buttonUsp">
							<button class="btn btn-secondary mr-2" type="button" (click)="viewTechnology(technology.uuid)">
								<i class="fa fa-eye" aria-hidden="true"></i>
							</button>

							<button class="btn btn-dark mr-2" type="button" (click)="editTechnology(technology.uuid)">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</button>

							<button class="btn btn-danger" type="button" (click)="deleteButtonTechnology(technology.uuid, i)">
								<i class="fa fa-trash" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
				<div class="selectFeatures col-md-6 mb-5">
<!--					Features-->
					<div class="titleUSP d-flex justify-content-md-between align-items-md-center">
						<label>{{"wheel-edit.feature" | translate}}</label>
					</div>
					<div class="d-md-flex justify-content-md-center">
						<button class="m-4 p-2 btn btn-outline-dark add" type="button" (click)="addFeature()">
							<i class="fa fa-plus mr-1" aria-hidden="true"></i>{{"wheel-edit.addFeature" | translate}}
						</button>
					</div>

					<div class="form-control form-control-lg SelectFormControl d-md-flex justify-content-md-between align-items-md-center"
						 *ngFor="let features of editWheel.features, let i=index">
						<div *ngIf="uspLang=='EN'">
							<i (click)="removeFeature(i, features.uuid)" class="fa fa-times"></i> {{features.title.en}}
						</div>
						<div *ngIf="uspLang=='AR'">
							<i (click)="removeFeature(i, features.uuid)" class="fa fa-times"></i> {{features.title.ar}}
						</div>
						<div class="buttonUsp">
							<button class="btn btn-secondary mr-2" type="button" (click)="viewFeature(features.uuid)">
								<i class="fa fa-eye" aria-hidden="true"></i>
							</button>

							<button class="btn btn-dark mr-2" type="button" (click)="editFeature(features.uuid)">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
			</div>

			
			<div class="updateImage mb-3">

                
				<div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 selectImageProduct">
                        <div *ngIf = "editWheel.imageUrl && !fileDataClearPersisted" class="preview-edit-image">
                            <img [src]="editWheel.imageUrl" (error)="pictNotLoadingProduct($event)">
                            <button class="btn btn-danger" type="button" (click)="deletePersistedImage('IMAGE')">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </div>
                        
						<div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
							<label class="mr-3">{{"wheel-edit.productImageToUpload" | translate}}</label>
							<small>{{"wheel-edit.youCanOnlyUploadOneImage" | translate}}</small>
						</div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDropped('IMAGE', $event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandler('IMAGE', $event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<h3>{{"wheel-edit.dragAndDrop" | translate}}</h3>
							<h2 class="productImage">{{"wheel-edit.productIMAGE" | translate}}</h2>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
				</div>

				<div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="fileDataSafePreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.productImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct('IMAGE')" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="fileDataSafePreviewUrl">
							</div>
						</div>
					</div>
				</div>
                
			</div>
        
        <div class="updateImage mb-3">
                
				<div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 selectImageProduct">

                        <div class="preview-edit-image">
                            <div *ngIf = "editWheel.tyreImage1Url && !fileTyre1DataClearPersisted">
                                <img [src]="editWheel.tyreImage1Url" (error)="pictNotLoadingProduct($event)">
                                <button class="btn btn-danger" type="button" (click)="deletePersistedImage('TYRE1')">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDropped('TYRE1', $event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandler('TYRE1', $event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
                    
                    <div class="col-4 selectImageProduct">

                        <div class="preview-edit-image">
                            <div *ngIf = "editWheel.tyreImage2Url && !fileTyre2DataClearPersisted">
                                <img [src]="editWheel.tyreImage2Url" (error)="pictNotLoadingProduct($event)">
                                <button class="btn btn-danger" type="button" (click)="deletePersistedImage('TYRE2')">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDropped('TYRE2', $event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandler('TYRE2', $event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
                    
                    <div class="col-4 selectImageProduct">

                        <div class="preview-edit-image">
                            <div *ngIf = "editWheel.tyreImage3Url && !fileTyre3DataClearPersisted">
                                <img [src]="editWheel.tyreImage3Url" (error)="pictNotLoadingProduct($event)">
                                <button class="btn btn-danger" type="button" (click)="deletePersistedImage('TYRE3')">
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDropped('TYRE3', $event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandler('TYRE3', $event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
				</div>

				<div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="fileTyre1DataSafePreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.productImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct('TYRE1')" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="fileTyre1DataSafePreviewUrl">
							</div>
						</div>
					</div>
                    
                    <div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="fileTyre2DataSafePreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.productImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct('TYRE2')" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="fileTyre2DataSafePreviewUrl">
							</div>
						</div>
					</div>
                    
                    <div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="fileTyre3DataSafePreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.productImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct('TYRE3')" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="fileTyre3DataSafePreviewUrl">
							</div>
						</div>
					</div>
				</div>
			<div class="updateImage mb-3">
				<div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 selectImagePattern">
                        <div *ngIf = "editWheel.patternImageUrl && !filePatternDataClearPersisted" class="preview-edit-image">
                            <img [src]="editWheel.patternImageUrl" (error)="pictNotLoadingProduct($event)">
                            <button class="btn btn-danger" type="button" (click)="deletePersistedImage('PATTERN')">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                        </div>
                        
						<div class="imagePreview d-md-flex justify-content-md-between align-items-md-center">
							<label class="mr-3">{{"wheel-edit.patternImageToUpload" | translate}}</label>
							<small>{{"wheel-edit.youCanOnlyUploadOneImage" | translate}}</small>
						</div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							 appDnd (fileDropped)="onFileDropped('PATTERN', $event)">
							<input type="file" accept=".jpg, .png, .svg"
								   (change)="fileBrowseHandler('PATTERN', $event)"/>
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<h3>{{"wheel-edit.dragAndDrop" | translate}}</h3>
							<h2 class="productImage">{{"wheel-edit.patternImage" | translate}}</h2>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
				</div>
				<div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">
					<div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="filePatternDataSafePreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.patternImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct('PATTERN')" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="filePatternDataSafePreviewUrl">
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

		<div class="row">
			<div class="col-2 buttonBack d-md-flex align-items-md-center">
				<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
			</div>
			<div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
				<button type="submit" class="btn btn-dark justify-content-md-center align-items-md-center">
					<!-- [disabled]="!k.form.valid" -->
					<i class="fa fa-save mr-2"></i>{{"wheel-edit.save" | translate}}
				</button>
			</div>
			<div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center">
				<button type="button" class="btn btn-dark justify-content-md-center align-items-md-center"
					[routerLink]="['/dashboard/home/wheels/detail', editWheel.uuid]">
					<i class="fa fa-eye mr-2"></i>{{"wheel-edit.detail" | translate}}
				</button>
			</div>
		</div>
	</form>
</div>
