import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

import { Assessment } from 'src/app/shared/models/assessment';
import { AssessmentService } from 'src/app/core/services/assessment.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-assessment-list',
  templateUrl: './assessment-list.component.html',
  styleUrls: ['./assessment-list.component.scss']
})
export class AssessmentListComponent implements OnInit {

  assessments: Assessment[] = [];
  searchText: string = '';
  modalLoad: NgbModalRef;
  private API = environment.baseUrlCMS;
  private EXCEL = '/assessment/export';
  private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  filter = {
    pageNumber: 1,
    sizeSize: 5,
    totalNumberOfRecords: null,
    totalNumberOfPages: null,
    hasPreviousPage: false,
    hasNextPage: false,
  }

  paging = {
    page: 0,
    size: 20,
    query: ''
  }

  constructor(private assessmentService: AssessmentService, 
    private _modal: NgbModal, 
    private sanitizer: DomSanitizer,
    private _translate: TranslateService) {
      this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.ngOnInit();
      });
    }

  ngOnInit() {
    let actualLanguage = this._translate.currentLang;
    this.assessmentService.getAssessment(this.paging, actualLanguage.toUpperCase())
      .subscribe((data: any) => {
        this.assessments = data.data;
        this.filter = data.paging;
        this.filter.pageNumber++;
        //console.log(data);
      });
  }

  //Validation Assessment
  accept(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    this.assessmentService.acceptAssessment(uuid)
      .subscribe(data => {
        this.modalClose();
        this.ngOnInit();
        //console.log(data);
      });
  }

  reject(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    this.assessmentService.rejectAssessment(uuid)
      .subscribe(data => {
        this.modalClose();
        this.ngOnInit();
        //console.log(data);
      });
  }

  //Modals
  modalClose() {
    this.modalLoad.close();
  }

  findFilter() {
    this.paging.page = 0;
    this.getFilter();
  }

  getFilter() {
    //console.log(this.paging)
    let actualLanguage = this._translate.currentLang;
    this.assessmentService.getAssessment(this.paging, actualLanguage.toUpperCase())
      .subscribe((data: any) => {
        this.assessments = data.data;
        this.filter = data.paging;
        this.filter.pageNumber++;
        //console.log(data);
      });
  }

  cleanFilter() {
    this.paging = {
      page: 0,
      size: 20,
      query: ''
    }
    let actualLanguage = this._translate.currentLang;
    this.assessmentService.getAssessment(this.paging, actualLanguage.toUpperCase())
      .subscribe((data: any) => {
        this.assessments = data.data;
      })
  }

  pageUp() {
    if (this.filter.hasNextPage) {
      this.paging.page++;
      //console.log(this.filter.pageNumber);
      this.getFilter();
    }
  }
  pageDown() {
    if (this.filter.hasPreviousPage) {
      this.paging.page--;
      //console.log(this.filter.pageNumber)
      this.getFilter();
    }
  }
  
  downloadFile() {
    this.assessmentService.getExcel().subscribe(data => {
    var byteArray = new Uint8Array(data);
    var a = window.document.createElement('a');

    a.href = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/octet-stream'}));
    a.download = "Assessment.xlsx";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    });
  }
}