import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { OeService } from 'src/app/core/services/oe.service';
import { BrandModel } from 'src/app/shared/models/brand';

@Component({
  selector: 'app-oe-detail-brands',
  templateUrl: './oe-detail-brands.component.html',
  styleUrls: ['./oe-detail-brands.component.scss']
})
export class OeDetailBrandsComponent implements OnInit {

  brand: BrandModel = new BrandModel();
  id: string;
  uspLang: string = 'EN';

  constructor(private oeService: OeService, private _location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    let id = this.route.snapshot.params.id;

    this.oeService.getItem(id).subscribe((data: any) => {
      this.brand = data.data;
      //console.log(this.brand);
    })
  }

  pictNotLoadingBrand(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  backClicked() {
    this._location.back();
  }

}
