import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oe',
  templateUrl: './oe.component.html',
  styleUrls: ['./oe.component.scss']
})
export class OeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
