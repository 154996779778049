import { Pipe, PipeTransform } from '@angular/core';
import { EditionLanguage } from "../../components/forms/lang-edition-switch/edition-language";

@Pipe({
  name: 'filterWheel'
})
export class WheelPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 2) return value;

    const resultWheels = [];
    for (const wheel of value) {
        const containsPatternSlogan = Object.keys(EditionLanguage)
            .map(l => l.toLowerCase())
            .map(l => wheel.patternSlogan[l] || '')
            .some(ps => ps.includes(arg.toLowerCase()));
        
      if (wheel.familyName.toLowerCase().indexOf(arg.toLowerCase()) > -1 ||
          containsPatternSlogan ||
           wheel.patternName.toLowerCase().indexOf(arg.toLowerCase()) > -1) {

        resultWheels.push(wheel);
      };
    };
    return resultWheels;
  }

}
