<div class="container-fluid">
    <div class="mb-5">
        <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
    </div>
    <form (ngSubmit)="createWheel(k)" #k="ngForm" method="POST" enctype="multipart/form-data">
        <div class="form-group mb-md-5">
            <div class="d-md-flex justify-content-between">
                <div class="flex-row col-md-4">
                    <label>{{"wheel-add.patternName" | translate}}</label>
                    <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'"
                        [(ngModel)]="addProduct.patternName" name="patternName" required>
                    <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'"
                        [(ngModel)]="addProduct.patternName" name="patternName" required>
                </div>

                <div class="flex-row col-md-4">
                    <label>{{"wheel-edit.quality" | translate}}</label>
                    <select [(ngModel)]="addProduct.quality" name="quality" class="form-control form-control-lg"
                        required>
                        <option [value]='"STANDARD"'>{{"wheel-edit.quality.standard" | translate}}</option>
                        <option [value]='"PREMIUM"'>{{"wheel-edit.quality.premium" | translate}}</option>
                    </select>
                </div>
            </div>

            <div class="d-md-flex justify-content-between">

                <div class="flex-row col-md-4">
                    <label>{{"wheel-add.application" | translate}}</label>
                    <select *ngIf="uspLang=='EN'" [(ngModel)]='addProduct.application.uuid' name="application"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let application of applications" [value]="application.uuid">
                            {{application.name.en}}
                        </option>
                    </select>
                    <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.application.uuid' name="application"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let application of applications" [value]="application.uuid">
                            {{application.name.ar}}
                        </option>
                    </select>
                </div>

                <div class="flex-row col-md-4">
                    <label>{{"wheel-add.subcategory" | translate}}</label>
                    <select *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.subcategory.uuid" name="subcategory"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let subcategory of subcategories" [value]="subcategory.uuid">
                            {{subcategory.name.en}}
                        </option>
                    </select>
                    <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.subcategory.uuid' name="subcategory"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let subcategory of subcategories" [value]="subcategory.uuid">
                            {{subcategory.name.ar}}
                        </option>
                    </select>
                </div>

                <div class="flex-row col-md-4">
                    <label>{{"wheel-add.axle" | translate}}</label>
                    <select *ngIf="uspLang=='EN'" [(ngModel)]='addProduct.axle.uuid' name="axle"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let axle of axles" [value]="axle.uuid">{{axle.name.en}}</option>
                    </select>
                    <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.axle.uuid' name="axle"
                        class="form-control form-control-lg" required>
                        <option *ngFor="let axle of axles" [value]="axle.uuid">{{axle.name.ar}}</option>
                    </select>
                </div>
            </div>

            <div class="d-md-flex justify-content-between">
                <div class="selectUSP col-md-6 mb-5">
                    <div class="titleUSP d-flex justify-content-md-between align-items-md-center">
                        <label>{{"wheel-add.technologies" | translate}}</label>
                        <small
                            class="uspSelect d-flex justify-content-end">{{"wheel-add.youCanOnlySelectUpTo" | translate}}</small>
                    </div>
                    <select multiple *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.technologies" name="technologies"
                        class="form-control form-control-lg" #formSelect="ngModel">
                        <option id="{{technology.uuid}}" *ngFor="let technology of technologies" [value]="technology"
                            (mousedown)="onMouseDownTechnology($event, technology.uuid, technology.name)"
                            (mousemove)="$event.preventDefault()">
                            {{technology.name.en}}
                        </option>
                    </select>
                    <select multiple *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.technologies" name="technology"
                        class="form-control form-control-lg" #formSelect="ngModel">
                        <option id="{{technology.uuid}}" *ngFor="let technology of technologies" [value]="technology"
                            (mousedown)="onMouseDownTechnology($event, technology.uuid, technology.name)"
                            (mousemove)="$event.preventDefault()">
                            {{technology.name.ar}}
                        </option>
                    </select>

                    <div *ngIf="uspLang=='EN'">
                        <div class="form-control form-control-lg SelectFormControl mt-2"
                            *ngFor="let technology of addProduct.technologies, let i=index">
                            <i (click)="removeModel(i, technology.uuid)" class="fa fa-times"></i> {{technology.name.en}}
                        </div>
                    </div>
                    <div *ngIf="uspLang=='AR'">
                        <div class="form-control form-control-lg SelectFormControl mt-2"
                            *ngFor="let technology of addProduct.technologies, let i=index">
                            <i (click)="removeModel(i, technology.uuid)" class="fa fa-times"></i> {{technology.name.ar}}
                        </div>
                    </div>
                </div>
            </div>



            <div class="updateImage mb-3">
                <div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">
                    <div class="col-4 selectImageProduct">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label class="mr-3">{{"wheel-add.productImageToUpload" | translate}}</label>
                            <small>{{"wheel-add.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped('IMAGE', $event)">
                            <input type="file" accept=".jpg, .png, .svg"
                                (change)="fileBrowseHandler('IMAGE', $event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"wheel-add.dragAndDrop" | translate}}</h3>
                            <h2 style="color: red;">{{"wheel-add.productImage" | translate}}</h2>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>
                </div>

                <div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">
                    <div class="col-4 logoPreviewPRODUCT mb-3">
                        <div class="imagePreview" *ngIf="fileDataSafePreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.productImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageProduct('IMAGE')"
                                    aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="imagePreviewWheel d-flex justify-content-md-center">
                                <img [src]="fileDataSafePreviewUrl">
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="updateImage mb-3">
                <div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">
                    <div class="col-4 selectImageProduct">
                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped('TYRE1', $event)">
                            <input type="file" accept=".jpg, .png, .svg"
                                (change)="fileBrowseHandler('TYRE1', $event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="col-4 selectImageProduct">
                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped('TYRE2', $event)">
                            <input type="file" accept=".jpg, .png, .svg"
                                (change)="fileBrowseHandler('TYRE2', $event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="col-4 selectImageProduct">
                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped('TYRE3', $event)">
                            <input type="file" accept=".jpg, .png, .svg"
                                (change)="fileBrowseHandler('TYRE3', $event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>
                </div>

                <div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">
                    <div class="col-4 logoPreviewPRODUCT mb-3">
                        <div class="imagePreview" *ngIf="fileTyre1DataPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.productImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageProduct('TYRE1')"
                                    aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="imagePreviewWheel d-flex justify-content-md-center">
                                <img [src]="fileTyre1DataPreviewUrl">
                            </div>
                        </div>
                    </div>

                    <div class="col-4 logoPreviewPRODUCT mb-3">
                        <div class="imagePreview" *ngIf="fileTyre2DataPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.productImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageProduct('TYRE2')"
                                    aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="imagePreviewWheel d-flex justify-content-md-center">
                                <img [src]="fileTyre2DataPreviewUrl">
                            </div>
                        </div>
                    </div>

                    <div class="col-4 logoPreviewPRODUCT mb-3">
                        <div class="imagePreview" *ngIf="fileTyre3DataPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.productImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageProduct('TYRE3')"
                                    aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="imagePreviewWheel d-flex justify-content-md-center">
                                <img [src]="fileTyre3DataPreviewUrl">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack">
                <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels-commercial/list']"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid || !this.fileData">
                    <i class="fa fa-save mr-2"></i>{{"wheel-add.save" | translate}}</button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>