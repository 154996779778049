import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs';
import {AssessmentCommercialService} from '../../../../../core/services/commercial/assessment-commercial.service';
import {ActivatedRoute} from '@angular/router';
import {AssessmentCommercial} from '../../../../../shared/models/commercial/assessment-commercial.model';

@Component({
  selector: 'app-assessment-commercial-detail',
  templateUrl: './assessment-commercial-detail.component.html',
  styleUrls: ['./assessment-commercial-detail.component.scss']
})
export class AssessmentCommercialDetailComponent implements OnInit {

  assessment: Subject<AssessmentCommercial> = new Subject<AssessmentCommercial>();

  constructor(
      private route: ActivatedRoute,
      private assessmentCommercialService: AssessmentCommercialService
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.params.id;
    this.assessmentCommercialService.getAssessmentById(id)
        .subscribe(value => {
          this.assessment.next(value.data);
        })
  }

}
