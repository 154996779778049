import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Wheel } from 'src/app/shared/models/wheels/wheel';
import { ProductsService } from 'src/app/core/services/products.service';
import { Technologies } from 'src/app/shared/models/wheels/objectWheel/technologies';
import { TechnologiesService } from 'src/app/core/services/technologies.service';
import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { UspService } from 'src/app/core/services/usp.service';
import { ProductRft } from 'src/app/shared/models/wheels/objectWheel/rft';
import { Light } from 'src/app/shared/models/wheels/objectWheel/light';
import { Zoom } from 'src/app/shared/models/wheels/objectWheel/zoom';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { NotifyPopupComponent } from 'src/app/shared/components/popups/notify-popup/notify-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';


@Component({
  selector: 'app-wheel-add',
  templateUrl: './wheel-add.component.html',
  styleUrls: ['./wheel-add.component.scss']
})

export class WheelAddComponent implements OnInit {

  addProduct: Wheel = new Wheel();
  productRft: ProductRft = new ProductRft();
  lightWheel3D: Light = new Light();
  zoom3D: Zoom = new Zoom();
  modalLoad: NgbModalRef;
  modalNotify: NgbModalRef;
  usp: Usp[] = [];
  technologies: Technologies[] = [];
  segment: any[] = [];
  operation: any[] = [];
  oeRep: any[] = [];
  errors: string[] = [];
  errorsm: string;

  fileDataProduct: File = null;
  fileDataCms: File = null;
  fileDataApp: File = null;
  previewUrlProduct: any = null;
  previewUrlCms: any = null;
  previewUrlApp: any = null;
  productPreviewUrl: SafeUrl;
  cmsPreviewUrl: SafeUrl;
  appPreviewUrl: SafeUrl;
  uspLang: string = 'EN';

  constructor(
    private _router: Router,
    private pservice: ProductsService,
    private techService: TechnologiesService,
    private uspService: UspService,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.attrDataSegment();
    this.attrDataOperation();
    this.attrDataOe();
    this.attrDataUsp();
    this.attrDataTech();
  }

  // Obtaining data to add.
  attrDataSegment() {
    this.pservice.getAttrSegment().subscribe((data: any) => {
      this.segment = data.data;
    });
  }

  attrDataOperation() {
    this.pservice.getAttrOperation().subscribe((data: any) => {
      this.operation = data.data;
    });
  }

  attrDataOe() {
    this.pservice.getAttrOe().subscribe((data: any) => {
      this.oeRep = data.data;
    });
  }

  attrDataUsp() {
    this.uspService.getUspAll().subscribe((data: any) => {
      this.usp = data.data;
    });
  }

  attrDataTech() {
    this.techService.getTechAll().subscribe((data: any) => {
      this.technologies = data.data;
    });
  }

  refreshSelectedUsp(){
    setTimeout(()=>{
      this.addProduct.usp.forEach(usp => {
        document.getElementById(usp.key)['selected'] = true;
      });
    },100);
    
  }

  //MULTI-SELECT USP
  onMouseDownUsp(event: MouseEvent, item: string, uuid: string, name: string, arname: string, appImgUrl: string, cmsImgUrl: string) {
    event.preventDefault();

    if (!event.target['selected'] && (this.addProduct.usp.length < 3)) {
      event.target['selected'] = !event.target['selected'];
      this.addProduct.usp.push({ key: item, name: name, arname: arname, uuid: uuid, appImgUrl: appImgUrl, cmsImgUrl: cmsImgUrl });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.addProduct.usp.forEach(usp => {
        if (usp.key == item) i = index;
        //console.log(i);
        index++;
      });
      this.addProduct.usp.splice(i, 1);
      if (index > -1) { }
    }
  }
  removeModel(i: number, key: string) {
    document.getElementById(key)['selected'] = false;
    this.addProduct.usp.splice(i, 1);
  }


  //MULTI-SELECT TECHNOLOGIES
  onMouseDownTechnologies(event: MouseEvent, uuid: string, title: string, artitle: string, description: string, ardescription: string, image: string) {
    event.preventDefault();

    if (!event.target['selected']) {
      event.target['selected'] = !event.target['selected'];
      this.addProduct.technologies.push({ uuid: uuid, title: title, artitle: artitle, description: description, ardescription: ardescription, image: image });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.addProduct.technologies.forEach(technologies => {
        if (technologies.title == title) i = index;
        //console.log(i);
        index++;
      });
      this.addProduct.technologies.splice(i, 1);
      if (index > -1) { }
    }
  }
  removeModelTechnologies(i: number, title: string) {
    document.getElementById(title)['selected'] = false;
    this.addProduct.technologies.splice(i, 1);
  }

  onmousemove = function (event) {
    event.preventDefault();
  }

  //UPDATE PRODUCT IMAGE
  //On file drop handler
  onFileDroppedProduct($event) {
    this.prepareFilesListProduct($event);
  }

  //Handle file from browsing
  fileBrowseHandlerProduct($event) {
    this.prepareFilesListProduct($event);
  }

  //Delete Image
  deleteImageProduct() {
    this.productPreviewUrl = null;
    this.previewUrlProduct = null;
  }

  prepareFilesListProduct(fileInput: any) {
    this.fileDataProduct = <File>fileInput.target.files[0];
    this.previewProduct();
  }

  previewProduct() {
    let mimeTypeProduct = this.fileDataProduct.type;
    if (mimeTypeProduct.match(/image\/*/) == null) {
      return;
    }

    let readerProduct = new FileReader();
    readerProduct.readAsDataURL(this.fileDataProduct);
    readerProduct.onload = (_event) => {
      this.previewUrlProduct = readerProduct.result;
      this.productPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlProduct);
    }
  }

  //UPDATE CMS LOGO
  //On file drop handler
  onFileDroppedCms($event) {
    this.prepareFilesListCms($event);
  }

  //Handle file from browsing
  fileBrowseHandlerCms($event) {
    this.prepareFilesListCms($event);
  }

  //Delete Image
  deleteImageCMS() {
    this.cmsPreviewUrl = null;
    this.previewUrlCms = null;
  }

  prepareFilesListCms(fileInput: any) {
    this.fileDataCms = <File>fileInput.target.files[0];
    this.previewCms();
  }

  previewCms() {
    let mimeTypeCms = this.fileDataCms.type;
    if (mimeTypeCms.match(/image\/*/) == null) {
      return;
    }

    let readerCms = new FileReader();
    readerCms.readAsDataURL(this.fileDataCms);
    readerCms.onload = (_event) => {
      this.previewUrlCms = readerCms.result;
      this.cmsPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlCms);
    }
  }


  //UPDATE APP LOGO
  //On file drop handler
  onFileDroppedApp($event) {
    this.prepareFilesListApp($event);
  }

  //Handle file from browsing
  fileBrowseHandlerApp($event) {
    this.prepareFilesListApp($event);
  }

  //Delete Image
  deleteImageApp() {
    this.appPreviewUrl = null;
    this.previewUrlApp = null;
  }

  prepareFilesListApp(fileInput: any) {
    this.fileDataApp = <File>fileInput.target.files[0];
    this.previewApp();
  }

  previewApp() {
    let mimeTypeApp = this.fileDataApp.type;
    if (mimeTypeApp.match(/image\/*/) == null) {
      return;
    }

    let readerApp = new FileReader();
    readerApp.readAsDataURL(this.fileDataApp);
    readerApp.onload = (_event) => {
      this.previewUrlApp = readerApp.result;
      this.appPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlApp);
    }
  }

  maxNumber(event: any) {
    if (parseFloat(this.addProduct.modelIntentisy) >= 2) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-add.messageTitle');
          modal.componentInstance.messageDescription = 
          // 'The value can only be from 0.00001 to 2, both included.';
          this._translate.instant('wheel-add.messageDescription');
          this.modalClose();
    }
    parseFloat(this.addProduct.modelIntentisy) == 0.00001 && parseFloat(this.addProduct.modelIntentisy) <= 2;
    return this.addProduct.modelIntentisy;
  }

  maxZoom(event: any) {
    if (parseFloat(this.addProduct.modelZoom) >= 15 && parseFloat(this.addProduct.modelZoom) !== null) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-add.messageTitle');
          modal.componentInstance.messageDescription = 
          // 'The entered value cannot be greater than 15.';
          this._translate.instant('wheel-add.messageDescription1');
          this.modalClose();
    }
  }

  maxRotation(event: any) {
    if (parseFloat(this.addProduct.modelRotation) > 6.283) {
      let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error entering value.';
          this._translate.instant('wheel-add.messageTitle');
          modal.componentInstance.messageDescription = 
          // 'The entered value cannot be larger than 6,283.';
          this._translate.instant('wheel-add.messageDescription2');
          this.modalClose();
    }
  }

  //PRODUCT CREATE
  createWheel(form: NgForm) {

    let newProduct = {
      usp: '',
      technologies: ''
    };

    this.addProduct.usp.forEach(Usp => {
      if (newProduct.usp == '')
        newProduct.usp = newProduct.usp + Usp.key;
      else
        newProduct.usp = newProduct.usp + ',' + Usp.key;
    });

    //console.log(newProduct.usp);

    this.addProduct.technologies.forEach(Technologies => {
      if (newProduct.technologies == '')
        newProduct.technologies = newProduct.technologies + Technologies.uuid;
      else
        newProduct.technologies = newProduct.technologies + ',' + Technologies.uuid;
    });

    //console.log(newProduct.technologies);

    this.modalNotify = this._modal.open(NotifyPopupComponent, { backdrop: "static", keyboard: false });
    this.modalNotify.componentInstance.messageTitle = "Notification";

    this.modalNotify.result.then((result) => {

      const formData = new FormData();
      formData.append('familyName', this.addProduct.familyName);
      formData.append('patternName', this.addProduct.patternName);
      formData.append('patternSlogan', this.addProduct.patternSlogan);
      formData.append('arpatternSlogan', this.addProduct.arpatternSlogan);
      formData.append('segment', this.addProduct.segment.key);
      formData.append('operation', this.addProduct.operation.key);
      formData.append('oeRep', this.addProduct.oeRep.key);
      formData.append('usp', newProduct.usp);
      formData.append('technologies', newProduct.technologies);
      formData.append('rft', JSON.stringify(this.addProduct.rft));
      formData.append('productOrder', this.addProduct.productOrder);
      formData.append('applogo', this.fileDataApp);
      formData.append('cmslogo', this.fileDataCms);
      formData.append('image', this.fileDataProduct);
      formData.append('notify', result);
      formData.append('modelLight2', this.addProduct.modelLight2);
      formData.append('modelZoom', this.addProduct.modelZoom);
      formData.append('modelIntentisy', this.addProduct.modelIntentisy.toString());
      formData.append('modelRotation', this.addProduct.modelRotation.toString());
      formData.append('modelFlHeight', this.addProduct.modelFlHeight.toString());


      //console.log(result);


      //console.log(this.wheel);
      this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
      // if (this.modalLoad) {
      //   $(document).keydown(function (objEvent) {
      //     if (objEvent.keyCode == 9) {  //Tab pressed
      //       objEvent.preventDefault(); //Stops its action
      //     }
      //   });
      // }

      this.pservice.createProduct(formData)
        .subscribe(res => {
          //console.log(res);
          this.modalClose();
          this._router.navigateByUrl('home/wheels/list')
        }, err => {
          if (err.error.errors)
            this.errors = err.error.errors;
          else
            this.errorsm = err.error.message;
        });
    });
  }

  modalClose() {
    this.modalLoad.close();
  }
}
