import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { Attr } from 'src/app/shared/models/wheels/objectWheel/attr';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

@Component({
  selector: 'app-categories-edit',
  templateUrl: './categories-edit.component.html',
  styleUrls: ['./categories-edit.component.scss']
})
export class CategoriesEditComponent implements OnInit {

  editCategory: Attr = new Attr();
  modalLoad: NgbModalRef;
  typeCategory: any = {
    type: 'SHARECATEGORY'
  };
  id: string;
  errors: any[] = [];
  uspLang: string = 'EN';

  constructor(
    private _location: Location,
    private categoryService: CategoriesService,
    private _modal: NgbModal,
    private _route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.id = this._route.snapshot.params.id;

    this.categoryService.getAttrDetailCategory(this.id)
      .subscribe((data: any) => {
        this.editCategory = data.data;
      });
  }

  //EDIT CATEGORY
  categoryEdit(form: NgForm) {

    let updateCategory = {
      name: this.editCategory.name.en,
      arname: this.editCategory.name.ar,
      key: this.editCategory.key = this.editCategory.name.en.toUpperCase(),
      type: this.typeCategory.type
    };

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
    //   $(document).keydown(function (objEvent) {
    //     if (objEvent.keyCode == 9) {  //Tab pressed
    //       objEvent.preventDefault(); //Stops its action
    //     }
    //   });
    // }
    this.categoryService.getAttrUpdateCategory(this.id, updateCategory)
      .subscribe(data => {
        this.modalClose();
        this.router.navigateByUrl('home/categories');
      }, err => {
        this.errors = err.error.errors;
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }

}
