<div class="container-fluid">
	<form (ngSubmit)="edit(k)" #k="ngForm">
		<div class="d-md-flex justify-content-md-start">
			<h2>{{'region-edit.editRegion' | translate}}</h2>
		</div>
		<div class="mb-5"></div>
		<div class="d-flex justify-content-md-start">
			<app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
		</div>
		<div class="mb-5"></div>
		<div class="d-md-flex justify-content-between">
			<div class="flex-row col-6">
				<label>{{"region-edit.name" | translate}}</label>
				<input [(ngModel)]='editRegion.name[uspLang.toLowerCase()]' name="name" type="text"
					class="form-control">
			</div>
			<div class="mb-5 flex-row col-6">
				<app-multiselect label="{{'region-edit.responsables'| translate}}" [availableOptions]="users"
					[adapter]="responsableAdapter"
					[(value)]="editRegion.responsables"></app-multiselect>
			</div>
		</div>

		<div class="d-md-flex">
			<div class="buttonSave mr-1">
				<button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
					{{"region-edit.save" | translate}}</button>
			</div>
			<div class="buttonBack">
					<button type="button" class="btn btn-outline-danger" (click)="backClicked()">{{"region-edit.cancel" | translate}}</button>
			</div>
		</div>
	</form>

	<div class="alert alert-danger" role="alert" *ngIf="errors.length">
		<div *ngFor="let error of errors">
			<h5>{{error}}</h5>
		</div>
	</div>
</div>