import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EditionLanguage } from './edition-language';

@Component({
  selector: 'app-lang-edition-switch',
  templateUrl: './lang-edition-switch.component.html',
  styleUrls: ['./lang-edition-switch.component.scss']
})
export class LangEditionSwitchComponent implements OnInit {

  @Input()
  language?: string;

  @Output()
  languageChange: EventEmitter<EditionLanguage> = new EventEmitter<EditionLanguage>();

  currentLang: EditionLanguage;

  languages = Object.keys(EditionLanguage);

  constructor() { }

  ngOnInit(): void {
    const langToEmit = this.language || 'EN';

    this.emitLang(langToEmit);
  }

  emitLang(lang: string) {
    const editionLang = EditionLanguage[lang];

    this.currentLang = editionLang;
    this.languageChange.emit(editionLang);
  }

  entryClass(entry: string) {
    if (entry === this.currentLang) {
      return 'btn-dark';
    } else {
      return 'btn-secondary lang-edition-switch-inactive';
    }
  }
}
