import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { UspService } from 'src/app/core/services/usp.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

@Component({
  selector: 'app-usp-edit',
  templateUrl: './usp-edit.component.html',
  styleUrls: ['./usp-edit.component.scss']
})
export class UspEditComponent implements OnInit {

  editUsp: Usp = new Usp();
  modalLoad: NgbModalRef;
  uuid: string;
  wheelId: string;
  errors: string[] = [];
  uspLang: string = 'EN';

  fileDataCms: File = null;
  fileDataApp: File = null;
  previewUrlCms: any = null;
  previewUrlApp: any = null;
  cmsPreviewUrl: SafeUrl;
  appPreviewUrl: SafeUrl;

  constructor(private uspService: UspService,
    private _route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private _location: Location,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {


    this.uuid = this._route.snapshot.params.id;
    this.wheelId = this._route.snapshot.params.idWheel;

    this.uspService.getUsp(this.uuid)
      .subscribe((data: any) => {
        this.editUsp = data.data;
      });
  }


  //UPDATE CMS IMAGE
  //On file drop handler
  onFileDroppedCms($event) {
    this.prepareFilesListCms($event);
  }

  //Handle file from browsing
  fileBrowseHandlerCms($event) {
    this.prepareFilesListCms($event);
  }

  //Delete Image
  deleteImageCms() {
    this.cmsPreviewUrl = null;
    this.previewUrlCms = null;
  }

  prepareFilesListCms(fileInput: any) {
    this.fileDataCms = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    let mimeTypeCms = this.fileDataCms.type;
    if (mimeTypeCms.match(/image\/*/) == null) {
      return;
    }

    let readerCms = new FileReader();
    readerCms.readAsDataURL(this.fileDataCms);
    readerCms.onload = (_event) => {
      this.previewUrlCms = readerCms.result;
      this.cmsPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlCms);
    }
  }


  //UPDATE APP IMAGE
  //On file drop handler
  onFileDroppedApp($event) {
    this.prepareFilesListApp($event);
  }

  //Handle file from browsing
  fileBrowseHandlerApp($event) {
    this.prepareFilesListApp($event);
  }

  //Delete Image
  deleteImageApp() {
    this.appPreviewUrl = null;
    this.previewUrlApp = null;
  }

  prepareFilesListApp(fileInput: any) {
    this.fileDataApp = <File>fileInput.target.files[0];
    this.previewApp();
  }

  previewApp() {
    let mimeTypeApp = this.fileDataApp.type;
    if (mimeTypeApp.match(/image\/*/) == null) {
      return;
    }

    let readerApp = new FileReader();
    readerApp.readAsDataURL(this.fileDataApp);
    readerApp.onload = (_event) => {
      this.previewUrlApp = readerApp.result;
      this.appPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlApp);
    }
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/b_logo_black.png';
    event.target.style = 'width: 4vw; max-width: 4vw';
  }

  pictNotLoadingApp(event) {
    event.target.src = 'assets/img/b_logo_white.png';
    event.target.style = 'width: 4vw; max-width: 4vw';
  }

  edit(form: NgForm) {

    const formData = new FormData();
    formData.append('name', this.editUsp.name.en);
    formData.append('arname', this.editUsp.name.ar);
    formData.append('key', this.editUsp.key);
    formData.append('cmsfile', this.fileDataCms);
    formData.append('appfile', this.fileDataApp);

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
		// 	$(document).keydown(function(objEvent) {
		// 		if (objEvent.keyCode == 9) {  //Tab pressed
		// 			objEvent.preventDefault(); //Stops its action
		// 		}
		// 	});
		// }
    this.uspService.updateUsp(this.uuid, formData)
      .subscribe(res => {
        //console.log(res);
        this.modalClose();
        this.router.navigate(['/dashboard/home/wheels/edit', this.wheelId]);
      }, err => {
        this.errors = err.error.errors;
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
