<div class="container-fluid">
    <div class="row d-md-flex justify-content-md-center align-items-md-baseline mb-5">
        <div class="col-8">
            <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                <input class="form-control" [(ngModel)]="paging.query" name="searchText" type="text"
                       placeholder='{{"assessment-list.searchAssessment" | translate}}'>
                <button (click)="getAssessments()" class="btn btn-dark mr-1"><i class="fa fa-search" aria-hidden="true"></i></button>
                <button (click)="cleanFilter()" class="btn btn-dark"><i class="fa fa-broom" aria-hidden="true">
                    {{"assessment-list.clean" | translate}}</i></button>
            </div>
        </div>

        <div class="col-4 buttonDownload d-md-flex justify-content-md-end align-items-md-center pr-0">
            <button class="btn btn-outline-dark" (click)="downloadFile()">
                <i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i><span>
                    {{"assessment-list.downloadExcel" | translate}}</span>
            </button>
        </div>
    </div>

    <app-assessment-commercial-item (deleteEvent)="onItemDelete($event)" [item]="assessment" *ngFor="let assessment of assessments">
    </app-assessment-commercial-item>

    <div class="row blockPagination d-md-flex justify-content-md-end align-items-md-center">
        <div class="blockPageNavigation">
            <nav aria-label="Page navigation">
                <ul class="pagination pagination-sm">
                    <li class="page-item" *ngIf="filter.hasPreviousPage">
                        <a class="page-link" (click)="pageDown()">{{"assessment-list.previous" | translate}}</a>
                    </li>
                    <li class="page-item" ><a class="page-link">{{"assessment-list.page" | translate}} {{this.filter.pageNumber}} {{"assessment-list.from" | translate}}
                        {{filter.totalNumberOfPages}}</a></li>
                    <li class="page-item" *ngIf="filter.hasNextPage">
                        <a class="page-link" (click)="pageUp()">{{"assessment-list.next" | translate}}</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</div>
