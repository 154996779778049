<div class="container-fluid">
	<div>
		<div class="d-flex justify-content-between">
			<div class="col-md-10">
				<div style="text-align: left">
					<div class="d-flex justify-content-start">
						<div class="mr-5">
							<app-lang-edition-switch [(language)]='uspLang'></app-lang-edition-switch>
						</div>
						<div>
							<div class="form-group search d-flex align-items-md-baseline">
								<input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
									placeholder='{{"region-list.searchRegion" | translate}}'>
								<i class="fa fa-search" aria-hidden="true"></i>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-md-2">
				<div style="text-align: right">
					<button class="btn btn-outline-dark" (click)="createRegion()">
						{{"region-list.addRegion" | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>

	<div class="row card mb-2 pr-0" *ngFor="let regions of listRegion | filterRegion: searchText, let i = index">
		<div class="d-flex align-items-center bd-highlight">
			<a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3"
				[routerLink]="['/dashboard/home/region/detail', regions.uuid]">
				<div class="row w-100">
					<div class="col-9">
						<span>{{i+1}}. </span>
						<b>{{"region-list.name" | translate}}:</b> {{regions.name[uspLang.toLowerCase()]}}
					</div>
				</div>
			</a>

			<div class="buttons d-flex mr-4">
				<button class="btn btn-dark mr-1" type="button"
					[routerLink]="['/dashboard/home/region/edit', regions.uuid]">
					<i class="fa fa-pencil" aria-hidden="true"></i>
				</button>
				<button class="btn btn-danger" type="button" (click)="deleteRegion(regions.uuid, i)">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</button>
			</div>

		</div>
	</div>
</div>