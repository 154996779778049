import { Component, Inject } from '@angular/core';
import { ShareService, CONSUMER_SHARE } from 'src/app/core/services/share.service';

@Component({
  selector: 'app-share-edit',
  templateUrl: './share-edit.component.html',
  styleUrls: ['./share-edit.component.scss']
})
export class ShareEditComponent {

  constructor(@Inject(CONSUMER_SHARE) readonly shareService: ShareService) { }

}
