import { MultiselectAdapter } from 'src/app/modules/home/components/forms/multiselect/multiselect-adapter';

export class Responsables {
    static multiselectAdapter(): MultiselectAdapter<Responsables> {
        return {
            keySelect: r => r.uuid,
            render: r => r.username
        };
    }

    uuid: string = "";
    name: string = "";
    surname: string = "";
    email: string = "";
    username: string = "";
    role: string = "";
    status: string = "";
    attempt: number = 0;
    reset: boolean = true;
    lastLogin: Date;
    country: string = "";

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }
}