import { NgModule } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { MenuModule } from './components/menu/menu.module';
import { ErrorPopupComponent } from './components/popups/error-popup/error-popup.component';
import { LoadingPopupComponent } from './components/popups/loading-popup/loading-popup.component';
import { ValidPopupComponent } from './components/popups/valid-popup/valid-popup.component';
import { CustomModalComponent } from './components/modals/custom-modal/custom-modal.component';
import { DeletePopupComponent } from './components/popups/delete-popup/delete-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterListLanguagesPipe } from './pipe/filterListLanguages/filter-list-languages.pipe';
import { NotifyPopupComponent } from './components/popups/notify-popup/notify-popup.component';
import { MockHttpInterceptor } from "./models/commercial/mock-httpinterceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

@NgModule({
  declarations: [
    HeaderComponent,
    ErrorPopupComponent,
    LoadingPopupComponent,
    ValidPopupComponent,
    CustomModalComponent,
    DeletePopupComponent,
    FilterListLanguagesPipe,
    NotifyPopupComponent
  ],
  imports: [
    CommonModule,
    MenuModule,
    NgbModule,
    TranslateModule
  ],
  entryComponents: [
    ErrorPopupComponent,
    LoadingPopupComponent,
    CustomModalComponent,
    DeletePopupComponent,
    NotifyPopupComponent,
    ValidPopupComponent
  ],
  providers: [
    NgbActiveModal
  ],
  exports: [
    HeaderComponent,
  ]
})
export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [
                {provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true }
            ]
        }
    }
}
