export class BrandModel {
  uuid: string = '';
  key: string = '';
  name: any = '';
  arname: any = '';
  brand: any = '';
  arbrand: any = '';
  brandlogo: string = '';

  constructor(values: Object = {}) {
    Object.assign(values, this);
  }
}