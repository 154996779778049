import { Countries } from './objectRegion/countries';
import { Responsables } from './objectRegion/responsables';
import { TranslateService } from '@ngx-translate/core';
import { MultiselectAdapter } from 'src/app/modules/home/components/forms/multiselect/multiselect-adapter';

export class Region {
    public uuid: string = '';
    public name: any = '';
    public arname: any = '';
    public key: string = '';
    public countries: Countries[] = [];
    public responsables: Responsables[] = [];

    constructor(values: object = {}) {
        this.countries = new Array<Countries>();
        this.responsables = new Array<Responsables>();

        Object.assign(this, values);
    }

    static multiselectAdapter(translate: TranslateService): MultiselectAdapter<Region> {
        return {
            render: region => {
                const currentLang = translate.currentLang || translate.defaultLang || 'en';

                const result = region.name[currentLang];

                if (!result) {
                    console.error(`No value for region.name.${currentLang}`);
                    return Object.values(region.name)[0] as string;
                }

                return result;
            },
            keySelect: region => region.uuid
        };
    }
}
