import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  collapedSideBar: boolean;

  constructor() { }

  ngOnInit() {

  }

  receiveCollapsed($event) {
    this.collapedSideBar = $event;
  }

}
