<div class="row card mb-2 pt-3 pb-3 pr-0">
    <div class="d-flex align-items-center bd-highlight">
        <div class="row w-100 d-flex flex-grow-1 bd-highlight align-items-center ml-md-3">
            <div class="col-10">
                <b> {{"sales-tools-item.partner" | translate }}: </b> {{item.partner.name}}
            </div>
        </div>
        <div class="buttons d-flex mr-4">
            <button class="btn btn-dark mr-1" type="button"
                    [routerLink]="['/dashboard/home/salestools/catalogue/edit', item.partner.uuid]">
                <i class="fa fa-pencil" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
