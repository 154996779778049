import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filterAllShare'
})
export class CommentPipe implements PipeTransform {

  transform(value: any, args: any): any {

    if (args === '' || args.length < 2) return value;

    const resultShare = [];
    for (const Shares of value) {
      if (Shares.title.toLowerCase().indexOf(args.toLowerCase()) > -1) {
        resultShare.push(Shares);
      };
    };
    return resultShare;
  }

}
