<div class="container-fluid">
	<div class="row d-md-flex justify-content-md-center align-items-md-baseline mb-5">
		<div class="col-8">
			<div class="form-group search d-md-flex justify-content-md-left align-items-md-baseline">
				<input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
					placeholder='{{"user-list.searchUser" | translate}}'>
				<i class="fa fa-search mr-5" aria-hidden="true"></i>
			</div>
		</div>

		<div class="col-4 buttonAdd d-md-flex justify-content-md-end align-items-md-center">
			<button class="btn btn-outline-dark mr-2" id="buttonAdd" (click)="createUser()">
				<i class="fa fa-plus-circle mr-2" aria-hidden="true"></i><span> {{ "user-list.addUser" | translate }}
				</span>
			</button>

			<button class="btn btn-outline-dark" id="buttonAdd" (click)="exportExcel()">
				<i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i>
				<span>{{ "user-list.downloadExcel" | translate }}</span>
			</button>
		</div>
	</div>

	<div class="row card mb-2 pt-3 pb-3 pr-0" *ngFor="let user of users | filterUser: searchText, let i = index">
		<div class="d-flex align-items-center bd-highlight">
			<a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3"
				[routerLink]="['/dashboard/home/users/detail', user.uuid]">
				<div class="row w-100">
					<div class="col-3 text-wrap">
						<span>{{i+1}}. </span>
						<b> {{"user-list.username" | translate }}: </b> {{user.username}}
					</div>
					<div class="col-3">
						<b> {{"user-list.name" | translate }}: </b> {{user.name}}
					</div>
					<div class="col-3">
						<b> {{"user-list.surname" | translate }}: </b> {{user.surname}}
					</div>
					<div class="col-3">
						<span><small>
								<h6>{{"user-list.lastLogin" | translate}} </h6>{{user.lastLogin | date: 'short'}}
							</small></span>
					</div>

				</div>
			</a>
			<div class="buttons d-flex mr-4">
				<button class="btn btn-dark mr-1" type="button"
					[routerLink]="['/dashboard/home/users/edit', user.uuid]">
					<i class="fa fa-pencil" aria-hidden="true"></i>
				</button>
				<button class="btn btn-danger" type="button" (click)="deleteUser(user.uuid, i)">
					<i class="fa fa-trash" aria-hidden="true"></i>
				</button>
			</div>

		</div>
	</div>

</div>
