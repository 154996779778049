import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { ProductCommercialSubcategory } from "../../../shared/models/commercial/product-commercial-subcategory.model";
import { LocalStorageKey, LocalStorageManager } from "../local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class ProductsCommercialSubcategoryService {

  headers = new HttpHeaders({ "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) });

  private API = environment.baseUrlCMS;
  private WHEEL = "/commercial/product/subcategory";

  constructor(private http: HttpClient) { }

  getAllSubcategories(): Observable<Generic<ProductCommercialSubcategory[]>> {
    return this.http.get<Generic<ProductCommercialSubcategory[]>>(this.API + this.WHEEL, { headers: this.headers });
  }
}
