import { Translated } from "./translated.model";

export class ProductCommercialFeature {

    public uuid: string = "";
    public title: Translated = new Translated();
    public content: Translated = new Translated();
    public imageUrl: string = "";
    public productCommercialGroupUUID: string = "";
}
