<div class="container-fluid">
        <div class="form-group d-md-flex flex-md-column mb-md-5">
    
            <div class="row imageShare d-flex justify-content-center">
                <img class="mb-5" [src]="editShare.imgUrl" (error)="pictNotLoading($event)">
            </div>
    
            <label>{{"share-detail.title" | translate}}</label>
            <input class="form-control" [value]="editShare.title" name="name" type="text" readonly>
    
            <label>{{"share-detail.category" | translate}}</label>
            <input class="form-control" [value]="editShare.category.key" name="category" type="text" readonly>
    
            <label>{{"share-detail.status" | translate}}</label>
            <input class="form-control" [value]="editShare.status" name="status" type="text" readonly>
    
            <label>{{"share-detail.description" | translate}}</label>
            <input class="form-control" [value]="editShare.description" name="category" type="text" readonly>
        </div>
    
    
        <div class="row">
            <div class="col-2 buttonBack">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-10 buttonEdit d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-dark" [routerLink]="[shareService.routes.shareEdit, editShare.uuid]">
                    <i class="fa fa-pencil mr-2"></i>{{"share-detail.edit" | translate}}</button>
            </div>
        </div>
    </div>