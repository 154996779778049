<div class="container-fluid">
	<div class="form-group d-md-flex flex-md-column mb-md-5">
		<div class="d-flex justify-content-md-start">
			<app-lang-edition-switch [(language)]="lang"></app-lang-edition-switch>
		</div>
		<div class="mb-5"></div>
		<div class="d-md-flex justify-content-between">
			<div class="flex-row col-6">
				<label>{{"region-detail.name" | translate}}</label>
				<input [(ngModel)]='region.name[lang.toLowerCase()]' name="name" type="text" class="form-control"
					   readonly>
			</div>
			<div class="mb-5 flex-row col-6">
				<app-multiselect label="{{'region-detail.responsables'| translate}}"
								 [availableOptions]="region.responsables"
								 [adapter]="responsableAdapter"
								 [(value)]="region.responsables"
								 editable="false"
				></app-multiselect>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-2 buttonBack d-md-flex align-items-center">
			<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
		</div>
		<div class="col-10 buttonSave d-md-flex justify-content-md-end align-items-center">
			<button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/region/edit', region.uuid]">
				<i class="fa fa-pencil mr-2"></i>{{"region-detail.edit" | translate}}</button>
		</div>
	</div>
</div>
