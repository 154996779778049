<div class="header container-fluid">
  <section class="row d-md-flex justify-content-md-end justify-content-between">
    <div class="col-4 d-flex justify-content-center align-items-center">
      <img class="logo" routerLink="/dashboard" src="/assets/img/Bridgestone-logo_negative.png" alt="logo bridgestone">
    </div>

    <div class="col-4">
      <ul (click)="showMenuLanguage()" id="languageArrow" class="float-right d-flex selectLanguage">
        <!-- <li class="languageFirst" [value]=""><img src="{{ selectedLanguageObject.name }}" alt="{{ selectedLanguageObject.name }}"><img id="downArrow" src="/assets/svg/downArrow.svg"> -->
          <li class="languageFirst"  [value]="">{{ selectedLanguageObject.name }}<img id="downArrow" src="/assets/svg/downArrow.svg">
          <ul *ngIf="!isMenuShow" class="menuLanguage">
            <li *ngFor="let language of listLanguages | filterListLang: selectedLanguageObject.id" (click)="changeLanguage(language)">
              {{ language.name }}
              <!-- <img src="{{ language.name }}" alt="{{ language.name }}"> -->
            </li>
          </ul>
        </li>
      </ul> 
    </div>
  </section> 
</div>