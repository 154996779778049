import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Attr } from 'src/app/shared/models/wheels/objectWheel/attr';
import { CategoriesService } from 'src/app/core/services/categories.service';

@Component({
  selector: 'app-categories-detail',
  templateUrl: './categories-detail.component.html',
  styleUrls: ['./categories-detail.component.scss']
})
export class CategoriesDetailComponent implements OnInit {

  detailCategories: Attr = new Attr();
  uspLang: string = 'EN';

  constructor(
    private categoryService: CategoriesService,
    private _route: ActivatedRoute,
    private _location: Location
  ) { }

  ngOnInit() {

    let uuidItem = this._route.snapshot.params.id;

    this.categoryService.getAttrDetailCategory(uuidItem)
      .subscribe((data: any) => {
        this.detailCategories = data.data;
      });
  }

  backClicked() {
    this._location.back();
  }
}
