<div class="container-fluid">
    <div class="mb-5">
        <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
    </div>
    <form (ngSubmit)="createWheel(k)" #k="ngForm" method="POST" enctype="multipart/form-data">
        <div class="form-group mb-md-5">
            <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-4">
            <label>{{"wheel-add.familyName" | translate}}</label>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.familyName" name="familyName"
                required>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.familyName" name="familyName"
                required>
                </div>

                <div class="flex-row col-md-4">
            <label>{{"wheel-add.patternName" | translate}}</label>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.patternName" name="patternName"
                required>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.patternName" name="patternName"
                required>
                </div>

                <div class="flex-row col-md-4">
            <label>{{"wheel-add.patternSlogan" | translate}}</label>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.patternSlogan"
                name="patternSlogan" required>
            <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.arpatternSlogan"
                name="patternSlogan" required>
                </div>
            </div>

            <div class="d-md-flex justify-content-between">
        
                <div class="flex-row col-md-4">
            <label>{{"wheel-add.segment" | translate}}</label>
            <select *ngIf="uspLang=='EN'" [(ngModel)]='addProduct.segment.key' name="segment" class="form-control form-control-lg" required>
                <option *ngFor="let Segment of segment" [value]="Segment.key">{{Segment.key}}</option>
            </select>
            <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.segment.key' name="segment" class="form-control form-control-lg" required>
                <option *ngFor="let Segment of segment" [value]="Segment.key">{{Segment.key}}</option>
            </select>
            </div>

            <div class="flex-row col-md-4">
            <label>{{"wheel-add.operation" | translate}}</label>
            <select *ngIf="uspLang=='EN'" [(ngModel)]='addProduct.operation.key' name="operation" class="form-control form-control-lg"
                required>
                <option *ngFor="let Operation of operation" [value]="Operation.key">{{Operation.key}}</option>
            </select>
            <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.operation.key' name="operation" class="form-control form-control-lg"
                required>
                <option *ngFor="let Operation of operation" [value]="Operation.key">{{Operation.key}}</option>
            </select>
            </div>

            <div class="flex-row col-md-4">
            <label>{{"wheel-add.oeRep" | translate}}</label>
            <select *ngIf="uspLang=='EN'" [(ngModel)]='addProduct.oeRep.key' name="oeRep" class="form-control form-control-lg" required>
                <option *ngFor="let OERep of oeRep" [value]="OERep.key">{{OERep.key}}</option>
            </select>
            <select *ngIf="uspLang=='AR'" [(ngModel)]='addProduct.oeRep.key' name="oeRep" class="form-control form-control-lg" required>
                <option *ngFor="let OERep of oeRep" [value]="OERep.key">{{OERep.key}}</option>
            </select>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-6">
                <label>{{"wheel-add.rft" | translate}}</label>
                <select *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.rft" name="rft" type="text" class="form-control form-control-lg" required>
                    <option *ngFor="let rft of productRft.rft" [value]="rft">{{rft}}</option>
                </select>
                <select *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.rft" name="rft" type="text" class="form-control form-control-lg" required>
                    <option *ngFor="let rft of productRft.rft" [value]="rft">{{rft}}</option>
                </select>
            </div>
            <div class="flex-row col-md-6">
                <label>{{"wheel-add.productOrder" | translate}}</label>
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.productOrder" name="productOrder"
                    id="number" type="number" min="1" pattern="^[0-9]+" required>
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.productOrder" name="productOrder"
                    id="number" type="number" min="1" pattern="^[0-9]+" required>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="selectUSP col-md-6 mb-5">
                <div class="titleUSP d-flex justify-content-md-between align-items-md-center">
                    <label>{{"wheel-add.usp" | translate}}</label>
                    <small class="uspSelect d-flex justify-content-end">{{"wheel-add.youCanOnlySelectUpTo" | translate}}</small>
                </div>
                <select multiple *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.usp" name="usp" class="form-control form-control-lg"
                    #formSelect="ngModel">
                    <option id="{{Usp.key}}" *ngFor="let Usp of usp" [value]="Usp"
                        (mousedown)="onMouseDownUsp($event, Usp.key, Usp.uuid, Usp.name, Usp.arname, Usp.appImgUrl, Usp.cmsImgUrl)"
                        (mousemove)="$event.preventDefault()">
                        {{Usp.name.en}}
                    </option>
                </select>
                <select multiple *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.usp" name="usp" class="form-control form-control-lg"
                    #formSelect="ngModel">
                    <option id="{{Usp.key}}" *ngFor="let Usp of usp" [value]="Usp"
                        (mousedown)="onMouseDownUsp($event, Usp.key, Usp.uuid, Usp.name, Usp.arname, Usp.appImgUrl, Usp.cmsImgUrl)"
                        (mousemove)="$event.preventDefault()">
                        {{Usp.name.ar}}
                    </option>
                </select>
                
                <div *ngIf="uspLang=='EN'">
                    <div class="form-control form-control-lg SelectFormControl mt-2" *ngFor="let Usp of addProduct.usp, let i=index">
                        <i (click)="removeModel(i, Usp.key)" class="fa fa-times"></i> {{Usp.name.en}}
                    </div>
                </div>
                <div *ngIf="uspLang=='AR'">
                    <div class="form-control form-control-lg SelectFormControl mt-2" *ngFor="let Usp of addProduct.usp, let i=index">
                        <i (click)="removeModel(i, Usp.key)" class="fa fa-times"></i> {{Usp.name.ar}}
                    </div>
                </div>
            </div>

            <div class="selectTechnologies col-md-6 mb-5">
                <label>{{"wheel-add.technologies" | translate}}</label>
                <select multiple *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.technologies" name="technologies"
                    class="form-control form-control-lg" #formSelect="ngModel">
                    <option id="{{Technologies.title}}" *ngFor="let Technologies of technologies" [value]="Technologies"
                        (mousedown)="onMouseDownTechnologies($event, Technologies.uuid, Technologies.title,  Technologies.artitle, Technologies.description, Technologies.ardescription, Technologies.image)"
                        (mousemove)="$event.preventDefault()">
                        {{Technologies.title.en}}
                    </option>
                </select>
                <select multiple *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.technologies" name="technologies"
                    class="form-control form-control-lg" #formSelect="ngModel">
                    <option id="{{Technologies.title}}" *ngFor="let Technologies of technologies" [value]="Technologies"
                        (mousedown)="onMouseDownTechnologies($event, Technologies.uuid, Technologies.title,  Technologies.artitle, Technologies.description, Technologies.ardescription, Technologies.image)"
                        (mousemove)="$event.preventDefault()">
                        {{Technologies.title.ar}}
                    </option>
                </select>

                <div class="form-control form-control-lg SelectFormControl mt-2"
                    *ngFor="let Technologies of addProduct.technologies, let i=index">
                    <div *ngIf="uspLang=='EN'">
                        <i (click)="removeModelTechnologies(i, Technologies.title)" class="fa fa-times"></i>
                        {{Technologies.title.en}}
                    </div>
                    <div *ngIf="uspLang=='AR'">
                        <i (click)="removeModelTechnologies(i, Technologies.title)" class="fa fa-times"></i>
                        {{Technologies.title.ar}}
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 d-md-flex justify-content-md-between align-items-md-center mb-4 pl-0 pr-0">
            <div class="flex-row col-md-2">
                <label>Light2</label>
                <select *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelLight2" name="modelLight2" type="text" class="form-control form-control-lg">
                    <option *ngFor="let modelLight2 of lightWheel3D.modelLight2" [value]="modelLight2">{{modelLight2}}</option>
                </select>
                <select *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelLight2" name="modelLight2" type="text" class="form-control form-control-lg">
                    <option *ngFor="let modelLight2 of lightWheel3D.modelLight2" [value]="modelLight2">{{modelLight2}}</option>
                </select>
            </div>
            <div class="flex-row col-md-3 pl-0 pr-0" style="border: 2px solid white">
                <div class="flex-row col-md-5">
                    <label>Zoom</label>
                    <select *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelZoom" name="modelZoom" type="text" class="form-control form-control-lg">
                        <option *ngFor="let modelZoom of zoom3D.modelZoom" [value]="modelZoom">{{modelZoom}}</option>
                    </select>
                    <select *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelZoom" name="modelZoom" type="text" class="form-control form-control-lg">
                        <option *ngFor="let modelZoom of zoom3D.modelZoom" [value]="modelZoom">{{modelZoom}}</option>
                    </select>
                </div>
                <div class="flex-row col-md-7">
                    <label class="mb-0">
                        <h6>{{"wheel-add.modelZoom" | translate}}</h6>
                    </label>
                    <input class="form-control form-control-lg input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelZoom"
                        type="number" (keypress)="maxZoom($event)" name="modelZoom" max="15">
                    <input class="form-control form-control-lg input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelZoom"
                        type="number" (keypress)="maxZoom($event)" name="modelZoom" max="15">
                    <div class="danger">
                        <small class="text-danger" *ngIf="addProduct.modelZoom > '15.0'">{{"wheel-add.errorValue" | translate}}</small>
                    </div>
                </div>
            </div>
            <div class="flex-row col-md-3 modelIntentisy">
                <div class="d-md-flex justify-content-md-between">
                    <label>Intensity</label>
                    <h6 class="intensityValue">{{"wheel-add.modelIntentisy" | translate}}</h6>
                </div>
                <input class="form-control input-underline input-lg mb-0" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelIntentisy" type="number"
                    name="modelIntentisy" (keypress)="maxNumber($event)" min="0.00001" max="2">
                    <input class="form-control input-underline input-lg mb-0" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelIntentisy" type="number"
                    name="modelIntentisy" (keypress)="maxNumber($event)" min="0.00001" max="2">
                <div class="danger">
                    <small class="text-danger" *ngIf="addProduct.modelIntentisy > '2.0'">{{"wheel-add.errorValue" | translate}}</small>
                </div>
            </div>
            <div class="flex-row col-md-2 modelIntentisy">
                <div class="d-md-flex justify-content-md-between">
                    <label>Rotation</label>
                    <h6 class="intensityValue">{{"wheel-add.modelRotation" | translate}}</h6>
                </div>
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelRotation" type="number"
                    name="modelRotation" (keypress)="maxRotation($event)" max="6.283">
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelRotation" type="number"
                    name="modelRotation" (keypress)="maxRotation($event)" max="6.283">
                <div class="danger">
                    <small class="text-danger"
                        *ngIf="addProduct.modelRotation > '6.283'">{{"wheel-add.errorValue" | translate}}</small>
                </div>
            </div>
            <div class="flex-row col-md-2">
                <label>FlHeight</label>
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="addProduct.modelFlHeight" type="number"
                    name="modelFlHeight">
                <input class="form-control input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="addProduct.modelFlHeight" type="number"
                    name="modelFlHeight">
            </div>
        </div>

            <div class="updateImage mb-3">
                <div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">

                    <div class="col-4 selectImageProduct">
                        <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                            <label class="mr-3">{{"wheel-add.productImageToUpload" | translate}}</label>
                            <small>{{"wheel-add.youCanOnlyUploadOneImage" | translate}}</small>
                        </div>

                        <div class="dropzone mb-5" appDnd (fileDropped)="onFileDroppedProduct($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerProduct($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"wheel-add.dragAndDrop" | translate}}</h3>
                            <h2 style="color: red;">{{"wheel-add.productImage" | translate}}</h2>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="col-4 selectLogoCMS">
                        <div class="titleLogoPreview d-md-flex justify-content-md-between align-items-md-center">
                            <label class="mr-3">{{"wheel-add.cmsLogoToUpload" | translate}}</label>
                            <small>{{"wheel-add.youCanOnlyUploadOneLogo" | translate}}</small>
                        </div>

                        <div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
                            appDnd (fileDropped)="onFileDroppedCms($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerCms($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"wheel-add.dragAndDropLogo" | translate}}</h3>
                            <h2 style="color: #000000; background-color: #ffffff; width: max-content;">
                                {{"wheel-add.cmsLogo" | translate}}</h2>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>

                    <div class="col-4 selectLogoAPP">
                        <div class="titleLogoPreview d-md-flex justify-content-md-between align-items-md-center">
                            <label class="mr-3">{{"wheel-add.appLogoToUpload" | translate}}</label>
                            <small>{{"wheel-add.youCanOnlyUploadOneLogo" | translate}}</small>
                        </div>

                        <div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
                            appDnd (fileDropped)="onFileDroppedApp($event)">
                            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerApp($event)" />
                            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                            <h3>{{"wheel-add.dragAndDropLogo" | translate}}</h3>
                            <h2 style="color: #ffffff; background-color: #000000; width: max-content;">{{"wheel-add.appLogo" | translate}}</h2>
                            <p class="constraints-info">(.jpg, .png, .svg)</p>
                        </div>
                    </div>
                </div>

                <div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">

                    <div class="col-4 logoPreviewPRODUCT mb-3">
                        <div class="imagePreview" *ngIf="productPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.productImagePreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="imagePreviewWheel d-flex justify-content-md-center">
                                <img [src]="productPreviewUrl">
                            </div>
                        </div>
                    </div>

                    <div class="col-4 logoPreviewCMS mb-3">
                        <div class="logoPreview" *ngIf="cmsPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.cmsLogoPreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageCMS()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="cmsPreview d-flex justify-content-md-center">
                                <img [src]="cmsPreviewUrl">
                            </div>
                        </div>
                    </div>

                    <div class="col-4 logoPreviewAPP mb-3">
                        <div class="image-preview" *ngIf="appPreviewUrl">
                            <div class="d-md-flex justify-content-md-between align-items-md-center">
                                <label>{{"wheel-add.appLogoPreview" | translate}}</label>
                                <button type="button" class="close" (click)="deleteImageApp()" aria-label="Close">
                                    <i class="fa fa-times-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="appPreview d-flex justify-content-md-center">
                                <img [src]="appPreviewUrl">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack">
                <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels/list']"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    <i class="fa fa-save mr-2"></i>{{"wheel-add.save" | translate}}</button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>