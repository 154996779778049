import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

import {RegionService} from 'src/app/core/services/region.service';
import {Region} from 'src/app/shared/models/regions/region';
import {ModalService} from '../../../../../core/services/modal.service';

@Component({
	selector: "app-region-list",
	templateUrl: "./region-list.component.html",
	styleUrls: ["./region-list.component.scss"],
})
export class RegionListComponent implements OnInit {

	listRegion: Region[] = [];
	searchText: string = '';
	uspLang: string = 'EN';

	constructor(
		private router: Router,
		private regionService: RegionService,
		private modalService: ModalService,
		private _modal: NgbModal,
		private _translate: TranslateService) { }

	ngOnInit() {
		this.loadRegions();
	}

	private loadRegions() {
		this.regionService.getAllRegion()
			.subscribe(data => {
				this.listRegion = data.data;
			});
	}

	createRegion() {
		this.router.navigate(["/dashboard/home/region/add"]);
	}

	deleteRegion(id: string, index: number) {
		this.modalService.showDeleteAskModal(() => {
			const modalDelete = this.modalService.showDeleteProgressModal()
			this.regionService.deleteRegion(id)
				.subscribe(_ => {
					modalDelete.close();
					this.loadRegions();
				}, _ =>{
					modalDelete.close();
					this.modalService.showErrorModal("modal.errorTitle", "modal.errorDescription");
				});
		});
	}
}
