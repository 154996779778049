import { Component, OnInit, Input } from '@angular/core';
import { ShareModel } from 'src/app/shared/models/share/share';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ShareService } from 'src/app/core/services/share.service';
import { NgForm } from '@angular/forms';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import constants from 'src/app/core/constants';

@Component({
  selector: 'app-common-share-add',
  templateUrl: './share-add.component.html',
  styleUrls: ['./share-add.component.scss']
})
export class ShareCommonAddComponent implements OnInit {

  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService
  ) { }

  @Input()
  shareService: ShareService;


  newComment: ShareModel = new ShareModel();
  categories: any[] = [];
  allOptionCategory: any[] = [];
  optionCategory: any[] = [];
  modalLoad: NgbModalRef;
  fileData: File = null;
  previewUrl: any = null;
  safePreviewUrl: SafeUrl;
  errors: string[] = [];
  errorsm: string;

  ngOnInit(): void {
    this.atrrDataCategory();
  }

  onFileDropped = ($event) => {
    this.prepareFilesList($event);
  }

  fileBrowseHandler = (file) => {
    this.prepareFilesList(file);
  }

  prepareFilesList = (fileInput: any) => {
    this.fileData = fileInput.target.files[0] as File;
    this.preview();
  }

  preview = () => {
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.safePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrl);
    }
  }

  atrrDataCategory() {
    this.categoriesService.getAttrCategory()
      .subscribe((data: any) => {
        this.categories = data.data;

        for (let i = 0; i < this.categories.length; i++) {
          this.allOptionCategory.push(this.categories[i].key);
        }
        this.optionCategory = Array.from(new Set(this.allOptionCategory));
      });
  }

  createComment(form: NgForm) {
    const formData = new FormData();
    formData.append('title', this.newComment.title);
    formData.append('category', this.newComment.category.key);
    formData.append('description', this.newComment.description);
    formData.append('file', this.fileData);

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });

    this.shareService.createComment(formData)
      .subscribe(data => {
        this.modalClose();
        this.router.navigateByUrl(this.shareService.routes.sharesList);
      }, err => {
        if (err.error.errors) {
          this.errors = err.error.errors;
        } else {
          this.errorsm = err.error.message;
          const modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          this._translate.instant('share-add.messageTitle');
          modal.componentInstance.messageDescription = 
          this._translate.instant('share-add.messageDescription');
          this.modalClose();
        }
      });
  }

  modalClose = () => {
    this.modalLoad.close();
  }

  pictNotLoading = (event) => {
    event.target.src = constants.imageUrl.noimage;
  }
}
