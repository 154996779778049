import { Component } from '@angular/core';
import { SalesToolsService } from 'src/app/core/services/sales-tools.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, catchError, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-stock-list-upload',
  templateUrl: './stock-list-upload.component.html',
  styleUrls: ['./stock-list-upload.component.scss']
})
export class StockListUploadComponent {

  now = new Date();
  file?: File;
  loadingModal: NgbModalRef;

  lastUpdateDate$: Observable<Date>

  constructor(
    private readonly salesToolsService: SalesToolsService,
    private readonly modalService: ModalService,
    private readonly translate: TranslateService
  ) {
    this.lastUpdateDate$ = this.getLastUpdateDate();
  }

  private extractErrorMessage(e: any, fallback?: string) {
    return e.error?.message || fallback;
  }

  private getLastUpdateDate() {
    const showErrorModalOnFailure = e => {
      console.error('error while trying to get stock list', e);

      this.modalService.showErrorModal('', this.extractErrorMessage(e));

      throw e;
    };

    return this.salesToolsService.getStockListInfo().pipe(
      map(s => s.data),
      filter(data => !!data),
      map(data => data.lastUpdate),
      filter(lastUpdateString => !!lastUpdateString),
      map(lastUpdateString => new Date(lastUpdateString)),
      catchError(showErrorModalOnFailure)
    );
  }

  openLoadingModal() {
    this.loadingModal = this.modalService.showLoadingModal();
  }

  closeLoadingModal() {
    this.loadingModal.close();
  }

  uploadStockList() {
    if (this.file) {
      this.openLoadingModal();
      this.salesToolsService
        .uploadStockList(this.file)
        .subscribe(
          () => {
            this.closeLoadingModal();
            this.modalService.showValidModal();
          },
          err => {
            console.error('Error while trying to upload stock list', err);
            this.closeLoadingModal();
            const errorMessage = this.extractErrorMessage(err, this.translate.instant('sales-tools.upload-error'));
            this.modalService.showErrorModal('', errorMessage);
          }
        );
    }
  }

  onFileChange(file: File) {
    this.file = file;
  }
}
