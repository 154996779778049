<div class="container-fluid">
    <form (ngSubmit)="createFeature(k)" #k="ngForm" method="POST" >

        <div class="form-group mb-md-5">
            <div class="row form-group imageShare d-flex justify-content-center">
                <img class="mb-5" [src]="feature.imageUrl" (error)="pictNotLoading($event)">
            </div>
            <div class="d-flex">
                <label>{{"feature-add.title" | translate}}</label>
                <div class="d-flex justify-content-end w-100">
                    <p role="button" (click)="lang='EN'" [ngClass]="{'font-weight-bold':lang === 'EN'}">
                        EN &nbsp;
                    </p>|
                    <p role="button" (click)="lang='AR'" [ngClass]="{'font-weight-bold':lang === 'AR'}">&nbsp; AR</p>
                </div>
            </div>
            <input *ngIf="lang=='EN'" [(ngModel)]="feature.title.en" name="title.en" type="text" class="form-control form-control-lg"
                   required>
            <input *ngIf="lang=='AR'" [(ngModel)]="feature.title.ar" name="title.ar" type="text" class="form-control form-control-lg"
                   required>
            <div class="d-flex">
                <label>{{"feature-add.content" | translate}}</label>
            </div>
            <input *ngIf="lang=='EN'" [(ngModel)]="feature.content.en" name="content.en" type="text" class="form-control form-control-lg"
                   required>
            <input *ngIf="lang=='AR'" [(ngModel)]="feature.content.ar" name="content.ar" type="text" class="form-control form-control-lg"
                   required>

            <input [(ngModel)]="feature.productCommercialGroupUUID" name="productUUID" type="text"
                   hidden>
            <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                <label>{{"feature-add.chooseImageToUpload" | translate}}</label>
                <small>{{"feature-add.youCanOnlyUploadOneImage" | translate}}</small>
            </div>

            <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" accept=".png, .jpg, .svg" (change)="fileBrowseHandler($event)" />
                <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                <h3>{{"feature-add.dragAndDrop" | translate}}</h3>
                <p class="constraints-info">(.jpg, .png, .svg)</p>
            </div>

            <div class="image-preview" *ngIf="safePreviewUrl">
                <div class="d-md-flex justify-content-md-between align-items-md-center">
                    <label>{{"feature-add.imagePreview" | translate}}</label>
                    <button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="imagePreview d-flex justify-content-md-center">
                    <img [src]="safePreviewUrl" width="auto" height="300" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack d-md-flex align-content-md-center">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
                <button type="submit" class="btn btn-dark" [disabled]="!k.form.valid">
                    <i class="fa fa-save mr-2"></i>{{"feature-add.save" | translate}}</button>
            </div>
            <div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center"
                 [routerLink]="['/dashboard/home/feature-commercial/detail', feature.uuid]">
                <button type="button" class="btn btn-dark">
                    <i class="fa fa-eye mr-2"></i>{{"feature-add.detail" | translate}}
                </button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>
