import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

import { LoadingPopupComponent } from "src/app/shared/components/popups/loading-popup/loading-popup.component";

import { UserModel } from "src/app/shared/models/user";
import { UsersService } from "src/app/core/services/users.service";
import { Region } from "../../../../../shared/models/regions/region";
import { RegionUploadAndShare } from "../../../../../shared/models/regions/regionUploadAndShare";
import { Countries } from "../../../../../shared/models/regions/objectRegion/countries";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { tap } from "rxjs/operators";
import { RegionService } from "../../../../../core/services/region.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  user: UserModel = new UserModel();
  id: string;
  modalLoad: NgbModalRef;
  userCountries: Countries[] = [];
  userRegions: Region[] = [];
  userRegionsUploadAndShare: RegionUploadAndShare[] = [];
  regionAdapter = Region.multiselectAdapter(this.translate);
  regionUploadAndShareAdapter = RegionUploadAndShare.multiselectAdapter(
    this.translate
  );
  countryAdapter = Countries.multiselectAdapter();

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private _modal: NgbModal,
    private usersService: UsersService,
    private translate: TranslateService,
    private regionService: RegionService
  ) {}

  ngOnInit() {
    let id = this._route.snapshot.params.id;

    forkJoin([
      this.usersService
        .getUser(id)
        .pipe(tap((data) => (this.user = data.data))),
      this.regionService
        .getAllCountry()
        .pipe(tap((data) => (this.userCountries = data.data))),
      this.regionService
        .getAllRegion()
        .pipe(tap((data) => (this.userRegions = data.data))),
      this.regionService
        .getAllRegionUploadAndShare()
        .pipe(tap((data) => (this.userRegionsUploadAndShare = data.data))),
    ]).subscribe(() => {
      this.userCountries = this.userCountries.filter((c) =>
        this.user.countries.includes(c.key)
      );
      this.userRegions = this.userRegions.filter((r) =>
        this.user.regions.includes(r.key)
      );
      this.userRegionsUploadAndShare = this.userRegionsUploadAndShare.filter(
        (r) => this.user.shareRegions.includes(r.key)
      );
    });
  }

  //Activate or Deactivate User
  activate(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });
    // if (this.modalLoad) {
    // 	$(document).keydown(function (objEvent) {
    // 	  if (objEvent.keyCode == 9) {  //Tab pressed
    // 		objEvent.preventDefault(); //Stops its action
    // 	  }
    // 	});
    //   }
    this.usersService.activateUpdateUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
      //console.log(data);
    });
  }

  deactivate(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });
    // if (this.modalLoad) {
    // 	$(document).keydown(function (objEvent) {
    // 	  if (objEvent.keyCode == 9) {  //Tab pressed
    // 		objEvent.preventDefault(); //Stops its action
    // 	  }
    // 	});
    //   }
    this.usersService.deactivateUpdateUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
      //console.log(data);
    });
  }

  reset(uuid: string) {
    this.modalLoad = this._modal.open(LoadingPopupComponent, {
      backdrop: "static",
      keyboard: false,
    });
    // if (this.modalLoad) {
    // 	$(document).keydown(function (objEvent) {
    // 	  if (objEvent.keyCode == 9) {  //Tab pressed
    // 		objEvent.preventDefault(); //Stops its action
    // 	  }
    // 	});
    //   }
    this.usersService.resetUser(uuid).subscribe((data) => {
      this.modalClose();
      this.ngOnInit();
      //console.log(data);
    });
  }

  //Modals
  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
