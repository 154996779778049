<div class="row card mb-2 pr-0">
    <div class="d-flex align-items-center bd-highlight flex-nowrap">
        <a class="d-flex flex-grow-1 bd-highlight align-items-center ml-md-3"
           [routerLink]="['/dashboard/home/assessment-commercial/detail', item.uuid]">
            <div class="row w-100 flex-nowrap">
                <div class="col-2">
                    <b>{{"assessment-commercial-item.assessor" | translate}}:</b> {{item.assessor}}
                </div>

                <div class="col-2">
                    <b>{{"assessment-commercial-item.partner" | translate}}:</b>
                    {{item.partner}}
                </div>
                <div class="col-2">
                    <b>{{"assessment-commercial-item.dealer" | translate}}:</b>
                    {{item.dealer}}
                </div>
                <div class="col-2">
                    <b>{{"assessment-commercial-item.trainee" | translate}}:</b>
                    {{item.trainee}}
                </div>

                <div class="col-2">
                    <span><small><h6>{{"assessment-commercial-item.createdAt" | translate}}:</h6>{{item.createdAt | date: 'short'}}</small></span>
                </div>
            </div>
        </a>
        <div class="buttons d-flex mr-4">
            <button class="btn btn-dark mr-1" type="button" [routerLink]="['/dashboard/home/assessment-commercial/detail', item.uuid]">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
            <button class="btn btn-danger" type="button" (click)="delete()">
                <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</div>
