import { Component, Inject } from '@angular/core';
import { ShareService, CONSUMER_SHARE } from 'src/app/core/services/share.service';

@Component({
  selector: 'app-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss']
})
export class ShareListComponent {
  constructor(@Inject(CONSUMER_SHARE) readonly shareService: ShareService) { }
}
