import { Component, OnInit } from '@angular/core';
import {ProductCommercialFeature} from '../../../../../shared/models/commercial/product-commercial-feature.model';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {ProductsCommercialFeatureService} from '../../../../../core/services/commercial/products-commercial-feature.service';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {LoadingPopupComponent} from '../../../../../shared/components/popups/loading-popup/loading-popup.component';
import {ErrorPopupComponent} from '../../../../../shared/components/popups/error-popup/error-popup.component';
import constants from 'src/app/core/constants';

@Component({
  selector: 'app-feature-commercial-add',
  templateUrl: './feature-commercial-add.component.html',
  styleUrls: ['./feature-commercial-add.component.scss']
})
export class FeatureCommercialAddComponent implements OnInit {

  feature: ProductCommercialFeature = new ProductCommercialFeature();
  modalLoad: NgbModalRef;
  errors: any[] = [];
  errorsm: string;
  fileData: File = null;
  previewUrl: any = null;
  safePreviewUrl: SafeUrl;
  lang: string = 'EN';
  productCommercialGroupUUID: string;


  constructor(private _route: ActivatedRoute,
              private _location: Location,
              private featureService: ProductsCommercialFeatureService,
              private _modal: NgbModal,
              private sanitizer: DomSanitizer,
              private _translate: TranslateService) { }

  ngOnInit() {
    this._route.queryParams
        .subscribe(params => {
          this.productCommercialGroupUUID = params.productCommercialGroupUUID;
        });
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  deleteImageProduct() {
    this.safePreviewUrl = null;
    this.previewUrl = null;
  }

  prepareFilesList(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    let mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.safePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrl);
    }
  }

  createFeature(form: NgForm) {

    const formData = new FormData();
    formData.append('title.EN', this.feature.title.en);
    formData.append('title.AR', this.feature.title.ar);
    formData.append('content.EN', this.feature.content.en);
    formData.append('content.AR', this.feature.content.ar);
    formData.append('productCommercialGroupUUID', this.productCommercialGroupUUID);
    if(!!this.fileData)
      formData.append('image', this.fileData);

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    this.featureService.create(formData)
        .subscribe(data => {
          //console.log(data);
          this.modalClose();
          this._location.back();
        }, err => {
          if (err.error.errors){
            this.errors = err.error.errors;
          } else {
            this.errorsm = err.error.message;
            let modal = this._modal.open(ErrorPopupComponent);
            modal.componentInstance.messageTitle =
                this._translate.instant('feature-edit.messageTitle');
            modal.componentInstance.messageDescription =
                this._translate.instant('feature-edit.messageDescription');
            this.modalClose();
          }
        });
  }

  modalClose() {
    this.modalLoad.close();
  }

  pictNotLoading(event) {
    event.target.src = constants.imageUrl.noimage;
  }

  backClicked() {
    this._location.back();
  }

}
