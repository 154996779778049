import { UserModel } from "./user";

export class UserRole {

    static readonly ADMIN: string = 'ADMIN';
    static readonly POWER_ADMIN: string = "POWER_ADMIN";
    static readonly SUB_ADMIN: string = "SUB_ADMIN";
    static readonly LITE_ADMIN: string = "LITE_ADMIN";
    static readonly POWER_USER: string = "POWER_USER";
    static readonly SUB_USER: string = "SUB_USER";
    static readonly LITE_USER: string = "LITE_USER";
    static readonly STOCK_USER: string = "STOCK_USER";
    static readonly RETAIL: string = "RETAIL";

    roles: string[] = [
        UserRole.SUB_ADMIN,
        UserRole.LITE_ADMIN,
        UserRole.POWER_USER,
        UserRole.SUB_USER,
        UserRole.LITE_USER,
        UserRole.STOCK_USER,
        UserRole.RETAIL
    ];

    superAdminRoles: string[] = [
        UserRole.ADMIN,
        UserRole.POWER_ADMIN
    ]

    adminRoles: string[] = [
        UserRole.POWER_ADMIN,
        UserRole.SUB_ADMIN,
        UserRole.LITE_ADMIN,
        UserRole.POWER_USER,
        UserRole.SUB_USER,
        UserRole.LITE_USER,
        UserRole.STOCK_USER,
        UserRole.RETAIL
    ];

    stockUserRoles: string[] = [
        UserRole.STOCK_USER
    ];

    constructor(values: Partial<UserRole> = {}) {
        Object.assign(values, this);
    }

    static readonly isManager = (user: UserModel) =>
        user.role === UserRole.SUB_ADMIN
        || user.role === UserRole.SUB_USER
        || user.role === UserRole.POWER_USER;
}
