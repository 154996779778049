<nav class="sidebar" [ngClass]="{sidebarPushRight: isActive, collapsed: collapsed}">
    <div class="list-group">
        <a routerLink="/dashboard/home/salestools/stocklist/upload" class="list-group-item"
            *ngIf="shouldShowStockListUpdate()">
            <fa-icon [icon]="faFileUpload"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.uploadStockList" | translate }}</span>
        </a>
        <ng-container *ngIf="shouldShowNonStockMenu()">
            <a routerLink="/dashboard/home/users" class="list-group-item">
                <fa-icon [icon]="faUsers"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.users" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/partners" class="list-group-item">
                <fa-icon [icon]="faUsers"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.partners" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/salestools" class="list-group-item">
                <fa-icon [icon]="faBook"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.salesTools" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/wheels-commercial" class="list-group-item">
                <fa-icon [icon]="faTruckMonster"></fa-icon>
                &nbsp;&nbsp;<span>{{ "menu.productsCommercial" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/wheels" class="list-group-item">
                <fa-icon [icon]="faTruckMonster"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.products" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/region" class="list-group-item">
                <fa-icon [icon]="faGlobe"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.region" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/oe" class="list-group-item">
                <fa-icon [icon]="faCar"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.oe" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/share" class="list-group-item">
                <fa-icon [icon]="faShareAlt"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.share" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/share/commercial" class="list-group-item">
                <fa-icon [icon]="faShareAltSquare"></fa-icon>
                &nbsp;&nbsp;<span>{{ "menu.shareCommercial" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/assessment" class="list-group-item">
                <fa-icon [icon]="faClipboardList"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.assessment" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/assessment-commercial" class="list-group-item">
                <fa-icon [icon]="faClipboardList"></fa-icon>
                &nbsp;&nbsp;<span>{{ "menu.assessmentCommercial" | translate }}</span>
            </a>
            <a routerLink="/dashboard/home/mileage-commercial" class="list-group-item">
                <fa-icon [icon]="faClipboardList"></fa-icon>
                &nbsp;&nbsp;<span>{{ "menu.mileageCommercial" | translate }}</span>
            </a>
            <hr>
            <a class="list-group-item">
                <span><app-survey-export></app-survey-export></span>
            </a>
            <hr>
        </ng-container>
        <hr>
        <a routerLink="/" (click)="onLoggedout()" class="list-group-item">
            <fa-icon [icon]="faPowerOff"></fa-icon>&nbsp;&nbsp;<span>{{ "menu.logout" | translate }}</span>
        </a>
    </div>
    <div class="toggle-button" [ngClass]="{collapsed: collapsed}" (click)="toggleCollapsed()">
        <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
    </div>
</nav>
