import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { ProductCommercialGroup } from "../../../shared/models/commercial/product-commercial-group.model";
import { LocalStorageKey, LocalStorageManager } from "../local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class ProductsCommercialService {

  headers = new HttpHeaders({ "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) });

  // API
  private API = environment.baseUrlCMS;

  // Endpoints
  private WHEEL = "/commercial/product";

  constructor(private http: HttpClient) { }

  getProductGroups(): Observable<Generic<ProductCommercialGroup[]>> {
    return this.http.get<Generic<ProductCommercialGroup[]>>(this.API + this.WHEEL, { headers: this.headers });
  }

  getProductById(uuid: string): Observable<Generic<ProductCommercialGroup>> {
    return this.http.get<Generic<ProductCommercialGroup>>(this.API + this.WHEEL + "/" + uuid, { headers: this.headers });
  }

  createProduct(newProduct: any): Observable<Generic<ProductCommercialGroup[]>> {
    return this.http.post<Generic<ProductCommercialGroup[]>>(this.API + this.WHEEL, newProduct, { headers: this.headers });
  }

  updateProduct(uuid: string, wheelUpdate: any): Observable<Generic<ProductCommercialGroup[]>> {
    return this.http.put<Generic<ProductCommercialGroup[]>>(this.API + this.WHEEL + "/" + uuid, wheelUpdate, { headers: this.headers });
  }

  deleteProduct(uuid: string): Observable<Generic<ProductCommercialGroup[]>> {
    return this.http.delete<Generic<ProductCommercialGroup[]>>(this.API + this.WHEEL + "/" + uuid, { headers: this.headers });
  }

  uploadFile(formData: FormData): Observable<Generic<string>> {
    formData.append('file', (<HTMLInputElement>$('input[type=file]')[0]).files[0]);
    return this.http.post<Generic<string>>(this.API + "/commercial/product/import", formData, { headers: this.headers });
  }
}
