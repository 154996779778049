import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wheels-commercial',
  templateUrl: './wheels-commercial.component.html',
  styleUrls: ['./wheels-commercial.component.scss']
})
export class WheelsCommercialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
