<!-- <div class="container-fluid mb-2">
    <form #fileForm="ngForm" method="POST" enctype="multipart/form-data">
        <label>Choose file to upload</label>
        <div class="input-group">
            <div class="custom-file">
                <input class="custom-file-input inputfile form-control input-underline input-lg" type="file"
                    accept=".xls, .xlsx" name="fileName" id="file" (change)="onFileChange($event)">
                <label class="custom-file-label d-flex align-items-center" id="file_name">File</label>
            </div>
            <div class="input-group-append">
                <button class="btn btn-dark" type="button" (click)="saveFile()">
                    <i class="fa fa-floppy-o mr-1" aria-hidden="true"></i>
                    Save
                </button>
            </div>
        </div>
    </form>
</div>

<div class="container-fluid mt-3 mb-3">
    <label>or...</label>
</div> -->

<div class="container-fluid">
    <div class="d-md-flex justify-content-end">
        <a class=" btn btn-dark btn-lg mr-2" role="button"
            href="assets/excel/pilot 20 static data 2.0 - XLight Laith June 1.xlsx"
            download="pilot 20 static data 2.0 - XLight Laith June 1.xlsx"><i class="fa fa-cloud-download mr-1"
                aria-hidden="true"></i>
                {{"wheel-file.staticTestExcel" | translate}}
        </a>
        <a class=" btn btn-dark btn-lg" role="button"
            href="assets/excel/Dynamic_Sheet_Sizes_Final_XLight.xlsx" download="Dynamic_Sheet_Sizes_Final_XLight.xlsx"><i
                class="fa fa-cloud-download mr-1" aria-hidden="true"></i>
                {{"wheel-file.dynamicTestExcel" | translate}}
        </a>
    </div>
    <label>{{"wheel-file.chooseFileToUpload" | translate}}</label>

    <form #fileForm="ngForm" method="POST" enctype="multipart/form-data">
        <div class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" accept=".xls, .xlsx" (change)="fileBrowseHandler($event.target.files)" />
            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
            <h3>{{"wheel-file.dragAndDropOrSelectExcelFileHere" | translate}}</h3>
            <p class="constraints-info">{{"wheel-file.type" | translate}}</p>
        </div>
        <div>
            <small>{{"wheel-file.subtitle" | translate}}</small>
        </div>
        <div class="files-list d-flex flex-column">
            <div class="single-file" *ngFor="let file of files; let i = index">
                <div class="file-icon">
                    <img src="./assets/img/excelicon.ico" alt="Excel File">
                </div>
                <div class="info">
                    <h4 class="name">
                        {{ file?.name }}
                    </h4>
                    <p class="size">
                        {{ file?.size| formatBytes: 3 }}
                    </p>
                    <div class="delete justify-content-end">
                        <i class="fa fa-trash-o fa-2x" aria-hidden="true" (click)="deleteFile(i)"></i>
                    </div>
                    <!-- <div class="progress mb-1">
                        <div class="progress-bar" role="progressbar" style="width: 0%" aria-valuenow="0"
                            aria-valuemin="0" aria-valuemax="100">0%</div>
                    </div> -->
                    <!-- <div class="information" style="display:none;">
                        <div class="alert alert-success">File ready to save.</div>
                    </div> -->
                </div>
            </div>
        </div>
        <div>
            <label>{{"wheel-file.typeOfDocumentToSave" | translate}} </label>
        </div>
        <div class="input-group-append d-flex justify-content-center">
            <button class="col-6 btn btn-dark btn-lg d-flex justify-content-center btnstatic" type="button" (click)="saveFile()">
                <i class="fa fa-floppy-o d-flex align-self-center mr-2" aria-hidden="true"></i>{{"wheel-file.saveStaticExcel" | translate}}
            </button>
            <button class="col-6 btn btn-outline-dark btn-lg d-flex justify-content-center ml-2" type="button" (click)="saveFileDyn()">
                <i class="fa fa-floppy-o d-flex align-self-center mr-2" aria-hidden="true"></i>{{"wheel-file.saveDynamicExcel" | translate}}
            </button>
        </div>
    </form>
    <div class="row mt-5">
        <div class="col-2 buttonBack d-md-flex align-items-md-center pl-2">
            <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels/list']"></i>
        </div>
    </div>
    <!-- <label class="mt-5">Click the next buttons to download the sample Excel files:</label>
    <div class="input-group-append d-flex justify-content-center">
        <button class="col-6 btn btn-dark btn-lg d-flex justify-content-center btnstatic" type="button" (click)="downloadFileStatic()">
            <i class="fa fa-cloud-download d-flex align-self-center mr-2" aria-hidden="true"></i>Download static Excel
        </button>
        <button class="col-6 btn btn-outline-dark btn-lg d-flex justify-content-center ml-2" type="button" (click)="downloadFileDinamic()">
            <i class="fa fa-cloud-download d-flex align-self-center mr-2" aria-hidden="true"></i>Download dinamic Excel
        </button>
    </div> -->
</div>
