<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <h2>{{'sales-tools.stock-list-upload' | translate}}</h2>
        <p>{{'sales-tools.upload-stock-list-here' | translate}}</p>
    </div>
    <div class="mb-5"></div>
</div>

<div class="container-fluid">
    <app-stock-upload-input [lastChanged]="lastUpdateDate$ | async" (fileChange)='onFileChange($event)'>
    </app-stock-upload-input>
    <button class="btn btn-primary" [disabled]='!file' (click)="uploadStockList()">
        {{'sales-tools.send-file' | translate}}
    </button>
</div>