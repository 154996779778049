import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../authentication/auth.service';

import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  popup: NgbModalRef;

  //API
  private API = environment.baseUrlCMS;
  private LOGIN = '/login';

  constructor(private _auth: AuthService,
      private _router: Router,
      private _modal: NgbModal,
      private _translate: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const token: string = this._auth.getToken();
  
    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {

        if (err.status === 401) {
          this._auth.logout();
          this._router.navigateByUrl('/');
        }
        if (err.status === 403) {
          let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Your user does not have permissions to access this resource';
          this._translate.instant('error-popup.messageTitle1');
          // modal.componentInstance.messageDescription = 'Your user does not have permissions to access this resource';
          this._router.navigateByUrl('/dashboard');
        }
        if (err.status === 404) {
            if (request.url.includes("stock_list")) {
                return new Observable<HttpEvent<any>>();
            }
          let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = this._translate.instant('app-forgot.messageTitle');
          modal.componentInstance.messageDescription = this._translate.instant('app-forgot.messageDescription');
          this._router.navigateByUrl('/dashboard');
        }

        return throwError( err );

      })
    );
  }
}
