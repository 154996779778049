import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CustomModalComponent} from '../../shared/components/modals/custom-modal/custom-modal.component';
import {DeletePopupComponent} from '../../shared/components/popups/delete-popup/delete-popup.component';
import {LoadingPopupComponent} from '../../shared/components/popups/loading-popup/loading-popup.component';
import {ValidPopupComponent} from '../../shared/components/popups/valid-popup/valid-popup.component';
import {ErrorPopupComponent} from '../../shared/components/popups/error-popup/error-popup.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private modal: NgbModal) {
    }

    showDeleteAskModal(onSubmit?: () => void, onCancel?: () => void) {
        const modalRef = this.modal.open(CustomModalComponent, {backdrop: 'static', keyboard: false});
        modalRef.componentInstance.messageTitle = 'modal.messageTitle';
        modalRef.result.then(value => {
            if (value)
                onSubmit?.apply(this);
            else
                onCancel?.apply(this);
        })
    }

    showDeleteProgressModal(): NgbModalRef {
        return this.modal.open(DeletePopupComponent, {backdrop: 'static', keyboard: false});
    }

    showErrorModal(title: string = '', description: string = 'modal.errorMessage'): NgbModalRef {
        return this.showModal(ErrorPopupComponent, title, description);
    }

    showLoadingModal(): NgbModalRef {
        return this.modal.open(LoadingPopupComponent, {backdrop: 'static', keyboard: false});
    }

    showValidModal(): NgbModalRef {
        return this.showModal(ValidPopupComponent, 'modal.validTitle', 'modal.validDescription')
    }

    private showModal(modalClass: any, title: string, description: string) {
        const modalRef = this.modal.open(modalClass);
        modalRef.componentInstance.messageTitle = title
        modalRef.componentInstance.messageDescription = description
        return modalRef;
    }
}
