import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { Wheel } from 'src/app/shared/models/wheels/wheel';
import { environment } from 'src/environments/environment';
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  headers = new HttpHeaders({ "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN) });

  private API = environment.baseUrlCMS;

  private WHEEL = "/product";
  private EXCEL = "/blob/static";
  private EXCELDYN = "/blob/dynamic";

  constructor(private http: HttpClient) { }

  getProducts(): Observable<Generic<Wheel[]>> {
    return this.http.get<Generic<Wheel[]>>(this.API + this.WHEEL, { headers: this.headers });
  }

  getProductById(uuid: string): Observable<Generic<Wheel[]>> {
    return this.http.get<Generic<Wheel[]>>(this.API + this.WHEEL + "/" + uuid, { headers: this.headers });
  }

  getAttrOperation(): Observable<Generic<Wheel[]>> {
    return this.http.get<Generic<Wheel[]>>(this.API + "/attr?type=OPERATION", { headers: this.headers });
  }

  getAttrSegment(): Observable<Generic<Wheel[]>> {
    return this.http.get<Generic<Wheel[]>>(this.API + "/attr?type=SEGMENT", { headers: this.headers });
  }

  getAttrOe(): Observable<Generic<Wheel[]>> {
    return this.http.get<Generic<Wheel[]>>(this.API + "/attr?type=OE_REP", { headers: this.headers });
  }

  createProduct(newProduct: any): Observable<Generic<Wheel[]>> {
    return this.http.post<Generic<Wheel[]>>(this.API + this.WHEEL, newProduct, { headers: this.headers });
  }

  updateProduct(uuid: string, wheelUpdate: any): Observable<Generic<Wheel[]>> {
    return this.http.put<Generic<Wheel[]>>(this.API + this.WHEEL + "/" + uuid, wheelUpdate, { headers: this.headers });
  }

  deleteProduct(uuid: string): Observable<Generic<Wheel[]>> {
    return this.http.delete<Generic<Wheel[]>>(this.API + this.WHEEL + "/" + uuid, { headers: this.headers });
  }

  uploadFile(formData: FormData): Observable<Generic<Wheel[]>> {
    formData.append('file', (<HTMLInputElement>$('input[type=file]')[0]).files[0]);
    return this.http.post<Generic<Wheel[]>>(this.API + this.EXCEL, formData, { headers: this.headers });
  }
  uploadFileDyn(formData: FormData): Observable<Generic<Wheel[]>> {
    formData.append('file', (<HTMLInputElement>$('input[type=file]')[0]).files[0]);
    return this.http.post<Generic<Wheel[]>>(this.API + this.EXCELDYN, formData, { headers: this.headers });
  }
}
