import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsersComponent } from './pages/users/users.component';
import { UserComponent } from './pages/users/user/user.component';
import { HomeComponent } from './pages/home/home.component';
import { WheelsComponent } from './pages/wheels/wheels.component';
import { RegionComponent } from './pages/region/region.component';
import { WheelListComponent } from './pages/wheels/wheel-list/wheel-list.component';
import { WheelAddComponent } from './pages/wheels/wheel-add/wheel-add.component';
import { WheelDetailComponent } from './pages/wheels/wheel-detail/wheel-detail.component';
import { WheelEditComponent } from './pages/wheels/wheel-edit/wheel-edit.component';
import { WheelFileComponent } from './pages/wheels/wheel-file/wheel-file.component';
import { RegionListComponent } from './pages/region/region-list/region-list.component';
import { RegionEditComponent } from './pages/region/region-edit/region-edit.component';
import { RegionDetailComponent } from './pages/region/region-detail/region-detail.component';
import { RegionAddComponent } from './pages/region/region-add/region-add.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { UserEditComponent } from './pages/users/user-edit/user-edit.component';
import { UserAddComponent } from './pages/users/user-add/user-add.component';
import { UserFileComponent } from './pages/users/user-file/user-file.component';
import { ShareComponent } from './pages/share/share.component';
import { ShareListComponent } from './pages/share/share-list/share-list.component';
import { ShareDetailComponent } from './pages/share/share-detail/share-detail.component';
import { ShareEditComponent } from './pages/share/share-edit/share-edit.component';
import { ShareAddComponent } from './pages/share/share-add/share-add.component';
import { OeListBrandsComponent } from './pages/oe/oe-list-brands/oe-list-brands.component';
import { OeEditBrandsComponent } from './pages/oe/oe-edit-brands/oe-edit-brands.component';
import { OeAddBrandsComponent } from './pages/oe/oe-add-brands/oe-add-brands.component';
import { OeDetailBrandsComponent } from './pages/oe/oe-detail-brands/oe-detail-brands.component';
import { OeComponent } from './pages/oe/oe.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { AssessmentListComponent } from './pages/assessment/assessment-list/assessment-list.component';
import { AssessmentDetailComponent } from './pages/assessment/assessment-detail/assessment-detail.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { TechnologiesListComponent } from './pages/technologies/technologies-list/technologies-list.component';
import { TechnologiesDetailComponent } from './pages/technologies/technologies-detail/technologies-detail.component';
import { TechnologiesAddComponent } from './pages/technologies/technologies-add/technologies-add.component';
import { TechnologiesEditComponent } from './pages/technologies/technologies-edit/technologies-edit.component';
import { UspComponent } from './pages/usp/usp.component';
import { UspAddComponent } from './pages/usp/usp-add/usp-add.component';
import { UspEditComponent } from './pages/usp/usp-edit/usp-edit.component';
import { UspDetailComponent } from './pages/usp/usp-detail/usp-detail.component';
import { CategoriesListComponent } from './pages/categories/categories-list/categories-list.component';
import { CategoriesDetailComponent } from './pages/categories/categories-detail/categories-detail.component';
import { CategoriesEditComponent } from './pages/categories/categories-edit/categories-edit.component';
import { CategoriesAddComponent } from './pages/categories/categories-add/categories-add.component';
import { WheelsCommercialComponent } from "./pages/wheels-commercial/wheels-commercial.component";
import { WheelCommercialListComponent } from "./pages/wheels-commercial/list/wheel-commercial-list.component";
import { WheelCommercialDetailComponent } from "./pages/wheels-commercial/detail/wheel-commercial-detail.component";
import { WheelCommercialEditComponent } from "./pages/wheels-commercial/edit/wheel-commercial-edit.component";
import { WheelCommercialAddComponent } from "./pages/wheels-commercial/add/wheel-commercial-add.component";
import { WheelCommercialFileComponent } from "./pages/wheels-commercial/file/wheel-commercial-file.component";
import { TechnologiesCommercialListComponent } from "./pages/technologies-commercial/technologies-list/technologies-commercial-list.component";
import { TechnologiesCommercialDetailComponent } from "./pages/technologies-commercial/technologies-detail/technologies-commercial-detail.component";
import { TechnologiesCommercialEditComponent } from "./pages/technologies-commercial/technologies-edit/technologies-commercial-edit.component";
import { TechnologiesCommercialAddComponent } from "./pages/technologies-commercial/technologies-add/technologies-commercial-add.component";
import { FeatureCommercialDetailComponent } from './pages/feature-commercial/feature-detail/feature-commercial-detail.component';
import { FeatureCommercialEditComponent } from './pages/feature-commercial/feature-edit/feature-commercial-edit.component';
import { FeatureCommercialAddComponent } from './pages/feature-commercial/feature-add/feature-commercial-add.component';
import { PartnersComponent } from "./pages/partners/partners.component";
import { PartnerListComponent } from "./pages/partners/partner-list/partner-list.component";
import { PartnerEditComponent } from "./pages/partners/partner-edit/partner-edit.component";
import { PartnerAddComponent } from "./pages/partners/partner-add/partner-add.component";
import { AssessmentCommercialComponent } from './pages/assessment-commercial/assessment-commercial.component';
import { AssessmentCommercialListComponent } from './pages/assessment-commercial/assessment-commercial-list/assessment-commercial-list.component';
import { AssessmentCommercialDetailComponent } from './pages/assessment-commercial/assessment-commercial-detail/assessment-commercial-detail.component';
import { MileageCommercialComponent } from './pages/mileage-commercial/mileage-commercial.component';
import { SalesToolsComponent } from './pages/sales-tools/sales-tools.component';
import { CatalogueEditComponent } from './pages/sales-tools/catalogue-edit/catalogue-edit.component';
import { StockListComponent } from './pages/sales-tools/stock-list/stock-list.component';
import { CatalogueListComponent } from './pages/sales-tools/catalogue-list/catalogue-list.component';
import { ShareCommercialListComponent } from './pages/share-commercial/share-commercial-list/share-commercial-list.component';
import { ShareCommercialDetailComponent } from './pages/share-commercial/share-commercial-detail/share-commercial-detail.component';
import { ShareCommercialEditComponent } from './pages/share-commercial/share-commercial-edit/share-commercial-edit.component';
import { ShareCommercialAddComponent } from './pages/share-commercial/share-commercial-add/share-commercial-add.component';
import { StockListUploadComponent } from './pages/sales-tools/stock-list-upload/stock-list-upload.component';


const routes: Routes = [
	{
		path: '', component: HomeComponent,
		children: [
			{ path: '', redirectTo: 'wheels', pathMatch: 'full' },
			{
				path: 'users', component: UsersComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: UserListComponent },
					{ path: 'detail/:id', component: UserComponent },
					{ path: 'edit/:id', component: UserEditComponent },
					{ path: 'add', component: UserAddComponent },
					{ path: 'file', component: UserFileComponent }

				]
			},
			{
				path: 'partners', component: PartnersComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: PartnerListComponent },
					{ path: 'edit/:id', component: PartnerEditComponent },
					{ path: 'add', component: PartnerAddComponent }
				]
			},
			{
				path: 'wheels', component: WheelsComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: WheelListComponent },
					{ path: 'detail/:id', component: WheelDetailComponent },
					{ path: 'edit/:id', component: WheelEditComponent },
					{ path: 'add', component: WheelAddComponent },
					{ path: 'file', component: WheelFileComponent }
				]
			},
			{
				path: 'wheels-commercial', component: WheelsCommercialComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: WheelCommercialListComponent },
					{ path: 'detail/:id', component: WheelCommercialDetailComponent },
					{ path: 'edit/:id', component: WheelCommercialEditComponent },
					{ path: 'add', component: WheelCommercialAddComponent },
					{ path: 'file', component: WheelCommercialFileComponent }
				]
			},
			{
				path: 'technologies-commercial', component: TechnologiesComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: TechnologiesCommercialListComponent },
					{ path: 'detail/:id', component: TechnologiesCommercialDetailComponent },
					{ path: 'edit/:id', component: TechnologiesCommercialEditComponent },
					{ path: 'add', component: TechnologiesCommercialAddComponent }
				]
			},
			{
				path: 'feature-commercial', component: TechnologiesComponent,
				children: [
					{ path: 'detail/:id', component: FeatureCommercialDetailComponent },
					{ path: 'edit/:id', component: FeatureCommercialEditComponent },
					{ path: 'add', component: FeatureCommercialAddComponent }
				]
			},
			{
				path: 'region', component: RegionComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: RegionListComponent },
					{ path: 'detail/:id', component: RegionDetailComponent },
					{ path: 'edit/:id', component: RegionEditComponent },
					{ path: 'add', component: RegionAddComponent }
				]
			},
			{
				path: 'share', component: ShareComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: ShareListComponent },
					{ path: 'detail/:id', component: ShareDetailComponent },
					{ path: 'edit/:id', component: ShareEditComponent },
					{ path: 'add', component: ShareAddComponent }
				]
			},
			{
				path: 'share/commercial', component: ShareComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: ShareCommercialListComponent },
					{ path: 'detail/:id', component: ShareCommercialDetailComponent },
					{ path: 'edit/:id', component: ShareCommercialEditComponent },
					{ path: 'add', component: ShareCommercialAddComponent }
				]
			},
			{
				path: 'oe', component: OeComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: OeListBrandsComponent },
					{ path: 'detail/:id', component: OeDetailBrandsComponent },
					{ path: 'edit/:id', component: OeEditBrandsComponent },
					{ path: 'add', component: OeAddBrandsComponent }
				]
			},
			{
				path: 'assessment', component: AssessmentComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: AssessmentListComponent },
					{ path: 'detail/:id', component: AssessmentDetailComponent },
					{ path: 'detail/:id/accept', component: AssessmentDetailComponent },
					{ path: 'detail/:id/reject', component: AssessmentDetailComponent }
				]
			},
			{
				path: 'assessment-commercial', component: AssessmentCommercialComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: AssessmentCommercialListComponent },
					{ path: 'detail/:id', component: AssessmentCommercialDetailComponent },
				]
			},
			{
				path: 'technologies', component: TechnologiesComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: TechnologiesListComponent },
					// { path: 'detail/:id/:idWheel', component: TechnologiesDetailComponent },
					{ path: 'detail/:id', component: TechnologiesDetailComponent },
					// { path: 'edit/:id/:idWheel', component: TechnologiesEditComponent },
					{ path: 'edit/:id', component: TechnologiesEditComponent },
					{ path: 'add', component: TechnologiesAddComponent }
				]
			},
			{
				path: 'usp', component: UspComponent,
				children: [
					{ path: 'detail/:id/:idWheel', component: UspDetailComponent },
					{ path: 'detail/:id', component: UspDetailComponent },
					{ path: 'edit/:id/:idWheel', component: UspEditComponent },
					{ path: 'edit/:id', component: UspEditComponent },
					{ path: 'add', component: UspAddComponent }
				]
			},
			{
				path: 'categories', component: UspComponent,
				children: [
					{ path: '', redirectTo: 'list', pathMatch: 'full' },
					{ path: 'list', component: CategoriesListComponent },
					{ path: 'detail/:id', component: CategoriesDetailComponent },
					{ path: 'edit/:id', component: CategoriesEditComponent },
					{ path: 'add', component: CategoriesAddComponent }
				]
			},
			{
				path: 'mileage-commercial', component: MileageCommercialComponent
			},
			{
				path: 'salestools', component: SalesToolsComponent,
				children: [
					{ path: '', redirectTo: 'stocklist', pathMatch: 'full' },
					{ path: 'stocklist', component: StockListComponent },
					{ path: 'catalogue', component: CatalogueListComponent }
				]
			},
			{ path: 'salestools/stocklist/upload', component: StockListUploadComponent },
			{
				path: 'salestools/catalogue/add', component: CatalogueEditComponent
			},
			{
				path: 'salestools/catalogue/edit/:id', component: CatalogueEditComponent
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class HomeRoutingModule { }
