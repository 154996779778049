export class UserModel {
  public uuid: string = "";
  public name: string = "";
  public surname: string = "";
  public email: string = "";
  public username: string = "";
  public role: string = "";
  public status: string = "";
  public attempt: number = 0;
  public reset: boolean = true;
  public lastLogin: Date;
  public countries: string[] = [];
  public partner: string = "";
  public application: string = "";
  public regions: string[] = [];
  public shareRegions: string[] = [];

  //public password: string = "";

  constructor(values: Object = {}) {
    Object.assign(values, this);
  }
}
