import { Segment } from './objectWheel/segment';
import { Operation } from './objectWheel/operation';
import { OeRep } from './objectWheel/oeRep';
import { Usp } from './objectWheel/usp';
import { Technologies } from './objectWheel/technologies';
import { Attr } from './objectWheel/attr';

export class Wheel {

    public uuid: string = "";
    public familyName: string = "";
    public patternName: string = "";
    public patternSlogan: any = "";
    public arpatternSlogan: any = "";
    public cmsLogo: string = "";
    public appLogo: string = "";
    public image: string = "";
    public modelRender: string = "";
    public segment: Segment;
    public operation: Operation;
    public oeRep: OeRep;
    public usp: Usp[] = [];
    public technologies: Technologies[] = [];
    public attr: Attr[] = [];
    public rft: string = "";
    public productOrder: any;
    public listimage: string = "";
    public modelLight2: string = "";
    public modelZoom: string = "";
    public modelIntentisy: string = "";
    public modelRotation: string = "";
    public modelFlHeight: string = "";

    constructor(values: Object = {}) {

        this.segment = new Segment();
        this.operation = new Operation();
        this.oeRep = new OeRep();
        this.usp = new Array<Usp>();
        this.technologies = new Array<Technologies>();
        this.attr = new Array<Attr>();

        Object.assign(this, values);
    }
}
