import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shareCategory'
})
export class ShareCategoryPipe implements PipeTransform {

  transform(value: any, searchCategory?: any): any {
    return (searchCategory && searchCategory != 'Select Category') ? value.filter(opt => opt.category.name === searchCategory) : value;
  }
}
