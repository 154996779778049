<div class="container-fluid">
    <form #k="ngForm" (ngSubmit)="forgot(k)">
        <div class="row d-md-flex flex-column justify-content-md-center align-items-md-center mt-md-5">
            <div class="col-md-4">
                <fieldset class="form-group">
                    <input class="form-control input-underline input-lg"
                        placeholder="{{ 'app-forgot.username' | translate }}" [(ngModel)]="login.username"
                        name="username" required>
                </fieldset>
                <button type="submit" class="btn btn-danger btn-block"
                    [disabled]="!k.form.valid">{{ "app-forgot.button"  | translate }}</button>
            </div>
        </div>
        <div class="row">
            <div class="col-2 buttonBack d-md-flex align-content-md-start align-items-md-center">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
        </div>
    </form>
</div>