import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { TranslateService } from '@ngx-translate/core';

import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { ProductCommercialTechnology } from "../../../../../shared/models/commercial/product-commercial-technology.model";
import { ProductsCommercialTechnologyService } from "../../../../../core/services/commercial/products-commercial-technology.service";
import constants from 'src/app/core/constants';

@Component({
  selector: 'app-technologies-edit',
  templateUrl: './technologies-commercial-edit.component.html',
  styleUrls: ['./technologies-commercial-edit.component.scss']
})
export class TechnologiesCommercialEditComponent implements OnInit {

  technologies: ProductCommercialTechnology = new ProductCommercialTechnology();
  modalLoad: NgbModalRef;
  uuid: string;
  errors: any[] = [];
  errorsm: string;
  fileData: File = null;
  previewUrl: any = null;
  safePreviewUrl: SafeUrl;
  uspLang: string = 'EN';
  
  constructor(private _route: ActivatedRoute,
    private _location: Location,
    private technologyService: ProductsCommercialTechnologyService,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.uuid = this._route.snapshot.params.id;

    this.technologyService.get(this.uuid)
      .subscribe((data: any) => {
        this.technologies = data.data;
        console.log(this.technologies);
      });
  }

  //UPDATE IMAGE

  //On file drop handler
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  //Handle file from browsing
  fileBrowseHandler(file) {
    this.prepareFilesList(file);
  }

  //Delete Image
  deleteImageProduct() {
    this.safePreviewUrl = null;
    this.previewUrl = null;
  }

  prepareFilesList(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    let mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
      this.safePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrl);
    }
  }

  //EDIT TECHNOLOGY
  edit(form: NgForm) {
    
    const formData = new FormData();
    formData.append('name.EN', this.technologies.name.en);
    formData.append('name.AR', this.technologies.name.ar);
    formData.append('image', this.fileData);

    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    this.technologyService.update(this.uuid, formData)
      .subscribe(data => {
        //console.log(data);
        this.modalClose();
        this._location.back();
      }, err => {
        if (err.error.errors){
          this.errors = err.error.errors;
        } else {
          this.errorsm = err.error.message;
          let modal = this._modal.open(ErrorPopupComponent);
          modal.componentInstance.messageTitle = 
          // 'Error to edit technology.';
          this._translate.instant('technologies-edit.messageTitle');
          modal.componentInstance.messageDescription = 
          // 'Please check all the fields and try again.';
          this._translate.instant('technologies-edit.messageDescription');
          this.modalClose();
        }
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  pictNotLoading(event) {
    event.target.src = constants.imageUrl.noimage;
  }

  backClicked() {
    this._location.back();
  }

}
