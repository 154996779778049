<div class="container-fluid">
        <form (ngSubmit)="createComment(k)" #k="ngForm">
            <div class="form-group">
    
                <div class="row form-group imageShare d-flex justify-content-center">
                    <img class="mb-5" [src]="newComment.imgUrl" (error)="pictNotLoading($event)">
                </div>
    
                <label>{{"share-add.title" | translate}}</label>
                <input [(ngModel)]="newComment.title" name="title" type="text" class="form-control" required>
    
                <label>{{"share-add.category" | translate}}</label>
                <select [(ngModel)]="newComment.category.key" name="category" type="text"
                    class="form-control form-control-lg" required>
                    <option *ngFor="let Category of optionCategory" [value]="Category">
                        {{Category}}</option>
                </select>
    
                <label>{{"share-add.description" | translate}}</label>
                <input [(ngModel)]="newComment.description" name="description" type="text" class="form-control">
    
                <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                    <label>{{"share-add.chooseImageToUpload" | translate}}</label>
                    <small>{{"share-add.youCanOnlyUploadOneImage" | translate}}</small>
                </div>
    
                <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" accept=".png, .jpg, .svg" (change)="fileBrowseHandler($event)" />
                    <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                    <h3>{{"share-add.dragAndDrop" | translate}}</h3>
                    <p class="constraints-info">(.jpg, .png, .svg)</p>
                </div>
    
    
                <div class="image-preview" *ngIf="safePreviewUrl">
                    <label>{{"share-add.imagePreview" | translate}}</label>
                    <div class="imagePreview d-flex justify-content-md-center">
                        <img [src]="safePreviewUrl" width="auto" height="300" />
                    </div>
                </div>
    
            </div>
    
            <div class="row">
                <div class="col-2 buttonBack">
                    <i class="fa fa-angle-double-left" [routerLink]="[shareService.routes.sharesList]"></i>
                </div>
                <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                    <button type="submit" class="btn btn-dark">
                        <i class="fa fa-save mr-2"></i>{{"share-add.save" | translate}}</button>
                </div>
            </div>
        </form>
    
        <div class="alert alert-danger" role="alert" *ngIf="errors.length">
            <div *ngFor="let error of errors">
                <h5>{{error}}</h5>
            </div>
        </div>
    
        <div class="alert alert-danger" role="alert" *ngIf="errorsm">
            <h5>{{errorsm}}</h5>
        </div>
    </div>