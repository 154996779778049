<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-5">
        <div class="logoTechnologies d-md-flex justify-content-md-center">
            <img [src]="technologies.imageUrl" (error)="pictNotLoading($event)" alt="Image">
        </div>
    
        <div class="d-flex">
            <label>{{"technologies-detail.title" | translate}}</label>
            <div class="d-flex justify-content-end w-100">
                <p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
                    EN &nbsp;
                </p>|
                <p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p>
            </div>
        </div>
            <input *ngIf="uspLang=='EN'" [value]="technologies.name.en" name="name.en" type="text"
                class="form-control form-control-lg" readonly>
            <input *ngIf="uspLang=='AR'" [value]="technologies.name.ar" name="name.ar" type="text"
                class="form-control form-control-lg" readonly>
    </div>

    <div class="row">
        <div class="col-2 buttonBack d-md-flex">
            <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
        </div>
        <div class="col-10 buttonEdit d-md-flex justify-content-md-end">
            <button type="button" class="btn btn-dark"
                [routerLink]="['/dashboard/home/technologies/edit', technologies.uuid]">
                <i class="fa fa-pencil mr-2"></i>{{"technologies-detail.edit" | translate}}</button>
        </div>
    </div>
</div>