import { Component, Inject } from '@angular/core';
import { ShareService, CONSUMER_SHARE } from 'src/app/core/services/share.service';

@Component({
  selector: 'app-share-add',
  templateUrl: './share-add.component.html',
  styleUrls: ['./share-add.component.scss']
})
export class ShareAddComponent {

  constructor(@Inject(CONSUMER_SHARE) readonly shareService: ShareService) { }

}
