import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { Generic } from "src/app/shared/models/generic";
import { UserModel } from "src/app/shared/models/user";
import { environment } from "src/environments/environment";
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
	providedIn: "root",
})
export class UsersService {

	private API = environment.baseUrlCMS;

	private USERS = "/user";
	private EXCEL = "/blob/profile";

	headers = new HttpHeaders({
		Authorization: "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
	});

	constructor(private _http: HttpClient) { }

	getUsersExcelBlob(): Observable<Blob> {
		return this._http.get(
			this.API + this.USERS + '/export',
			{
				headers: this.headers,
				observe: 'body',
				responseType: 'blob'
			}
		);
	}

	getAllUsers(): Observable<Generic<UserModel[]>> {
		return this._http.get<Generic<UserModel[]>>(
			this.API + this.USERS
		);
	}


	getUser(uuidItem: string): Observable<Generic<UserModel>> {
		return this._http.get<Generic<UserModel>>(
			this.API + this.USERS + "/" + uuidItem,
			{ headers: this.headers }
		);
	}

	createUser(newUser: any): Observable<Generic<UserModel[]>> {
		return this._http.post<Generic<UserModel[]>>(
			this.API + this.USERS, newUser,
			{ headers: this.headers }
		);
	}

	updateItem(uuidItem: string, editUser: any): Observable<Generic<UserModel[]>> {
		return this._http.put<Generic<UserModel[]>>(
			this.API + this.USERS + "/" + uuidItem, editUser,
			{ headers: this.headers }
		);
	}

	deleteUser(uuidItem: string) {
		return this._http.delete(this.API + this.USERS + "/" + uuidItem,
			{ headers: this.headers }
		);
	}

	uploadFile(formData: FormData): Observable<Generic<UserModel[]>> {
		formData.append('file', (<HTMLInputElement>$('input[type=file]')[0]).files[0]);
		return this._http.post<Generic<UserModel[]>>(
			this.API + this.EXCEL, formData,
			{ headers: this.headers });
	}

	activateUpdateUser(uuidItem: string): Observable<Generic<UserModel[]>> {
		return this._http.put<Generic<UserModel[]>>(this.API + this.USERS + "/" + uuidItem + "/active", { headers: this.headers });
	}

	deactivateUpdateUser(uuidItem: string): Observable<Generic<UserModel[]>> {
		return this._http.put<Generic<UserModel[]>>(this.API + this.USERS + "/" + uuidItem + "/inactive", { headers: this.headers });
	}

	resetUser(uuidItem: string): Observable<Generic<UserModel[]>> {
		return this._http.put<Generic<UserModel[]>>(this.API + this.USERS + "/" + uuidItem + "/reset", { headers: this.headers });
	}

	updatePassword(uuidItem: string, formData: any): Observable<Generic<UserModel[]>> {
		return this._http.put<Generic<UserModel[]>>(this.API + this.USERS + "/updatepass" + "/" + uuidItem, formData, { headers: this.headers });
	}
}
