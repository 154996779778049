<div class="container-fluid">
  <div class="row justify-content-between">
    <div class="col">
      <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
    </div>

    <div class="col">
      <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
        <input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
          placeholder='{{"oe-list-brands.searchOe" | translate}}'>
        <i class="fa fa-search" aria-hidden="true"></i>
      </div>
    </div>

    <div class="col">
      <div class="buttonAdd d-md-flex justify-content-md-end">
        <button class="btn btn-outline-dark" (click)="createOE()">
          <i class="fa fa-plus-circle mr-2"
            aria-hidden="true"></i><span>{{"oe-list-brands.addModel" | translate}}</span>
        </button>
      </div>
    </div>
    </div>

  <div class="row card align-items-md-center mb-3" *ngFor="let brand of brands | filterOe: searchText, let i=index">

    <div class="d-inline-flex bd-highlight w-100 flex-nowrap">

      <div class="col-1 logoBrand d-md-flex justify-content-md-center align-items-md-center">
        <img [src]="brand.logo" (error)="pictNotLoadingBrand($event)">
      </div>

      <div class="col-11">

        <div class="row brand d-inline-flex bd-highlight w-100">

          <div class="d-inline-flex align-items-md-center justify-content-between w-100">
            <div>
              <a class="l-grey" *ngIf="uspLang=='EN'"
                (click)="arrayBrands[i]=!arrayBrands[i]"><b>{{"oe-list-brands.brand" | translate}}:</b>
                {{brand.brand.en}}</a>
              <a class="l-grey" *ngIf="uspLang=='AR'"
                (click)="arrayBrands[i]=!arrayBrands[i]"><b>{{"oe-list-brands.brand" | translate}}:</b>
                {{brand.brand.ar}}</a>
            </div>

            <div>
              <button class="badge badge-light border-danger"
                (click)="arrayBrands[i]=!arrayBrands[i]">{{"oe-list-brands.viewModels" | translate}}</button>
            </div>

          </div>

          <!-- <div class="col-5">
                <a [routerLink]="['/dashboard/home/oe/detail', brand.uuid]"><b>Name:</b> {{brand.name}}</a>
              </div> -->

        </div>

        <div class="models" *ngIf="arrayBrands[i]">
          <div class="row mt-4 flex-nowrap" *ngFor="let model of brand.models">
            <div class="col-8">
              <a *ngIf="uspLang=='EN'"
                [routerLink]="['/dashboard/home/oe/detail', model.uuid]"><b>{{"oe-list-brands.model" | translate}}:</b>
                {{model.name.en}}</a>
              <a *ngIf="uspLang=='AR'"
                [routerLink]="['/dashboard/home/oe/detail', model.uuid]"><b>{{"oe-list-brands.model" | translate}}:</b>
                {{model.name.ar}}</a>
              <hr>
            </div>

            <div class="col-4">
              <button class="btn btn-dark mr-2" type="button" [routerLink]="['/dashboard/home/oe/edit', model.uuid]">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </button>
              <button class="btn btn-danger" type="button" (click)="deleteItem(model.uuid, i)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>