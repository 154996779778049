<div class="container-fluid">
    <h1>Upload mileage file</h1>
    <br/>
    <div class="form-group d-md-flex flex-md-column">
        <form method="POST" enctype="multipart/form-data">
            <div class="dropzone" appDnd>
                <input type="file" accept=".xls, .xlsx" (change)="fileBrowseHandler($event.target.files)"
                       onclick="value = null"/>
                <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                <h3> {{ "user-file.title-2" | translate }} </h3>
                <p class="constraints-info"> {{ "user-file.type" | translate }} </p>
            </div>
            <br/>
            <div class="files-list d-flex flex-column">
                <div class="single-file" *ngIf="file">
                    <div class="file-icon">
                        <img src="./assets/img/excelicon.ico" alt="Excel File">
                    </div>
                    <div class="info">
                        <h4 class="name">
                            {{ file.name }}
                        </h4>
                        <p class="size">
                            {{ file.size | formatBytes: 3 }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="input-group-append d-flex justify-content-center">
                <button class="col-6 btn btn-dark btn-lg d-flex justify-content-center btnstatic" type="button"
                        (click)="uploadFile()">
                    <i class="fa fa-floppy-o d-flex align-self-center mr-2"
                       aria-hidden="true"></i>{{"wheel-file.saveStaticExcel" | translate}}
                </button>
            </div>
        </form>
    </div>
</div>
