import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Generic } from 'src/app/shared/models/generic';
import { Attr } from 'src/app/shared/models/wheels/objectWheel/attr';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private API = environment.baseUrlCMS;

  private attr = '/attr';

  headers = new HttpHeaders({ "Content-type": "application/json" });

  constructor(private _http: HttpClient) { }

  getAttrCategory(): Observable<Generic<Attr[]>> {
    return this._http.get<Generic<Attr[]>>(this.API + "/attr?type=SHARECATEGORY", { headers: this.headers });
  }

  postAttrCreateCategory(newCategory: any): Observable<Generic<Attr[]>> {
    return this._http.post<Generic<Attr[]>>(this.API + this.attr, newCategory, { headers: this.headers });
  }

  getAttrDetailCategory(uuidItem: string): Observable<Generic<Attr[]>> {
    return this._http.get<Generic<Attr[]>>(this.API + this.attr + '/' + uuidItem, { headers: this.headers });
  }

  getAttrUpdateCategory(uuidItem: string, updateCategory: any): Observable<Generic<Attr[]>> {
    return this._http.put<Generic<Attr[]>>(this.API + this.attr + '/' + uuidItem, updateCategory, { headers: this.headers });
  }

  getAttrDeleteCategory(uuidItem: string): Observable<Generic<Attr[]>> {
    return this._http.delete<Generic<Attr[]>>(this.API + this.attr + '/' + uuidItem, { headers: this.headers });
  }
}
