<div class="container-fluid">

    <div class="mb-5">
        <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
    </div>

    <div class="viewImageLogo d-md-flex mb-5">

        <div class="col-md-12 d-md-flex">
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="labelImageProduct">
                    <label>{{"wheel-detail.productImage" | translate}}:</label>
                </div>
                <div class="imageProduct ml-5">
                    <img [src]="wheel.image" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>

            <div class="col-6 viewLogo">
                <div class="viewLogoCms mb-5">
                    <div class="labelLogoCms mb-2">
                        <label>{{"wheel-detail.cmsLogo" | translate}}:</label>
                    </div>
                    <div class="logoCms">
                        <img [src]="wheel.cmsLogo" (error)="pictNotLoadingCms($event)">
                    </div>
                </div>

                <div class="viewLogoApp">
                    <div class="labelLogoApp mb-2">
                        <label>{{"wheel-detail.appLogo" | translate}}:</label>
                    </div>
                    <div class="logoApp">
                        <img [src]="wheel.appLogo" (error)="pictNotLoadingApp($event)">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group d-md-flex flex-md-column mb-md-5">

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.familyName" | translate}}</label>
                <input [value]="wheel.familyName" name="familyName" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.patternName" | translate}}</label>
                <input [value]="wheel.patternName" name="patternName" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.patternSlogan" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.patternSlogan.en" name="patternSlogan" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.patternSlogan.ar" name="patternSlogan" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.segment" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.segment.name.en" name="segment" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.segment.name.ar" name="segment" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.operation" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.operation.name.en" name="operation" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.operation.name.ar" name="operation" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.oeRep" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.oeRep.name.en" name="oeRep" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.oeRep.name.ar" name="oeRep" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-6 selectRft">
                <label>{{"wheel-detail.rft" | translate}}</label>
                <input value={{wheel.rft}} name="rft" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-6">
                <label>{{"wheel-detail.productOrder" | translate}}</label>
                <input [(ngModel)]="wheel.productOrder" name="productOrder" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-6 selectUSP mb-5">
                <label>{{"wheel-detail.usp" | translate}}</label>
                <div *ngFor="let usp of wheel.usp">
                    <input *ngIf="uspLang=='EN'" [value]="usp.name.en" name="usp" type="text" class="form-control SelectFormControl" readonly>
                    <input *ngIf="uspLang=='AR'" [value]="usp.name.ar" name="usp" type="text" class="form-control SelectFormControl" readonly>
                </div>
            </div>
        
            <div class="flex-row col-md-6 selectTechnologies mb-5">
                <label>{{"wheel-detail.technologies" | translate}}</label>
                <div *ngFor="let technologies of wheel.technologies">
                    <input *ngIf="uspLang=='EN'" [value]="technologies.title.en" name="technologies" type="text" class="form-control SelectFormControl"
                        readonly>
                        <input *ngIf="uspLang=='AR'" [value]="technologies.title.ar" name="technologies" type="text" class="form-control SelectFormControl"
                        readonly>
                </div>
            </div>
        </div>

        <label style="text-align: center;">{{"wheel-detail.model3D" | translate}}</label>
        <div class="image3d d-flex justify-content-center">
            <iframe id="iframe" width="700" height="350" [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
        </div>

        <div class="col-12 d-md-flex justify-content-md-between mt-5 pl-0 pr-0">
            <div class="flex-row col-md-2">
                <label>Light2</label>
                 <input [value]="wheel.modelLight2" name="modelLight2" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-2">
                <label>Zoom</label>
                 <input [value]="wheel.modelZoom" name="modelZoom" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-2">
                <label>Intensity</label>
                 <input [value]="wheel.modelIntentisy" name="modelIntentisy" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-2">
                <label>Rotation</label>
                 <input [value]="wheel.modelRotation" name="modelRotation" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-2">
                <label>FlHeight</label>
                 <input [value]="wheel.modelFlHeight" name="modelFlHeight" type="text" class="form-control" readonly>
            </div>
        </div>

        <!-- <div class="row d-md-flex justify-content-md-around">
            <div class="col-3 d-md-flex justify-content-md-around align-items-md-center">
                <label>Light intensity</label>
                <input type="range" class="custom-range" min="0.1" max="2" step="0.1">
            </div>
        
            <div class="col-3 d-md-flex justify-content-md-around align-items-md-center">
                <label>Rotation</label>
                <input type="range" class="custom-range ml-3" min="0.1" max="2" step="0.1">
            </div>
        </div> -->

        <div class="row mt-5">
            <div class="col-2 buttonBack d-md-flex align-content-md-center">
                <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels/list']"></i>
            </div>
            <div class="col-10 buttonEdit d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/wheels/edit', wheel.uuid]">
                    <i class="fa fa-pencil mr-2"></i>{{"wheel-detail.edit" | translate}}</button>
            </div>
        </div>
    </div>