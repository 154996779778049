<div class="container-fluid">
    <form #techForm="ngForm" (ngSubmit)="createTechnology(techForm)">
        <div class="form-group mb-md-5">
            <div class="row form-group imageShare d-flex justify-content-center">
				<img class="mb-5" [src]="technologies.image" (error)="pictNotLoading($event)">
            </div>
            <div class="d-flex">
				<label>{{"technologies-add.title" | translate}}</label>
				<div class="d-flex justify-content-end w-100">         
					<p role="button" (click)="uspLang='EN'" [ngClass]="{'font-weight-bold':uspLang === 'EN'}">
						EN &nbsp; 
					</p>|
					<p role="button" (click)="uspLang='AR'" [ngClass]="{'font-weight-bold':uspLang === 'AR'}">&nbsp; AR</p> 
				</div>
			</div>
            
            <input *ngIf="uspLang=='EN'" [(ngModel)]="technologies.title" name="title" type="text"
                class="form-control input-underline input-lg" required>
            <input *ngIf="uspLang=='AR'" [(ngModel)]="technologies.artitle" name="artitle" type="text"
                class="form-control input-underline input-lg" required>

                <div class="d-flex">
                    <label>{{"technologies-add.description" | translate}}</label>
                </div>
            <textarea *ngIf="uspLang=='EN'" [(ngModel)]="technologies.description" name="description" type="text"
                class="form-control form-control-lg"></textarea>
            <textarea *ngIf="uspLang=='AR'" [(ngModel)]="technologies.ardescription" name="ardescription" type="text"
                class="form-control form-control-lg"></textarea>

            <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
                <label>{{"technologies-add.chooseImageToUpload" | translate}}</label>
                <small>{{"technologies-add.youCanOnlyUploadOneImage" | translate}}</small>
            </div>

            <div class="dropzone mb-5" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" accept=".png, .jpg, .jpeg, .gif, .svg" (change)="fileBrowseHandler($event)" />
                <i class="fa fa-download fa-3x" aria-hidden="true"></i>
                <h3>{{"technologies-add.dragAndDrop" | translate}}</h3>
                <p class="constraints-info">(.jpg, .png, .svg)</p>
            </div>


            <div class="image-preview" *ngIf="safePreviewUrl">
                <div class="d-md-flex justify-content-md-between align-items-md-center">
                    <label>{{"technologies-add.imagePreview" | translate}}</label>
                    <button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
                        <i class="fa fa-times-circle" aria-hidden="true"></i>
                    </button>
                </div>
                <div class="imagePreview d-flex justify-content-md-center" width="auto">
                    <img [src]="safePreviewUrl" width="auto" height="300" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-2 buttonBack">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
            <div class="col-8 buttonSave d-md-flex justify-content-md-center">
                <!-- <button type="submit" class="btn btn-dark" [disabled]="!techForm.form.valid"> -->
                <button type="submit" class="btn btn-dark">
                    <i class="fa fa-save mr-2"></i>{{"technologies-add.save" | translate}}</button>
            </div>
        </div>
    </form>

    <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
        <div *ngFor="let error of errors">
            <h5>{{error}}</h5>
        </div>
    </div>

    <div class="alert alert-danger mt-md-3" role="alert" *ngIf="errorsm">
        <h5>{{errorsm}}</h5>
    </div>
</div>