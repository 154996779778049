export class Assessment {

    uuid: string = '';
    assessor: string = "";
    partner: string = "";
    dealer: string = "";
    trainee: string = "";
    percentage: string = "";
    comment: string = '';
    status: string = '';
    appearance: string = '';
    definingVisit: string = '';
    creditSituation: string = '';
    openOrders: string = '';
    stockCheck: string = '';
    brandVisibility: string = '';
    drivingMIXOpportunities: string = '';
    presentingProduct: string = '';
    collectMarketInfo: string = '';
    outstandingAndCollectPayment: string = '';
    leaveToDealerBSGiveaway: string = '';
    updateDealerCardInfo: string = '';
    createdAt: Date;
    updatedAt: Date;

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }

}