import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES_CMS } from '../../models/const';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  listLanguages = LANGUAGES_CMS;
  selectedLanguageId: string;
  selectedLanguageObject: any;

  isMenuShow = true;
  isLenguaje = true;

  constructor(private _translate: TranslateService) { }

  ngOnInit() {
    // Translation
    let arrayListLanguages = this.listLanguages.filter(group => group.id).map(group => group.id);
    // console.log(arrayListLanguages);
    this._translate.addLangs(arrayListLanguages);
    // console.log(this._translate.currentLang);
    this.selectedLanguageId = this._translate.currentLang;
    // console.log(this.selectedLanguageId);
    this.selectedLanguageObject = this.listLanguages.find(lang => lang.id == this.selectedLanguageId);
  }

  changeLanguage(newLanguage: any) {
    this.selectedLanguageObject = newLanguage;
    this.selectedLanguageId = this.selectedLanguageObject.id;
    // console.log(this.selectedLanguageId);
    this._translate.use(this.selectedLanguageId);
  }

  showLenguaje() {
    this.isLenguaje = !this.isLenguaje;
  }

  showMenuLanguage() {
    this.isMenuShow = !this.isMenuShow;
  }

}
