
export class LocalStorageManager {


    public static get(key: LocalStorageKey): string | null {
        return localStorage.getItem(this.getKeyName(key));
    }

    public static set(key: LocalStorageKey, value: string): void {
        localStorage.setItem(this.getKeyName(key), value);
    }

    public static remove(key: LocalStorageKey): void {
        localStorage.removeItem(this.getKeyName(key));
    }

    public static removeAll(): void {
        localStorage.clear();
    }
    
    private static getKeyName(key: LocalStorageKey) : string {
        return LocalStorageKey[key];
    }

}

export enum LocalStorageKey
{
    ACCESS_TOKEN,
    ROLE
}
