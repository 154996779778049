<ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item">
        <a class="nav-link {{routerStockListActive.isActive ? 'active' : ''}}" id="stocklist-tab" data-toggle="pill"
           role="tab"
           routerLinkActive #routerStockListActive="routerLinkActive"
           [routerLink]="['/dashboard/home/salestools/stocklist']"
           [routerLinkActiveOptions]="{exact: true}"
           aria-controls="stocklist" aria-selected="true">{{"sales-tools.stock-list" | translate}}</a>
    </li>
    <li class="nav-item">
        <a class="nav-link {{routerCatalogueActive.isActive ? 'active' : ''}}" id="catalogue-tab" data-toggle="pill"
           role="tab"
           routerLinkActive #routerCatalogueActive="routerLinkActive"
           [routerLink]="['/dashboard/home/salestools/catalogue']"
           aria-controls="catalogue" aria-selected="false">{{"sales-tools.catalogue" | translate}}</a>
    </li>
</ul>

<router-outlet></router-outlet>
