import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MultiselectAdapter } from './multiselect-adapter';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent<OptionType> implements OnInit, OnChanges {

  constructor() { }

  private static _defaultAdapter = {
    render: option => option.toString(),
    keySelect: option => option.toString()
  } as MultiselectAdapter<any>;

  @Input()
  label: string;

  @Input()
  availableOptions: OptionType[];

  @Input()
  adapter: MultiselectAdapter<OptionType> = MultiselectComponent._defaultAdapter;

  @Input()
  value: OptionType[] | undefined;

  @Input()
  editable: boolean = true;

  @Output()
  valueChange = new EventEmitter<OptionType[]>();

  private selectedKeys: string[] = [];

  ngOnInit(): void {
    this.refreshSelectedKeys();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('value' in changes) {
      this.refreshSelectedKeys();
    }
  }

  private refreshSelectedKeys = () => {
    this.selectedKeys = (this.value || []).map(this.getKey);
  }

  renderOption = (option: OptionType): string => {
    return this.adapter.render(option);
  }

  getKey = (option: OptionType): string => {
    return this.adapter.keySelect(option);
  }

  getSelectedOptions = (): OptionType[] => {
    return this.availableOptions.filter(o => this.selectedKeys.find(key => key === this.getKey(o)));
  }

  isOptionSelected = (option: OptionType): boolean => {
    return this.selectedKeys.includes(this.getKey(option));
  }

  optionClass = (option: OptionType): string => {
    return this.isOptionSelected(option) ? 'multiselect-selected-option' : '';
  }

  onSelectKey = (key: string) => {
    this.selectedKeys.push(key);

    this.valueChange.emit(this.getSelectedOptions());
  }

  removeOption = (option: OptionType) => {
    this.selectedKeys = this.selectedKeys.filter(key => key !== this.getKey(option));

    this.valueChange.emit(this.getSelectedOptions());
  }

}
