import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from "@angular/forms";
import { Location } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { BrandModel } from 'src/app/shared/models/brand';
import { OeService } from 'src/app/core/services/oe.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-oe-edit-brands',
  templateUrl: './oe-edit-brands.component.html',
  styleUrls: ['./oe-edit-brands.component.scss']
})
export class OeEditBrandsComponent implements OnInit {

  brand: BrandModel = new BrandModel();
  modalLoad: NgbModalRef;
  errors: any[] = [];
  id: string;
  uspLang: string = 'EN';

  fileDataBrand: File = null;
  previewUrlBrand: any = null;
  brandPreviewUrl: SafeUrl;


  constructor(private oeService: OeService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.oeService.getItem(this.id)
      .subscribe((data: any) => {
        //console.log(data);
        this.brand = data.data;
      });
  }

  //UPDATE BRAND IMAGE
  //On file drop handler
  onFileDroppedBrand($event) {
    this.prepareFilesListBrand($event);
  }

  //Handle file from browsing
  fileBrowseHandlerBrand($event) {
    this.prepareFilesListBrand($event);
  }

  prepareFilesListBrand(fileInput: any) {
    this.fileDataBrand = <File>fileInput.target.files[0];
    this.previewBrand();
  }

  previewBrand() {
    let mimeTypeBrand = this.fileDataBrand.type;
    if (mimeTypeBrand.match(/image\/*/) == null) {
      return;
    }

    let readerBrand = new FileReader();
    readerBrand.readAsDataURL(this.fileDataBrand);
    readerBrand.onload = (_event) => {
      this.previewUrlBrand = readerBrand.result;
      this.brandPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlBrand);
    }
  }
  //Delete Image
  deleteImageBrand() {
    this.brandPreviewUrl = null;
    this.previewUrlBrand = null;
  }

  pictNotLoadingBrand(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
    event.target.style = 'height: 5vw; max-height: 5vw';
  }


  //EDIT OE
  edit(form: NgForm) {

    const formData = new FormData();
    formData.append('name', this.brand.name.en);
    formData.append('arname', this.brand.name.ar);
    formData.append('key', this.brand.key);
    formData.append('brand', this.brand.brand.en);
    formData.append('arbrand', this.brand.brand.ar);
    formData.append('brandlogo', this.fileDataBrand);



    //console.log(this.brand)
    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
    //   $(document).keydown(function (objEvent) {
    //     if (objEvent.keyCode == 9) {  //Tab pressed
    //       objEvent.preventDefault(); //Stops its action
    //     }
    //   });
    // }
    this.oeService.updateItem(this.id, formData)
      .subscribe(res => {
        //console.log(data);
        this.modalClose();
        this.router.navigateByUrl('home/oe/list');
      }, err => {
        this.errors = err.error.errors;
        //console.log(err);
      });
  }

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
