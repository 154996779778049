export class Light {

    modelLight2: string[] = [
        'left',
        'center',
        'right'
    ];

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }
}