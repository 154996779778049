<div class="container-fluid">
	<form (ngSubmit)="edit()" #f="ngForm">
		
		<div class="form-group mb-md-5">
			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-3">
					<label>{{"partner-edit.name" | translate}}</label>
					<input [(ngModel)]="editPartner.name" name="name" type="text" class="form-control" required/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-3">
					<b>{{"partner-edit.assessment" | translate}}</b>
					<br/>
					{{"partner-edit.assessment-description" | translate}} <br/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6 managers">
					<app-multiselect label="{{'partner-edit.managers' | translate}}" [availableOptions]="managers"
									 [(value)]="editPartner.managers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>

				<div class="flex-row col-md-6 trainees">
					<app-multiselect label="{{'partner-edit.trainees' | translate}}" [availableOptions]="trainees"
									 [(value)]="editPartner.trainees" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>
			</div>

			<hr/>

			<div class="d-md-flex justify-content-between mt-5">
				<div class="flex-row col-md-6">
					<b>{{"partner-edit.salesTools" | translate}}</b>
					<br/>
					{{"partner-edit.salesTools-description" | translate}} <br/>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6 managers">
					<app-multiselect label="{{'partner-edit.stockList' | translate}}" [availableOptions]="stockUsers"
									 [(value)]="editPartner.stockUsers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>

				<div class="flex-row col-md-6 trainees">
					<app-multiselect label="{{'partner-edit.mobileUsers' | translate}}" [availableOptions]="mobileUsers"
									 [(value)]="editPartner.mobileUsers" [adapter]="partnerUserAdapter">
					</app-multiselect>
				</div>
			</div>

			<br/>

			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-1">
					<div class="buttonSave">
						<button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
							{{"partner-edit.save" | translate}}</button>
					</div>
				</div>
				<div class="flex-row col-md-1">
					<div class="buttonCancel">
						<button type="button" class="btn btn-outline-danger"
								[routerLink]="['/dashboard/home/partners/list']">
							{{"partner-edit.cancel" | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="alert alert-danger" role="alert" *ngIf="errors">
		<div *ngFor="let error of errors">
			<h5>{{ error }}</h5>
		</div>
	</div>
</div>
