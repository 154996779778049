import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { BrandModel } from 'src/app/shared/models/brand';
import { OeService } from 'src/app/core/services/oe.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-oe-add-brands',
  templateUrl: './oe-add-brands.component.html',
  styleUrls: ['./oe-add-brands.component.scss']
})
export class OeAddBrandsComponent implements OnInit {

  brand: BrandModel = new BrandModel();
  modalLoad: NgbModalRef;
  errors: string[] = [];
  errorsm: string;
  uspLang: string = 'EN';

  fileDataBrand: File = null;
  previewUrlBrand: any = null;
  brandPreviewUrl: SafeUrl;

  constructor(private oeService: OeService,
    private router: Router,
    private _modal: NgbModal,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  //UPDATE BRAND IMAGE
  //On file drop handler
  onFileDroppedBrand($event) {
    this.prepareFilesListBrand($event);
  }

  //Handle file from browsing
  fileBrowseHandlerBrand($event) {
    this.prepareFilesListBrand($event);
  }

  prepareFilesListBrand(fileInput: any) {
    this.fileDataBrand = <File>fileInput.target.files[0];
    this.previewBrand();
  }

  previewBrand() {
    let mimeTypeBrand = this.fileDataBrand.type;
    if (mimeTypeBrand.match(/image\/*/) == null) {
      return;
    }

    let readerBrand = new FileReader();
    readerBrand.readAsDataURL(this.fileDataBrand);
    readerBrand.onload = (_event) => {
      this.previewUrlBrand = readerBrand.result;
      this.brandPreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.previewUrlBrand);
    }
  }
  //Delete Image
  deleteImageBrand() {
    this.brandPreviewUrl = null;
    this.previewUrlBrand = null;
  }

  pictNotLoadingBrand(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  createItem(form: NgForm) {

    const formData = new FormData();
    formData.append('name', this.brand.name);
    formData.append('arname', this.brand.arname);
    formData.append('key', this.brand.key = this.brand.name.toUpperCase());
    formData.append('brand', this.brand.brand);
    formData.append('arbrand', this.brand.arbrand);
    formData.append('brandlogo', this.fileDataBrand);


    this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
    // if (this.modalLoad) {
    //   $(document).keydown(function (objEvent) {
    //     if (objEvent.keyCode == 9) {  //Tab pressed
    //       objEvent.preventDefault(); //Stops its action
    //     }
    //   });
    // }
    this.oeService.createItem(formData)
      .subscribe(data => {
        //console.log(data)
        this.errorsm = '';
        this.modalClose();
        this.router.navigateByUrl('home/oe');
      }, err => {
        if (err.error.errors)
          this.errors = err.error.errors;
        else
          this.errorsm = err.error.message;
        //console.log(err)
        //console.log(this.errorsm);
      })

  }

  modalClose() {
    this.modalLoad.close();
  }
}
