import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/shared/models/login';

@Injectable({
  providedIn: 'root'
})

export class PasswordService {

  private API = environment.baseUrlCMS;

  private login = '/login';
  private forgot = '/forgotpass';

  constructor(private _http: HttpClient) { }

  forgotPassword(username: string): Observable<Generic<Login>> {
    return this._http.put<Generic<Login>>(this.API + this.login + this.forgot + "/" + username, null);
  }
}
