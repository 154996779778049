<div class="container-fluid">

    <div class="mb-5">
        <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>   
    </div>
    
    <div class="viewImageLogo d-md-flex mb-5">

        <div class="col-md-12 d-md-flex">
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="labelImageProduct">
                    <label>{{"wheel-detail.productImage" | translate}}:</label>
                </div>
                <div class="imageProduct ml-5">
                    <img [src]="wheel.imageUrl" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>
        </div>
    </div>
    
    <div class="viewImageLogo d-md-flex mb-5">

        
        <div class="col-md-12 d-md-flex">
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="imageProduct ml-5">
                    <img [src]="wheel.tyreImage1Url" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>
            
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="imageProduct ml-5">
                    <img [src]="wheel.tyreImage2Url" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>
            
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="imageProduct ml-5">
                    <img [src]="wheel.tyreImage3Url" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>


        </div>
    </div>

    <div class="row viewImageLogo d-md-flex justify-content-md-between align-items-md-center">

        <div class="d-md-flex justify-content-md-between align-items-md-center">
            <div class="col-4 viewImageProduct d-md-flex">
                <div class="imageProduct ml-5">
                    <img [src]="wheel.patternImageUrl" (error)="pictNotLoadingProduct($event)">
                </div>
            </div>
        </div>
    </div>

    <div class="form-group d-md-flex flex-md-column mb-md-5">

        <div class="d-md-flex justify-content-between">

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.patternName" | translate}}</label>
                <input [value]="wheel.patternName" name="patternName" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-edit.quality" | translate}}</label>
                <input [value]="wheel.quality == 'PREMIUM' ? ('wheel-edit.quality.premium' | translate) : ('wheel-edit.quality.standard' | translate)" name="quality" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.application" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.application.name.en" name="application" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.application.name.ar" name="application" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.subcategory" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.subcategory?.name.en" name="subcategory" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.subcategory?.name.ar" name="subcategory" type="text" class="form-control" readonly>
            </div>

            <div class="flex-row col-md-4">
                <label>{{"wheel-detail.axle" | translate}}</label>
                <input *ngIf="uspLang=='EN'" [value]="wheel.axle.name.en" name="axle" type="text" class="form-control" readonly>
                <input *ngIf="uspLang=='AR'" [value]="wheel.axle.name.ar" name="axle" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-6 selectUSP mb-5">
                <label>{{"wheel-detail.technologies" | translate}}</label>
                <div *ngFor="let technology of wheel.technologies">
                    <input *ngIf="uspLang=='EN'" [value]="technology.name.en" name="technology" type="text" class="form-control SelectFormControl" readonly>
                    <input *ngIf="uspLang=='AR'" [value]="technology.name.ar" name="technology" type="text" class="form-control SelectFormControl" readonly>
                </div>
            </div>

            <div class="flex-row col-md-6 mb-5">
                <label>{{"wheel-detail.features" | translate}}</label>
                <div *ngFor="let feature of wheel.features">
                    <input *ngIf="uspLang=='EN'" [value]="feature.title.en" name="feature" type="text" class="form-control SelectFormControl" readonly>
                    <input *ngIf="uspLang=='AR'" [value]="feature.title.ar" name="feature" type="text" class="form-control SelectFormControl" readonly>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-2 buttonBack d-md-flex align-content-md-center">
                <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/wheels-commercial/list']"></i>
            </div>
            <div class="col-10 buttonEdit d-md-flex justify-content-md-end">
                <button type="button" class="btn btn-dark" [routerLink]="['/dashboard/home/wheels-commercial/edit', wheel.uuid]">
                    <i class="fa fa-pencil mr-2"></i>{{"wheel-detail.edit" | translate}}</button>
            </div>
        </div>
    </div>
