import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-tools',
  templateUrl: './sales-tools.component.html',
  styleUrls: ['./sales-tools.component.scss']
})
export class  SalesToolsComponent{

}
