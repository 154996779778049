import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AssessmentCommercial} from '../../../../../shared/models/commercial/assessment-commercial.model';
import {AssessmentCommercialService} from '../../../../../core/services/commercial/assessment-commercial.service';
import {ModalService} from '../../../../../core/services/modal.service';

@Component({
  selector: 'app-assessment-commercial-item',
  templateUrl: './assessment-commercial-item.component.html',
  styleUrls: ['./assessment-commercial-item.component.scss']
})
export class AssessmentCommercialItemComponent {

  @Input() item: AssessmentCommercial;

  @Output() deleteEvent = new EventEmitter<string>();

  constructor(
      private assessmentCommercialService: AssessmentCommercialService,
      private modalService: ModalService
  ) { }

  delete() {
    this.modalService.showDeleteAskModal(()=>{this.deleteAssessment()})
  }

  private deleteAssessment() {
    const modalRef = this.modalService.showDeleteProgressModal();
    this.assessmentCommercialService.deleteAssessment(this.item.uuid)
        .subscribe(_ => {
          this.deleteEvent.emit(this.item.uuid);
          modalRef.close()
        });
  }
}
