import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTechnologies'
})
export class TechnologiesPipe implements PipeTransform {

  transform(value: any, args: any): any {

    if (args === '' || args.length < 2) return value;

    const resultTechnologies = [];
    for (const technology of value) {
      if (technology.title.en.toLowerCase().indexOf(args.toLowerCase()) > -1) {
        resultTechnologies.push(technology);
      };
    };
    return resultTechnologies;
  }
}
