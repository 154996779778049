import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Login } from 'src/app/shared/models/login';
import { PasswordService } from 'src/app/core/services/password.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';

@Component({
  selector: 'app-app-forgot-password',
  templateUrl: './app-forgot-password.component.html',
  styleUrls: ['./app-forgot-password.component.scss']
})
export class AppForgotPasswordComponent implements OnInit {

  login: Login = new Login();
  modalLoad: NgbModalRef;

  constructor(
    private passwordService: PasswordService,
    private router: Router,
    private _modal: NgbModal,
    private _location: Location,
  ) { }

  ngOnInit() {
  }

  forgot(form: NgForm) {

    let updateUsername = {
      username: this.login.username
    };

    this.passwordService.forgotPassword(updateUsername.username)
      .subscribe(data => {
        this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
        // if (this.modalLoad) {
        //   $(document).keydown(function (objEvent) {
        //     if (objEvent.keyCode == 9) {  //Tab pressed
        //       objEvent.preventDefault(); //Stops its action
        //     }
        //   });
        // };
        this.modalClose();
        this.router.navigate(['']);
      });
  };

  modalClose() {
    this.modalLoad.close();
  }

  backClicked() {
    this._location.back();
  }
}
