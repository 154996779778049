import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from "src/environments/environment";
import { Technologies } from 'src/app/shared/models/wheels/objectWheel/technologies';
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class TechnologiesService {

  headers = new HttpHeaders({
    // "Content-Type": "application/json",
    "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  private API = environment.baseUrlCMS;

  private TECHNOLOGY = "/technology";

  constructor(private http: HttpClient) { }

  getTechAll(): Observable<Generic<Technologies[]>> {
    return this.http.get<Generic<Technologies[]>>(this.API + this.TECHNOLOGY, { headers: this.headers });
  }

  getTech(uuid: string): Observable<Generic<Technologies[]>> {
    return this.http.get<Generic<Technologies[]>>(this.API + this.TECHNOLOGY + '/' + uuid, { headers: this.headers });
  }

  createTech(formData: any): Observable<Generic<Technologies[]>> {
    return this.http.post<Generic<Technologies[]>>(this.API + this.TECHNOLOGY, formData, { headers: this.headers });
  }

  updateTech(uuid: string, formData: any): Observable<Generic<Technologies[]>> {
    return this.http.put<Generic<Technologies[]>>(this.API + this.TECHNOLOGY + "/" + uuid, formData, { headers: this.headers });
  }

  deleteTech(uuid: string): Observable<Generic<Technologies[]>> {
    return this.http.delete<Generic<Technologies[]>>(this.API + this.TECHNOLOGY + "/" + uuid, { headers: this.headers });
  }

}
