import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notify-popup',
  templateUrl: './notify-popup.component.html',
  styleUrls: ['./notify-popup.component.scss']
})
export class NotifyPopupComponent implements OnInit {

  @Input() messageTitle;


  constructor(
    public _activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  accept() {
    this._activeModal.close(true);
  }

  decline() {
    this._activeModal.close(false);
  }
}
