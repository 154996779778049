import { UserModel } from 'src/app/shared/models/user';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';

import { RegionService } from "src/app/core/services/region.service";
import { UsersService } from "src/app/core/services/users.service";
import { Region } from "src/app/shared/models/regions/region";
import { Countries } from "src/app/shared/models/regions/objectRegion/countries";
import {Responsables} from '../../../../../shared/models/regions/objectRegion/responsables';

@Component({
	selector: "app-region-detail",
	templateUrl: "./region-detail.component.html",
	styleUrls: ["./region-detail.component.scss"],
})
export class RegionDetailComponent implements OnInit {

	region: Region = new Region();
	users: UserModel[] = [];
	countries: Countries[] = [];
	id: string;
	lang: string = 'EN';
	responsableAdapter = Responsables.multiselectAdapter();

	constructor(
		private _route: ActivatedRoute,
		private _location: Location,
		private regionService: RegionService,
		private usersService: UsersService
	) { }

	ngOnInit() {

		let id = this._route.snapshot.params.id;

		this.regionService.getRegion(id).subscribe((data: any) => {
			this.region = data.data;
			//console.log(this.region);
		});

		this.regionService.getAllCountry().subscribe(data => {
			this.countries = data.data;
		});

		this.usersService.getAllUsers().subscribe(data => {
			this.users = data.data;
		});
	}

	backClicked() {
		this._location.back();
	}
}
