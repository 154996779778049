import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor() { }

  downloadBlob(blob: Blob, fileName: string): void {
    saveAs(blob, fileName);
  }

  download(url: string, fileName: string): void {
    saveAs(url, fileName);
  }
}
