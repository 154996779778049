// Import Angular
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// Import Menu
import { MenuComponent } from './menu.component';
import { MenuRoutingModule } from './menu-routing.module';

// Import component
import { SidebarComponent } from './sidebar/sidebar.component';
import { HomeModule } from 'src/app/modules/home/home.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    SidebarComponent,
    MenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FontAwesomeModule,
    HttpClientModule,
    MenuRoutingModule,
    HomeModule,
    TranslateModule
  ],
  exports: [
    SidebarComponent,
    MenuComponent
  ],
  providers: [SidebarComponent]
})
export class MenuModule { }
