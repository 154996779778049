import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { ProductCommercialTechnology } from "../../../../../shared/models/commercial/product-commercial-technology.model";
import { ProductsCommercialTechnologyService } from "../../../../../core/services/commercial/products-commercial-technology.service";

@Component({
  selector: 'app-technologies-commercial-list',
  templateUrl: './technologies-commercial-list.component.html',
  styleUrls: ['./technologies-commercial-list.component.scss']
})
export class TechnologiesCommercialListComponent implements OnInit {

  technologies: ProductCommercialTechnology[] = [];
  modal: NgbModalRef;
  modalDelete: NgbModalRef;
  searchText: string = '';
  uspLang: string = 'EN';

  constructor(private _router: Router,
    private _modal: NgbModal,
    private technologyService: ProductsCommercialTechnologyService,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.technologyService.getAllTechnologies().subscribe((data: any) => {
      this.technologies = data.data;
    });
  }

  pictNotLoading(event) {
    event.target.src = 'assets/img/B-pro_21apr90.svg';
    event.target.style = 'width: 2vw: max-width: 2vw';
  }

  createTechnology() {
    this._router.navigate(['/dashboard/home/technologies-commercial/add']);
  }

  deleteButton(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, {backdrop: "static", keyboard: false});
    this.modal.componentInstance.messageTitle = 
    // "You will delete a technology.";
    this._translate.instant('technologies-list.messageTitle');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('technologies-list.messageDescription');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, {backdrop: "static", keyboard: false});
        this.technologyService.delete(uuid)
          .subscribe(data => {
            this.modalClose();
            this.technologies.splice(index, 1);
            this.ngOnInit();
            //console.log(data)
          });
      } else {
        this.modal.close;
      }
    });
  }

  modalClose() {
    this.modalDelete.close();
  }
}
