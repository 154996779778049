export class Zoom {

    modelZoom: string[] = [
        'low',
        'med',
        'max'
    ];

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }
}