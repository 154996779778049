import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-valid-popup',
  templateUrl: './valid-popup.component.html',
  styleUrls: ['./valid-popup.component.scss']
})
export class ValidPopupComponent implements OnInit {

  @Input() messageTitle;
  @Input() messageDescription;

  constructor() { }

  ngOnInit(): void {
  }

}

