import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterOe'
})
export class OePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg === '' || arg.length < 2) return value;

    const resultBrands = [];
    for (const brand of value) {
      if (brand.brand.en.toLowerCase().indexOf(arg.toLowerCase()) > -1) {
        resultBrands.push(brand);
      };
    };
    return resultBrands;
  }
}
