import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { TechnologiesService } from 'src/app/core/services/technologies.service';
import { Technologies } from 'src/app/shared/models/wheels/objectWheel/technologies';

@Component({
  selector: 'app-technologies-detail',
  templateUrl: './technologies-detail.component.html',
  styleUrls: ['./technologies-detail.component.scss']
})
export class TechnologiesDetailComponent implements OnInit {

  technologies: Technologies = new Technologies();
  uspLang: string = 'EN';

  constructor(private _route: ActivatedRoute, private _location: Location, private techService: TechnologiesService) { }

  ngOnInit() {
    let uuid = this._route.snapshot.params.id;

    this.techService.getTech(uuid).subscribe((data: any) => {
      this.technologies = data.data;
      //console.log(this.technologies);
    })
  }

  pictNotLoading(event) {
    event.target.src = 'assets/img/B-pro_21apr90.svg';
    event.target.style = 'height: 10vw; max-height: 10vw';
  }

  backClicked() {
    this._location.back();
  }

}
