<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-5">
        <div class="d-flex mb-5">
            <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
        </div>
        <input class="form-control" name="name" type="text" [value]="detailCategories.name[uspLang.toLowerCase()]" readonly>
    </div>

    <div class="row">
        <div class="col-2 buttonBack d-md-flex align-items-center">
            <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
        </div>
        <div class="col-10 buttonSave d-md-flex justify-content-md-end align-items-center">
            <button type="button" class="btn btn-dark"
                [routerLink]="['/dashboard/home/categories/edit', detailCategories.uuid]">
                <i class="fa fa-pencil mr-2"></i>{{"categories-detail.edit" | translate}}</button>
        </div>
    </div>
</div>