import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { UspService } from 'src/app/core/services/usp.service';
import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';

@Component({
  selector: 'app-usp-detail',
  templateUrl: './usp-detail.component.html',
  styleUrls: ['./usp-detail.component.scss']
})
export class UspDetailComponent implements OnInit {

  detailUsp: Usp = new Usp();
  wheelId: string;
  uuid: string;
  uspLang: string = 'EN';

  constructor(
    private _route: ActivatedRoute,
    private _location: Location,
    private uspService: UspService
  ) { }

  ngOnInit() {
    this.uuid = this._route.snapshot.params.id;
    this.wheelId = this._route.snapshot.params.idWheel;

    this.uspService.getUsp(this.uuid)
      .subscribe((data: any) => {
        this.detailUsp = data.data;
      })
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/b_logo_black.png';
    event.target.style = 'width: 4vw; max-width: 4vw';
  }

  pictNotLoadingApp(event) {
    event.target.src = 'assets/img/b_logo_white.png';
    event.target.style = 'width: 4vw; max-width: 4vw';
  }

  backClicked() {
    this._location.back();
  }
}
