<div class="row justify-content-md-center mt-md-5">
    <div class="col-md-4">
        <form #loginForm="ngForm" (ngSubmit)="onLoggedin()">
            <fieldset class="form-group">
                <input class="form-control input-underline input-lg" placeholder="{{ 'login.user' | translate }}"
                    [(ngModel)]="login.username" name="username" #username required>
            </fieldset>
            <fieldset class="form-group">
                <input type="password" class="form-control input-underline input-lg"
                    placeholder="{{ 'login.pass'  | translate}}" [(ngModel)]="login.password" name="password" #password
                    required>
            </fieldset>
            <button type="submit" class="btn btn-danger btn-block"
                [disabled]="!loginForm.form.valid">{{ "login.button"  | translate }}</button>
        </form>
        <a class="d-flex justify-content-center align-items-center mt-4" [routerLink]="['/password/forgot']">
            {{"login.password" | translate}}</a>
    </div>
</div>