import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageKey, LocalStorageManager } from './local-storage-manager';
import { Observable } from 'rxjs';
import { Generic } from '../../shared/models/generic';
import { SalesToolsModel } from '../../shared/models/salestools/sales-tools';
import { SalesToolsFileModel } from 'src/app/shared/models/salestools/sales-tools-file';

@Injectable({
  providedIn: 'root'
})
export class SalesToolsService {

  private readonly API = environment.baseUrlCMS;
  private readonly SALESTOOLS = '/salestools';

  private readonly STOCK_LIST = this.API + this.SALESTOOLS + '/' + 'stock_list';

  headers = new HttpHeaders({
    Authorization: 'Bearer ' + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN),
  });

  constructor(private _http: HttpClient) {
  }

  getAllSalesTools(): Observable<Generic<SalesToolsModel[]>> {
    return this._http.get<Generic<SalesToolsModel[]>>(this.API + this.SALESTOOLS, { headers: this.headers });
  }

  uploadFiles(formData: FormData): Observable<Generic<string>> {
    return this._http.post<Generic<string>>(this.API + this.SALESTOOLS, formData, { headers: this.headers });
  }

  getSalesTools(uuid: string): Observable<Generic<SalesToolsModel>> {
    return this._http.get<Generic<SalesToolsModel>>(this.API + this.SALESTOOLS + '/' + uuid, { headers: this.headers });
  }

  uploadStockList(file: File): Observable<Generic<void>> {
    const formData = new FormData();
    formData.append('stockList', file);

    return this._http.post<Generic<void>>(this.STOCK_LIST, formData, { headers: this.headers });
  }

  getStockListInfo(): Observable<Generic<SalesToolsFileModel | null>> {
    return this._http.get<Generic<SalesToolsFileModel | null>>(this.STOCK_LIST, { headers: this.headers });
  }
}
