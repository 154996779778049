<div class="container-fluid">
	<div class="mb-5">
		<app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
	</div>
	<form (ngSubmit)="edit(k)" #k="ngForm" method="POST" enctype="multipart/form-data">

		<div class="viewImageLogo d-md-flex mb-5">

			<div class="col-md-12 d-md-flex">
				<div class="col-4 viewImageProduct d-md-flex">
					<div class="labelImageProduct">
						<label>{{"wheel-edit.productImage" | translate}}:</label>
					</div>
					<div class="imageProduct ml-5">
						<img [src]="editWheel.image" (error)="pictNotLoadingProduct($event)">
					</div>
				</div>

				<div class="col-6 viewLogo">
					<div class="viewLogoCms mb-5">
						<div class="labelLogoCms mb-2">
							<label>{{"wheel-edit.cmsLogo" | translate}}:</label>
						</div>
						<div class="logoCms">
							<img [src]="editWheel.cmsLogo" (error)="pictNotLoadingCms($event)">
						</div>
					</div>

					<div class="viewLogoApp">
						<div class="labelLogoApp mb-2">
							<label>{{"wheel-edit.appLogo" | translate}}:</label>
						</div>
						<div class="logoApp">
							<img [src]="editWheel.appLogo" (error)="pictNotLoadingApp($event)">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-group d-md-flex flex-md-column mb-md-5">

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.familyName" | translate}}</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.familyName" name="familyName" type="text" class="form-control"
						required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.familyName" name="familyName" type="text" class="form-control"
						required>
				</div>

				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.patternName" | translate}}</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.patternName" name="patternName" type="text" class="form-control"
						required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.patternName" name="patternName" type="text" class="form-control"
						required>
				</div>

				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.patternSlogan" | translate}}</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.patternSlogan.en" name="patternSlogan" type="text" class="form-control"
						required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.patternSlogan.ar" name="arpatternSlogan" type="text" class="form-control"
						required>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.segment" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.segment.name.en" name="segment" class="form-control form-control-lg"
						required>
						<option *ngFor="let Segment of segment" [value]="Segment.name.en">{{Segment.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.segment.name.ar" name="segment" class="form-control form-control-lg"
						required>
						<option *ngFor="let Segment of segment" [value]="Segment.name.ar">{{Segment.name.ar}}</option>
					</select>
				</div>
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.operation" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.operation.name.en" name="operation" class="form-control form-control-lg"
						required>
						<option *ngFor="let Operation of operation" [value]="Operation.name.en">{{Operation.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.operation.name.ar" name="operation" class="form-control form-control-lg"
						required>
						<option *ngFor="let Operation of operation" [value]="Operation.name.ar">{{Operation.name.ar}}</option>
					</select>
				</div>
				<div class="flex-row col-md-4">
					<label>{{"wheel-edit.oeRep" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.oeRep.name.en" name="oeRep" class="form-control form-control-lg"
						required>
						<option *ngFor="let OERep of oeRep" [value]="OERep.name.en">{{OERep.name.en}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.oeRep.name.ar" name="oeRep" class="form-control form-control-lg"
						required>
						<option *ngFor="let OERep of oeRep" [value]="OERep.name.ar">{{OERep.name.ar}}</option>
					</select>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6">
					<label>{{"wheel-edit.rft" | translate}}</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.rft" name="rft" class="form-control form-control-lg"
						#formSelect="ngModel" required>
						<option *ngFor="let Rft of productRft.rft" [value]=Rft>{{Rft}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.rft" name="rft" class="form-control form-control-lg"
						 required>
						<option *ngFor="let Rft of productRft.rft" [value]=Rft>{{Rft}}</option>
					</select>
				</div>
				<div class="flex-row col-md-6">
					<label>{{"wheel-edit.productOrder" | translate}}</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.productOrder" name="productOrder" type="text" class="form-control"
						id="number" type="number" min="1" pattern="^[0-9]+" required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.productOrder" name="productOrder" type="text" class="form-control"
						id="number" type="number" min="1" pattern="^[0-9]+" required>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="selectUSP col-md-6 mb-5">
					<div class="titleUSP d-flex justify-content-md-between align-items-md-center">
						<label>{{"wheel-edit.usp" | translate}}</label>
						<small class="uspSelect d-flex justify-content-end">{{"wheel-edit.youCanOnlySelectUpTo" | translate}}</small>
					</div>
					<select multiple *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.usp" name="usp" class="form-control form-control-lg"
						#formSelect="ngModel">
						<option id="{{Usp.key}}" *ngFor="let Usp of usp" [value]="Usp"
							(mousedown)="onMouseDownUsp($event, Usp.key, Usp.uuid, Usp.name, Usp.arname, Usp.appImgUrl, Usp.cmsImgUrl)"
							(mousemove)="$event.preventDefault()">
							{{Usp.name.en}}
						</option>
					</select>
					<select multiple *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.usp" name="usp" class="form-control form-control-lg"
						#formSelect="ngModel">
						<option id="{{Usp.key}}" *ngFor="let Usp of usp" [value]="Usp"
							(mousedown)="onMouseDownUsp($event, Usp.key, Usp.uuid, Usp.name, Usp.arname, Usp.appImgUrl, Usp.cmsImgUrl)"
							(mousemove)="$event.preventDefault()">
							{{Usp.name.ar}}
						</option>
					</select>
					

					<div class="d-md-flex justify-content-md-center">
						<button class="m-4 p-2 btn btn-outline-dark add" type="button" (click)="addUsp()">
							<i class="fa fa-plus mr-1" aria-hidden="true"></i>{{"wheel-edit.addUsp" | translate}}
						</button>
					</div>

					<div class="form-control form-control-lg SelectFormControl d-md-flex justify-content-md-between align-items-md-center"
						*ngFor="let Usp of editWheel.usp, let i=index">
						<div *ngIf="uspLang=='EN'">
							<i (click)="removeModel(i, Usp.key)" class="fa fa-times"></i> {{Usp.name.en}}
						</div>
						<div *ngIf="uspLang=='AR'">
							<i (click)="removeModel(i, Usp.key)" class="fa fa-times"></i> {{Usp.name.ar}}
						</div>
						<div class="buttonUsp">
							<button class="btn btn-secondary mr-2" type="button" (click)="viewUsp(Usp.uuid)">
								<i class="fa fa-eye" aria-hidden="true"></i>
							</button>

							<button class="btn btn-dark mr-2" type="button" (click)="editUsp(Usp.uuid)">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</button>

							<button class="btn btn-danger" type="button" (click)="deleteButtonUSP(Usp.uuid, i)">
								<i class="fa fa-trash" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>

				<div class="selectTechnologies col-md-6 mb-5">
					<label>{{"wheel-edit.technologies" | translate}}</label>
					<select multiple *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.technologies" name="technologies"
						class="form-control form-control-lg" #formSelect="ngModel">
						<option id="{{Technologies.title}}" *ngFor="let Technologies of technologies"
							[value]="Technologies"
							(mousedown)="onMouseDownTechnologies($event, Technologies.uuid, Technologies.title, Technologies.description, Technologies.image)"
							(mousemove)="$event.preventDefault()">
							{{Technologies.title.en}}
						</option>
					</select>
					<select multiple *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.technologies" name="technologies"
						class="form-control form-control-lg" #formSelect="ngModel">
						<option id="{{Technologies.title}}" *ngFor="let Technologies of technologies"
							[value]="Technologies"
							(mousedown)="onMouseDownTechnologies($event, Technologies.uuid, Technologies.title, Technologies.description, Technologies.image)"
							(mousemove)="$event.preventDefault()">
							{{Technologies.title.ar}}
						</option>
					</select>

					<div class="d-md-flex justify-content-md-center">
						<button class="m-4 p-2 btn btn-outline-dark add" (click)="addTechnology()" type="button">
							<i class="fa fa-plus mr-1" aria-hidden="true"></i>{{"wheel-edit.addTechnologies" | translate}}
						</button>
					</div>

					<div class="form-control form-control-lg SelectFormControl d-md-flex justify-content-md-between align-items-md-center"
						*ngFor="let Technologies of editWheel.technologies, let i=index">
						<div *ngIf="uspLang=='EN'">
							<i (click)="removeModelTechnologies(i, Technologies.title)" class="fa fa-times"></i>
							{{Technologies.title.en}}
						</div>
						<div *ngIf="uspLang=='AR'">
							<i (click)="removeModelTechnologies(i, Technologies.title)" class="fa fa-times"></i>
							{{Technologies.title.ar}}
						</div>

						<div class="buttonTechnology">
							<button class="btn btn-secondary mr-2" type="button" (click)="viewTechnology(Technologies.uuid)">
								<i class="fa fa-eye" aria-hidden="true"></i>
							</button>

							<button class="btn btn-dark mr-2" type="button" (click)="editTechnology(Technologies.uuid)">
								<i class="fa fa-pencil" aria-hidden="true"></i>
							</button>

							<button class="btn btn-danger" type="button" (click)="deleteButton(Technologies.uuid, i)">
								<i class="fa fa-trash" aria-hidden="true"></i>
							</button>
						</div>
					</div>
				</div>
			</div>

			<label style="text-align: center;">{{"wheel-detail.model3D" | translate}}</label>
			<div class="image3d d-flex justify-content-center mb-5">
				<iframe id="iframe" width="700" height="350" [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
			</div>

			<div class="col-12 d-md-flex justify-content-between pl-0 pr-0">
				<div class="flex-row col-md-2">
					<label>Light2</label>
					<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelLight2" name="modelLight2" type="text" class="form-control form-control-lg"
						(change)="light2($event)">
						<option *ngFor="let modelLight2 of lightWheel3D.modelLight2" [value]="modelLight2" required>{{modelLight2}}</option>
					</select>
					<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelLight2" name="modelLight2" type="text" class="form-control form-control-lg"
						(change)="light2($event)">
						<option *ngFor="let modelLight2 of lightWheel3D.modelLight2" [value]="modelLight2" required>{{modelLight2}}</option>
					</select>
				</div>

				<div class="flex-row col-md-3 pl-0 pr-0" style="border: 2px solid white">
					<div class="flex-row col-md-5">
						<label>Zoom</label>
						<select *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelZoom" name="modelZoom" type="text" class="form-control form-control-lg"
							(change)="zoom($event)">
							<option *ngFor="let modelZoom of zoom3D.modelZoom" [value]="modelZoom" required>{{modelZoom}}</option>
						</select>
						<select *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelZoom" name="modelZoom" type="text" class="form-control form-control-lg"
							(change)="zoom($event)">
							<option *ngFor="let modelZoom of zoom3D.modelZoom" [value]="modelZoom" required>{{modelZoom}}</option>
						</select>
					</div>
					<div class="flex-row col-md-7">
						<label class="mb-0">
							<h6>{{"wheel-edit.modelZoom" | translate}}</h6>
						</label>
						<input class="form-control form-control-lg input-underline input-lg" *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelZoom"
							type="number" (keypress)="maxZoom($event)" name="modelZoom" max="15" (change)="zoom($event)">
						<input class="form-control form-control-lg input-underline input-lg" *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelZoom"
							type="number" (keypress)="maxZoom($event)" name="modelZoom" max="15" (change)="zoom($event)">
						<div class="danger">
							<small class="text-danger"
								*ngIf="editWheel.modelZoom > '15.0'">{{"wheel-edit.errorValue" | translate}}</small>
						</div>
					</div>
				</div>

				<div class="flex-row col-md-3">
					<div class="d-md-flex justify-content-md-between">
						<label>Intensity</label>
						<h6 class="intensityValue">{{"wheel-edit.modelIntentisy" | translate}}</h6>
					</div>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelIntentisy" name="modelIntentisy" type="number" class="form-control" min="0.00001"
						max="2" (change)="intensity($event)" (keypress)="maxNumber($event)" required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelIntentisy" name="modelIntentisy" type="number" class="form-control" min="0.00001"
						max="2" (change)="intensity($event)" (keypress)="maxNumber($event)" required>
					<div class="danger">
						<small class="text-danger"
							*ngIf="editWheel.modelIntentisy > '2.0'">{{"wheel-edit.errorValue" | translate}}</small>
					</div>
				</div>

				<div class="flex-row col-md-2">
					<div class="d-md-flex justify-content-md-between">
						<label>Rotation</label>
						<h6 class="intensityValue">{{"wheel-edit.modelRotation" | translate}}</h6>
					</div>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelRotation" name="modelRotation" type="number" class="form-control" max="6.283"
						(change)="rotation($event)" (keypress)="maxRotation($event)" required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelRotation" name="modelRotation" type="number" class="form-control" max="6.283"
						(change)="rotation($event)" (keypress)="maxRotation($event)" required>
					<div class="danger">
						<small class="text-danger"
							*ngIf="editWheel.modelRotation > '6.283'">{{"wheel-edit.errorValue" | translate}}</small>
					</div>
				</div>

				<div class="flex-row col-md-2">
					<label>FlHeight</label>
					<input *ngIf="uspLang=='EN'" [(ngModel)]="editWheel.modelFlHeight" name="modelFlHeight" type="number" class="form-control"
						(change)="flHeight($event)" required>
					<input *ngIf="uspLang=='AR'" [(ngModel)]="editWheel.modelFlHeight" name="modelFlHeight" type="number" class="form-control"
						(change)="flHeight($event)" required>
				</div>
			</div>

			<div class="updateImage mb-3">
				<div class="row selectImage d-md-flex justify-content-md-between align-items-md-center">

					<div class="col-4 selectImageProduct">
						<div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
							<label class="mr-3">{{"wheel-edit.productImageToUpload" | translate}}</label>
							<small>{{"wheel-edit.youCanOnlyUploadOneImage" | translate}}</small>
						</div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDroppedProduct($event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerProduct($event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<h3>{{"wheel-edit.dragAndDrop" | translate}}</h3>
							<h2 class="productImage">{{"wheel-edit.productIMAGE" | translate}}</h2>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>

					<div class="col-4 selectLogoCMS">
						<div class="titleLogoPreview d-md-flex justify-content-md-between align-items-md-center">
							<label class="mr-3">{{"wheel-edit.cmsLogoToUpload" | translate}}</label>
							<small>{{"wheel-edit.youCanOnlyUploadOneLogo" | translate}}</small>
						</div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDroppedCms($event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerCms($event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<h3>{{"wheel-edit.dragAndDropLogo" | translate}}</h3>
							<h2 class="cmsLogo">{{"wheel-edit.cmsLOGO" | translate}}</h2>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>

					<div class="col-4 selectLogoAPP">
						<div class="titleLogoPreview d-md-flex justify-content-md-between align-items-md-center">
							<label class="mr-3">{{"wheel-edit.appLogoToUpload" | translate}}</label>
							<small>{{"wheel-edit.youCanOnlyUploadOneLogo" | translate}}</small>
						</div>

						<div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5"
							appDnd (fileDropped)="onFileDroppedApp($event)">
							<input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerApp($event)" />
							<i class="fa fa-download fa-3x" aria-hidden="true"></i>
							<h3>{{"wheel-edit.dragAndDropLogo" | translate}}</h3>
							<h2 class="appLogo">{{"wheel-edit.appLOGO" | translate}}</h2>
							<p class="constraints-info">(.jpg, .png, .svg)</p>
						</div>
					</div>
				</div>

				<div class="row imageLogoPreview d-md-flex justify-content-md-between align-items-md-center">

					<div class="col-4 LogoPreviewPRODUCT">
						<div class="imagePreview" *ngIf="productPreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.productImagePreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageProduct()" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="imagePreviewWheel d-flex justify-content-md-center">
								<img [src]="productPreviewUrl">
							</div>
						</div>
					</div>

					<div class="col-4 LogoPreviewCMS">
						<div class="logoPreview" *ngIf="cmsPreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.cmsLogoPreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageCMS()" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="cmsPreview d-flex justify-content-md-center">
								<img [src]="cmsPreviewUrl">
							</div>
						</div>
					</div>

					<div class="col-4 LogoPreviewAPP">
						<div class="image-preview" *ngIf="appPreviewUrl">
							<div class="d-md-flex justify-content-md-between align-items-md-center">
								<label>{{"wheel-edit.appLogoPreview" | translate}}</label>
								<button type="button" class="close" (click)="deleteImageApp()" aria-label="Close">
									<i class="fa fa-times-circle" aria-hidden="true"></i>
								</button>
							</div>
							<div class="appPreview d-flex justify-content-md-center">
								<img [src]="appPreviewUrl">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-2 buttonBack d-md-flex align-items-md-center">
				<i class="fa fa-angle-double-left" (click)="backClicked()"></i>
			</div>
			<div class="col-8 buttonSave d-md-flex justify-content-md-center align-items-md-center">
				<button type="submit" class="btn btn-dark justify-content-md-center align-items-md-center">
					<!-- [disabled]="!k.form.valid" -->
					<i class="fa fa-save mr-2"></i>{{"wheel-edit.save" | translate}}
				</button>
			</div>
			<div class="col-2 buttonDetail d-md-flex justify-content-md-end align-items-md-center">
				<button type="button" class="btn btn-dark justify-content-md-center align-items-md-center"
					[routerLink]="['/dashboard/home/wheels/detail', editWheel.uuid]">
					<i class="fa fa-eye mr-2"></i>{{"wheel-edit.detail" | translate}}
				</button>
			</div>
		</div>
	</form>
</div>