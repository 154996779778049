import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ShareService } from 'src/app/core/services/share.service';
import { ShareModel } from 'src/app/shared/models/share/share';
import { Location } from '@angular/common';
import constants from 'src/app/core/constants';

@Component({
  selector: 'app-common-share-detail',
  templateUrl: './share-detail.component.html',
  styleUrls: ['./share-detail.component.scss']
})
export class ShareCommonDetailComponent implements OnInit {

  editShare: ShareModel = new ShareModel();

  constructor(
    private _location: Location,
    private route: ActivatedRoute
  ) { }

  @Input()
  shareService: ShareService

  ngOnInit() {
    const id = this.route.snapshot.params.id;

    this.shareService.getComment(id)
      .subscribe((data: any) => {
        this.editShare = data.data;
      })
  }

  pictNotLoading = (event) => {
    event.target.src = constants.imageUrl.noimage;
  }

  backClicked = () => {
    this._location.back();
  }

}
