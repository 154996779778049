import { HttpClient } from '@angular/common/http';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Cookie } from 'ng2-cookies';

import { TranslateService } from '@ngx-translate/core';

import { CustomModalComponent } from 'src/app/shared/components/modals/custom-modal/custom-modal.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { NotifyPopupComponent } from 'src/app/shared/components/popups/notify-popup/notify-popup.component';
import { ProductCommercialGroup } from "../../../../../shared/models/commercial/product-commercial-group.model";
import { ProductCommercialTechnology } from "../../../../../shared/models/commercial/product-commercial-technology.model";
import { ProductCommercialApplication } from "../../../../../shared/models/commercial/product-commercial-application.model";
import { ProductCommercialSubcategory } from "../../../../../shared/models/commercial/product-commercial-subcategory.model";
import { ProductCommercialAxle } from "../../../../../shared/models/commercial/product-commercial-axle.model";
import { ProductsCommercialService } from "../../../../../core/services/commercial/products-commercial.service";
import { ProductsCommercialApplicationService } from "../../../../../core/services/commercial/products-commercial-application.service";
import { ProductsCommercialSubcategoryService } from "../../../../../core/services/commercial/products-commercial-subcategory.service";
import { ProductsCommercialAxleService } from "../../../../../core/services/commercial/products-commercial-axle.service";
import { ProductsCommercialTechnologyService } from "../../../../../core/services/commercial/products-commercial-technology.service";
import { Translated } from "../../../../../shared/models/commercial/translated.model";

@Component({
  selector: 'app-wheel-edit',
  templateUrl: './wheel-commercial-edit.component.html',
  styleUrls: ['./wheel-commercial-edit.component.scss']
})
export class WheelCommercialEditComponent implements OnInit {

  editWheel: ProductCommercialGroup = new ProductCommercialGroup();
  technologies: ProductCommercialTechnology[] = [];
  
  modal: NgbModalRef;
  modalLoad: NgbModalRef;
  modalDelete: NgbModalRef;
  modalNotify: NgbModalRef;
  
  applications: ProductCommercialApplication[] = [];
  subcategories: ProductCommercialSubcategory[] = [];
  axles: ProductCommercialAxle[] = [];
  uuid: string;
  
  emptyFile: File = new File([new Blob()], "empty.file");
  
  newTechnology: string;

  fileData: File = null;
  fileDataPreviewUrl: any = null;
  fileDataSafePreviewUrl: SafeUrl;
  fileTyre1Data: File = null;
  fileTyre1DataPreviewUrl: any = null;
  fileTyre1DataSafePreviewUrl: SafeUrl;
  fileTyre2Data: File = null;
  fileTyre2DataPreviewUrl: any = null;
  fileTyre2DataSafePreviewUrl: SafeUrl;
  fileTyre3Data: File = null;
  fileTyre3DataPreviewUrl: any = null;
  fileTyre3DataSafePreviewUrl: SafeUrl;
  filePatternData: File = null;
  filePatternDataPreviewUrl: any = null;
  filePatternDataSafePreviewUrl: SafeUrl;
  fileDataClearPersisted: boolean = false;
  fileTyre1DataClearPersisted: boolean = false;
  fileTyre2DataClearPersisted: boolean = false;
  fileTyre3DataClearPersisted: boolean = false;
  filePatternDataClearPersisted: boolean = false;

  uspLang: string = 'EN';
  safeUrl: SafeUrl;

  errorMessage: string;

  constructor(private _route: ActivatedRoute,
    private router: Router,
    private _modal: NgbModal,
    private productService: ProductsCommercialService,
    private applicationService: ProductsCommercialApplicationService,
    private subcategoryService: ProductsCommercialSubcategoryService,
    private axleService: ProductsCommercialAxleService,
    private technologyService: ProductsCommercialTechnologyService,
    private sanitizer: DomSanitizer,
    private _translate: TranslateService,
    private _httpClient: HttpClient) { }

  ngOnInit() {
    this.uuid = this._route.snapshot.params.id;

    if (localStorage.getItem("editWheel") && JSON.parse(localStorage.getItem("editWheel")).uuid == this.uuid) {
      console.log(this.uuid)
      this.editWheel = JSON.parse(localStorage.getItem("editWheel"));
    }

    this.loadProduct();
    this.loadApplications();
    this.loadSubcategories();
    this.loadAxles();
    this.loadTechnologies();
  }

  loadProduct() {
    this.productService.getProductById(this.uuid).subscribe((data: any) => {
        this.editWheel = data.data;

        if (this.editWheel.subcategory == null) {
            this.editWheel.subcategory = new ProductCommercialSubcategory();
        }
    });
  }

  loadApplications() {
    this.applicationService.getAllApplications().subscribe((data: any) => {
      this.applications = data.data;
    });
  }

  loadSubcategories() {
    this.subcategoryService.getAllSubcategories().subscribe((data: any) => {
      this.subcategories = data.data;
    });
  }

  loadAxles() {
    this.axleService.getAllAxles().subscribe((data: any) => {
      this.axles = data.data;
    });
  }

  loadTechnologies() {
    this.technologyService.getAllTechnologies()
      .subscribe(data => {
        this.technologies = data.data;

        setTimeout(() => {
          if (Cookie.check('technology_key'))
            this.newtech();
          this.editWheel.technologies.forEach(usp => {
            document.getElementById(usp.uuid)['selected'] = true;
          });
        }, 100);
      });
  }

  refreshSelectedUsp(){
    setTimeout(()=>{
      this.editWheel.technologies.forEach(usp => {
        document.getElementById(usp.uuid)['selected'] = true;
      });
    },100); 
  }


  //MULTI-SELECT USP
  onMouseDownTechnologies(event: MouseEvent, uuid: string, name: Translated) {
    event.preventDefault();

    if (!event.target['selected'] && (this.editWheel.technologies.length < 3)) {
      event.target['selected'] = !event.target['selected'];
      this.editWheel.technologies.push({ uuid: uuid, name: name, imageUrl: null });
    } else if (event.target['selected']) {
      event.target['selected'] = !event.target['selected']
      let index: number = -1;
      index = 0;
      let i = index;
      this.editWheel.technologies.forEach(technology => {
        if (technology.uuid == uuid) i = index;
        //console.log(i);
        index++;
      });
      this.editWheel.technologies.splice(i, 1);
      if (index > -1) { }
    }
  }
  
  removeModel(i: number, key: string) {
    document.getElementById(key)['selected'] = false;
    this.editWheel.technologies.splice(i, 1);
  }

    removeFeature(i: number, key: string) {
        this.editWheel.features.splice(i, 1);
    }

  newtech() {
    this.newTechnology = Cookie.get("technology_key");

    this.technologies.forEach(technology => {
      if (technology.uuid == this.newTechnology)
        this.editWheel.technologies.push({ uuid: technology.uuid, name: technology.name, imageUrl: technology.imageUrl });
    })
    Cookie.delete('technology_key');
  }

  onmousemove = function (event) {
    event.preventDefault();
  }


    //UPDATE IMAGE
    onFileDropped(type, $event) {
        this.prepareFilesList(type, $event);
    }

    fileBrowseHandler(type, file) {
        this.prepareFilesList(type, file);
    }

    deleteImageProduct(type) {
        switch (type) {
            case 'IMAGE':
                this.fileDataSafePreviewUrl = null;
                this.fileDataPreviewUrl = null;
                break;
            case 'TYRE1':
                this.fileTyre1DataSafePreviewUrl = null;
                this.fileTyre1DataPreviewUrl = null;
                break;
            case 'TYRE2':
                this.fileTyre2DataSafePreviewUrl = null;
                this.fileTyre2DataPreviewUrl = null;
                break;
            case 'TYRE3':
                this.fileTyre3DataSafePreviewUrl = null;
                this.fileTyre3DataPreviewUrl = null;
                break;
            case 'PATTERN':
                this.filePatternDataSafePreviewUrl = null;
                this.filePatternDataPreviewUrl = null;
                break;
        }
    }
    
    deletePersistedImage(type) {
        switch (type) {
            case 'IMAGE':
                this.fileDataClearPersisted = true;
                break;
            case 'TYRE1':
                this.fileTyre1DataClearPersisted = true;
                break;
            case 'TYRE2':
                this.fileTyre2DataClearPersisted = true;
                break;
            case 'TYRE3':
                this.fileTyre3DataClearPersisted = true;
                break;
            case 'PATTERN':
                this.filePatternDataClearPersisted = true;
                break;
        }
    }

    prepareFilesList(type, fileInput: any) {
        switch (type) {
            case 'IMAGE':
                this.fileData = <File>fileInput.target.files[0];
                break;
            case 'TYRE1':
                this.fileTyre1Data = <File>fileInput.target.files[0];
                break;
            case 'TYRE2':
                this.fileTyre2Data = <File>fileInput.target.files[0];
                break;
            case 'TYRE3':
                this.fileTyre3Data = <File>fileInput.target.files[0];
                break;
            case 'PATTERN':
                this.filePatternData = <File>fileInput.target.files[0];
                break;
        }
        this.preview(type);
    }

    preview(type) {

        let fd;

        switch (type) {
            case 'IMAGE':
                fd = this.fileData;
                break;
            case 'TYRE1':
                fd = this.fileTyre1Data;
                break;
            case 'TYRE2':
                fd = this.fileTyre2Data;
                break;
            case 'TYRE3':
                fd = this.fileTyre3Data;
                break;
            case 'PATTERN':
                fd = this.filePatternData;
                break;
        }

        let mimeType = fd.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        let reader = new FileReader();
        reader.readAsDataURL(fd);
        reader.onload = (_event) => {
            this.updateUrls(type, reader.result);
        }
    }

    updateUrls(type, preview) {
        switch (type) {
            case 'IMAGE':
                this.fileDataPreviewUrl = preview;
                this.fileDataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE1':
                this.fileTyre1DataPreviewUrl = preview;
                this.fileTyre1DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE2':
                this.fileTyre2DataPreviewUrl = preview;
                this.fileTyre2DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'TYRE3':
                this.fileTyre3DataPreviewUrl = preview;
                this.fileTyre3DataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
            case 'PATTERN':
                this.filePatternDataPreviewUrl = preview;
                this.filePatternDataSafePreviewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(preview);
                break;
        }
    }

  pictNotLoadingProduct(event) {
    event.target.src = 'assets/img/B-pro_21apr90_v2.svg';
    event.target.style = 'width: 10vw; height: 10vw';
  }

  pictNotLoadingCms(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }

  pictNotLoadingApp(event) {
    event.target.src = 'assets/img/B-pro_21apr.svg';
  }


  edit(form: NgForm) {

    let technologies: string = '';

    this.editWheel.technologies.forEach(tech => {
      if (technologies == '')
           technologies = technologies + tech.uuid;
      else
        technologies = technologies + ',' + tech.uuid;
    });

    const features: string = this.editWheel.features.map(feature => feature.uuid).join();


    this._translate.instant('wheel-edit.messageTitleNotify');

        const formData = new FormData();
        formData.append('patternName', this.editWheel.patternName);
        formData.append('axle.uuid', this.editWheel.axle.uuid);
        formData.append('application.uuid', this.editWheel.application.uuid);
        if (!!this.editWheel.subcategory.uuid) {
            formData.append('subcategory.uuid', this.editWheel.subcategory.uuid);
        }
        formData.append('uspAr', this.editWheel.uspAr);
        formData.append('uspEn', this.editWheel.uspEn);
        formData.append('new', this.editWheel.new.toString());
        if (this.editWheel.performance['wear'] != null) {
            formData.append('performance.wear', this.editWheel.performance['wear'].toString());
        }
        if (this.editWheel.performance['irregular'] != null) {
            formData.append('performance.irregular', this.editWheel.performance['irregular'].toString());
        }
        if (this.editWheel.performance['heat'] != null) {
            formData.append('performance.heat', this.editWheel.performance['heat'].toString());
        }
        if (this.editWheel.performance['fuel'] != null) {
            formData.append('performance.fuel', this.editWheel.performance['fuel'].toString());
        }
        if (this.editWheel.performance['traction'] != null) {
            formData.append('performance.traction', this.editWheel.performance['traction'].toString());
        }
        if (this.editWheel.performance['onOff'] != null) {
            formData.append('performance.onOff', this.editWheel.performance['onOff'].toString());
        }
        if (this.editWheel.performance['noise'] != null) {
            formData.append('performance.noise', this.editWheel.performance['noise'].toString());
        }
        if (this.editWheel.performance['wet'] != null) {
            formData.append('performance.wet', this.editWheel.performance['wet'].toString());
        }
        formData.append('iconUUIDs', technologies);
        formData.append('featuresUUIDs', features);
        formData.append('quality', this.editWheel.quality);

        if (!!this.fileData) {
            formData.append('image', this.fileData);
        } else if (this.fileDataClearPersisted) {
            formData.append('image', this.emptyFile);
        }

        if (!!this.fileTyre1Data) {
            formData.append('tyreImage1', this.fileTyre1Data);
        } else if (this.fileTyre1DataClearPersisted) {
            formData.append('tyreImage1', this.emptyFile);            
        }

        if (!!this.fileTyre2Data) {
            formData.append('tyreImage2', this.fileTyre2Data);
        } else if (this.fileTyre2DataClearPersisted) {
            formData.append('tyreImage2', this.emptyFile);            
        }

        if (!!this.fileTyre3Data) {
            formData.append('tyreImage3', this.fileTyre3Data);
        } else if (this.fileTyre3DataClearPersisted) {
            formData.append('tyreImage3', this.emptyFile);            
        }

        if (!!this.filePatternData) {
            formData.append('patternImage', this.filePatternData);
        } else if (this.filePatternDataClearPersisted) {
            formData.append('patternImage', this.emptyFile);            
        }


      this.modalLoad = this._modal.open(LoadingPopupComponent, { backdrop: "static", keyboard: false });
      this.productService.updateProduct(this.uuid, formData)
        .subscribe(res => {
          //console.log(res);
          this.modalClose();
          this.router.navigateByUrl('home/wheels-commercial');
          try {
            localStorage.removeItem("editWheel");
          } catch { }
        });
  }

  //Delete Usp
  deleteButtonTechnology(uuid: string, index: number) {
    this.modal = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modal.componentInstance.messageTitle = 
    // "You will delete an USP.";
    this._translate.instant('wheel-edit.messageTitleUSP');
    this.modal.componentInstance.messageDescription = 
    // "Do you want to delete it really?";
    this._translate.instant('wheel-edit.messageDescriptionUSP');

    this.modal.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.technologyService.delete(uuid)
          .subscribe(data => {
            this.modalCloseDelete();
            this.editWheel.technologies.splice(index, 1);
            this.loadTechnologies();
            //this.ngOnInit();
            // console.log(data)
          });
        response => { this.loadTechnologies() };
      } else {
        this.modal.close;
      }
    });
  }

  addTechnology() {
    if (this.editWheel.technologies.length < 3) {
      localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
      this.router.navigateByUrl('/dashboard/home/technologies-commercial/add');
    } else {
      this.modal = this._modal.open(ErrorPopupComponent);
      this.modal.componentInstance.messageTitle = 
      // "There is already 3 USP";
      this._translate.instant('wheel-edit.messageTitle3USP');
      this.modal.componentInstance.messageDescription = 
      // "You need to remove one in order to create a new one";
      this._translate.instant('wheel-edit.messageDescription3USP');
    }
  }

  viewTechnology(tecId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/technologies-commercial/detail', tecId]);
  }

  editTechnology(tecId) {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/technologies-commercial/edit', tecId]);
  }

  addFeature() {
    localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
    this.router.navigate(['/dashboard/home/feature-commercial/add'],
        {queryParams: {productCommercialGroupUUID: this.editWheel.uuid}});
    }

    viewFeature(tecId) {
        localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
        this.router.navigate(['/dashboard/home/feature-commercial/detail', tecId]);
    }

    editFeature(tecId) {
        localStorage.setItem("editWheel", JSON.stringify(this.editWheel));
        this.router.navigate(['/dashboard/home/feature-commercial/edit', tecId],);
    }

  modalClose() {
    this.modalLoad.close();
  }

  modalCloseDelete() {
    this.modalDelete.close();
  }

  backClicked() {
    this.router.navigate(['/dashboard/home/wheels/list']);
  }
}
