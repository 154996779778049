import { Component } from '@angular/core';

@Component({
  selector: 'app-assessment-commercial',
  templateUrl: './assessment-commercial.component.html',
  styleUrls: ['./assessment-commercial.component.scss']
})
export class AssessmentCommercialComponent {

  constructor() { }

}
