import { Component, Output, EventEmitter, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
    faUsers, faTruckMonster, faExclamationTriangle, faPowerOff, faAngleDoubleLeft, faShareAlt, faCar,
    faClipboardList, faGlobe, faMicrochip, faShareAltSquare, faBook, faFileUpload
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/authentication/auth.service';
import { UserRole } from 'src/app/shared/models/userRole';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {

    readonly userRole = new UserRole();

    isActive = false;
    collapsed = false;
    showMenu = '';
    pushRightClass = 'push-right';

    role: string;

    // Icons
    faUsers = faUsers;
    faTruckMonster = faTruckMonster;
    faExclamationTriangle = faExclamationTriangle;
    faPowerOff = faPowerOff;
    faAngleDoubleLeft = faAngleDoubleLeft;
    faShareAlt = faShareAlt;
    faCar = faCar;
    faClipboardList = faClipboardList;
    faGlobe = faGlobe;
    faMicrochip = faMicrochip;
    faShareAltSquare = faShareAltSquare;
    faBook = faBook;
    faFileUpload = faFileUpload;

    @Output() collapsedEvent = new EventEmitter<boolean>();

    constructor(public router: Router, private authService: AuthService, private changeDetectorRef: ChangeDetectorRef) {
        this.router.events.subscribe(val => {
            if (
                val instanceof NavigationEnd &&
                window.innerWidth <= 992 &&
                this.isToggled()
            ) {
                this.toggleSidebar();
            }
        });

        this.authService.roleSubject.asObservable().subscribe(
            role => {
                this.role = role;
                this.changeDetectorRef.detectChanges();
            }
        )

        this.role = this.authService.getRole();
    }

    ngOnInit() {
    }

    eventCalled() {
        this.isActive = !this.isActive;
    }

    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element;
        }
    }

    toggleCollapsed() {
        this.collapsed = !this.collapsed;
        this.collapsedEvent.emit(this.collapsed);
        console.log(this.role);
    }

    isToggled(): boolean {
        const dom: Element = document.querySelector('body');
        return dom.classList.contains(this.pushRightClass);
    }

    toggleSidebar() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(this.pushRightClass);
    }

    rltAndLtr() {
        const dom: any = document.querySelector('body');
        dom.classList.toggle('rtl');
    }

    onLoggedout() {
        this.authService.logout();
    }

    isSuperAdmin() {
        return this.userRole.superAdminRoles.includes(this.role);
    }

    isStockUser() {
        return this.role === UserRole.STOCK_USER;
    }

    shouldShowNonStockMenu() {
        return !this.isStockUser();
    }

    shouldShowStockListUpdate() {
        return !this.isSuperAdmin();
    }
}
