import { MultiselectAdapter } from 'src/app/modules/home/components/forms/multiselect/multiselect-adapter';

export class Countries {

    uuid: string = "";
    key: string = "";
    name: string = "";

    constructor(values: Object = {}) {
        Object.assign(values, this);
    }

    static multiselectAdapter(): MultiselectAdapter<Countries> {
        return {
            keySelect: c => c.uuid,
            render: c => c.name
        }
    }
}