import { Injectable, Injector } from "@angular/core";
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent, HttpResponse, HttpHeaders
} from "@angular/common/http";
import {Observable} from 'rxjs';

const urls = [
];

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        for (const element of urls) {
            if (request.url.endsWith(element.url)) {
                console.log('Loaded from json : ' + request.url);
                return new Observable<HttpEvent<any>>(
                    observer => observer.next(new HttpResponse({ status: 200, body: element.json, headers: new HttpHeaders(element.headers)})));
            }
        }
        console.log('Loaded from http call :' + request.url);
        return next.handle(request);
    }
}
