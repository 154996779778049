import { Component } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Bridgstone CMS Backoffice';
  textDir: string = 'ltr';

  constructor(private _translate: TranslateService) {

    let browserLang = this._translate.getBrowserLang();
    // PARA USAR POR DEFECTO EL DEL NAVEGADOR DEL USUARIO: this._translate.use(browserLang);
    this._translate.use('en');

    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang == 'ar') {
        this.textDir = 'rtl';
      }
      else {
        this.textDir = 'ltr';
      }
    });
  }
}
