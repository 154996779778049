import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./modules/home/pages/login/login.component";
import { AuthGuard } from "./core/guards/authGuard";
import { AppPasswordComponent } from './modules/home/pages/app-password/app-password.component';
import { AppForgotPasswordComponent } from './modules/home/pages/app-password/app-forgot-password/app-forgot-password.component';
import { AppUpdatePasswordComponent } from './modules/home/pages/app-password/app-update-password/app-update-password.component';

const routes: Routes = [
	{
		path: '', component: LoginComponent
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./shared/components/menu/menu.module').then(m => m.MenuModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'password', component: AppPasswordComponent,
		children: [
			{ path: '', redirectTo: 'forgot', pathMatch: 'full' },
			{ path: 'forgot', component: AppForgotPasswordComponent },
			{ path: 'update/:id', component: AppUpdatePasswordComponent }
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
