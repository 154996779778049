<div class="container-fluid">
    <div class="row justify-content-between">
        <div class="col-md-10">
            <div class="d-flex justify-content-start">
                <div class="mr-5">
                    <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
                </div>

                <div class="form-group search d-md-flex justify-content-md-center align-items-md-baseline">
                    <input class="form-control" [(ngModel)]="searchText" name="searchText" type="text"
                           placeholder='{{"wheel-list.searchProduct" | translate}}'>
                    <i class="fa fa-search mr-5" aria-hidden="true"></i>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="d-md-flex justify-content-md-end">
                <button class="btn btn-outline-dark mr-2 p-2" id="buttonAddProduct" (click)="createWheel()">
                    <i class="fa fa-plus-circle mr-2"
                        aria-hidden="true"></i><span>{{"wheel-list.addProduct" | translate}}</span>
                </button>        
                <button class="btn btn-outline-dark p-2" id="buttonAddExcel" (click)="readWheel()">
                    <i class="fa fa-file-excel-o mr-2" aria-hidden="true"></i><span>{{"wheel-list.addExcel" | translate}}</span>
                </button>
            </div>
        </div>
        </div>


<div class="row card mb-3" *ngFor="let wheel of wheels | filterWheel: searchText, let i = index">

    <div class="col-12 d-inline-flex bd-highlight">
        <div class="col operationWheel d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='EN'">
            <span class="operationKey inline-block"><b>{{wheel.operation.name.en}}</b></span>
        </div>
        <div class="col operationWheel d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='AR'">
            <span class="operationKey inline-block"><b>{{wheel.operation.name.ar}}</b></span>
        </div>

        <div class="col-2 imageWheel d-md-flex justify-content-md-center">
            <img [src]="wheel.image" (error)="pictNotLoading($event)">
        </div>

        <div class="col infoWheel">
            <div class="logoSloganWhell d-md-flex flex-column">
                <div class="yesLogo mb-3" *ngIf="wheel.cmsLogo!= ''" [id]="'silogo'+i">
                    <img [src]="wheel.cmsLogo" (error)="pictNotLoadingLogo($event,i)">
                </div>
                <div class="noLogo d-none" [id]="'nologo'+i">
                    <h3>{{wheel.familyName}} - {{wheel.patternName}}</h3>
                </div>
                <span *ngIf="uspLang=='EN'">{{wheel.patternSlogan.en}}</span>
                <span *ngIf="uspLang=='AR'">{{wheel.patternSlogan.ar}}</span>
            </div>

            <div class="uspWheel d-md-flex justify-content-md-between align-items-md-center" *ngIf="uspLang=='EN'">
                <span class="d-md-flex justify-content-center align-items-md-center" *ngFor="let Usp of wheel.usp">
                    <img [src]="Usp.cmsImgUrl" (error)="pictNotLoadingCms($event)">{{Usp.name.en}}
                </span>
            </div>
            <div class="uspWheel d-md-flex justify-content-md-between align-items-md-center" *ngIf="uspLang=='AR'">
                <span class="d-md-flex justify-content-center align-items-md-center" *ngFor="let Usp of wheel.usp">
                    <img [src]="Usp.cmsImgUrl" (error)="pictNotLoadingCms($event)">{{Usp.name.ar}}
                </span>
            </div>
        </div>

        <div class="col-2 oerepWheel d-md-flex flex-column">
            <div class="oerep d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='EN'">
                <span><b>{{wheel.oeRep.name.en}}</b></span>
            </div>
            <div class="oerep d-md-flex justify-content-md-center align-items-md-center" *ngIf="uspLang=='AR'">
                <span><b>{{wheel.oeRep.name.ar}}</b></span>
            </div>
            <div class="buttonAction d-md-flex justify-content-md-center align-items-md-end">
                <button class="btn btn-secondary mr-1" type="button"
                    [routerLink]="['/dashboard/home/wheels/detail', wheel.uuid]">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
                <button class="btn btn-dark mr-1" type="button"
                    [routerLink]="['/dashboard/home/wheels/edit', wheel.uuid]">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button class="btn btn-danger" type="button" (click)="deleteButton(wheel.uuid, i)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
</div>