import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRegion'
})
export class RegionPipe implements PipeTransform {

  transform(value: any, args: any): any {

    if (args === '' || args.length < 2) return value;

    const resultListRegion = [];
    for (const regions of value) {
      if (regions.name.en.toLowerCase().indexOf(args.toLowerCase()) > -1) {
        resultListRegion.push(regions);
      };
    };
    return resultListRegion;
  }
}
