import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SalesToolsModel} from '../../../../../shared/models/salestools/sales-tools';
import {FileDownloadService} from '../../../services/file-download.service';
import {SalesToolsService} from '../../../../../core/services/sales-tools.service';

@Component({
  selector: 'app-stock-list-item',
  templateUrl: './stock-list-item.component.html',
  styleUrls: ['./stock-list-item.component.scss']
})
export class StockListItemComponent {

  @Input()
  stockListItem: SalesToolsModel

  @Output() deleteEvent = new EventEmitter<string>();

  constructor(private fileDownload: FileDownloadService,
              private salesToolsService: SalesToolsService) {
  }

  deleteFile() {
    const formData = new FormData();
    formData.append('partnerId', this.stockListItem.partner.uuid);
    formData.append('stockList', new File([new Blob()], 'empty.file'))
    this.salesToolsService.uploadFiles(formData)
        .subscribe(_ => this.deleteEvent.emit())
  }

  downloadFile() {
    this.fileDownload.download(this.stockListItem.stockList.fileUrl, `Stock list ${this.stockListItem.partner.name}.xlsx`)
  }
}
