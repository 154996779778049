<div class="container-fluid">
    <form #k="ngForm" (ngSubmit)="update(k)">
        <div class="row d-md-flex flex-column justify-content-md-center align-items-md-center mt-md-5">
            <div class="col-md-4">
                <fieldset class="form-group">
                    <input type="password" class="form-control input-underline input-lg"
                        placeholder="{{ 'app-update.oldPassword' | translate }}" [(ngModel)]="editPassword.oldPass"
                        name="oldPass" required>
                </fieldset>
                <fieldset class="form-group">
                    <input type="password" class="form-control input-underline input-lg"
                        placeholder="{{ 'app-update.newPassword' | translate }}" [(ngModel)]="editPassword.newPass"
                        name="newPass" required>
                </fieldset>
                <fieldset class="form-group">
                    <input type="password" class="form-control input-underline input-lg"
                        placeholder="{{ 'app-update.reNewPassword' | translate }}" [(ngModel)]="editPassword.reNewPass"
                        name="reNewPass" required>
                </fieldset>
                <button type="submit" class="btn btn-danger btn-block"
                    [disabled]="!k.form.valid">{{ "app-update.button"  | translate }}</button>
            </div>
        </div>
        <div class="row">
            <div class="col-2 buttonBack d-md-flex align-content-md-start align-items-md-center">
                <i class="fa fa-angle-double-left" (click)="backClicked()"></i>
            </div>
        </div>
    </form>
</div>