<div class="d-flex flex-row-reverse">
    <button class="btn btn-lg btn-dark mr-1" type="button"
            [routerLink]="['/dashboard/home/salestools/catalogue/add']">
        {{ 'catalogue-list.addNew' | translate }}
    </button>
</div>

<div class="mt-3" *ngFor="let item of salesToolsItems">
    <app-catalogue-list-item [item]="item"></app-catalogue-list-item>
</div>
