<div class="container-fluid">
    <div class="form-group d-md-flex flex-md-column mb-md-4">
        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.assessor" | translate}}</label>
                <input [(ngModel)]="assessment.assessor" name="assessor" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.partner" | translate}}</label>
                <input [(ngModel)]="assessment.partner" name="partner" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.dealer" | translate}}</label>
                <input [(ngModel)]="assessment.dealer" name="dealer" type="text" class="form-control" readonly>
            </div>
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.trainee" | translate}}</label>
                <input [(ngModel)]="assessment.trainee" name="trainee" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.percentage" | translate}}</label>
                <input [(ngModel)]="assessment.percentage" name="percentage" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-9">
                <label>{{"assessment-detail.comment" | translate}}</label>
                <input [(ngModel)]="assessment.comment" name="comment" type="text" class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.status" | translate}}</label>
                <input [(ngModel)]="assessment.status" name="status" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.appearance" | translate}}</label>
                <input [(ngModel)]="assessment.appearance" name="appearance" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.definingVisit" | translate}}</label>
                <input [(ngModel)]="assessment.definingVisit" name="definingVisit" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.creditSituation" | translate}}</label>
                <input [(ngModel)]="assessment.creditSituation" name="creditSituation" type="text" class="form-control"
                    readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.openOrders" | translate}}</label>
                <input [(ngModel)]="assessment.openOrders" name="openOrders" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.stockCheck" | translate}}</label>
                <input [(ngModel)]="assessment.stockCheck" name="stockCheck" type="text" class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.brandVisibility" | translate}}</label>
                <input [(ngModel)]="assessment.brandVisibility" name="brandVisibility" type="text" class="form-control"
                    readonly>
            </div>
        
            <div class="flex-row col-md-3">
                <label>{{"assessment-detail.drivingMixOpportunities" | translate}}</label>
                <input [(ngModel)]="assessment.drivingMIXOpportunities" name="drivingMIXOpportunities" type="text"
                    class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-3">
        <label>{{"assessment-detail.presentingProduct" | translate}}</label>
        <input [(ngModel)]="assessment.presentingProduct" name="presentingProduct" type="text" class="form-control"
            readonly>
            </div>

            <div class="flex-row col-md-3">
        <label>{{"assessment-detail.collectMarketInfo" | translate}}</label>
        <input [(ngModel)]="assessment.collectMarketInfo" name="collectMarketInfo" type="text" class="form-control"
            readonly>
            </div>

            <div class="flex-row col-md-3">
        <label>{{"assessment-detail.outstandingAndCollectPayment" | translate}}</label>
        <input [(ngModel)]="assessment.outstandingAndCollectPayment" name="outstandingAndCollectPayment" type="text"
            class="form-control" readonly>
            </div>

            <div class="flex-row col-md-3">
        <label>{{"assessment-detail.leaveToDealerBsGiveaway" | translate}}</label>
        <input [(ngModel)]="assessment.leaveToDealerBSGiveaway" name="leaveToDealerBSGiveaway" type="text"
            class="form-control" readonly>
            </div>
        </div>

        <div class="d-md-flex justify-content-between">
            <div class="flex-row col-md-4">
                <label>{{"assessment-detail.updateDealerCardInfo" | translate}}</label>
                <input [(ngModel)]="assessment.updateDealerCardInfo" name="updateDealerCardInfo" type="text"
                    class="form-control" readonly>
            </div>
        
            <div class="flex-row col-md-4">
                <label>{{"assessment-detail.createdAt" | translate}}</label>
                <input name="createdAt" type="text" class="form-control" [value]="assessment.createdAt | date: 'short'"
                    readonly>
            </div>
        
            <div class="flex-row col-md-4">
                <label>{{"assessment-detail.updatedAt" | translate}}</label>
                <input name="updatedAt" type="text" class="form-control" [value]="assessment.updatedAt | date: 'short'"
                    readonly>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-2 buttonBack d-md-flex align-items-md-center">
            <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/assessment/list']"></i>
        </div>
    </div>
</div>