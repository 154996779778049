<div class="container-fluid">
  <form (ngSubmit)="createItem(f)" #f="ngForm">
    <div class="form-group 
    ">
      <div class="d-flex mb-5">
        <app-lang-edition-switch [(language)]="uspLang"></app-lang-edition-switch>
      </div>
      <input *ngIf="uspLang=='EN'" [(ngModel)]="brand.name" name="name" type="text" class="form-control" required>
      <input *ngIf="uspLang=='AR'" [(ngModel)]="brand.arname" name="arname" type="text" class="form-control" required>
      <!-- <div style="display: none;">
        <label>Key</label>
        <input [(ngModel)]="brand.key" name="key" type="text" class="form-control">
      </div> -->
      <label>{{"oe-add-brands.brand" | translate}}</label>
      <input *ngIf="uspLang=='EN'" [(ngModel)]="brand.brand" name="brand" type="text" class="form-control" required>
      <input *ngIf="uspLang=='AR'" [(ngModel)]="brand.arbrand" name="arbrand" type="text" class="form-control" required>
    </div>

    <div class="updateImage mb-3">
      <div class="row selectImage d-md-flex align-items-md-center">

        <div class="col selectImageProduct">
          <div class="titleImagePreview d-md-flex justify-content-md-between align-items-md-center">
            <label class="mr-3">{{"oe-add-brands.brandLogoToUpload" | translate}}</label>
            <small>{{"oe-add-brands.youCanOnlyUploadOneLogo" | translate}}</small>
          </div>

          <div class="dropzone d-md-flex flex-column justify-content-md-center align-items-md-center mb-5" appDnd
            (fileDropped)="onFileDroppedBrand($event)">
            <input type="file" accept=".jpg, .png, .svg" (change)="fileBrowseHandlerBrand($event)" />
            <i class="fa fa-download fa-3x" aria-hidden="true"></i>
            <h3>{{"oe-add-brands.dragAndDropOrSelectLogoFileHere" | translate}}</h3>
            <p class="constraints-info">(.jpg, .png, .svg)</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row imageLogoPreview d-md-flex justify-content-md-center align-items-md-center">

      <div class="col-4 LogoPreviewPRODUCT">
        <div class="imagePreview" *ngIf="brandPreviewUrl">
          <div class="d-md-flex justify-content-md-between align-items-md-center">
            <label>{{"oe-add-brands.brandLogoPreview" | translate}}</label>
            <button type="button" class="close" (click)="deleteImageBrand()" aria-label="Close">
              <i class="fa fa-times-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div class="imagePreview d-flex justify-content-md-center">
            <img [src]="brandPreviewUrl">
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-2 buttonBack">
        <i class="fa fa-angle-double-left" [routerLink]="['/dashboard/home/oe/list']"></i>
      </div>
      <div class="col-8 buttonSave d-md-flex justify-content-md-center">
        <button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
          <i class="fa fa-save mr-2"></i>{{"oe-add-brands.save" | translate}}</button>
      </div>
    </div>
  </form>

  <div class="alert alert-danger mt-md-5" role="alert" *ngIf="errors.length">
    <div *ngFor="let error of errors">
      <h5>{{error}}</h5>
    </div>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="errorsm">
    <h5>{{errorsm}}</h5>
  </div>
</div>