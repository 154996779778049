import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Generic } from 'src/app/shared/models/generic';
import { environment } from "src/environments/environment";
import { Usp } from 'src/app/shared/models/wheels/objectWheel/usp';
import { LocalStorageKey, LocalStorageManager } from "./local-storage-manager";

@Injectable({
  providedIn: 'root'
})
export class UspService {

  headers = new HttpHeaders({
    "Authorization": "Bearer " + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  private API = environment.baseUrlCMS;

  private USP = "/usp";

  constructor(private http: HttpClient) { }

  getUspAll(): Observable<Generic<Usp[]>> {
    return this.http.get<Generic<Usp[]>>(this.API + this.USP, { headers: this.headers });
  }

  getUsp(uuid: string): Observable<Generic<Usp[]>> {
    return this.http.get<Generic<Usp[]>>(this.API + this.USP + '/' + uuid, { headers: this.headers });
  }

  createUsp(newUsp: any): Observable<Generic<Usp[]>> {
    return this.http.post<Generic<Usp[]>>(this.API + this.USP, newUsp, { headers: this.headers });
  }

  updateUsp(uuid: string, uspUpdate: any): Observable<Generic<Usp[]>> {
    return this.http.put<Generic<Usp[]>>(this.API + this.USP + "/" + uuid, uspUpdate, { headers: this.headers });
  }

  deleteUsp(uuid: string): Observable<Generic<Usp[]>> {
    return this.http.delete<Generic<Usp[]>>(this.API + this.USP + "/" + uuid, { headers: this.headers });
  }

}
