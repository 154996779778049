<div class="multiselect-full-width">
    <label *ngIf="label">{{label}}</label>

    <select class="multiselect-full-width" *ngIf="editable === true" multiple #selectNode (change)="onSelectKey($event.target.value)">
        <option *ngFor="let o of availableOptions" [value]="getKey(o)" [ngClass]="optionClass(o)">
            {{renderOption(o)}}
        </option>
    </select>

    <div class="multiselect-elements-container" #elementsContainer>
        <div class="d-md-flex justify-content-between multiselect-entry" *ngFor="let o of getSelectedOptions()">
            <div class="flex-row col-md-10">
                <div class="multiselect-option-container">
                    {{renderOption(o)}}
                </div>
            </div>
            <div class="flex-row col-md-2" *ngIf="editable === true">
                <div class="multiselect-close-button-container">
                    <button type="button" class="multiselect-close-button" (click)="removeOption(o)">X</button>
                </div>
            </div>
        </div>
    </div>
</div>
