import {Component} from '@angular/core';
import {FileDownloadService} from '../../services/file-download.service';
import {SurveyService} from '../../../../core/services/survey.service';
import {ModalService} from '../../../../core/services/modal.service';

@Component({
  selector: 'app-survey-export',
  templateUrl: './survey-export.component.html',
  styleUrls: ['./survey-export.component.scss']
})
export class SurveyExportComponent {

  constructor(private fileDownloadService: FileDownloadService,
              private surveyService: SurveyService,
              private modalService: ModalService) {
  }


  downloadSurveys() {
    const loadingModal = this.modalService.showLoadingModal();
    const fileName = `Survey-${(new Date()).toDateString()}.xlsx`;
    this.surveyService.getSurveysBlob().subscribe(data => {
      loadingModal.close();
      this.fileDownloadService.downloadBlob(data, fileName);
    }, _ => {
      console.log(_)
      loadingModal.close();
      this.modalService.showErrorModal()
    });
  }

}
