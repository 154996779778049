import { Component, OnInit } from "@angular/core";
import { NgbModalRef, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { TranslateService } from '@ngx-translate/core';

import { PartnersService } from "src/app/core/services/partners.service";
import { PartnerModel } from "src/app/shared/models/partner";
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';
import { ModalService } from "../../../../../core/services/modal.service";

@Component({
    selector: "app-partnerlist",
    templateUrl: "./partner-list.component.html",
    styleUrls: ["./partner-list.component.scss"],
})
export class PartnerListComponent implements OnInit {

    partners: PartnerModel[] = [];
    modalDelete: NgbModalRef;
    searchText: string = '';

    constructor(
        private partnersService: PartnersService,
        private modalService: ModalService,
        private router: Router,
        private modal: NgbModal,
    ) {
    }

    ngOnInit() {
        this.partnersService.getAllPartners()
            .subscribe((data: any) => {
                this.partners = data.data;
            });
    }

    createPartner() {
        this.router.navigate(["/dashboard/home/partners/add"]);
    }

    deletePartner(id: string, index: number) {
        this.modalService.showDeleteAskModal(() => {
            this.modalDelete = this.modal.open(DeletePopupComponent, {backdrop: "static", keyboard: false});
            this.partnersService.deletePartner(id)
                .subscribe((data) => {
                    this.modalClose();
                    this.partners.splice(index, 1);
                    this.ngOnInit();
                });
        });
    }

    modalClose() {
        this.modalDelete.close();
    }
}
