import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { PartnerModel, PartnerUserModel } from "../../../../../shared/models/partner";
import { PartnersService } from "../../../../../core/services/partners.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-partneradd",
    templateUrl: "./partner-add.component.html",
    styleUrls: ["./partner-add.component.scss"],
})

export class PartnerAddComponent implements OnInit {
    addPartner: PartnerModel = new PartnerModel();
    managers: PartnerUserModel[] = [];
    trainees: PartnerUserModel[] = [];
    stockUsers: PartnerUserModel[] = [];
    mobileUsers: PartnerUserModel[] = [];
    modalLoad: NgbModalRef;
    errors: string[] = [];
    partnerUserAdapter = PartnerUserModel.multiselectAdapter();

    constructor(private partnersService: PartnersService,
                private t: TranslateService,
                private router: Router,
                private _modal: NgbModal) {
    }


    createPartner() {

        let newPartner = {
            name: this.addPartner.name,
            managers: this.addPartner.managers,
            trainees: this.addPartner.trainees,
            stockUsers: this.addPartner.stockUsers,
            mobileUsers: this.addPartner.mobileUsers
        };

        this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});

        this.partnersService.createPartner(newPartner)
            .subscribe(data => {
                this.modalClose();
                this.router.navigateByUrl('home/partners');
            }, err => {
                this.modalClose();
                this.errors = [this.t.instant("partner-add.error")];
            });
    }

    modalClose() {
        this.modalLoad.close();
    }

    ngOnInit(): void {
        this.partnersService.getAllManagers()
            .subscribe((data: any) => {
                this.managers = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            });

        this.partnersService.getAllTrainees()
            .subscribe((data: any) => {
                this.trainees = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            });

        this.partnersService.getAllStockUsers()
            .subscribe((data: any) => {
                this.stockUsers = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            });

        this.partnersService.getAllMobileUsers()
            .subscribe((data: any) => {
                this.mobileUsers = data.data
                    .map(res => new PartnerUserModel(res))
                    .sort(PartnerUserModel.defaultSort)
            });
    }
}
