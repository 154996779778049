import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Login } from 'src/app/shared/models/login';
import { AuthService } from 'src/app/core/authentication/auth.service';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { UserRole } from 'src/app/shared/models/userRole';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    login: Login = new Login();
    loading: boolean = false;
    modalLoad: NgbModalRef;
    reset: boolean;
    userRole = new UserRole();

    constructor(public _router: Router,
                private _auth: AuthService,
                private _modal: NgbModal) {
    }

    ngOnInit() {
        this.clearAllCookies();
    }

    private clearAllCookies() {
        let c = document.cookie.split("; ");
        for (let i in c) {
            let cookie = /^[^=]+/.exec(c[i]);
            if (cookie == null || cookie.length == 0) {
                return;
            }
            document.cookie = cookie[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    }

    private async navigateAfterLogin() {
        const isStockUser = this.userRole.stockUserRoles.includes(this._auth.getRole());
        if (isStockUser) {
            await this._router.navigate(['/home/salestools/stocklist/upload']);
        } else {
            await this._router.navigate(['/dashboard']);
        }
    }

    onLoggedin() {
        this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});
        this._auth.obtainAccessToken(this.login, () => {
            this.reset = this._auth.reset;
            console.log(this.reset);
            this.modalLoad.close();
            if (this.reset === true) {
                this._router.navigate(['/password/update', this._auth.uuid]);
            }
            else {
                this.navigateAfterLogin();
            }
        });
    }
}
