import { Component, OnInit } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { Attr } from 'src/app/shared/models/wheels/objectWheel/attr';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { CustomModalComponent } from "src/app/shared/components/modals/custom-modal/custom-modal.component";
import { DeletePopupComponent } from 'src/app/shared/components/popups/delete-popup/delete-popup.component';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  listCategories: Attr[] = new Array(new Attr);
  modalConfirm: NgbModalRef;
  modalDelete: NgbModalRef;
  searchText: string = "";
  uspLang: string = 'EN';

  constructor(
    private categoriesService: CategoriesService,
    private router: Router,
    private _modal: NgbModal,
    private _translate: TranslateService) { }

  ngOnInit() {
    this.categoriesService.getAttrCategory()
      .subscribe(data => {
        this.listCategories = data.data;
        //console.log(data);
      });
  }

  createCategory() {
    this.router.navigate(["/dashboard/home/categories/add"]);
  }

  deleteCategory(id: string, index: number) {
    this.modalConfirm = this._modal.open(CustomModalComponent, { backdrop: "static", keyboard: false });
    this.modalConfirm.componentInstance.messageTitle =
      // "Are you sure you want to delete this item?";
    this._translate.instant('categories-list.messageTitle');
    this.modalConfirm.componentInstance.messageDescription =
      // "This action cannot be ";
    this._translate.instant('categories-list.messageDescription');

    this.modalConfirm.result.then((result) => {
      if (result === true) {
        this.modalDelete = this._modal.open(DeletePopupComponent, { backdrop: "static", keyboard: false });
        this.categoriesService.getAttrDeleteCategory(id)
          .subscribe((data) => {
            this.modalClose();
            this.listCategories.splice(index, 1);
            this.ngOnInit();
          })
      }
    });
  }

  modalClose() {
    this.modalDelete.close();
  }

}
