import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss']
})
export class InputFileComponent {

  @Input()
  file: File;

  @Output()
  fileChange = new EventEmitter<File>()

  @Input()
  fileTypes: string[] = [];

  @Input()
  id: string;

  fileBrowseHandler(files: File[]) {
    this.fileChange.emit(files[0]);
  }
}
