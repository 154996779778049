import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { ValidPopupComponent } from 'src/app/shared/components/popups/valid-popup/valid-popup.component';
import { ErrorPopupComponent } from 'src/app/shared/components/popups/error-popup/error-popup.component';
import { LoadingPopupComponent } from 'src/app/shared/components/popups/loading-popup/loading-popup.component';
import { ProductsService } from 'src/app/core/services/products.service';

@Component({
  selector: 'app-wheel-file',
  templateUrl: './wheel-file.component.html',
  styleUrls: ['./wheel-file.component.scss']
})
export class WheelFileComponent {

  isHovering: boolean;
  files: any[] = [];
  modalLoad: NgbModalRef;

  //On file drop handler
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  //Handle file from browsing
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  //Delete file from files list
  //@param index (File index)
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  //Convert Files list to normal array list
  //@param files (Files List)
  prepareFilesList(file) {
    for (const item of file) {
      item.progress = 0;
      this.files.length === 1;
      if (this.files.length < 1) {
        this.files.push(item);
      } else {
        let modal = this._modal.open(ErrorPopupComponent);
        modal.componentInstance.messageTitle = 
        // 'Maximum number of Excel files exceeded.';
        this._translate.instant('wheel-file.messageTitle2');
        modal.componentInstance.messageDescription = 
        // 'Only a single Excel file can be uploaded.';
        this._translate.instant('wheel-file.messageDescription2');
      }
    }
    const fileTypes = ['.xls', '.xlsx'];
    return fileTypes.includes(file.type);
  }

  //Save file
  saveFileDyn() {
    let formData = new FormData();
    for (let i in this.files) {
      formData.append("file", this.files[i]);
    }
    // let xhr = new XMLHttpRequest();
    // let urlDyn = "http://3.127.149.0:8080/blob/dynamic";
    // xhr.open("POST", urlDyn);
    // xhr.send(formData);
    this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});
    this.pservice.uploadFileDyn(formData).subscribe((res) => {
      console.log('Response:', res);
      this.modalClose();
      let modal = this._modal.open(ValidPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'Successful upload!';
      this._translate.instant('wheel-file.messageTitle');
      modal.componentInstance.messageDescription = 
      // 'The file has been successfully uploaded.'
      this._translate.instant('wheel-file.messageDescription');
      this._router.navigateByUrl('home/wheels');
    }, (err) => {
      this.modalClose();
      let modal = this._modal.open(ErrorPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'The file is invalid.';
      this._translate.instant('wheel-file.messageTitle1');
      modal.componentInstance.messageDescription = 
      // 'Please check the file type and try again.';
      this._translate.instant('wheel-file.messageDescription1');
    });
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  constructor(private _route: ActivatedRoute,
    private _router: Router,
    private _modal: NgbModal,
    private pservice: ProductsService,
    private _translate: TranslateService
    ) {
}

  //Commented HTML methods to save and upload files to a server
  saveFile() {
    // console.log("Upload");
    let formData = new FormData();
    for (let i in this.files) {
      formData.append("file", this.files[i]);
    }
    this.modalLoad = this._modal.open(LoadingPopupComponent, {backdrop: "static", keyboard: false});
    this.pservice.uploadFile(formData).subscribe((res) => {
      console.log('Response:', res);
      this.modalClose();
      let modal = this._modal.open(ValidPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'Successful upload!';
      this._translate.instant('wheel-file.messageTitle');
      modal.componentInstance.messageDescription = 
      // 'The file has been successfully uploaded.'
      this._translate.instant('wheel-file.messageDescription');
      this._router.navigateByUrl('home/wheels');
    }, (err) => {
      this.modalClose();
      let modal = this._modal.open(ErrorPopupComponent);
      modal.componentInstance.messageTitle = 
      // 'The file is invalid.';
      this._translate.instant('wheel-file.messageTitle1');
      modal.componentInstance.messageDescription = 
      // 'Please check the file type and try again.';
      this._translate.instant('wheel-file.messageDescription1');
    });
  }

  modalClose() {
		this.modalLoad.close();
  }
  
  // downloadFileStatic(){
  //   let link = document.createElement("a");
  //   link.download = "pilot 20 static data 2_0 - XLight Laith June 1";
  //   link.href = "assets/excel/pilot 20 static data 2.0 - XLight Laith June 1.xlsx";
  //   link.click();
  // }

  // downloadFileDinamic(){
  //   let link = document.createElement("a");
  //   link.download = "Dynamic_Sheet_Sizes_Final_XLight";
  //   link.href = "assets/excel/Dynamic_Sheet_Sizes_Final_XLight.xlsx";
  //   link.click();
  // }

  ngOnInit() {
  }  
}
