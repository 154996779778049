<div class="container-fluid">
	<form (ngSubmit)="edit(f)" #f="ngForm">
		<div class="d-md-flex justify-content-md-end">
			<div>
				<button class="btn btn-outline-success" type="button" aria-hidden="true"
					(click)="activate(editUser.uuid)"
					*ngIf="editUser.status == 'inactive'">{{"user-edit.activate" | translate}}</button>
				<button class="btn btn-outline-danger" type="button" aria-hidden="true"
					(click)="deactivate(editUser.uuid)"
					*ngIf="editUser.status == 'active'">{{"user-edit.deactivate" | translate}}</button>
			</div>
			<div>
				<button class="btn btn-dark ml-2" type="button" aria-hidden="true"
					(click)="reset(editUser.uuid)">{{"user-edit.reset" | translate}}</button>
			</div>
		</div>
		<div class="form-group mb-md-5">
			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-6">
					<label>{{"user-edit.username" | translate}}</label>
					<input [(ngModel)]="editUser.username" name="username" type="text" class="form-control" required
						readonly />
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user-edit.email" | translate}}</label>
					<input [(ngModel)]="editUser.email" name="email" type="email" class="form-control" required />
				</div>
			</div>

			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-6">
					<label>{{"user-edit.name" | translate}}</label>
					<input [(ngModel)]="editUser.name" name="name" type="text" class="form-control" required />
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user-edit.surname" | translate}}</label>
					<input [(ngModel)]="editUser.surname" name="surname" type="text" class="form-control" required />
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6">
					<label>{{"user-edit.role" | translate}}</label>
					<select [(ngModel)]="editUser.role" name="role" class="form-control form-control-lg"
						#formSelect="ngModel" *ngIf="cookieRole==adminRole" required>
						<option *ngFor="let Role of userRole.adminRoles" [value]="Role">
							{{Role}}</option>
					</select>

					<select [(ngModel)]="editUser.role" name="role" class="form-control form-control-lg"
						#formSelect="ngModel" *ngIf="cookieRole!=adminRole" required>
						<option *ngFor="let roles of userRole.roles" [value]="roles">
							{{roles}}</option>
					</select>
				</div>

				<div class="flex-row col-md-6">
					<label>{{"user-edit.application" | translate}}</label>
					<select [(ngModel)]='editUser.application' name="application" class="form-control form-control-lg"
						required>
						<option *ngFor="let application of userApplications" [value]="application">{{application}}
						</option>
					</select>
				</div>
			</div>

			<div class="d-md-flex justify-content-between">
				<div class="flex-row col-md-6">
					<app-multiselect label="{{'user-edit.countries' | translate}}" [availableOptions]="userCountries"
						[(value)]="selectedCountries" [adapter]="countryAdapter">
					</app-multiselect>
				</div>

				<div class="flex-row col-md-6">
					<app-multiselect label="{{'user-edit.regions' | translate}}" [availableOptions]="userRegions"
						[(value)]="selectedRegions" [adapter]="regionAdapter">
					</app-multiselect>
				</div>
			</div>

			<div class="d-md-flex justify-content-end">
				<div class="flex-row col-md-6">
					<app-multiselect label="{{'user-edit.regionsUploadAndShare' | translate}}" [availableOptions]="userRegionsUploadAndShare"
					    [(value)]="selectedRegionsUploadAndShare" [adapter]="regionUploadAndShareAdapter">
					</app-multiselect>
				</div>
			</div>
			
			<div class="d-md-flex justify-content-start">
				<p></p>
			</div>
			<div class="d-md-flex justify-content-start">
				<div class="flex-row col-md-1">
					<div class="buttonSave">
						<button type="submit" class="btn btn-dark" [disabled]="!f.form.valid">
							{{"user-edit.save" | translate}}</button>
					</div>
				</div>
				<div class="flex-row col-md-1">
					<div class="buttonCancel">
						<button type="button" class="btn btn-outline-danger" [routerLink]="['/dashboard/home/users/list']">
							{{"user-add.cancel" | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</form>

	<div class="alert alert-danger" role="alert" *ngIf="errors">
		<div *ngFor="let error of errors">
			<h5>{{ error }}</h5>
		</div>
	</div>
</div>
