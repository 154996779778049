import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Generic} from '../../../shared/models/generic';
import {AssessmentCommercial} from '../../../shared/models/commercial/assessment-commercial.model';
import {environment} from '../../../../environments/environment';
import {LocalStorageKey, LocalStorageManager} from '../local-storage-manager';

@Injectable({
  providedIn: 'root'
})
export class AssessmentCommercialService {

  constructor(private http: HttpClient) { }

  headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + LocalStorageManager.get(LocalStorageKey.ACCESS_TOKEN)
  });

  private API = environment.baseUrlCMS;
  private ASSESSMENT = '/commercial/assessment';

  private static createParams(filter: any): HttpParams {
    let params = new HttpParams();
    params = params.append('page', filter.page.toString());
    params = params.append('size', filter.size.toString());

    if (filter.query !== '')
      params = params.append('query', filter.query);

    return params;
  }

  getAssessment(filter: any): Observable<Generic<AssessmentCommercial[]>> {
    const params = AssessmentCommercialService.createParams(filter);

    return this.http.get<Generic<AssessmentCommercial[]>>(this.API + this.ASSESSMENT, { params, headers: this.headers });
  }

  deleteAssessment(uuid: string): Observable<Generic<AssessmentCommercial>> {
    return this.http.delete<Generic<AssessmentCommercial>>(this.API + this.ASSESSMENT + '/' + uuid, { headers: this.headers });
  }

  getAssessmentById(uuid: number): Observable<Generic<AssessmentCommercial>> {
    return this.http.get<Generic<AssessmentCommercial>>(this.API + this.ASSESSMENT+ '/' + uuid, { headers: this.headers });
  }

  getUsersAssessmentBlob(): Observable<Blob> {
    return this.http.get(
        this.API + this.ASSESSMENT + '/export',
        {
          headers: this.headers,
          observe: 'body',
          responseType: 'blob'
        }
    );
  }
}
